import { Injectable } from '@angular/core';
import { Config } from '../../services/config/config.service';
import { LocalStorage } from '../../services/utils/localstorage/localstorage.service';
import { setInitialState } from './init-state-from-localstorage.actions';
import { AppState } from '../../NGRX/core.state';
import { Store } from '@ngrx/store';

const APP_PREFIX = 'ANMS-';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    private _localStorage: LocalStorage,
    private _config: Config,
    private store: Store<AppState>
  ) {
    this.initInitialState();
  } 

  initInitialState(): any {
    if(!this._config.CONFIG.isMobile) return;
    this.newLoadInitialState().then();
  }

  async newLoadInitialState(): Promise<any> {
    const asyncInitialStateKeys = await this._localStorage.getKeys();
    let promiseArray: any[] = [];

    asyncInitialStateKeys
                         .filter((key: string) => key.includes(APP_PREFIX))
                         .forEach((storageKey: string) => {
                            promiseArray.push(this._localStorage.get(storageKey));
                         })
    // for each and filter keys to include APP_PREFIX
    
    // make array and do  async promise all - and test when nothing is saved
    const localstoragePiecesOfState = await Promise.all(promiseArray);
    
    let newState = {};
    asyncInitialStateKeys// can comment filter for the moment to see if weget values at all
    .filter((key: string) => key.includes(APP_PREFIX))
    .forEach((storageKey: string, index: number) => {
       const stateKey = storageKey.split('-')[1];
       newState[stateKey] = localstoragePiecesOfState[index];
    });
    this.store.dispatch(setInitialState({initialState: newState}));
  }

  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          // .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );
        
        let currentStateRef = state;

        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(
              localStorage.getItem(storageKey) || '{}'
            );
            return;
          }

          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {}); 
  }

  setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    if(!this._config.CONFIG.isMobile) return;
    this._localStorage.set(`${APP_PREFIX}${key}`, value).then();
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`) || '{}');
  }

  removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
    if(!this._config.CONFIG.isMobile) return;
    this._localStorage.remove(`${APP_PREFIX}${key}`).then();
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    const retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}
