import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState, RouterStateUrl, selectRouter } from '../../NGRX/core.state';
import { ConfigurationState } from '../configuration/configuration.models';
import { MicrositePoolsState, PoolsMenuScope, RacingCodes, SportsCodes } from './pool.models';
import * as _ from 'lodash-es';
import { RouterReducerState } from '@ngrx/router-store';
import { PoolList } from '../../models/pool-list';
import { selectConfigurationState } from '../configuration/configuration.selectors';
import { PoolDisplayGroup } from '../../models/pool-display-group';

export const selectMicrositePoolsState = createFeatureSelector<
  AppState,
  MicrositePoolsState
>('micrositePools');

export const selectPools = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state
);


export const selectPoolsMenu = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.menu
);

export const selectCurrentPool = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.currentPool
);

export const selectFeaturedPool = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.featuredPool
)

export const selectHeadlinePool = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.headlinePools
)

export const selectCurrentPoolSelections = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => (state.currentPoolSelections && state.currentPool?.id) ? state.currentPoolSelections[state.currentPool.id] : undefined
);

export const selectCurrentGTSelections = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => (state.currentGTSelections && state.currentPool?.id) ? state.currentGTSelections[state.currentPool.id] : undefined
);

export const selectPoolFilters = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.filters
);

export const selectPoolExtraModel = createSelector(
  selectMicrositePoolsState,
  (state: MicrositePoolsState) => state.poolsExtraModel
);


export const selectPoolListMenuByView = createSelector(
  selectMicrositePoolsState,
  selectConfigurationState,
  (pools: MicrositePoolsState, configuration: ConfigurationState): Array<SportsCodes | RacingCodes | string> => {
    if(_.isEmpty(pools.menu)) return;
    const displayGroups: PoolDisplayGroup[] = pools.menu[configuration.displayState.view].display_groups;
    const sportCodeList: Array<SportsCodes | RacingCodes | string> = _.uniq(_.map(displayGroups
      .filter(displayGroup => displayGroup.pools.length > 0),
      (dp) => dp.sport_code)
    );
    return sportCodeList;
  }
);

export const selectSportCode =
createSelector(
  selectMicrositePoolsState,
  selectConfigurationState,
  selectRouter,
  (pools: MicrositePoolsState, configuration: ConfigurationState, router: RouterReducerState<RouterStateUrl>): PoolsMenuScope.SportCode => {
    let sportCode = {};
    sportCode[configuration.displayState.view] = router.state.params.sport_code ? router.state.params.sport_code.replace('-', '_').toUpperCase() : pools.currentSportCode[configuration.displayState.view];
    return sportCode;
  }
);

export const getPoolsMenuByView =
createSelector(
  selectMicrositePoolsState,
  selectConfigurationState,
  selectRouter,
  (pools: MicrositePoolsState, configuration: ConfigurationState, router: RouterReducerState<RouterStateUrl>): PoolList => {

    if (!configuration.displayState.view || !pools.menu) {
      return;
    }
    /**
     * Refactored for performance improvement
     *
     * Removed the unnecessary creation of an empty sportCode object and directly calculated the sportCode value.
     * Simplified the calculation of subCode using optional chaining and nullish coalescing operators.
     * Avoided unnecessary variable assignments for poolListWithGroupsAvailable.
     * Simplified the setting of groups_availables based on conditions.
     */
    const view = configuration.displayState.view;
    const sportCode = router.state.params.sport_code?.replace(/-/g, '_').toUpperCase() || pools.currentSportCode[view];

    const subCode = router.state.params.sub_code?.replace(/-/g, '_').toUpperCase() || null;

    const poolListWithGroupsAvailable: PoolList = _.cloneDeep(pools.menu[view]) || {};

    try {
      if (poolListWithGroupsAvailable.display_groups) {
        poolListWithGroupsAvailable.groups_availables = poolListWithGroupsAvailable.display_groups
          .filter(el => el.sport_code === sportCode)
          .filter(el => !subCode || el.pool_type === subCode);
      } else {
        poolListWithGroupsAvailable.groups_availables = [];
      }

      return poolListWithGroupsAvailable;
    } catch {
      poolListWithGroupsAvailable.groups_availables = [];
      return poolListWithGroupsAvailable;
    }



    // if(!configuration.displayState.view || !pools.menu) return;
    //
    // let sportCode = {};
    // sportCode[configuration.displayState.view] = router.state.params.sport_code ? router.state.params.sport_code.replace('-', '_').toUpperCase() : pools.currentSportCode[configuration.displayState.view];
    //
    // const subCode: string | null = router.state.params.sub_code ? router.state.params.sub_code.replace('-', '_').toUpperCase() : null
    //
    // let poolListWithGroupsAvailable: PoolList;
    // poolListWithGroupsAvailable = _.cloneDeep(pools.menu[configuration.displayState.view] || {});
    // const groups: PoolDisplayGroup[] = poolListWithGroupsAvailable?.display_groups;
    // poolListWithGroupsAvailable['groups_availables'] = (!groups || !groups.length) ? [] : groups
    // .filter(el => el.sport_code === sportCode[configuration.displayState.view])
    // .filter((el: any) => subCode ? el.pool_type === subCode : true);
    // return poolListWithGroupsAvailable;
  }
);
