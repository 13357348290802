import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../core.state';

// import { selectMicrositeWebsocketsState } from '../../NGRX/core.state';
export const selectMicrositeWebsocketsState = createFeatureSelector<
  AppState, 
  MicrositeWebsocketsState
>('micrositeWebsockets');

import { MicrositeWebsocketsState } from './coreWebsockets.models';

export const selectMicrositeWebsockets = 
// undefined;
createSelector(
  selectMicrositeWebsocketsState,
  (state: MicrositeWebsocketsState) => state
);

export const selectMicrositeWebsocketsBalance = 
// undefined;
createSelector(
  selectMicrositeWebsocketsState,
  (state: MicrositeWebsocketsState) => state.balance
);

export const selectMicrositeWebsocketsEvent = 
// undefined;
createSelector(
  selectMicrositeWebsocketsState,
  (state: MicrositeWebsocketsState) => state.currentEvent
);
