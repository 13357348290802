import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Config } from './../config/config.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PoolsMenuScope } from '@cbcore/NGRX/pool/pool.models';
import { selectPoolExtraModel } from '@cbcore/NGRX/pool/pool.selectors';
import { AppState } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';

@Injectable()
export class HomepageRouteGuard implements CanActivate {
  
  private poolExtraModel$: Observable<PoolsMenuScope.PoolsExtraModel>;
  private ngDestroyed$ = new Subject();

  constructor(
    private _config: Config,
    private _router: Router,
    private store: Store<AppState>
  ) {}
  canActivate() {
    const canShowHomepage = true;

    this.poolExtraModel$ = this.store.select(selectPoolExtraModel);
    this.poolExtraModel$.pipe(
      takeUntil(this.ngDestroyed$),
      filter(poolExtraModel => poolExtraModel.ALL !== null)
    ).subscribe(poolExtraModel => {
      if (poolExtraModel) {
        this.ngDestroyed$.next();
        if (poolExtraModel.ALL !== 0) {
          return this.checkEnabled(true);
        } else {
          return this.checkEnabled(false);
        }
      } else {
        return false;
      }
    });

    return this.checkEnabled(canShowHomepage);
  }

  checkEnabled(canShowHomepage: any): boolean {
    if (canShowHomepage && this._config.CONFIG.haveHomepage) {
      return true;
    }

    this._router.navigate(['/pools']);
    return false;
  }
}
