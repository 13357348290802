import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class ReadyService {
  private $ready: ReplaySubject<any> = new ReplaySubject<any>(undefined);

  constructor() { }

  ready(): ReplaySubject<any> {
    return this.$ready;
  }

  setReady(val: any): void {
    this.$ready.next(val);
  }
}
