import { Injectable } from '@angular/core';
import { WindowService } from '../window/window.service';

@Injectable()
export class LocalStorage {
  private _window: Window;
  private prefix = '';
  // private prefix = 'cb_';
  private storage: any = null;

  constructor(private _windowService: WindowService) {
    this._window = _windowService.nativeWindow;
    if (this.checkSupport()) {
      this.storage = this._window.localStorage;
    }
  }

  getKey(key: any) {
    return `${this.prefix}${key}`;
  }

  public callGetKey(key: any) {
    return this.getKey(key);
  }

  private checkSupport(): boolean | undefined {
    try {
      if ('localStorage' in this._window && this._window.localStorage !== null) {
        this._window.localStorage.setItem(this.getKey('localstorage'), 'true');
        this._window.localStorage.removeItem(this.getKey('localstorage'));
        return true;
      } else {
        return;
      }
    } catch (e) {
      console.warn('LocalStorage is not supported in this browser.');
      return;
    }
  }

  public async set(key: string, value: any = null): Promise<any> {
    if (this.storage) {
      return this.storage.setItem(this.getKey(key), JSON.stringify(value));
    }
  }

  public async get(key: string): Promise<any> {
    if (this.storage && this.storage.getItem(this.getKey(key))) {
      try {
        return JSON.parse(this.storage.getItem(this.getKey(key)) || null);
      } catch (error) {
        return null;
      }
    }
  }

  public async remove(key: string): Promise<any> {
    if (this.storage) {
      return this.storage.removeItem(this.getKey(key));
    }
  }

  public async getKeys(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Object.keys(this.storage));
      }, 10);
    });
  }
}
