import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Config } from '../config/config.service';
import { BehaviorSubject } from 'rxjs';
import { interval } from "rxjs";

import { AppState } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
import { setWebsocketBalance, setWebsocketEvent } from '@cbcore/NGRX/coreWebsockets/coreWebsockets.actions';
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SecureWebsocketService {
  // private $subscriber: BehaviorSubject<any> = new BehaviorSubject(null);
  public socket: any;
  private channel: any = {};
  private websocketsUrl: any;
  private websocketsKey: any;
  private apiUrl: any;
  private maxRetryTimes = 5;
  private retryCount = 0;

  constructor(
    private _config: Config,
    private store: Store<AppState>
  ) {
    this.apiUrl = this._config.CONFIG.apiUrl;
    this.websocketsKey = this._config.CONFIG.websocketsKey;
    this.websocketsUrl = this._config.CONFIG.websocketsUrl;
  }

  connect(headers: any): void {
    if (!this.isConnected()) {
      const parseHeaders: any = {};
      parseHeaders['uid'] = headers.uid;
      if (this._config.CONFIG.fallbackSecureWSnonCB) {
        parseHeaders['access-token'] = 'disabled';
        parseHeaders['client'] = 'disabled';
        parseHeaders['expiry'] = 'disabled';
      } else {
        parseHeaders['access-token'] = headers['access-token'];
        parseHeaders['client'] = headers.client;
        parseHeaders['expiry'] = headers.expiry;
      }
      this.socket = new Pusher(this._config.CONFIG.websocketsKey, {
        wsHost: this._config.CONFIG.websocketsUrl,
        cluster: this._config.CONFIG.websocketCluster,
        disableStats: true,
        enabledTransports: ['ws'],
        authEndpoint: `${this.apiUrl}/pusher_auths`,
        auth: {
          headers: parseHeaders
        }
      });

      this.subscribeChannel(`private-user-${parseHeaders['uid']}`);
      this.bindAllChannelEvents(`private-user-${parseHeaders['uid']}`);
    }
  }

  subscribe(channel: string) {
    try {
      this.socket.subscribe(channel);
    } catch (e) {
      console.info('Unable to connect to: ' + channel);
    }
  }

  isConnected(): boolean {
    if (typeof this.socket !== 'undefined') {
      if (this.socket.connection.state === 'connected' || this.socket.connection.state === 'connecting') {
        return true;
      }
    }
    return false;
  }

  getChannel(channel: string): any {
    try {
      return this.socket.getChannel(channel);
    } catch (e) {
      return null;
    }
  }

  closeConnection() {
    if (this.isConnected()) {
      this.socket.disconnect();
      this.channel = {};
    }
  }

  subscribeChannel(channelName: string) {
    if(!this.channel || !this.socket) return;
    this.channel = this.socket.subscribe(channelName);
  }

  unSubscribeChannel(channelName: string) {
    if (typeof this.channel !== 'undefined') {
      this.socket.unsubscribe(channelName);
      this.channel = undefined;
    }
  }

  bindAllChannelEvents(channelName: string) {
    // we will retry 5 times the bind, because in some cases the WS is not present
    // if still fails, we stop retrying
    if (!this.socket) {
      if (this.retryCount > this.maxRetryTimes) {
        console.info('Secure WS not connected');
        return;
      }

      this.retryCount++;
      interval(500).pipe(take(1)).subscribe(() => {
        this.bindAllChannelEvents(channelName);
      })
    } else {
      this.socket.allChannels().forEach((channel: any) => {
        if (channel.subscribed === false) {
          channel.bind_global((event: any, data: any) => {

            try {
              if (channel.name.includes('private-syndicate-')) {
                this.handleCommentaryEvents(event, data);
              }
            } catch (e) {
              console.info('Commentary WS failed', e);
            }

            try {
              if (channel.name.includes('private-user-')) {
                this.handleUserEvents(event, data);
                this.handleGTEvents(event, data);

                // TODO: this one here
                if (event === 'balance_update') {
                  if (data) {
                    this.store.dispatch(setWebsocketBalance({
                      balance: data
                    }));
                  }
                }
              }
            } catch (e) {
              console.info('Private WS failed', e);
            }

            try {
              if (channel.name.includes('private-group-ticket')) {
                this.handleGTEvents(event, data);
              }
            } catch (e) {
              console.info('GT WS failed', e);
            }
          })
        }
      });
    }
  }

  handleCommentaryEvents(event, data): void {
    const basicEvents = {
      'social_create': 'SOCIAL_CREATE'
    }

    if (!basicEvents[event] !== undefined) return;

    // basically we should dispatch an event to show the refresh button
    if (basicEvents[event] === 'SOCIAL_CREATE')
      this.store.dispatch(setWebsocketEvent({
        event: basicEvents[event],
        data: data?.data
      }));


  }

  handleUserEvents(event, data): void {
    const allowCashOuts = this._config.CONFIG.allowCashOuts;
    const basicEvents = {
      'new_offer': 'USER_NEW_OFFER',
      'remove_offer': 'USER_REMOVE_OFFER'
    }

    if (!basicEvents[event] || !allowCashOuts) return;

    this.store.dispatch(setWebsocketEvent({
      event: basicEvents[event],
      data: data?.data
    }));
  }

  handleGTEvents(event: any, data: any): void {
    /* INFO UPDATE */
    const basicEvents = {
      'group_ticket_info_update': 'INFO_UPDATE',
      'new_vote': 'VOTES_UPDATE',
      'syndicate-creation': 'SYNDICATE_CREATED'
    }

    if (!basicEvents[event]) return;

    // basically we should dispatch an event to show the refresh button
    if (basicEvents[event] === 'INFO_UPDATE' ||
      basicEvents[event] === 'VOTES_UPDATE' ||
      basicEvents[event] === 'SYNDICATE_CREATED'
    )
      this.store.dispatch(setWebsocketEvent({
        event: basicEvents[event],
        data: data
      }));
  }
}
