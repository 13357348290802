import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { UtilService } from '../services/utils/utils/utils.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class PoolClient {
  public endpoint: string;
  public streaming_endpoint: string;
  public scores_endpoint: string;

  constructor(public http: HttpClient, public UtilService: UtilService, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/pools';
    this.streaming_endpoint = _config.CONFIG.streamUrl;
    this.scores_endpoint = _config.CONFIG.mvpPointsUrl;
  }

  list(ungrouped: boolean = false, model: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('ungrouped', 'true')
      .set('includes', 'legs,legs.sport_event,legs.sport_event.competitors,legs.sport_event.sport_event_competitors');

    Object.keys(model.filters).forEach((key, index) => {
      if (model.filters[key]) {
        params = params.set(`filters[${key}]`, model.filters[key]);
      }
    });

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getMenu(): Observable<any> {
    const params = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', '');
    return this.http.get(this.endpoint + '/menu', { params })
      .pipe(
        map(data => {
          return data
        }),
        catchError(err => {
          return throwError(err)
        })
      );
  }

  get(id: number): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('no_serializer', 'true');

    return this.http.get(this.endpoint + '/' + id, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  widget(): Observable<any> {
    return this.http.get(this.endpoint + '/widget')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  featured(): Observable<any> {
    return this.http.get(this.endpoint + '/featured')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  recommended(): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('num_pools', '1');

    return this.http.get(this.endpoint + '/recommended')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  headline(): Observable<any> {
    const params = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'prizes,smart_pick_perms');

    return this.http.get(this.endpoint + '/headline', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  headlines(): Observable<any> {
    const params = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'prizes,smart_pick_perms')

    return this.http.get(this.endpoint + '/headlines', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getWidgetPool(): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'prizes,smart_pick_perms');

    return this.http.get(this.endpoint + '/paper_widget', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getSmartPick(id: number, lines: number): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('lines', lines.toString());

    return this.http.get(this.endpoint + '/' + id + '/smart_pick', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getStats(id: number): Observable<any> {
    return this.http.get(this.endpoint + '/' + id + '/stats')
      .pipe(
        map(data => this.UtilService.toObject(data, 'id')),
        catchError(err => throwError(err))
      );
  }

  getScores(): Observable<any> {
    let model = {
      "offence": { "passing_td": 4, "passing_yards_25": 1, "passing_yards_25_extra": 0.04, "passing_yards_300": 3, "interception": -1, "rushing_td": 6, "rushing_yards_10": 1, "rushing_yards_10_extra": 0.1, "rushing_yards_100": 3, "receiving_td": 6, "yard_receiving_10": 1, "yard_receiving_10_extra": 0.1, "yard_receiving_100": 3, "reception": 1, "punt": 6, "fumble_lost": -1, "conversion_2_pt": 2, "offensive_fumble_recovery_td": 6 },
      "defense": { "sack": 1, "interception": 2, "fumble_recovery": 2, "punt": 6, "interception_return_td": 6, "fumble_recovery_td": 6, "blocked_punt": 6, "safety": 2, "blocked_kick": 2, "conversion_2_pt": 2, "points_allowed_0": 10, "points_allowed_1_6": 7, "points_allowed_7_13": 4, "points_allowed_14_20": 1, "points_allowed_21_27": 0, "points_allowed_28_34": -1, "points_allowed_35": -4 }
    };

    return of(model);
    // return this.http.get(this.scores_endpoint + '/scores.json', { withCredentials: false })
    //   .map(data => data)
    //   .catch(err => Observable.throw(err));
  }

  getExternalContent(id: number): Observable<any> {
    return this.http.get(this.endpoint + '/' + id + '/external_content')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getStreamingData(model: any): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('username', model.username.toString())
      .set('merchant', model.merchant_code.toString());

    return this.http.get(this.streaming_endpoint + '/' + model.streamId, { params: params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
