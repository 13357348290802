import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Location } from '@angular/common';

import {Config} from "@cbcore/services/config/config.service";
import {ImageClient} from "@cbcore/client/image";
import {ImageService} from "@cbcore/services/utils/image/image.service";
import {ManagerClient} from "@cbcore/client/manager";
import * as _ from 'lodash-es';
import { CbModalService} from "@cbui/helpers/components/modal/modal.service";
import { AppState, callMicrositeSessions } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
import { getManager } from '@cbcore/NGRX/manager/manager.actions';

@Component({
  selector: 'syndicate-profile-create-component',
  templateUrl: './syndicate-profile-create.html'
})
export class SyndicateProfileCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  public $subscribers: any = {};
  public defaultManager: any = {};
  public imageList!: any;
  public showSelector!: boolean;
  public isProfileImageUpdated: boolean;
  public error!: string | null | undefined;
  public haveManagerTwitter: boolean;
  public viewList: boolean = false;
  public avatarCropped: File | null = null;
  public isCropping: boolean | null = null;
  public android: boolean | null = null;

  public imagesUrl: string;
  public fallbackMangerImage: string;

  public step: number = 1;

  @ViewChild('username') usernameInput: ElementRef;

  @Output() closingModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public _managerClient: ManagerClient,
    public _imageClient: ImageClient,
    public _config: Config,
    public _imageService: ImageService,
    public store: Store<AppState>,
    @Inject(CbModalService) public cbModalService: CbModalService
  ){
  }

  ngOnInit(): void {
    this.haveManagerTwitter = this._config.CONFIG.haveManagerTwitter;
    this.imagesUrl = this._config.CONFIG.imagesUrl;
    this.fallbackMangerImage = this._config.CONFIG.fallbackSyndicateProfileImage;
    this.$subscribers.images = this._imageClient.getConfig().subscribe(
      data => this.updateImages(data)
    );
  }

  ngAfterViewInit(): void {
    this.usernameInput.nativeElement.focus();
  }

  updateImages(data: any): void {
    this.imageList = this._imageService.createImageList(this._config.CONFIG.imagesUrl, data);
    this.defaultManager.picture_url =
      (typeof this.defaultManager.picture_url === 'undefined') ? _.sampleSize(this.imageList, 1) : this.defaultManager.picture_url;
  }

  cancelCreate(): void {
    const mdl = this.cbModalService.get('createProfile');
    mdl.close();
  }

  selectImage(image: any, toggle: boolean): void {
    this.defaultManager.picture_url = image;
    this.avatarCropped = null;
    if (toggle) {
      this.showSelector = !this.showSelector;
    }
    this.isProfileImageUpdated = true;
  }

  setFallbackImage(): void {
    this.defaultManager.picture_url = this.imagesUrl + this.fallbackMangerImage;
  }


  ngOnDestroy(): void {
    for (let key in this.$subscribers) {
      this.$subscribers[key].unsubscribe();
    }
  }

  createManager(): void {
    this.error = undefined;
    let regex: any = new RegExp('^[\sA-z0-9_ -]{1,32}$');

    if (typeof this.defaultManager.username === 'undefined') {
      this.error = 'PROFILE_EMPTY_USERNAME';
      return;
    }

    if (!regex.test(this.defaultManager.username)) {
      this.error = 'PROFILE_USERNAME_INVALID';
      return;
    }

    this.defaultManager.username = this.removeEmojis(this.defaultManager.username);

    // Payload and request
    let payload: any = {
      manager: {
        username: String(this.defaultManager.username).trim(),
        twitter_username: this.defaultManager.twitter_username,
        // tslint:disable-next-line: max-line-length
        picture_url: this.avatarCropped ? undefined : (this.isProfileImageUpdated) ? this.defaultManager.picture_url : _.shuffle(this.imageList)[0],
        picture: this.avatarCropped || undefined
      }
    };

    this._managerClient.create(payload).subscribe(result => {
      this.defaultManager = result;
      this.step++;
    }, error => {
      this.error = error.code;
    });
  }

  updateManager(): void {
    this.error = undefined;

    let payload: any = {
      id: this.defaultManager.id,
      manager: {
        picture_url: this.defaultManager.picture_url,
        twitter_username: this.defaultManager.twitter_username,
        picture: this.avatarCropped || undefined
      }
    };

    this._managerClient.update(payload).subscribe(result => {
      this.closingModal.emit(true);
      this.store.dispatch(callMicrositeSessions());
      // this._profileMiddleware.load(this.defaultManager.id);
      this.store.dispatch(getManager({id: this.defaultManager.id}));
      this.cancelCreate();
    }, error => {
      this.error = error.code;
    });
  }

  removeEmojis(string: string): string {
    // tslint:disable-next-line: max-line-length
    const regex: RegExp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }

  setCroppedImage(event: any): void {
    this.defaultManager.picture_url = event;
    this.avatarCropped = event;
    this.isProfileImageUpdated = true;
    this.showSelector = false;
  }

  manageCropping(e: any): void {
    this.isCropping = e;
  }

}
