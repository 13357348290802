import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OfferClient} from "@cbcore/client/offer";
import { DefaultNotificationComponent } from '../default';

@Component({
  selector: 'offer-notification-component',
  templateUrl: './offer.html'
})
export class OfferNotificationComponent extends DefaultNotificationComponent {
  constructor(public _offerClient: OfferClient, public _router: Router) {
    super();
    // tslint:disable-next-line:no-empty
    this._offerClient.get().subscribe(() => { }, () => { });
  }

  navigateToTickets(): void {
    this._router.navigate(['/', 'tickets']);
  }
}
