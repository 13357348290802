import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StakeService {
  private selectedStake: string | undefined;
  private activeCrossSell: boolean = false;
  private $subscriber: BehaviorSubject<any>;

  constructor () {
    this.$subscriber = new BehaviorSubject(this.selectedStake);
  }

  getStake() {
    return this.$subscriber;
  }

  setStake(stake: string, isCrossSell: boolean = false): void {
    this.activeCrossSell = isCrossSell || false;
    this.selectedStake = stake;
    this.$subscriber.next(this.selectedStake);
  }

  clearStake(): void {
    if (!this.activeCrossSell) {
      this.selectedStake = undefined;
      this.$subscriber.next(this.selectedStake);
    }
  }
}
