import { AfterContentInit, Directive, ElementRef, Input, InjectionToken } from '@angular/core';
import { AppState, track } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
export let loadingButton: any = new InjectionToken('btnEl');

@Directive({
  selector: '[track]'
})

export class TrackingDirective implements AfterContentInit {
  @Input('track') trackingEvent: any;

  btnEl: HTMLElement;

  constructor(
    el: ElementRef,
    // private _trackingService: TrackingService
    private store: Store<AppState>
  ) {
    this.btnEl = el.nativeElement;
  }

  ngAfterContentInit(): void {
    this.btnEl.addEventListener('click', (trackingEvent) => {
      setTimeout(() => {
        this.trackEvent(trackingEvent);
      }, 1)
    });
  }

  trackEvent(trackingEvent: any): void {
    if (!trackingEvent)
      return;

    const trackingObject = {
      'event': this.trackingEvent.event ? this.trackingEvent.event : 'micrositeEvent',
      'eventCategory': this.trackingEvent.eventCategory ? this.trackingEvent.eventCategory : null,
      'eventAction': this.trackingEvent.eventAction ? this.trackingEvent.eventAction : null,
      'eventLabel': this.trackingEvent.eventLabel ? this.trackingEvent.eventLabel : null,
      'eventValue': this.trackingEvent.eventValue ? this.trackingEvent.eventValue : null
    }

    if (this.trackingEvent.isTracking) {
      this.store.dispatch(track({event: trackingObject}));
    }
  }
}
