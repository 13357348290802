import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReservedTicketsClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/reserved_tickets';
  }

  get(code: string): Observable<any> {
    return this.http.get(this.endpoint + '/' + code)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  reserve(body: object): Observable<any> {
    return this.http.post(this.endpoint, body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  sendEmail(code: string, body: any): Observable<any> {
    return this.http.post(this.endpoint + '/' + code + '/send_email_confirmation', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  place(code: string, body: any): Observable<any> {
    return this.http.post(this.endpoint + '/' + code + '/place', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
