import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()

@Pipe({ name: 'pairs' })

export class PairsPipe implements PipeTransform {
  constructor() {}
  transform(obj: any): any[] {
    if (Array.isArray(obj) || !this.isObject(obj)) {
      return obj;
    }

    return Object.entries(obj);
  }

  isObject(value: any) {
    return value !== null && typeof value === 'object';
  }
}
