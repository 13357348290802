import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {DateFormatPipe} from "@cbcore/pipes/date/date.pipe";
import { interval } from 'rxjs';

@Component({
  selector: 'cb-countdown',
  template: `<span [ngClass]="{'active': isTicker, 'started': isStarted, 'last-hour': isLastHour}">{{ displayTime }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CountdownAnimationComponent implements OnDestroy {
  public format: string = '{mm}:{ss}';
  public isTicker: boolean = false;
  public isStarted: boolean = false;
  public isLastHour: boolean = false;

  private runOnce: boolean = true;
  private _initialTime!: any;
  private _time!: any;
  private _subscribe$!: any;
  private _source$!: any;

  @Input()
  public set time(value: any) {
    this._initialTime = value;
    this._time = new Date(value);
    this._startTimer();
  }

  public get delta(): number {
    const date: any = new Date();
    const diff: number = Math.floor(this._time.getTime() - date.getTime());
    const seconds: any = Math.floor(diff / 1000);
    return Math.max(0, seconds);
  }

  showDefault(): string {
    return this._datePipe.transform(this._initialTime, 'short');
  }

  public get displayTime(): string {
    let days: number, hours: number, minutes: number, seconds: number, delta: number = this.delta, time: string = this.format;

    days = Math.floor(delta / 86400);
    delta -= days * 86400;
    hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    seconds = delta % 60;


    if (days < 1 && hours < 1 && ((minutes > 0) || (minutes === 0 && seconds > 0) || (minutes > 0 && seconds === 0))) {
      time = time.replace('{mm}', minutes < 10 ? '0' + minutes : (<any>minutes));
      time = time.replace('{ss}', seconds < 10 ? '0' + seconds : (<any>seconds));
      this.isTicker = true;
    }

    if (((days >= 0 && hours >= 1 && minutes >= 0 && seconds >= 0) || (days >= 1))) {
      time = this.showDefault();
    }

    if (days < 1 && hours < 1 && minutes >= 0 && seconds >= 0) {
      this.isLastHour = true;
    }

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      time = '00:00';
      this.isStarted = true;
      this.isLastHour = false;
    }
    return time;
  }

  constructor(private _changeDetector: ChangeDetectorRef, private _datePipe: DateFormatPipe) { }

  ngOnDestroy(): void {
    this._stopTimer();
  }

  private _startTimer(): void {
    if (this.delta <= 0 && !this.runOnce) {
      this.runOnce = false;
      return;
    }
    if (this.isStarted) {
      return;
    }
    this._stopTimer();
    this._source$ = interval(1000);
    this._subscribe$ = this._source$.subscribe(() => {
      this._changeDetector.detectChanges();
      if (this.delta <= 0 && !this.runOnce) {
        this.runOnce = false;
        this._subscribe$.unsubscribe();
      }
    });
  }

  private _stopTimer(): void {
    if (this._subscribe$) {
      this._subscribe$.unsubscribe();
    }
    if (this._source$) {
      this._source$ = undefined;
    }
  }

}
