import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationState } from '@cbcore/NGRX/configuration/configuration.models';
import { AppState, selectConfiguration, selectMicrositeSessions, selectMyManager } from '@cbcore/NGRX/core.state';
import { MicrositeSessionsState } from '@cbcore/NGRX/coreAuth/coreAuth.models';
import { getManager } from '@cbcore/NGRX/manager/manager.actions';
import { ManagersScope } from '@cbcore/NGRX/manager/manager.model';
import {CalculationsService} from "@cbcore/services/utils/calculations/calculations.service";
import {Config} from "@cbcore/services/config/config.service";
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'syndicate-profile-rebate-component',
  templateUrl: './syndicate-profile-rebate.html'
})

export class SyndicateProfileRebateComponent implements OnInit, OnDestroy {

  public $subscribers: any = {};

  @Output() toggleMyRebate: EventEmitter<boolean> = new EventEmitter(false);

  public authManagerId: any;
  public isLoggedIn: boolean | null = null;
  public currencyCode: any;
  public weeklyRebate: number;
  public weeklySpent: number;
  public currentRebate: any = null;
  public nextRebate: any = null;
  public rebateReached: any = null;
  public rebate_tiers: any[];
  public rates: any;
  public profile: any = null;
  public rebate: any;

  private micrositeSessionsState$: Observable<MicrositeSessionsState>;
  private myManager$: Observable<ManagersScope.Manager>;
  private configuration$: Observable<ConfigurationState>;
  private ngDestroyed$ = new Subject();

  constructor(
    private _calculationsService: CalculationsService,
    private _router: Router,
    private store: Store<AppState>
  ) {
  }

  get nextTuesday(): any {
    const refDate = new Date();
    const dayOfWeek = 1;
    refDate.setDate(refDate.getDate() + 1 +
      (dayOfWeek + 7 - refDate.getDay() - 1) % 7);

    return refDate;
  }

  ngOnInit(): void {
    this.configuration$ = this.store.select(selectConfiguration);
    this.configuration$.pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe(configuration => {
      this.currencyCode = !this.currencyCode ? configuration.micrositeCurrency : this.currencyCode;
    });

    this.micrositeSessionsState$ = this.store.select(selectMicrositeSessions);
    this.micrositeSessionsState$.pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe(micrositeSessions => {
      this.isLoggedIn = !!micrositeSessions.ext_id;
      this.currencyCode = micrositeSessions.currency_code;
      this.authManagerId = micrositeSessions.manager_id;
    });

    // this.$subscribers.auth = this._authService.isLoggedIn().subscribe((auth) => {
    //   this.isLoggedIn = auth;
    //   if (this.isLoggedIn) {
    //     this.currencyCode = this._authService.getCurrencyCode();
    //     this.authManagerId = this._authService.getManagerId();
    //     if (this.authManagerId) {
    //       // this._profileMiddleware.load(this.authManagerId);
    //       this.store.dispatch(getManager({id: this.authManagerId}));
    //     }
    //   } else {
    //     this.$subscribers.currency = this._settingsMiddleware.getDefaultMicrositeCurrency().subscribe(currency => {
    //       if (currency) {
    //         this.currencyCode = currency;
    //       }
    //     });
    //   }
    // });

    // const managerObservable = this._profileMiddleware.get();
    this.myManager$ = this.store.select(selectMyManager).pipe(
      takeUntil(this.ngDestroyed$)
    );

    this.$subscribers.all = combineLatest([this.configuration$, this.myManager$])
      .subscribe(([configuration, profile]) => {
        if (configuration?.coreSettings && configuration?.fxRates && profile) {
          this.rebate = profile.live_rebate_rate;
          this.profile = profile;
          this.rates = configuration.fxRates;
          this.weeklyRebate = this.calculateRate(this.rebate.weekly_rebate_units, Number(this.rates.rate));
          this.weeklySpent = this.calculateRate(this.rebate.weekly_spent_units, Number(this.rates.rate));
          this.rebate_tiers = configuration.coreSettings.rebate_tiers;
          this.getNextRebate(this.rebate.weekly_spent_units, this.rebate_tiers);
          this.getRebateReached(this.rebate.weekly_spent_units, this.rebate_tiers);
          this.getCurrentRebate(this.rebate, this.rebate_tiers);
        }
      });
  }

  calculateRate(amount: number, fxRate: any) {
    return amount * fxRate;
  }

  calculateBarWidth(): number {
    return this._calculationsService.uiSydnicateRebateCalculateBarWidth(this.rebate, this.nextRebate);
  }

  getNextRebate(rebate: number, tiers: any): void {
    this.nextRebate = this._calculationsService.uiSyndicateRebateGetNextRebate(rebate, tiers);
  }

  getRebateReached(rebate: number, tiers: any): void {
    this.rebateReached = this._calculationsService.uiSyndicateRebateGetRebateReached(rebate, tiers);
  }

  getCurrentRebate(rebate: any, tiers: any) {
    this.currentRebate = this._calculationsService.uiSyndicateRebateGetCurrentRebate(rebate, tiers);
  }


  toggleRebate(): void {
    this.toggleMyRebate.emit(true);
  }

  openCreateSyndicate() {
    this.toggleRebate();
    setTimeout(() => {
      this._router.navigate(['/', 'syndicates', 'create']);
    }, 1);
  }

  isManager(): boolean {
    return this.authManagerId === this.profile.id;
  }

  ngOnDestroy(): void {
    for (const key in this.$subscribers) {
      if (this.$subscribers[key]) {
        this.$subscribers[key].unsubscribe();
      }
    }
    this.ngDestroyed$.next();
  }

}
