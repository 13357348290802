import { createFeatureSelector, createSelector } from '@ngrx/store'

// import { selectManagersState } from "../core.state";
export const selectManagersState = createFeatureSelector<AppState, ManagersState>('managers');

import { ManagersState } from "../../NGRX/manager/manager.model";

import {AppState} from "@cbcore/NGRX/core.state";
import {RouterStateUrl} from "@cbcore/NGRX/core.state";
import {selectConfiguration} from "@cbcore/NGRX/configuration/configuration.selectors";
import {selectRouter} from "@cbcore/NGRX/router/router.selector";

import { ConfigurationState } from '../configuration/configuration.models';
import { RouterReducerState } from '@ngrx/router-store';

export const selectManagersList = createSelector(selectManagersState, (state: ManagersState) => state.list)

export const selectManager = createSelector(selectManagersState, (state: ManagersState) => state.manager)
export const selectMyManager = createSelector(selectManagersState, (state: ManagersState) => state.my_manager)

export const selectCurrentManager = createSelector(
    selectManagersState,
    selectRouter,
    (state: ManagersState, routerState: RouterReducerState<RouterStateUrl>) => {
        // console.log('no state 3', routerState);
        // return {id: ''};
    // return (routerState && ((state.my_manager && (String(state.my_manager.id) === routerState.state.params['manager_id'])) ? state.my_manager : state.manager))
    return ((state.my_manager && (String(state.my_manager.id) === routerState?.state.params['manager_id'])) ? state.my_manager : state.manager)
});

export const selectManagersFilters = createSelector(selectManagersState, (state: ManagersState) => state.listParams);


export const selectLeaderboard = createSelector(selectManagersState, (state: ManagersState) => state.leaderboard)

export const selectTopFollowed = createSelector(selectManagersState, (state: ManagersState) => state.top_followed)

export const selectRebate = createSelector(
    selectManagersState,
    selectConfiguration,
    (state: ManagersState, configuration: ConfigurationState): any => {
        return Number(state.my_manager?.live_rebate_rate?.weekly_rebate_units) * Number(configuration.fxRates?.rate)
    })

export const selectCurrentRebate = createSelector(
    selectManagersState,
    selectConfiguration,
    (state: ManagersState, configuration: ConfigurationState): any => {
        return configuration.coreSettings?.rebate_tiers.find((tier: any) => tier.id === state.my_manager.live_rebate_rate?.rebate_tier_id);
    })

export const selectPinnedSyndicates = createSelector(selectManagersState, (state: ManagersState) => state.my_manager?.pinnedSyndicates || []);

export const selectTopRoiManagers = createSelector(selectManagersState, (state: ManagersState) => state.top_positive_roi);
