import { NgModule, Injectable } from '@angular/core';

// import { PoolMiddleware } from './pool/pool';
// import { PoolListMiddleware } from './pool/pool-list';
// import { PoolRecommendedMiddleware } from './pool/pool-recommended';
// import { PoolHeadlineMiddleware } from './pool/pool-headline';
// import { HeadlinePoolsMiddleware } from './pool/headlines';
// import { FeaturedPoolMiddleware } from './pool/pool-featured';
// import { TicketMiddleware } from './ticket/ticket';
// import { TicketListMiddleware } from './ticket/ticket-list';
// import { SyndicateMiddleware } from './syndicate/syndicate';
// import { SyndicateListMiddleware } from './syndicate/syndicate-list';
// import { TokenMiddleware } from './freeplay/token';
// import { GiveawayMiddleware } from './freeplay/giveaway';
// import { ManagerMiddleware } from './manager/manager';
// import { ProfileMiddleware } from './manager/profile';
// import { RefresherMiddleware } from "./refresher/refresher";

// import { LeaderboardMiddleware } from './manager/leaderboard';
// import { LeaderboardWidgetMiddleware } from './manager/leaderboard-widget';
// import { FxRatesMiddleware } from './fx_rates/fx_rates';
// import { SettingsMiddleware } from './settings/settings';
// import { HomepageMiddleware } from './syndicate/syndicate-list-homepage';
// import { SyndicateListWinsMiddleware } from './syndicate/syndicate-list-wins';
import { TrackingMiddleware } from './tracking/tracking.middleware';

// import { CommentaryMiddleware} from "./commentary/commentary";

// import {GroupTicketListMiddleware} from "@cbcore/middlewares/group_ticket/group-ticket-list";
// import {GroupTicketInfoMiddleware} from "@cbcore/middlewares/group_ticket/group-ticket-info";
// import {GroupTicketMiddleware} from "@cbcore/middlewares/group_ticket/group-ticket";

// export * from './pool/pool';
// export * from './pool/pool-list';
// export * from './pool/pool-recommended';
// export * from './pool/pool-headline';
// export * from './pool/headlines';
// export * from './ticket/ticket';
// export * from './ticket/ticket-list';
// export * from './syndicate/syndicate';
// export * from './syndicate/syndicate-list';
// export * from './freeplay/token';
// export * from './freeplay/giveaway';
// export * from './manager/leaderboard';
// export * from './manager/leaderboard-widget';
// export * from './manager/manager';
// export * from './manager/profile';
// export * from './fx_rates/fx_rates';
// export * from './settings/settings';
// export * from './syndicate/syndicates-list-homepage';
// export * from './tracking/tracking.middleware';

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    // PoolMiddleware,
    // PoolListMiddleware,
    // PoolRecommendedMiddleware,
    // PoolHeadlineMiddleware,
    // HeadlinePoolsMiddleware,
    // FeaturedPoolMiddleware,
    // TicketMiddleware,
    // TicketListMiddleware,
    // SyndicateMiddleware,
    // SyndicateListMiddleware,
    // TokenMiddleware,
    // GiveawayMiddleware,
    // ManagerMiddleware,
    // ProfileMiddleware,
    // LeaderboardMiddleware,
    // LeaderboardWidgetMiddleware,
    // FxRatesMiddleware,
    // SettingsMiddleware,
    // HomepageMiddleware,
    // SyndicateListWinsMiddleware,
    TrackingMiddleware,
    // RefresherMiddleware,
    // CommentaryMiddleware,
    // GroupTicketListMiddleware,
    // GroupTicketMiddleware,
    // GroupTicketInfoMiddleware
  ]
})
export class MiddlewareModule {
  constructor(
    // private _poolListMiddleware: PoolListMiddleware,
    // private _headlinePoolsMiddleware: HeadlinePoolsMiddleware,
    // private _fxRatesMiddleware: FxRatesMiddleware,
    // private _settingsMiddleware: SettingsMiddleware,
    // private _featuredPoolMiddleware: FeaturedPoolMiddleware,
    private _trackingMiddleware: TrackingMiddleware,
    // private groupTicketInfoMiddleware: GroupTicketInfoMiddleware
  ) {
    // this._fxRatesMiddleware.load();
    // this._settingsMiddleware.init();
    // this._poolListMiddleware.initPoolList();
    // this._headlinePoolsMiddleware.loadPoolHeadline();
    // this._featuredPoolMiddleware.initFeaturedPool();
    this._trackingMiddleware.initTracking();
    // this.groupTicketInfoMiddleware.loadGroupTicketsInfo({});
  }
}
