import { Injectable } from '@angular/core';
import { TrackingMiddleware} from "@cbcore/middlewares/tracking/tracking.middleware";
import {Config} from "@cbcore/services/config/config.service";

@Injectable()

export class WebLocationService {
  private geoId: string | null;
  private testUrl: string = 'https://www.colossusbets.com/env.config.js';

  constructor(
    private _trackingMiddleware: TrackingMiddleware,
    private config: Config
  ) { }

  initLocation(): any {
    if (!this.config.CONFIG.hasGeoFence) {
      return;
    }

    this.getGeoId().then(res => {
      this.geoId = res;
      this._trackingMiddleware.setGeoId(this.geoId);
    })
  }

  async getGeoId(): Promise<any> {
    if(this.geoId) return this.geoId;
    return new Promise((resolve, reject) => {
      // Not working due to: Refused to get unsafe header "geoip_country_code"
      // Ask infrastructure for: Access-Control-Expose-Headers: geoip_country_code
      var oXmlHttp = new XMLHttpRequest();
      oXmlHttp.onreadystatechange = () => {
        if (oXmlHttp.readyState == XMLHttpRequest.DONE) {
          if (oXmlHttp.status == 200) {
            var x = oXmlHttp.getResponseHeader('geoip_country_code');
            this.geoId = x;
            resolve(x);
          } else {
            resolve(null);
          }
        }
      }

      oXmlHttp.open('get', this.testUrl);
      oXmlHttp.send();
    })
  }
}
