import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';



// import { setModal } from '@cbwallet/NGRX/features/walletModal/walletModal.actions';
import { triggerRegistration, triggerSignIn } from '@cbcore/NGRX/core.state';
@Injectable()
export class MerchantEffects {
  // triggerSignIn = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(triggerSignIn),
  //     exhaustMap((propVal) => {
  //
  //       return of(setModal({modalType: 'login'}));
  //     }))}, { dispatch: true});
  //
  // triggerRegistration = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(triggerRegistration),
  //     exhaustMap((propVal) => {
  //
  //       return of(setModal({modalType: 'registration'}));
  //     }))}, { dispatch: true});

  constructor(
    private actions$: Actions
  ) {}
}


