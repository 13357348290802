import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'protocolCheck',
  pure: false
})

@Injectable()

export class ProtocolCheckPipe implements PipeTransform {

  constructor (
    private _domSanitizer: DomSanitizer
  ) {}

  transform(url: any): any {

    if(!url)
      return;

    var regExpData = /^data:/;
    var regExp = /^https:/;

    if (!regExp.test(url) && !regExpData.test(url)) {
        url = "https:" + url;
    }

    return url;
  }
}
