<div class="notification-box" *ngFor="let note of _notes">
  <div class="title">
    <span class="float-right">
        <i class="fal fa-times" (click)="hide(note)"></i>
    </span>
  </div>
  <div class="content-links">
    <div [translate]="'poolConfirmation.GOOD_LUCK'"></div>
  </div>
</div>
