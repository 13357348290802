import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash-es';
import { PoolSelections } from './selections.model';
import { FectaService } from './fecta.service';
import { SepService } from "./sep.service";
import { CorrelationsService } from "./correlations.service";
import { LocalStorage } from '../utils/localstorage/localstorage.service';

@Injectable()
export class SelectionService {
  private selections: object = {};
  private _subscriber: BehaviorSubject<any>;
  private sep_corelations: any = {};

  constructor(
    private _fectaService: FectaService,
    private _sepService: SepService,
    private _localStorage: LocalStorage,
    private _correlationsService: CorrelationsService
  ) {
    this._localStorage.get('selections').then(res => {
      this.selections = res || this.selections;
    });
    this._subscriber = new BehaviorSubject(this.selections);
  }

  initPool(id: number) {
    if (!this.selections[id]) {
      this.selections[id] = new PoolSelections(id);
    }
    if (this._subscriber) {
      this.updatePool(id);
    }
    this._correlationsService.getCorrelations(id).subscribe(value => {
      if (value && value[id]) {
        this.sep_corelations = value[id];
        this.updatePool(id);
      }
    })
  }

  getSelections(id: number): BehaviorSubject<any> {
    return this._subscriber;
  }

  /**
   *
   * @param poolId
   * @param legId - attention! basically it's leg_order
   * @param bin
   */
  toggleSelection(poolId: number, legId: number, bin: number) {
    // if leg is not define, create it
    if (!this.selections[poolId].legs[legId])
      this.selections[poolId].legs[legId] = [];

    // if leg contains the bin, then remove it
    // if leg NOT contains the bin, then add it
    if (_.includes(this.selections[poolId].legs[legId], bin)) {
      _.pull(this.selections[poolId].legs[legId], bin);
      // if leg has not bin, then remove the leg
      if (this.selections[poolId].legs[legId].length === 0)
        this.selections[poolId].legs = _.omit(this.selections[poolId].legs, legId.toString());
    } else {
      this.selections[poolId].legs[legId].push(bin);
    }
    this.updatePool(poolId);
    this.updateSmartpick(poolId);
    this.updateCrossSell(poolId);
  }

  clearLines(poolId: number) {
    this.selections[poolId].legs = {};
    this.selections[poolId].valid_legs = {};
    this.selections[poolId].smartpick = 0;
    this.selections[poolId].cross_sell = 0;
    this.updatePool(poolId);
  }

  clearExceedLines(poolId: number, max_lines: number) {
    if (this.selections[poolId]) {
      if (this.selections[poolId].total_lines > max_lines) {
        this.clearLines(poolId);
      }
    }
  }

  clearAllSelections() {
    this._localStorage.remove('selections');
    this.selections = {};
    this._subscriber.next(this.selections);
  }

  updateStorage() {
    this._localStorage.set('selections', this.selections);
  }

  updatePool(poolId: number) {
    this.calculateLines(poolId);
    this.calculateSelectionsString(poolId);
    this.calculateSelectionsStringVotes(poolId);
    this.updateValidLegs(poolId);
    this.updateStorage();
    this._subscriber.next(this.selections[poolId]);
  }

  updateValidLegs(poolId: number) {
    if (this.selections[poolId].type_code === 'RACE_ORDER') {
      this.calculateValidLines(poolId);
    }

    if (this.selections[poolId].type_code === 'MATCH') {
      this.calculateValidLineSEP(poolId);
    }
  }

  calculateValidLineSEP(poolId: number) {
    if (this.selections[poolId].legs_with_selections === this.selections[poolId].leg_num) {
      const sep = this._sepService.calculateSep(this.selections[poolId].selections_string,
        this.selections[poolId].leg_num,
        this.sep_corelations);
      this.selections[poolId].selections_string = sep.selections_string;
      this.selections[poolId].total_lines = sep.total_lines;
      this.selections[poolId].valid_legs = this._fectaService.generateValidLegs(sep.selections_string);
    }
  }

  calculateValidLines(poolId: number) {
    if (this.selections[poolId].legs_with_selections === this.selections[poolId].leg_num) {
      let fecta: any = this._fectaService.calculateFecta(this.selections[poolId].selections_string, this.selections[poolId].leg_num);
      this.selections[poolId].selections_string = fecta.selections_string;
      this.selections[poolId].total_lines = fecta.total_lines;
      this.selections[poolId].valid_legs = this._fectaService.generateValidLegs(fecta.selections_string);
    }
  }

  setLegNum(poolId: number, legNum: number) {
    if (this.selections[poolId]) {
      this.selections[poolId].leg_num = legNum;
      this.updateStorage();
    }
  }

  setPoolType(poolId: number, pool_type: string) {
    if (this.selections[poolId]) {
      this.selections[poolId].type_code = pool_type;
      this.updateStorage();
    }
  }

  removeLastSelectionInLeg(poolId: number, legId: number) {
    if (this.selections[poolId].legs[legId]) {
      _.pull(this.selections[poolId].legs[legId], _.first(this.selections[poolId].legs[legId]));
      // if leg has not bin, then remove the leg
      if (this.selections[poolId].legs[legId].length === 0)
        this.selections[poolId].legs = _.omit(this.selections[poolId].legs, legId.toString());
    }
  }

  removeSelectionsInLeg(poolId: number, legId: number) {
    this.selections[poolId].legs = _.omit(this.selections[poolId].legs, legId.toString());
  }

  calculateLines(poolId: number) {
    let index: number = 0;
    let lines: number = 0;
    if(!this.selections[poolId]) return;
    _.forEach(this.selections[poolId].legs, function (value) {
      // First iteration, set the number of lines to 1
      if (lines === 0)
        lines = 1;
      // Updated the number of lines
      lines *= value.length;
      index++;
    })
    this.selections[poolId].legs_with_selections = _.size(this.selections[poolId].legs);
    this.selections[poolId].total_lines = (this.selections[poolId].legs_with_selections === this.selections[poolId].leg_num) ? lines : 0;
  }

  calculateSelectionsString(poolId: number) {
    // if number of lines is bigger than 0, then generate the selection string
    if (this.selections[poolId].total_lines > 0) {
      let str: string = '';
      _.forEach(this.selections[poolId].legs, function (leg, key) {
        str += '/';
        _.forEach(leg, function (bin) {
          str += bin;
          if (_.last(leg) !== bin) {
            str += ',';
          }
        })
      });
      this.selections[poolId].selections_string = str.substring(1);
    } else {
      this.selections[poolId].selections_string = '';
    }
  }

  calculateSelectionsStringVotes(poolId: number) {
    let str: string = '';
    let tempLegs = this.selections[poolId].legs;
    for(let i=1; i<=this.selections[poolId].leg_num; i++) {
      if (tempLegs[i]) {
        _.forEach(tempLegs[i], function (bin) {
          str += bin;
          if (_.last(tempLegs[i]) !== bin) {
            str += ',';
          }
        })
        if (i !== this.selections[poolId].leg_num) {
          str += '/';
        }
      } else {
        if (i !== this.selections[poolId].leg_num) {
          str += '/';
        }
      }
    }
    this.selections[poolId].votes_string = str;
  }

  // this - here, pool-coupon, pool-coupon-gticket
  generateTicket(poolId: number, selections: string, smartpick: number) {
    // first clear the lines
    this.clearLines(poolId);
    // Then generate the selections based on the smartpick string
    let newLegs: any = {};
    let legs = selections.split('/');
    _.each(legs, function (leg, index) {
      var bins = leg.split(',');
      newLegs[index + 1] = [];
      _.each(bins, function (bin) {
        newLegs[index + 1].push(Number(bin));
      });
    });
    this.selections[poolId].legs = newLegs;
    this.selections[poolId].smartpick = smartpick;
    
    this.updatePool(poolId);
  }

  // this - here, freeplay-betslip, pool-betslip - after getting smartpick selection string
  generateSmartpick(poolId: number, selections: string) {
    this.selections[poolId].smartpick = 1;
    this.generateTicket(poolId, selections, 1);
  }

  // this - tickets-group-full
  generateGroupTicketSmartpick(poolId: number, selections: string) {
    this.selections[poolId].smartpick = 0;
    this.generateTicket(poolId, selections, 0);
  }

  // this - freeplay-cross-sell
  generateCrossSell(poolId: number, selections: string) {
    if (selections !== null) {
      this.generateSmartpick(poolId, selections);
      this.selections[poolId].smartpick = 0;
      this.selections[poolId].cross_sell = 1;
    }
  }

  updateSmartpick(poolId: number) {
    // if smartpick value is set (1), then sets smartpick modified (2)
    if (this.selections[poolId].smartpick === 1)
      this.selections[poolId].smartpick = 2;
  }

  updateCrossSell(poolId: number) {
    // if cross_sell value is set (1), then sets cross_sell modified (2)
    if (this.selections[poolId].cross_sell === 1)
      this.selections[poolId].cross_sell = 2;
  }

  // this, some util
  getTotalLines(selections: string): number {
    return _.reduce(selections.split('/'), (lines, line) => {
      return lines *= (_.size(line.split(',')));
    }, Number(1));
  }
}
