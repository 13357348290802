import { AfterContentInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
// export let loadingButton: any = new InjectionToken('btnEl');

@Directive({
  selector: '[loading]'
})

export class ButtonLoaderDirective implements AfterContentInit, OnChanges {
  @Input('loading') isLoading: boolean;
  btnEl: HTMLElement;
  btnLoadingClass: string = 'is-loading';

  constructor(el: ElementRef) {
    this.btnEl = el.nativeElement;
  }

  ngAfterContentInit(): void {
    this.btnEl.addEventListener('click', () => {
      setTimeout(() => {
        this.init();
      }, 1);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading.currentValue) {
      this.removeLoadingClass();
      this.enableBtn();
    }
  }

  init(): void {
    this.addLoadingClass();
    this.disableBtn();
  }

  addLoadingClass(): void {
    this.btnEl.classList.add(this.btnLoadingClass);
  }

  removeLoadingClass(): void {
    this.btnEl.classList.remove(this.btnLoadingClass);
  }

  disableBtn(): void {
    this.btnEl.setAttribute('disabled', 'disabled');
  }

  enableBtn(): void {
    this.btnEl.removeAttribute('disabled');
  }
}
