import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../core.state';
import { RouterStateUrl } from './router.state';

// import { selectRouterState } from '../core.state';
const selectRouterState = createFeatureSelector<
  AppState,
  RouterReducerState<RouterStateUrl>
>('router');


export const selectRouter = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => { 
    return state
  }
);

/*

getSelectors from '@ngrx/router-store' gives predefined selectors. but they don't work with the custom serializer

*/