import { createReducer, on, Action } from '@ngrx/store';
import { GroupTicketState} from "../../NGRX/group-tickets/group-tickets.model";
import {
  successCreate, successCreationState,
  successFollowers,
  successInfo, successSelectionsState, successShowVotes,
  successVote
} from "../../NGRX/group-tickets/group-ticket.actions";


export const initialState: GroupTicketState = {
  followers: undefined,
  info: undefined,
  in_creation: undefined,
  disableSelections: false,
  showVotes: false
};

const reducer = createReducer(
  initialState,
  on(successFollowers, (state, action) => {
    return {
      ...state,
      followers: action.response
    }
  }),
  on(successInfo, (state, action) => {
    return {
      ...state,
      info: action.response
    }
  }),
  on(successCreate, (state, action) => {
    return {
      ...state,
      response: action.response
    }
  }),
  on(successVote, (state, action) => {
    return {
      ...state,
      response: action.response
    }
  }),
  on(successCreationState, (state, action) => {
    return {
      ...state,
      in_creation: action.response
    }
  }),
  on(successSelectionsState, (state, action) => {
    return {
      ...state,
      disableSelections: action.response
    }
  }),
  on(successShowVotes, (state, action) => {
    return {
      ...state,
      showVotes: action.response
    }
  })
)
export function groupTicketReducer(
  state: GroupTicketState | undefined,
  action: Action
): GroupTicketState {
  return reducer(state, action);
}
