import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '@cbcore/services/config/config.service';
import { LocalStorage } from '@cbcore/services/utils/localstorage/localstorage.service';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from '../cookies/cookies.service';
import { CordovaService } from '../cordova/cordova.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class UtilService {
  private $pixel: BehaviorSubject<any> = new BehaviorSubject(null);
  private isMobile: boolean;

  constructor(
    private _cordovaService: CordovaService,
    private localStorage: LocalStorage,
    private _config: Config
  ) { }
  

  init(): void {
    this.isMobile = this._config.CONFIG.isMobile;
    this.setTheme();
    this.WebpIsSupported(function (isSupported: any) {
      const root = document.documentElement;
      if (isSupported) {
        root.classList.add('webp');
      } else {
        root.classList.add('no-webp');
      }
    });
  }

  toObject(array: any, key: string): any {
    return _.keyBy(array, function (e) {
      return e[key];
    });
  }

  getQueryParams($window?: any) {
    const params: any = {};
    const url: string = ($window || window).location.search;
    if (url.includes('?')) {
      url.split('?')[1].split('&').forEach((param) => {
        const tmp = param.split('=');
        params[tmp[0]] = tmp[1];
      });
    }
    return params;
  }

  getQueryParamsFromString(queryString: string) {
    if (!queryString) return null;
    let params: any = {};
    let separated = queryString.includes('?') ? queryString.split('?')[1] : queryString;
    separated.split('&').forEach((param) => {
      const tmp = param.split('=');
      params[tmp[0]] = tmp[1];
    });
    return params;
  }

  isFieldError(errors: any, path: any) {
    return _.result(errors, path, undefined);
  }

  getUserChannel() {
    if (this._cordovaService.isApp()) {
      return 'MOBILE_APP';
    }
    if (window.innerWidth < 640) {
      return 'MOBILE';
    }
    return 'DESKTOP';
  }

  isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }
  isUndefined(value: any): boolean {
    return typeof value === 'undefined' && value === null;
  }

  WebpIsSupported(callback: any) {
    // If the browser doesn't has the method createImageBitmap, you can't display webp format
    if (!window.createImageBitmap) {
      callback(false);
      return;
    }
    // Base64 representation of a white point image
    const webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

    // Retrieve the Image in Blob Format
    fetch(webpdata).then(function (response) {
      return response.blob();
    }).then(function (blob) {
      // If the createImageBitmap method succeeds, return true, otherwise false
      createImageBitmap(blob).then(function () {
        callback(true);
      }, function () {
        callback(false);
      });
    });
  }

  async getInitialTheme(): Promise<any> {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.localStorage.get('color-theme').then(res => {
        const theme: string = res;
        if (theme) {
          return theme;
        }
        const userMedia: any = window.matchMedia('(prefers-color-scheme: dark)');
        if (userMedia.matches) {
          return 'dark';
        }
        return 'light';
      })
    }
  }

  setTheme(theme?: string): void {
    if (theme)
    {
      if (/dark|light/.test(theme)) {
        document.documentElement.classList.remove('dark');
        document.documentElement.classList.remove('light');
        document.documentElement.classList.add(theme);
        localStorage.setItem('color-theme', theme);
      }
    } else {
      if(this.isMobile) return;
      this.localStorage.get('color-theme').then(res => {
        if (res) {
          if (res === 'dark') {
            localStorage.setItem('color-theme', 'dark');
            document.documentElement.classList.add('dark');
          } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.classList.add('light');
            localStorage.setItem('color-theme', 'light');
          }
        } else {
          if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            localStorage.setItem('color-theme', 'dark');
            document.documentElement.classList.add('dark');
          } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.classList.add('light');
            localStorage.setItem('color-theme', 'light');
          }
        }
      });
    }
  }
}
