<div id="ticketshare" *ngIf="!shareError && sharingEnabled && shareListEnabled && socialNetworks && syndicatesCounter >= 2">
  <div class="clearfix" *ngIf="!shareSuccess">
    <button class="direct-url-share" (click)="share()" [loading]="shareLoading" [ngClass]="{'btn-small': isSmall}">
      <i class="fas fa-share mr-custom-8"></i>
      <span class="share-list-available-text" [translate]="'buttons.SHARE_LIST'"></span>
    </button>
  </div>
  <div class="sharing-buttons" *ngIf="shareSuccess">
    <div class="sharing-button-item wa" (click)="whatsapp()" *ngIf="isMobileDevice() && socialNetworks.whatsapp">
      <i class="fab fa-whatsapp"></i>
    </div>
    <div class="sharing-button-item twitter" (click)="openLink(shareData.twitterUrl)" *ngIf="socialNetworks.twitter">
      <i class="fa-brands fa-x-twitter"></i>
    </div>
    <div class="sharing-button-item fb" (click)="openLink(shareData.facebookUrl)" *ngIf="socialNetworks.facebook">
      <i class="fab fa-facebook-f"></i>
    </div>
    <div class="sharing-button-item link" [ngClass]="{'completed': linkCopied}" (click)="copyLink($event)">
      <i *ngIf="!linkCopied" class="far fa-link link-icon mr-custom-6"></i>
      <span *ngIf="!linkCopied" [translate]="'syndicateJoin.COPY_LINK'"></span>

      <span *ngIf="linkCopied" [translate]="'syndicateJoin.LINK_COPIED'"></span>
    </div>
  </div>
</div>
