import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})

@Injectable()

export class CurrencySymbolPipe implements PipeTransform {
  private defaultSymbols: object = {
    'DEFAULT': '$$',
    'GBP': '£',
    'EUR': '€',
    'CHF': 'CHF',
    'SEK': ' kr',
    'NOK': ' kr',
    'USD': '$',
    'UUS': '$',
    'CAD': '$',
    'AUD': '$',
    'NZD': '$',
    'ZAR': 'R',
    'BAM': 'KM',
    'HRK': 'kn',
    'MKD': 'ден',
    'RSD': 'Дин.',
    'RUB': '₽',
    'UAH': 'грн',
    'CNY': '¥',
    'HKD': '$',
    'IDR': 'Rp',
    'JPY': '¥',
    'KRW': '₩',
    'MYR': 'RM',
    'SGD': '$',
    'THB': '฿',
    'VND': '₫',
    'BND': '$',
    'INR': '₹',
    'MXN': '$',
    'PHP': '₱',
    'PLN': 'zł',
    'RON': 'lei',
    'TWD': 'NT$',
    'MMK': 'K',
    'NGN': '₦',
    'AMD': '\u058F',
    'KZT': '\u20B8',
    'CZK': 'Kč',
    'HUF': 'Ft',
    'GHS': 'GH¢',
    'GHC': 'GH¢',
    'BRL': 'R$',
    'GEL': '\u20BE',
    'TRY': '\u20BA',
    'TZS': 'TSh',
    'KES': 'KSh',
    'TOM': 'TOM',
    'PEN': 'S/',
    'AZN': '₼',
    'XAF': 'FCFA',
    'CFA': 'FCFA',
    'RWF': 'FRw',
    'UGX': 'USh',
    'ZMW': 'K',
    'KHR': '៛',
    'ETB': 'ብር'
  };

  transform(currencyCode: string): any {
    const symbolCodes: any = this.defaultSymbols;

    /* This existed in old version, dont know if will be needed or not.
     * (converted to Angular2 - need only to inject service and use correct function)
     *
     * let locale: string = translateService.getLocale();
     * if (locale === 'es') {
     *  symbolCodes = Object.assign({}, this.defaultSymbols);
     *  symbolCodes.GBP = 'GBP';
     *  symbolCodes.USD = 'USD';
     * }
     *
     */


    return (symbolCodes[currencyCode] || symbolCodes.DEFAULT);
  }

}
