import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { CbModalService } from "@cbui/helpers/components/modal/modal.service";
import { CbModalComponent } from "@cbui/helpers/components/modal/modal";

@NgModule({
  declarations: [CbModalComponent],
  entryComponents: [CbModalComponent],
  exports: [CbModalComponent],
  imports: [CommonModule]
})
export class CbModalModule {
  /**
   * Use in AppModule: new instance of CbModal.
   */
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CbModalModule,
      providers: [
        CbModalService
      ]
    };
  }

  /**
   * Use in features modules with lazy loading: new instance of CbModal.
   */
  public static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: CbModalModule,
      providers: [
        CbModalService
      ]
    };
  }

  constructor(private serivce: CbModalService) {
  }
}
