import { createAction, props } from '@ngrx/store';
import { MicrositeSessionsState, Tokens } from './coreAuth.models';

export const callMicrositeSessions = createAction('[Auth] Login Microsite');
    
export const successMicrositeSessions = createAction('[Auth] Microsite Session Success', 
    props<{
        response?: MicrositeSessionsState,
        optionalData?: any
    }>());

export const setOptionalData = createAction('[Auth] Microsite Set Optional Data', 
    props<{
        optionalData?: any
    }>());

export const setCustomMultiplier = createAction('[Auth] Microsite Set Custom Currency Multiplier', 
    props<{
        curr_mult?: any
    }>());

export const callMicrositeRefreshTokens = createAction('[Auth] Refresh Tokens Microsite');

export const successMicrositeRefreshTokens = createAction('[Auth] Microsite Refresh Token Success', 
    props<{
        response: Tokens
    }>());

export const setMicrositeAuthTokenAuthorizationCode = createAction('[Auth] Microsite Set Authorization code', 
    props<{
        authorization_code: string
    }>());

export const failMicrositeSessions = createAction('[Auth] Microsite Session Fail', 
    props<{
        error: any
    }>());

export const triggerSignIn = createAction('[Auth] Microsite Trigger Sign In');

export const triggerRegistration = createAction('[Auth] Microsite Trigger registration');