import { PoolList } from "../../models/pool-list";
import * as _ from 'lodash-es';
import * as Big from 'big.js';
import { PoolFilters } from "../../models/pool-filters";
import { PoolSelections } from "../../services/selections/selections.model";

export interface MicrositePoolsState {
  currentPool: PoolScope.CurrentPool | undefined
  currentPoolSelections: PoolScope.Selections
  currentGTSelections: PoolScope.Selections
  // headlinePool: PoolScope.HeadlinePool | undefined
  // recommendedPool: PoolScope.RecommendedPool | undefined
  featuredPool: PoolScope.PoolFeatured | undefined

  headlinePools: PoolsMenuScope.PoolsHeadline | undefined
  menu: PoolsMenuScope.ParsedPoolsMenu | undefined
  poolsExtraModel: PoolsMenuScope.PoolsExtraModel
  filters: PoolsMenuScope.PoolFiltersByCategory | undefined
  currentSportCode: PoolsMenuScope.SportCode | undefined
}

export declare module PoolScope {
  export interface Selections {
    [pool_id: string]: PoolSelections;
  }

  export interface CurrentPool extends PoolsMenuScope.Pool {
    is_visible: boolean;
    settlement_status: string;
    num_units: string;
    in_rollback_mode: boolean;
    smart_pick_perms: number[];
    created_at: Date;
    settled_at?: any;

    leg_correlations: LegCorrelations;
    prizes: Prize[];
    legs: Leg[];
    history: PoolHistory[];
    // history, selectons, legs on also horse racing
  }

  export interface Prize {
    prize_type_code: string;
    amount: string;
    guarantee: string;
    carry_in: string;
    pay_out: string;
    win_units: string;
    prize_path: number;
    path_selections?: any;
    complete_path_selections?: any;
    id: number;
  }

  //// correlations
  export interface LegCorrelations {
    [correlationNumber: number]: Correlation | any
  }

  export interface Correlation {
    correlations: number[][];
  }
  ///

  // start pool history

  export interface PoolHistory {
    event_code: string;
    remaining_units: string;
    pool_prize_id: number;
    sport_event_history_id: number;
    prize_code: string;
    sport_event_history: SportEventHistory;
    impact_legs: ImpactLeg[];
  }

  export interface ImpactLeg {
    leg_id: number;
    official: boolean;
  }

  export interface SportEventHistory { // same as Leg
    sport_event_id: number;
    event_code: string;
    event_time: Date;
    event_info_json: EventInfoJson;
  }

  export interface EventInfoJson extends AddInfoJson {
    // astea nu apar la leg
    home_colour?: any;
    current_event_code?: any;
    market_results: MarketResult[];
    abandoned_market?: any;

    home_id: number;
    home_name: string;
    home_score: number;
    away_id: number;
    away_name: string;
    away_score: number;
    current_score: string;
    half_time_score: string;
    result_code: string;
  }

  export interface MarketResult {
    market_type: string;
    result: LegResult;
  }

  export interface LegResult { // can be Result
    home_first_half_score: number;
    away_first_half_score: number;
    home_second_half_score: number;
    away_second_half_score: number;
    cs_result: string;
    market_result: any;
    minute?: any;
    time_slot?: any;
    home_corners_first_half?: number;
    home_corners_second_half?: number;
    away_corners_first_half?: number;
    away_corners_second_half?: number;
    yellow_home_first_half?: number;
    yellow_home_second_half?: number;
    yellow_away_first_half?: number;
    yellow_away_second_half?: number;
    red_home_first_half?: number;
    red_home_second_half?: number;
    red_away_first_half?: number;
    red_away_second_half?: number;
    penalty_taken?: boolean;
  }
  // end pool history

  export interface Leg {
    id: number;
    type_code: string;
    official: boolean;
    display_order: number;
    leg_order: number;
    sport_code: string;
    result: LegResult;
    status?: any;
    winning_selections: any[];
    sport_event: SportEvent;
    selections: Selection[];
  }

  //

  export interface Selection {
    id: number;
    competitor_id?: any;
    sport_event_competitor_id?: any;
    bin: number;
    name: string;
    price: string;
    display_order: number;
    add_info_json?: any;
    price_net: string;
    unit_distributions: any[];
  }

  /* Sport Event*/
  export interface SportEvent {
    id: number;
    name: string;
    status: string;
    sport_code: string;
    sport_sub_code: string;
    sport_sub_code_desc?: any;
    period: string;
    add_info_json: AddInfoJson;
    event_number?: any;
    sched_start: Date;
    competitors: Competitor[];
    sport_event_competitors: SportEventCompetitor[];
    history: SportEventHistory[];
    venue?: any;
  }

  export interface AddInfoJson {
    home_id: number;
    home_name: string;
    home_score: number;
    away_id: number;
    away_name: string;
    away_score: number;
    current_score: string;
    half_time_score: string;
    result_code: string;
  }

  export interface Competitor {
    id: number;
    name: string;
    sport_code: string;
    sport_sub_code: string;
    colour: string;
    add_info_json?: any;
  }

  export interface SportEventCompetitor {
    id: number;
    sport_event_id: number;
    competitor_id: number;
    cloth_number?: any;
    name: string;
    status: string;
    withdrawn: boolean;
    result: number;
    favourite: boolean;
    probability?: any;
    fractional_probability?: any;
    add_info_json?: any;
  }

  // export interface HeadlinePool {}
  // export interface RecommendedPool {}
  export interface PoolFeatured {
    image_url: String,
    pool_id: Number,
    pool?: PoolsMenuScope.Pool // added after getting the response
  }
}

export declare module PoolsMenuScope {
  export interface Pool {
    id: number;
    name: string;
    type_code: string;
    name_code: string;
    rule_code: string;
    headline_prize: string;
    status: string;
    sched_start: Date;
    sched_next?: Date;
    stake_sizes: StakeSize[];
    leg_num: number;
    sport_code: string;
    has_sport_event_in_progress: boolean; // looks to be specific to the list
    sport_sub_code: string;
    show_sub_code: boolean;
    fx_rates: FxRate[];
    free_play_only: boolean;
    boosted: boolean;
    rollover: boolean;
    best_dividend_guarantee: boolean;
    external_id?: any;
    external_source?: any;
    trading_enabled: boolean;
    sport_event_info: SportEventInfo;
    overlay_image_url: string;
    venue_code?: any;
    available_syndicates: number;
    has_offers: boolean;
    currency: string;
    tiebreaker_question_code?: string;
  }

  export interface Response {
    running_pools: number[];
    up_next: number[];
    official_pools: number[];
    big_jackpots: number[];
    pools: Pool[];
  }

  export interface ParsedPoolsMenu {
    pools: PoolsDict;
    ALL?: PoolList;
    RACING?: PoolList;
    SPORTS?: PoolList;
  }

  export interface PoolsDict {
    [pool_id: string]: Pool;
  }

  export interface StakeSize {
    currency: string;
    country: string;
    stakes: string[];
    min_stake: string;
    max_stake: string;
    min_cost: string;
    max_cost: string;
  }

  export interface FxRate {
    currency: string;
    rate: string;
  }

  export interface SportEventInfo {
    home_id: number;
    home_name: string;
    away_id: number;
    away_name: string;
    first_event_number?: any;
    home_live_score?: string;
    away_live_score?: string;
  }

  // computed

  export interface SportCode {
    // ALL | RACING | SPORTS
    [pool_category: string]: SportsCodes | RacingCodes | string
  }

  export interface PoolsExtraModel {
    ALL: number,
    SPORTS: number,
    RACING: number,
    POOLS_VALUE: Big.Big,
    CURRENCY_CODE: string,
    OPEN_SYNDICATES: number,
    BDG: Pool[] //?
  }

  export interface PoolFiltersByCategory {
    ALL?: FiltersByStatus,
    SPORTS?: FiltersByStatus,
    RACING?: FiltersByStatus,
  }

  export interface FiltersByStatus {
    OPEN?: PoolFilters
    LIVE?: PoolFilters
    ALL?: PoolFilters
  }

  // main 'pools' key from ParsedPoolsMenu  should have it's own store key and below can be the 'menu' state
  // export type PoolCategs = 'ALL' | 'RACING' | 'SPORTS';
  // export type PoolsDictTwo = {
  //   [pool_category in PoolCategs]: PoolList | any;
  // }

  // HeadlinePoolsMiddleware
  export interface PoolsHeadline {
    ALL?: PoolHeadlineItem,
    RACING?: PoolHeadlineItem,
    SPORTS?: PoolHeadlineItem
  }

  export interface PoolHeadlineItem {
    pool_id: Number,
    sport_category_id: Number
    code?: 'ALL' | 'RACING' | 'SPORTS', // added after getting the response
  }
}

export enum PoolCategories {
  ALL = 'ALL',       // 1
  RACING = 'RACING', // 3
  SPORTS = 'SPORTS'  // 5
}

export enum RacingCodes {
  HORSE_RACING = 'HORSE_RACING',
  GREYHOUNDS = 'GREYHOUNDS'
}

export enum SportsCodes {
  FOOTBALL = 'FOOTBALL',
  NBA = 'NBA',
  NFL = 'NFL',
  TENNIS = 'TENNIS',
  ICE_HOCKEY = 'ICE_HOCKEY',
  DARTS = 'DARTS'
}
