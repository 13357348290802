import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class SessionClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/sessions';
  }

  // gives data model
  session(model: any) : Observable<any> {
    let params: HttpParams;
    if (model.authorization_code) {
      params = new HttpParams().set('authorization_code', model.authorization_code);
    } else {
      params = new HttpParams();
    }
    return this.http.get(this.endpoint, { params, withCredentials: true })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  login(credentials: object): Observable<any> {
    return this.http.post(this.endpoint, credentials, { withCredentials: true })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  logout(): Observable<any> {
    return this.http.get(this.endpoint + '/logout')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  refresh(body: any): Observable<any> {
    return this.http.post(this.endpoint + '/refresh', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  report(model: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('end_date', model.end_date)
      .set('start_date', model.start_date);

    return this.http.get(this.endpoint + '/report', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  isEmpty(obj: any) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return;
  }
}
