import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LocaleNumberFormatPipe } from '../locale-number-format/locale-number-format.pipe';
import * as Big from 'big.js';
import {Config} from "@cbcore/services/config/config.service";

@Pipe({
  name: 'formatUnits',
  pure: false
})

@Injectable()
export class FormatUnitsPipe implements PipeTransform {

  constructor(private nf: LocaleNumberFormatPipe,
              private config: Config) {}

  transform(numUnits: any, type: string = '-'): any {
    let dp: number = 0;
    numUnits = new Big.Big(numUnits);
    const unitsRoundedToInteger = this.config.CONFIG.unitsRoundedToInteger;

    if(numUnits.gte(100)) {
      dp = 0;
    } else if (numUnits.gte(10) && numUnits.lt(100)) {
      dp = unitsRoundedToInteger ? 0 : 1;
    } else if(numUnits.gt(0) && numUnits.lt(10)) {
      dp = unitsRoundedToInteger ? 0 : 2;
    } else {
      dp = 0;
      if(type === 'zero') {
        return '0';
      } else {
        return '-';
      }
    }

    return this.nf.transform(numUnits, dp, true);
  }
}
