import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FreeplayState } from "../../NGRX/freeplay/freeplay.model";
import {AppState} from "../../NGRX/core.state";

export const freeplayState = createFeatureSelector<AppState, FreeplayState>('freeplay');


export const selectFreeplayList = createSelector(freeplayState, (state: FreeplayState) => state.list);

export const selectFreeplayListError = createSelector(freeplayState, (state: FreeplayState) => state.list_error);

export const selectFreeplayToken = createSelector(freeplayState, (state: FreeplayState) => state.token);

export const selectFreeplayTokenError = createSelector(freeplayState, (state: FreeplayState) => state.token_error);

export const freeplayListLoading = createSelector(freeplayState, (state: FreeplayState) => state.is_loading);
