import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation/translation.service';
import { DateFormatPipe} from "@cbcore/pipes/date/date.pipe";

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {
  constructor(private translationService: TranslationService, private translateService: TranslateService, private df: DateFormatPipe) { }


  transform(value: any, ...args: unknown[]): unknown {
    if (!value) { return 'a long time ago'; }
    let time = (Date.now() - Date.parse(value)) / 1000;

    if (time < 10) {
      return this.translateService.instant('datetime.NOW');
    } else if (time < 60) {
      return this.translateService.instant('datetime.MOMENT_AGO');
    } else if (time > 86400) {
      return this.df.transform(value, 'comments');
    }

    const divider = [60, 60, 24];
    const string = ['SECOND', 'MINUTE', 'HOUR', 'DAY', 'MONTH', 'YEAR'];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 'S' : '';
    const key: string = this.translateService.instant('datetime.' + string[i] + plural) + ' ' + this.translateService.instant('datetime.AGO');
    return Math.floor(time) + ' ' + key;
  }
}
