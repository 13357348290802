import { MicrositePoolsState, PoolsMenuScope } from './pool.models';
import {
  callPoolsMenu,
  clearAllPoolSelections,
  clearPool,
  generateTicket,
  markSmartpick,
  partialUpdate,
  setPoolsExtraDetails,
  setSportCode,
  successPool,
  successPoolFeatured,
  successPoolsHeadline,
  successPoolsMenu,
  toggleSelection,
  clearCurrentLines,
  removeSelectionsInLeg,
  removeLastSelectionInLeg,
  markGroupTicketSmartpick,
  markCrossSell,
  callPool,
  generateGTTicket
} from './pool.actions';
import {createReducer, on, Action} from '@ngrx/store';
import { successCoreSettings } from '../../NGRX/configuration/configuration.actions';
import * as _ from 'lodash-es';
import * as Big from 'big.js';
import { PoolSelections } from '../../services/selections/selections.model';
import { calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, clearLines, updateValidLegs } from './parseSelections';

export const initialState: MicrositePoolsState = {
  currentPool: undefined,
  currentPoolSelections: {},
  currentGTSelections: {},
  // headlinePool: undefined, // PoolMiddleware.$subscriber + set correlations + selections
  // recommendedPool: undefined, // PoolMiddleware.$subscriber + set correlations + selections
  featuredPool: undefined,

  menu: undefined,
  poolsExtraModel: {
    ALL: null,
    SPORTS: null,
    RACING: null,
    POOLS_VALUE: new Big.Big(0),
    CURRENCY_CODE: 'GBP',
    OPEN_SYNDICATES: 0,
    BDG: []
  },
  filters: undefined,
  currentSportCode: {},
  headlinePools: undefined
};


const reducer = createReducer(
  initialState,

  on(callPool, (state, action) => {
    return {
      ...state,
      currentPool: undefined
    }
  }),

  on(successPool, (state, action) => {
    const initialCurrentPoolSelections: any = {
      id: action.pool.id,
      leg_num: action.pool.leg_num,
      type_code: action.pool.type_code,

      legs_with_selections: 0,
      selections_string: '',
      votes_string: '',
      total_lines: 0,
      smartpick: 0,
      cross_sell: 0,
      legs: {},
      valid_legs: {},

      sep_correlations: action.pool?.leg_correlations,
    };


    // const currentPoolSelections: any = _.chain((state.currentPoolSelections[action.pool.id] ? [_.cloneDeep(state.currentPoolSelections[action.pool.id])] : initialCurrentPoolSelections))
    //   .map((poolSelections: any) => calculateLines(poolSelections))
    //   .map((poolSelections: PoolSelections) => calculateSelectionsString(poolSelections))
    //   .map((poolSelections: PoolSelections) => calculateSelectionsStringVotes(poolSelections))
    //   .map((poolSelections: PoolSelections) => updateValidLegs(poolSelections))
    //   .head()
    //   .value();

    let poolSelections = initialCurrentPoolSelections;
    let gtSelections = initialCurrentPoolSelections;

    try {
      if (state.currentPoolSelections && state.currentPoolSelections[action.pool.id]) {
        poolSelections = _.cloneDeep(state.currentPoolSelections[action.pool.id])
      }

      if (state.currentGTSelections && state.currentGTSelections[action.pool.id]) {
        gtSelections =  _.cloneDeep(state.currentGTSelections[action.pool.id]);
      }
    } catch (e) {
      console.log('Error pool reducer, 105');
    }


    // const poolSelections = (state?.currentPoolSelections[action.pool.id] ? _.cloneDeep(state.currentPoolSelections[action.pool.id]) : initialCurrentPoolSelections);
    // const gtSelections =  (state?.currentGTSelections[action.pool.id] ? _.cloneDeep(state.currentGTSelections[action.pool.id]) : initialCurrentPoolSelections);
    const parseSelections = _.flow([calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, updateValidLegs]);


    return {
      ...state,
      currentPool: action.pool,
      currentPoolSelections: {
        ...state.currentPoolSelections,
        [`${action.pool.id}`]: parseSelections(poolSelections)
      },
      currentGTSelections: {
        ...state.currentGTSelections,
        [`${action.pool.id}`]: parseSelections(gtSelections)
      }
    }
  }),

  on(toggleSelection, (state, action) => {
    const legId = String(action.legId);
    const bin = String(action.bin);

    const currentPool = _.cloneDeep(state.currentPool);
    const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentPoolSelections[currentPool.id]);
    const currentGTSelections: PoolSelections = _.cloneDeep(state.currentGTSelections[currentPool.id]);

    const parseSelections = _.flow([calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, updateValidLegs]);

    //////

    if (action.context && (action.context === 'grouped_tickets' || action.context === 'all')) {
      if (!currentGTSelections.legs[legId]) {
        currentGTSelections.legs[legId] = [];
      }

      if (_.includes(currentGTSelections.legs[legId], Number(bin))) {
        _.pull(currentGTSelections.legs[legId], Number(bin));
        // if leg has not bin, then remove the leg
        if (currentGTSelections.legs[legId].length === 0)
          currentGTSelections.legs = _.omit(currentGTSelections.legs, legId.toString());
      } else {
        currentGTSelections.legs[legId].push(Number(bin));
      }
      const toggled_currentGTSelections: PoolSelections | any = parseSelections(currentGTSelections);
      if (toggled_currentGTSelections.smartpick === 1) {
        toggled_currentGTSelections.smartpick = 2;
      }

      // this.updateCrossSell(poolId);
      if (toggled_currentGTSelections.cross_sell === 1) {
        toggled_currentGTSelections.cross_sell = 2;
      }

      return {
        ...state,
        currentGTSelections: {
          ...state.currentGTSelections,
          [`${toggled_currentGTSelections.id}`]: toggled_currentGTSelections
        }
      }
    } else {
      // if leg is not defined, create it
      if (!currentPoolSelections.legs[legId]) {
        currentPoolSelections.legs[legId] = [];
      }
      // if leg contains the bin, then remove it
      // if leg NOT contains the bin, then add it
      if (_.includes(currentPoolSelections.legs[legId], Number(bin))) {
        _.pull(currentPoolSelections.legs[legId], Number(bin));
        // if leg has not bin, then remove the leg
        if (currentPoolSelections.legs[legId].length === 0)
          currentPoolSelections.legs = _.omit(currentPoolSelections.legs, legId.toString());
      } else {
        currentPoolSelections.legs[legId].push(Number(bin));
      }
      const toggled_currentPoolSelections: PoolSelections | any = parseSelections(currentPoolSelections);
      // this.updateSmartpick(poolId);
      if (toggled_currentPoolSelections.smartpick === 1) {
        toggled_currentPoolSelections.smartpick = 2;
      }

      // this.updateCrossSell(poolId);
      if (toggled_currentPoolSelections.cross_sell === 1) {
        toggled_currentPoolSelections.cross_sell = 2;
      }

      return {
        ...state,
        currentPoolSelections: {
          ...state.currentPoolSelections,
          [`${toggled_currentPoolSelections.id}`]: toggled_currentPoolSelections
        }
      }
    }

  }),

  on(clearAllPoolSelections, (state, action) => {
    return {
      ...state,
      currentPoolSelections: {},
      currentGTSelections: {}
    }
  }),

  on(generateTicket, (state, action) => {
    const parseSelections = _.flow([calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, updateValidLegs]);

    // clear it's lines

    const cross_sell = state.currentPoolSelections[state.currentPool.id].cross_sell;
    const smartpick = state.currentPoolSelections[state.currentPool.id].smartpick;
    const currentPoolSelections: PoolSelections = Object.assign({},
      clearLines(_.cloneDeep(state.currentPoolSelections[state.currentPool.id])),
      { cross_sell: cross_sell },
      { smartpick: smartpick }
    );

    // add new legs
    let newLegs: any = {};
    let legs = action.selections.split('/');
    _.each(legs, (leg, index) => {
      var bins = leg.split(',');
      newLegs[index + 1] = [];
      _.each(bins, function (bin) {
        newLegs[index + 1].push(Number(bin));
      });
    });
    currentPoolSelections.legs = newLegs;

    return {
      ...state,
      currentPoolSelections: {
        ...state.currentPoolSelections,
        [`${state.currentPool.id}`]: parseSelections(currentPoolSelections)
      }
    }
  }),

  on(generateGTTicket, (state, action) => {
    const parseSelections = _.flow([calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, updateValidLegs]);

    // clear it's lines

    const cross_sell = state.currentGTSelections[state.currentPool.id].cross_sell;
    const smartpick = state.currentGTSelections[state.currentPool.id].smartpick;
    const currentGTSelections: PoolSelections = Object.assign({},
      clearLines(_.cloneDeep(state.currentGTSelections[state.currentPool.id])),
      { cross_sell: cross_sell },
      { smartpick: smartpick }
    );

    // add new legs
    let newLegs: any = {};
    let legs = action.selections.split('/');
    _.each(legs, (leg, index) => {
      var bins = leg.split(',');
      newLegs[index + 1] = [];
      _.each(bins, function (bin) {
        newLegs[index + 1].push(Number(bin));
      });
    });
    currentGTSelections.legs = newLegs;

    return {
      ...state,
      currentGTSelections: {
        ...state.currentGTSelections,
        [`${state.currentPool.id}`]: parseSelections(currentGTSelections)
      }
    }
  }),

  on(markSmartpick, (state, action) => {
    if (action.context && (action.context === 'grouped_tickets' || action.context === 'all')) {
      const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentGTSelections[state.currentPool.id]);
      currentPoolSelections.smartpick = action.value || 1;
      // currentPoolSelections.cross_sell = 0;

      return {
        ...state,
        currentGTSelections: {
          ...state.currentGTSelections,
          [`${state.currentPool.id}`]: currentPoolSelections
        }
      }
    } else {
      const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentPoolSelections[state.currentPool.id]);
      currentPoolSelections.smartpick = action.value || 1;
      // currentPoolSelections.cross_sell = 0;

      return {
        ...state,
        currentPoolSelections: {
          ...state.currentPoolSelections,
          [`${state.currentPool.id}`]: currentPoolSelections
        }
      }
    }

  }),

  on(markGroupTicketSmartpick, (state, action) => {
    const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentGTSelections[state.currentPool.id]);
    currentPoolSelections.smartpick = 0;
    currentPoolSelections.cross_sell = 0;

    return {
      ...state,
      currentGTSelections: {
        ...state.currentGTSelections,
        [`${state.currentPool.id}`]: currentPoolSelections
      }
    }
  }),

  on(markCrossSell, (state, action) => {
    const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentPoolSelections[state.currentPool.id]);
    currentPoolSelections.smartpick = 0; // on the normal service, smartpick becomes 1 after ticket generation :-??
    currentPoolSelections.cross_sell = 1;

    return {
      ...state,
      currentPoolSelections: {
        ...state.currentPoolSelections,
        [`${state.currentPool.id}`]: currentPoolSelections
      }
    }
  }),

  on(clearCurrentLines, (state, action) => {
    const currentPoolSelections: PoolSelections = clearLines(_.cloneDeep(state.currentPoolSelections[state.currentPool.id]));
    const currentGTSelections: PoolSelections = clearLines(_.cloneDeep(state.currentGTSelections[state.currentPool.id]));

    const parseSelections = _.flow([calculateLines, calculateSelectionsString, calculateSelectionsStringVotes, updateValidLegs]);

    return {
      ...state,
      currentPoolSelections: {
        ...state.currentPoolSelections,
        [`${state.currentPool.id}`]: parseSelections(currentPoolSelections)
      },
      currentGTSelections: {
        ...state.currentGTSelections,
        [`${state.currentPool.id}`]: parseSelections(currentGTSelections)
      }
    }
  }),

  on(removeLastSelectionInLeg, (state, action) => {
    if (action.context && (action.context === 'grouped_tickets' || action.context === 'all')) {

      const currentGTSelections: PoolSelections = _.cloneDeep(state.currentGTSelections[state.currentPool.id]);
      if (currentGTSelections.legs[action.legId]) {
        _.pull(currentGTSelections.legs[action.legId], _.first(currentGTSelections.legs[action.legId]));
        // if leg has not bin, then remove the leg
        if (currentGTSelections.legs[action.legId].length === 0)
          currentGTSelections.legs = _.omit(currentGTSelections.legs, action.legId.toString());
      }

      return {
        ...state,
        currentGTSelections: {
          ...state.currentGTSelections,
          [`${state.currentPool.id}`]: currentGTSelections
        }
      }
    } else {
      const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentPoolSelections[state.currentPool.id]);
      if (currentPoolSelections.legs[action.legId]) {
        _.pull(currentPoolSelections.legs[action.legId], _.first(currentPoolSelections.legs[action.legId]));
        // if leg has not bin, then remove the leg
        if (currentPoolSelections.legs[action.legId].length === 0)
          currentPoolSelections.legs = _.omit(currentPoolSelections.legs, action.legId.toString());
      }
      return {
        ...state,
        currentPoolSelections: {
          ...state.currentPoolSelections,
          [`${state.currentPool.id}`]: currentPoolSelections
        }
      }
    }
  }),

  on(removeSelectionsInLeg, (state, action) => {
    if (action.context && (action.context === 'grouped_tickets' || action.context === 'all')) {
      const currentGTSelections: PoolSelections = _.cloneDeep(state.currentGTSelections[state.currentPool.id]);
      currentGTSelections.legs = _.omit(currentGTSelections.legs, action.legId.toString());
      return {
        ...state,
        currentGTSelections: {
          ...state.currentGTSelections,
          [`${state.currentPool.id}`]: currentGTSelections
        }
      }
    } else {
      const currentPoolSelections: PoolSelections = _.cloneDeep(state.currentPoolSelections[state.currentPool.id]);
      currentPoolSelections.legs = _.omit(currentPoolSelections.legs, action.legId.toString());
      return {
        ...state,
        currentPoolSelections: {
          ...state.currentPoolSelections,
          [`${state.currentPool.id}`]: currentPoolSelections
        }
      }
    }

  }),

  on(successPoolFeatured, (state, action) => {
    return {
      ...state,
      featuredPool: {
        image_url: action.featuredPoolResponse.image_url,
        pool_id: action.featuredPoolResponse.pool_id,
        pool: state.menu.pools[String(action.featuredPoolResponse.pool_id)]
      }
    }
  }),

  on(clearPool, (state, action) => {
    return {
      ...state,
      pool: undefined
    }
  }),

  on(successCoreSettings, (state, action) => {
    const poolsExtraModel = Object.assign({}, state.poolsExtraModel, {
      OPEN_SYNDICATES: action.response.open_syndicate_count,
      CURRENCY_CODE: action.response.default_currency
    });

    _.map(action.response.sport_categories, category => {
      const pools_category = _.find(action.response.sport_categories, {
        code: category.code
      });
      poolsExtraModel[category.code] = pools_category.pools_count;
    });
    return {
      ...state,
      poolsExtraModel: poolsExtraModel
    }
  }),

  on(setPoolsExtraDetails, (state, action) => {
    return {
      ...state,
      poolsExtraModel: action.poolsExtraModel,
      filters: action.filters
    }
  }),

  on(successPoolsMenu, (state, action) => {
    return {
      ...state,
      menu: action.parsedPoolsMenu
    }
  }),

  on(successPoolsHeadline, (state, action) => {
    if (!action.coreSettings) return state; // called by session refresh on app start
    const poolsHeadlineResponse = _.cloneDeep(action.poolsHeadlineResponse);
    const headlinePools: PoolsMenuScope.PoolsHeadline = _.keyBy(_.map(poolsHeadlineResponse, (pool) => {
      pool.code = _.result(_.find(action.coreSettings.sport_categories, { 'id': pool.sport_category_id }), 'code');
      return pool;
    }), 'code');

    return {
      ...state,
      headlinePools: headlinePools
    }
  }),

  on(setSportCode, (state, action) => {
    const newSportCode: any = Object.assign({}, state.currentSportCode, action.sportCode);
    return {
      ...state,
      currentSportCode: newSportCode
    }
  }),

  on(partialUpdate, (state, action) => {
    return ws[action.event](state, action)
  }),
);

export const ws = {
  POOL_INFO_CHANGED: (state: MicrositePoolsState, action): any => {
    /* common */
    const newData = action.data;
    const idOfElementToBeUpdated = newData.id;

    /* featuredPool */
    const featuredPoolToBeUpdated = _.cloneDeep(state.featuredPool);
    const shouldFeaturedPoolBeUpdated = !!featuredPoolToBeUpdated && (Number(state.featuredPool.pool_id) === Number(newData.id)) && new Big.Big(newData.headline_prize).gt(featuredPoolToBeUpdated.pool.headline_prize);
    try {
      featuredPoolToBeUpdated.pool.headline_prize = newData.headline_prize;
    } catch(e) {
      console.log('featured pool WS update failed');
    }

    /* poolList */
    // const elementToBeUpdated = _.cloneDeep(state.menu.pools[idOfElementToBeUpdated]) || undefined;
    // const shouldBeUpdated = !!elementToBeUpdated && new Big.Big(newData.headline_prize).gt(elementToBeUpdated.headline_prize);

    let elementToBeUpdated = undefined;
    let shouldBeUpdated: boolean;

    try {
      elementToBeUpdated = _.cloneDeep(state.menu.pools[idOfElementToBeUpdated]) || undefined;
      shouldBeUpdated = !!elementToBeUpdated && new Big.Big(newData.headline_prize).gt(elementToBeUpdated.headline_prize);
      elementToBeUpdated.headline_prize = newData.headline_prize;

    } catch (e) {
      elementToBeUpdated = undefined;
      shouldBeUpdated = false;
      console.log('pool list element WS update failed');
    }


    /* currentPool */
    // const currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
    // const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.id));

    let currentPoolToBeUpdated = undefined;
    let shouldCurrentPoolBeUpdated: boolean;

    // Update num_units
    try {
      currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
      shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.id));
      if (currentPoolToBeUpdated && new Big.Big(newData.num_units).gt(currentPoolToBeUpdated.num_units)) {
        currentPoolToBeUpdated.num_units = newData.num_units;
      }
    } catch (e) {
      currentPoolToBeUpdated = undefined;
      shouldCurrentPoolBeUpdated = false;
      console.log('current pool units WS update failed');
    }


    // Update headline_prize
    try {
      if (currentPoolToBeUpdated && new Big.Big(newData.headline_prize).gt(currentPoolToBeUpdated.headline_prize)) {
        currentPoolToBeUpdated.headline_prize = newData.headline_prize;
      }
    } catch (e) {
      console.log('current pool headline prize WS update failed');
    }


    // Update prizes
    try {
      if(shouldCurrentPoolBeUpdated) {
        for (const prize of currentPoolToBeUpdated.prizes) {
          if (new Big.Big(newData.pool_prizes[prize.prize_type_code].current_prize).gt(prize.amount)) {
            prize.amount = newData.pool_prizes[prize.prize_type_code].current_prize;
          }
        }
      }
    } catch (e) {
      console.log('current pool prizes WS update failed');
    }


    return {
      ...state,

      // poolList
      menu: shouldBeUpdated ? {
        ...state.menu,
        pools: {
          ...state.menu.pools,
          [`${idOfElementToBeUpdated}`]: elementToBeUpdated
        }
      } : state.menu,

      // featuredPool
      featuredPool: shouldFeaturedPoolBeUpdated ? {
        ...state.featuredPool,
        pool: featuredPoolToBeUpdated.pool
      } : state.featuredPool,

      currentPool: shouldCurrentPoolBeUpdated ? currentPoolToBeUpdated : state.currentPool
    }
  },

  SPORT_EVENT_COMPETITORS_UPDATE: (state: MicrositePoolsState, action): any => {
    /* common */
    const newData = action.data;
    // const idOfElementToBeUpdated = newData.id;

    /* currentPool */
    const currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
    // const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.id));
    const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.object_id));

    const leg = _.find(currentPoolToBeUpdated.legs, { 'id': newData.data.leg_id });
    if (leg) {
      const new_sport_event_competitors = newData.data.sport_event_competitors;
      const current_sport_event_competitors = _.result(leg, 'sport_event.sport_event_competitors', []);
      _.forEach(new_sport_event_competitors, (competitor: any) => {
        let new_competitor = _.find(current_sport_event_competitors, { 'id': competitor.id });
        new_competitor = Object.assign(new_competitor, {
          withdrawn: competitor.withdrawn,
          probability: competitor.probability,
          fractional_probability: competitor.fractional_probability,
          favourite: competitor.favourite
        });
      });
    }


    return {
      ...state,
      currentPool: (shouldCurrentPoolBeUpdated && leg) ? currentPoolToBeUpdated : state.currentPool
    }
  },

  SPORT_EVENT_COMPETITOR_UPDATE: (state: MicrositePoolsState, action): any => {
    /* common */
    const newData = action.data;
    // const idOfElementToBeUpdated = newData.id;

    /* currentPool */
    const currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
    const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.object_id));

    if(shouldCurrentPoolBeUpdated) {
      const pool_running = (currentPoolToBeUpdated.status === 'IN_PLAY');
      const pool_open = (currentPoolToBeUpdated.status === 'OPEN');
      // let skip_update = false;
      _.forEach(currentPoolToBeUpdated.legs, (leg: any) => {
        if (leg.sport_event.id === newData.data.sport_event_id) {
          _.forEach(leg.sport_event.sport_event_competitors, (competitor: any) => {
            if (competitor.competitor_id === newData.data.competitor_id) {
              // LOGIC: If pool is IN_PLAY or OPEN and have ONLY price update - skip it
              if ((pool_running || pool_open) &&
                (competitor.cloth_number === newData.data.cloth_number) &&
                (competitor.favourite === newData.data.favourite) &&
                (competitor.name === newData.data.name) &&
                (competitor.result === newData.data.result) &&
                (competitor.status === newData.data.status) &&
                (competitor.withdrawn === newData.data.withdrawn) &&
                (JSON.stringify(competitor.add_info_json) === JSON.stringify(newData.data.add_info_json)) &&
                ((competitor.probability !== newData.data.probability) || (competitor.fractional_probability !== newData.data.fractional_probability))) {
                // skip_update = true;
              }
              competitor = Object.assign(competitor, {
                add_info_json: newData.data.add_info_json,
                cloth_number: newData.data.cloth_number,
                favourite: newData.data.favourite,
                fractional_probability: newData.data.fractional_probability,
                name: newData.data.name,
                probability: newData.data.probability,
                result: newData.data.result,
                status: newData.data.status,
                withdrawn: newData.data.withdrawn
              });
            }
          });
        }
      });
    }


    return {
      ...state,
      currentPool: shouldCurrentPoolBeUpdated ? currentPoolToBeUpdated : state.currentPool
    }
  },

  SPORT_EVENT_UPDATE: (state: MicrositePoolsState, action): any => {
    /* common */
    const newData = action.data;
    // const idOfElementToBeUpdated = newData.id;

    /* currentPool */
    const currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
    // const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.id));
    const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.object_id));

    if(shouldCurrentPoolBeUpdated)
      _.forEach(currentPoolToBeUpdated.legs, (leg: any) => {
        if (leg.sport_event.id === newData.data.id) {
          leg.sport_event = Object.assign(leg.sport_event, {
            add_info_json: newData.data.add_info_json,
            name: newData.data.name,
            sched_start: newData.data.sched_start,
            status: newData.data.status,
            period: newData.data.period,
            sport_code: newData.data.sport_code,
            sport_sub_code: newData.data.sport_sub_code
          });
        }
      });

    return {
      ...state,
      currentPool: shouldCurrentPoolBeUpdated ? currentPoolToBeUpdated : state.currentPool
    }
  },

  RESULTS_UPDATED: (state: MicrositePoolsState, action): any => {
    /* common */
    const newData = action.data;
    // const idOfElementToBeUpdated = newData.id;

    /* currentPool */
    const currentPoolToBeUpdated = _.cloneDeep(state.currentPool || undefined);
    const shouldCurrentPoolBeUpdated = !!currentPoolToBeUpdated && (Number(currentPoolToBeUpdated.id) === Number(newData.object_id));

    if(!shouldCurrentPoolBeUpdated) return state;

    const leg = _.find(currentPoolToBeUpdated.legs, { 'id': newData.data.leg_id });
    if (leg) {
      const new_results = newData.data.result;
      leg.result = new_results;
    }

    return {
      ...state,
      currentPool: currentPoolToBeUpdated
    }
  },


  RELOAD_EVENTS: [
    // reload events for: PoolsListMiddleware, HeadlinePoolsMiddleware
    'POOL_STATUS_CHANGED', 'POOL_SHOW', 'POOL_HIDE',

    // TODO: these are single pool events so should refetch only the single pool.
    'UNIT_DISTRIBUTION_CHANGED', 'POOL_UPDATED', 'POOL_STATUS_CHANGED',

    // other reload events tied to authentication for: poolList
    'MICROSITE_AUTHENTICATION_REFRESH', 'USER_REGISTER', 'USER_LOGIN', 'USER_LOGOUT'
  ]
}


export function micrositePoolsReducer(
  state: MicrositePoolsState | undefined,
  action: Action
): MicrositePoolsState {
  return reducer(state, action);
}
