<span class="sport_sub_code mr-custom-4" *ngIf="
      pool.show_sub_code &&
      (pool.sport_code !== 'HORSE_RACING' &&
            pool.sport_code !== 'GREYHOUNDS' &&
            pool.sport_code !== 'FANTASY_NFL' &&
            pool.sport_sub_code !== 'MIXED')"
      [translate]="'sportSubCode.' + pool.sport_sub_code"
      [translateParams]="{defaultValue: pool.sport_sub_code}">
</span>


<span *ngIf="pool.sport_event_info && pool.type_code === 'MATCH'">
      <span class="competitor" [translate]="'competitors.' + pool.sport_event_info.home_id"  [translateParams]="{defaultValue: pool.sport_event_info.home_name}"></span>
      <span class="px-custom-3" [translate]="'versus.DEFAULT'"></span>
      <span class="competitor" [translate]="'competitors.' + pool.sport_event_info.away_id"  [translateParams]="{defaultValue: pool.sport_event_info.away_name}"></span>&nbsp;
</span>

<span [class]="'sport_sub_code mr-custom-4 flag-icon flag-icon-'+(pool.name_code | lowercase)"
      *ngIf="pool.name_code &&
            flag &&
            pool.name_code !== 'BETWAY' &&
            ((pool.sport_code === 'HORSE_RACING' || pool.sport_code === 'GREYHOUNDS') && pool.sport_sub_code !== 'MIXED')">
</span>
<span class="sport_sub_code mr-custom-4"
      *ngIf="racingTrack &&
      ((pool.sport_code === 'HORSE_RACING' || pool.sport_code === 'GREYHOUNDS') && pool.sport_sub_code !== 'MIXED')"
      [translate]="'poolFilters.' + pool.sport_code + '.' + pool.pool_type"
      [translateParams]="{ defaultValue: (('racingSportSubCode.' + (pool.sport_sub_code | sportSubCode)) | translate)}">
</span>
<!--<span class="sport_sub_code" *ngIf="pool.status === 'OPEN' && pool.sport_code === 'FANTASY_NFL' && pool.sport_sub_code !== 'MIXED'">-->
<!--  <span *ngIf="pool.show_sub_code" [translate]="'sportSubCode.CUSTOM_WEEK'" [translateParams]="{number: getWeek()}"></span>-->
<!--</span>-->
<strong class="cb-bold time"
        *ngIf="showtime && pool.sport_code !== 'FANTASY_NFL'">{{pool.sched_start | dateFormat: 'short'}}</strong>
