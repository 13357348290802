import { NgModule, Injectable } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';

import { Config } from '../config/config.service';
import { TranslationLoader } from './translation-loader';

import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient, _config: Config, _readyService: ReadyService): any {
  return new TranslationLoader(http, _config, _readyService);
}

import { TranslationService } from './translation.service';
import { MyCustomMissingTranslationHandler } from './missing-translation-handler.service';
import { ReadyService } from './ready.service';

import { InternalLanguageKeyMap } from './locale.constants';

// export * from './locale.constants';
// export * from './translation.service';

export function InternalLanguageKeyMapFactory(): any {
  return new InternalLanguageKeyMap();
}

@Injectable()
@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, Config, ReadyService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyCustomMissingTranslationHandler
      },
      isolate: true
    })
  ],
  declarations: [],
  exports: [],
  providers: [
    ReadyService,
    TranslationService,
    {
      provide: InternalLanguageKeyMap,
      useFactory: InternalLanguageKeyMapFactory,
      deps: []
    }
  ]
})
export class TranslationModule {
  constructor(_translationService: TranslationService) {
    _translationService.onInit();
  }
}
