import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppState } from '@cbcore/NGRX/core.state';
import { PoolsMenuScope } from '@cbcore/NGRX/pool/pool.models';
import { selectPoolExtraModel } from '@cbcore/NGRX/pool/pool.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class NoContentRouteGuard implements CanActivate {
  private poolExtraModel$: Observable<PoolsMenuScope.PoolsExtraModel>;
  private ngDestroyed$ = new Subject();
  
  constructor(
    private _router: Router,
    private store: Store<AppState>
  ) {}
  canActivate() {
    const havePools = true;

    // this.poolExtraModel$ = this.store.select(selectPoolExtraModel);
    // this.poolExtraModel$.pipe(
    //   takeUntil(this.ngDestroyed$),
    //   filter(poolExtraModel => poolExtraModel.ALL !== null)
    // ).subscribe(poolExtraModel => {
    //   if (poolExtraModel) {
    //     this.ngDestroyed$.next();
    //     if (poolExtraModel.ALL !== 0) {
    //       return this.checkEnabled(true);
    //     } else {
    //       return this.checkEnabled(false);
    //     }
    //   } else {
    //     return false;
    //   }
    // });

    return this.checkEnabled(havePools);
  }

  checkEnabled(havePools: any): boolean {
    if (havePools) {
      return true;
    }

    this._router.navigate(['/', 'no-content']);
    return false;
  }
}
