import { Injectable } from '@angular/core';
import { Config } from '../../config/config.service';
import { WindowService } from '../window/window.service';
import { PostMessageService } from '../postmessage/postmessage.service';

@Injectable()

export class ResizeService {
  private height: number | undefined;
  private _window: Window;
  private allowResizeEvents: boolean;

  constructor(private _config: Config, private windowRef: WindowService, private _postMessageService: PostMessageService) {
    this._window = windowRef.nativeWindow;
    this.allowResizeEvents = this._config.CONFIG.allowResizeEvents;
  }

  listen() {
    if (this.allowResizeEvents) {
      setInterval(() => {
        const merchant_element = document.getElementById('merchant');
        if (merchant_element) {
          if (this.height !== merchant_element.clientHeight) {
            this.onHeightChanged(merchant_element.clientHeight);
          }
        }
      }, 500);
    }
  }

  onHeightChanged(height: number) {
    this.height = height;
    this._postMessageService.resize({
      height: height
    });
  }
}
