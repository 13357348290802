import { Injectable, NgModule } from '@angular/core';

import { CommonModule } from "@angular/common";
// import { CbfeCoreModule } from "@cbcore/cbfe-core.module";
import { TranslateModule } from "@ngx-translate/core";

import { SyndicateProfileCreateComponent } from './syndicate-profile-create';
import { AvatarUploaderModule } from "@cbui/helpers/components/avatar-uploader/avatar-uploader.module";
import {FormsModule} from "@angular/forms";

export * from './syndicate-profile-create';

@Injectable()
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // CbfeCoreModule,
    AvatarUploaderModule,
    TranslateModule
  ],
  declarations: [
    SyndicateProfileCreateComponent
  ],
  exports: [
    SyndicateProfileCreateComponent
  ]
})
export class SyndicateProfileCreateModule {}
