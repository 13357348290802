import { Injectable, NgModule } from '@angular/core';

import { CommonModule } from "@angular/common";
// import { CbfeCoreModule} from "@cbcore/cbfe-core.module";
import { AvatarUploaderComponent } from './avatar-uploader';
import { AvatarCroppieModule } from "@cbui/helpers/components/avatar-croppie/avatar-croppie.module";
import { TranslateModule } from "@ngx-translate/core";

export * from './avatar-uploader';

@Injectable()
@NgModule({
  imports: [
    CommonModule,
    // CbfeCoreModule,
    AvatarCroppieModule,
    TranslateModule
  ],
  declarations: [
    AvatarUploaderComponent
  ],
  exports: [
    AvatarUploaderComponent
  ]
})
export class AvatarUploaderModule { }
