import { createAction, props } from '@ngrx/store';
import { CommentaryListModel, CommentaryParams, SyndicatesScope } from "./syndicates.model";


// SOLO SYNDICATE
export const callSyndicate = createAction('[Syndicate] Call syndicate',
  props<{
    id: number
  }>());

export const successSyndicate = createAction('[Syndicate] Get syndicate Success',
  props<{
    portion: SyndicatesScope.Syndicate
  }>());

// LIST & FILTERS

export const loadList = createAction('[Syndicates] Load syndicate list',
  props<{
    model?: any
  }>());

export const callList = createAction('[Syndicates] Call syndicate list',
  props<{
    model?: any
  }>());

export const listLoadMore = createAction('[Syndicates] Load more syndicate list');

// export const reloadList = createAction('[Syndicates] Reload syndicate list');

export const successList = createAction('[Syndicates] Success syndicate list',
  props<{
    response: SyndicatesScope.SyndicateList
  }>());

export const updateListItem = createAction('[Syndicates] Update syndicate list item',
  props<{
    syndicate: SyndicatesScope.Syndicate
  }>());

// export const getFilters = createAction('[Syndicates] Get list filters');

// export const updateFilters = createAction('',
//   props<{
//     model?: any
//   }>());
// export const successFilters = createAction('[Syndicates] Success Filters',
//   props<{
//     response: SyndicatesScope.Pagination
//   }>());

// export const clearFilters = createAction('[Syndicates] Clear filters');

// CREATE SYNDICATE
export const createSyndicate = createAction('[Syndicate] Create syndicate',
  props<{
    model?: SyndicatesScope.SyndicateCreatePayload
  }>());
export const successCreateSyndicate = createAction('[Syndicate] Create syndicate Success',
  props<{
    response: any// SyndicatesScope.SyndicateCreateSuccess
  }>());

// UPDATE SYNDICATE
export const updateSyndicate = createAction('[Syndicate] Update syndicate',
  props<{
    id: number,
    model?: any
  }>());
export const successUpdateSyndicate = createAction('[Syndicate] Update syndicate Success',
  props<{
    response: any // `any` because BE is fked and can't get the response
  }>());

export const clearCreateSuccess = createAction('[Syndicate] Clear create success');

// JOIN SYNDICATE

export const joinSyndicate = createAction('[Syndicate] Join syndicate',
  props<{
    id: string,
    model?: SyndicatesScope.SyndicateJoinPayload
  }>());

export const setSyndicatePublic = createAction('[Syndicate] Set as public',
  props<{
    id: number,
    model: any// SyndicatesScope.SyndicateJoinPayload
  }>());

export const setSyndicateVoidToSolo = createAction('[Syndicate] Set void to solo',
  props<{
    id: number,
    model: any// SyndicatesScope.SyndicateJoinPayload
  }>());

export const contributeMore = createAction('[Syndicate] Join Contribute More');

export const successJoinSyndicate = createAction('[Syndicate] Join syndicate Success',
  props<{
    response: SyndicatesScope.SyndicateJoinSuccess
  }>());

// SYNDICATE WINS

export const callHighlighted = createAction('[Syndicates] Call highlighted');

export const callSyndicateWins = createAction('[Syndicates] Call syndicate wins',
  props<{
    model: any
  }>());

export const successSyndicateWins = createAction('[Syndicates] Success wins list',
  props<{
    response: any
  }>());


// ACCEPT OFFER
export const acceptOffer = createAction('[Syndicate] Accept offer',
  props<{
    id: string,
    model?: any
  }>());
export const successAcceptOffer = createAction('[Syndicate] Accept offer Success',
  props<{
    response: any
  }>());

//// Errors - just because should be different for this feature

export const failSyndicate = createAction('[Syndicate] Fail',
  props<{
    error: any
  }>());

export const failSyndicateList = createAction('[Syndicate] List Fail',
  props<{
    error: any
  }>());

export const failSyndicateOffer = createAction('[Syndicate] Fail Accept Offer',
  props<{
    error: any
  }>());


// TRACKING ONLY
export const successSyndicatePublic = createAction('[Syndicate] Success set as public',
  props<{
    response: any// SyndicatesScope.SyndicateJoinPayload
  }>());

export const successSyndicateVoidToSolo = createAction('[Syndicate] Success void to solo',
  props<{
    response: any,// SyndicatesScope.SyndicateJoinPayload
    model: any// SyndicatesScope.SyndicateJoinPayload
  }>());


// Live updates
export const partialUpdate = createAction('[Syndicate] Partial update / refresh commentary',
  props<{
      data: any, // can have model for different websocket event's data
      event?: string
  }>());

// Commentary
export const loadComments= createAction('[Syndicate] Load Comments',
  props<{
    params: CommentaryParams
  }>());

export const loadMoreComments= createAction('[Syndicate] Load More Comments',
  props<{
    syndicateId: number
  }>());

export const successComments= createAction('[Syndicate] Success Comments',
  props<{
    response: CommentaryListModel
  }>());

export const updateComment= createAction('[Syndicate] Update Comment',
  props<{
    model: Comment
  }>());

export const successpdateComment= createAction('[Syndicate] Success Update Comment',
  props<{
    response: Comment
  }>());

export const createComment= createAction('[Syndicate] Call Create Comment',
  props<{
    model: CommentaryParams
  }>());

export const successCreateComment= createAction('[Syndicate] Success Create Comment',
  props<{
    response: Comment
  }>());

export const failCreateComment= createAction('[Syndicate] Fail Create Comment',
  props<{
    error: any
  }>());

export const deleteComment = createAction('[Syndicate] Delete Comment',
  props<{
    commentaryId: number
  }>());
