import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../core.state';

// import { selectConfigurationState } from '../../NGRX/core.state';
export const selectConfigurationState = createFeatureSelector<
  AppState,
  ConfigurationState
>('configuration');

import { ConfigurationState } from './configuration.models';

export const selectConfiguration = 
// undefined;
createSelector(
  selectConfigurationState,
  (state: ConfigurationState) => state
);

export const selectConfigurationCore = 
// undefined;
createSelector(
  selectConfigurationState,
  (state: ConfigurationState) => state.coreSettings
);

export const selectConfigurationLocal = 
// undefined;
createSelector(
  selectConfigurationState,
  (state: ConfigurationState) => state.localSettings
);
