export class PoolFilters {
  constructor (
    public all: any = undefined,
    public view: Array<string> = [],
    public sport_sub_code: Array<string> = [],
    public sport: Array<string> = [],
    public pool_type: Array<string> = [],
    public pool: Array<string> = []
  ) {}
}
