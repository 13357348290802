import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import { GroupTicketsScope } from '@cbcore/NGRX/group-tickets/group-tickets.model';


// GroupTicket - or Social, or whatever you want to call it
@Injectable()
export class GroupTicketClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '';
  }

    /**
   * Get the list of followers that have a manager entry themselves for a given customer. Follower managers can be filtered on username.
   * @param model: Object
   * model.group_ticket_id - optional, crosschecks if the follower was invited on this ticket
   * model.username - optional, follower's manager username filter
   */
  getManagerFollowers(model: any):  Observable<GroupTicketsScope.ManagerFollowers> {
    let params = new HttpParams();
    params = params.append('pool_id', model.pool_id);
    params = params.append('per', '9999');

    if (model.group_ticket_id) {
      params = params.append('group_ticket_id', model.group_ticket_id);
    }

    return this.http.get(this.endpoint + '/manager_followers', { params })
      .pipe(
        map((data: any) => data),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Returns meta information about the group tickets feature given a customer id.
   * @param model - Object
   * manager_id - manager id in the invitation might not be needed
   */
  getGroupTicketsInfo(model: any): Observable<any> {
    let params = new HttpParams();
    if (model.manager_id !== 'undefined') {
      params.set('manager_id', model.manager_id);
    }

    return this.http.get(this.endpoint + '/group_tickets/info', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Create group ticket by manager for a pool with a list of follower invitees to participate in the voting.
   * @param model
   */
  createGroupTicket(body: any): Observable<any> {

    return this.http.post(this.endpoint + '/group_tickets', body, { withCredentials: true })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Check if the manager has a grouped ticket for the given pool id and returns the group ticket data.
   * @param model
   * model.manager_id - manager to check
   * model.pool_id - pool id to check
   */
  getGroupTicket(model: any): Observable<any> {
    let params = new HttpParams();
    params.set('manager_id', model.manager_id);
    params.set('pool_id', model.pool_id);

    // return of(GET_GROUP_TICKET);

    return this.http.get(this.endpoint + '/group_tickets/by_pool_and_manager/' + model.pool_id + '/' + model.manager_id)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Update group ticket by manager for a pool with a list of follower invitees to participate in the voting.
   * @param model
   * model.group_ticket_id - group ticket to invite to [REQ]
   * model.follower_manager_ids - Array - list of manager ids from the persons followers who are managers [REQ]
   */
  updateGroupTicket(body: any): Observable<any> {
    return this.http.put(this.endpoint + '/group_tickets/' + body.group_ticket.group_ticket_id, body, { withCredentials: true })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Give your invitee opinion on what selections should be played.
   * @param model
   * model.group_ticket_id - the group ticket I am voting on
   * model.selection_string - selection string for the pool, that allows empties
   */
  vote(model: any): Observable<any> {
    const payload: any = {
      selection_string: model.selection_string,
      group_ticket_id: model.group_ticket_id
    }
    return this.http.post(this.endpoint + '/group_tickets/' + model.group_ticket_id + '/vote', payload, { withCredentials: true })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  /////

  /**
   * Get the list of grouped tickets that the manager(customer) is invited to vote into or created.
   * @param model
   * model.page - optional
   * model.per -optional
   * model.type - default all, optional, in [all, created, invited], to filter what is included in the list
   */
  getGroupTickets(model: any): Observable<any> {
    let params = new HttpParams()
      .set('page', model.page.toString())
      .set('per', model.per.toString())
      .set('type', model.filters.type.toString())

    // return of(GET_GROUP_TICKETS);

    return this.http.get(this.endpoint + '/group_tickets', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

}
