import { Injectable, NgModule } from '@angular/core';

import { UICommonModule } from '../../helpers/helpers.module';
import { MenuComponent } from './menu';
import { DesktopFixedMenuComponent } from "./desktop-fixed-menu";

import { MenuRouteService } from "@cbui/components/menu/menu.service";

export * from './menu';
export * from './desktop-fixed-menu';

const CB_MENU = [
  MenuComponent,
  DesktopFixedMenuComponent
]

@Injectable()
@NgModule({
  imports: [UICommonModule],
  declarations: [
    CB_MENU
  ],
  exports: [UICommonModule,
    CB_MENU
  ],
  providers: [
    MenuRouteService
  ]
})
export class MenuModule { }
