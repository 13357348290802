import { TranslateLoader } from '@ngx-translate/core';
import * as _ from 'lodash-es';

import { map, catchError } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Config } from "../config/config.service";
import { ReadyService } from './ready.service';

export class TranslationLoader implements TranslateLoader {
  private baseUrl: string = 'http://locales.colossusbets.com/languages';
  private scopes: Array<string>;
  private suffix: string = '.json';
  private isMobile: boolean = false;

  constructor(
    private http: HttpClient,
    private _config: Config,
    private _readyService: ReadyService
  ) {
    this.baseUrl = this._config.CONFIG.localesUrl;
    this.scopes = this._config.CONFIG.scopes;
    this.isMobile = this._config.CONFIG.isMobile;
  }

  public getTranslation(lang: string): any {
    if(this.isMobile) {
      // TODO: make translations loader work for mobile too without disabling and setting in another service
      this._readyService.setReady(true);
      return of(null)
    };
    const promises: Array<any> = [];
    for (const i in this.scopes) {
      if (this.scopes.hasOwnProperty(i)) {
        promises[i] = this.http.get(`${this.baseUrl}/${this.scopes[i]}/${lang}${this.suffix}?ngsw-bypass=true`).pipe(catchError(error => of(error)));
      }
    }
    return forkJoin(promises)
      .pipe(
        map(res => {
          let tx: any;
          for (const partial of res) {
            tx = _.merge(tx, partial);
          }
          this._readyService.setReady(true);
          return tx;
        }),
        catchError(catchError(error => of(error)))
      );
  }
}
