import { NgModule, Injectable } from '@angular/core';

import { WebsocketService } from './websockets.service';
import { SecureWebsocketService } from './secure-websocket.service';

// export * from './websockets.service';
// export * from './notifications.service';

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    WebsocketService,
    SecureWebsocketService
  ]
})
export class WebsocketsModule {
  constructor(private websocketService: WebsocketService) {
    websocketService.connect();
  }
}
