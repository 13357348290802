import { createFeatureSelector, createSelector } from '@ngrx/store';

// import { selectMicrositeSessionsState } from '../../NGRX/core.state';
export const selectMicrositeSessionsState = createFeatureSelector<
  AppState, 
  MicrositeSessionsState
>('micrositeSessions');

import { MicrositeSessionsState } from './coreAuth.models';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { initialState, micrositeSessionsReducer } from './coreAuth.reducer';
import { AppState } from '../core.state';

// const validRes: Observable<MicrositeSessionsState> = of(initialState);
// export const selectMicrositeSessions = (state: AppState, props: any): '"micrositePools" | "micrositeWebsockets" | "micrositeTracking" | "micrositeSessions" | "micrositeNotification" | "router" | "configuration => initialState;

export const selectMicrositeSessions = 
// undefined;
createSelector(
  selectMicrositeSessionsState,
  (state: MicrositeSessionsState) => state
);

export const selectMicrositeSessionsIsLoggedIn = 
// undefined;
createSelector(
  selectMicrositeSessionsState,
  (state: MicrositeSessionsState) => !!state.ext_id
);

export const selectMicrositeSessionsLoading = 
// undefined;
createSelector(
  selectMicrositeSessionsState,
  (state: MicrositeSessionsState) => state.isLoading
);