import { setLocalSettings } from '@cbcore/NGRX/configuration/configuration.actions';
import { AppState, triggerRegistration, triggerSignIn } from '@cbcore/NGRX/core.state';
import { Config } from "@cbcore/services/config/config.service";
import { Store } from '@ngrx/store';
import { PARTNER_CONFIG } from './config.model';
declare var SERVER_CONFIG: any;

// kept for compatibility until the ui is completely refactored. should dispatch localsettings in appmodule & replace methods with actual actions
export class MerchantConfig extends Config {
  constructor(
    private store: Store<AppState>
  ) {
    super();
    this.CONFIG = Object.assign(this.CONFIG, PARTNER_CONFIG, SERVER_CONFIG);
    this.store.dispatch(setLocalSettings({localSettings: this.CONFIG}));
  }

  // login() {
  //   this.store.dispatch(triggerSignIn());
  // }
  //
  // register() {
  //   this.store.dispatch(triggerRegistration());
  // }
}
