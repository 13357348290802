import { Component } from '@angular/core';
import { setDisplayState } from '@cbcore/NGRX/configuration/configuration.actions';
import { ConfigurationState } from '@cbcore/NGRX/configuration/configuration.models';
import { AppState, RouterStateUrl, selectConfiguration, selectRouter } from '@cbcore/NGRX/core.state';
import { RouterReducerState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'content-switch-component',
  templateUrl: './content-switch.html'
})
export class ContentSwitchComponent {
  public displayState: any;

  public ngDestroyed$ = new Subject();
  private router$: Observable<RouterReducerState<RouterStateUrl>>;
  private initialSetDisplayState: boolean = false;
  private configuration$: Observable<ConfigurationState>;

  constructor(
    private store: Store<AppState>
    ) { }

  ngOnInit(): void {
    // this.displayState = this._settingsMiddleware.displayState;
    
    this.configuration$ = this.store.select(selectConfiguration);
    this.configuration$.pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe(configuration => {
      this.displayState = configuration.displayState;
    });

    this.router$ = this.store.select(selectRouter)
    this.router$
    .pipe(takeUntil(this.ngDestroyed$))
    .subscribe(routeState => {
      if (!routeState) return;
      const previous_url = routeState.state.previousRoute;

      if(!this.initialSetDisplayState) {
        if(previous_url.includes('racing')){
          this.changeDisplayState('RACING');
        } else if(previous_url.includes('sports')){
          this.changeDisplayState('SPORTS');
        } else {
          this.changeDisplayState('SPORTS');
        }
      }
    });
  }

  changeDisplayState(type: string): void {
    this.initialSetDisplayState = true;
    this.store.dispatch(setDisplayState({displayState: {
      view: type
    }}));
  }
  //

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
  }
}
