import { createAction, props } from '@ngrx/store';
import { GroupTicketsScope } from "../../NGRX/group-tickets/group-tickets.model";

export const callFollowers = createAction('[GT] call followers',
  props<{
  model: any
}>());

export const successFollowers = createAction('[GT] call followers success',
  props<{
    response: GroupTicketsScope.ManagerFollowers
}>());

export const clearFollowers = createAction('[GT] Clear followers');

export const callInfo = createAction('[GT] call info',
props<{
  model: any
}>());

export const successInfo = createAction('[GT] call info success',
props<{
  response: GroupTicketsScope.GroupTicketsInfo
}>());

export const failFollowers = createAction('[GT] followers fail',
  props<{
    error: any
  }>());

export const failInfo = createAction('[GT] info fail',
  props<{
    error: any
  }>());


export const createGT = createAction('[GT] Create GT',
  props<{
  model: any
}>());

export const successCreate = createAction('[GT] Create GT success',
props<{
  response: any
}>());

export const failCreate = createAction('[GT] Create fail',
props<{
  error: any
}>());


export const voteGT = createAction('[GT] Vote GT',
props<{
  model: any
}>());

export const successVote = createAction('[GT] Vote GT success',
props<{
  response: any
}>());

export const failVote = createAction('[GT] Vote fail',
props<{
  error: any
}>());

// states

export const updateCreationState = createAction('[GT] update creation state',
props<{
  state: boolean
}>());

export const successCreationState = createAction('[GT] success creation state',
props<{
  response: any
}>());


export const updateSelectionsState = createAction('[GT] update selections state',
props<{
  state: boolean
}>());

export const successSelectionsState = createAction('[GT] success creation state',
props<{
  response: boolean
}>());

export const updateShowVotes = createAction('[GT] update show votes state',
  props<{
    state: boolean
  }>());

export const successShowVotes = createAction('[GT] success show votes state',
  props<{
    response: boolean
  }>());

////

export const partialUpdate = createAction('[Pools] Partial update',
props<{
  data: any, // can have model for different websocket event's data
  event?: string
}>());
