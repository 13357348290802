import { Injectable, NgModule } from '@angular/core';

import { UICommonModule } from '../../helpers/helpers.module';
import { NotificationComponent } from './notifications';
import { DefaultNotificationComponent } from './types/default';
import { OfferNotificationComponent } from './types/offer/offer';
import { FreeplayPlacedNotificationComponent } from './types/freeplay-placed/freeplay-placed';

export * from './notifications';

@Injectable()
@NgModule({
  imports: [
    UICommonModule
  ],
  declarations: [
    OfferNotificationComponent,
    FreeplayPlacedNotificationComponent,
    NotificationComponent,
    DefaultNotificationComponent
  ],
  providers: [
    DefaultNotificationComponent
  ],
  exports: [
    UICommonModule,
    NotificationComponent
  ]
})
export class NotificationsModule { }
