import { NgModule, Injectable } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';

import { CurrencyAmountPipe } from './currency-amount/currency-amount.pipe';
import { CurrencySymbolPipe } from './currency-symbol/currency-symbol.pipe';
import { DateFormatPipe } from './date/date.pipe';
import { FormatUnitsPipe } from './format-units/format-units.pipe';
import { LocaleNumberFormatPipe } from './locale-number-format/locale-number-format.pipe';
import { SportSubCodePipe } from './sport-sub-code/sport-sub-code.pipe';
import { OrderBy } from './orderBy/orderby.pipe';
import { Reverse } from './reverse/reverse.pipe';
import { OrderByNumber } from './orderByNumber/orderbynumber.pipe';
import { FormatOddsPipe } from './odds/odds.pipe';
import { OrderByCloth } from './orderByCloth/orderbycloth.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { ProtocolCheckPipe } from './protocol-check/protocol-check.pipe';
import { GroupByPipe } from './group-by/group-by.pipe';
import { PairsPipe } from './pairs/pairs.pipe';
import { DateAgoPipe} from "./date-ago/date-ago.pipe";
import { FollowersPipe } from "@cbcore/pipes/followers/followers.pipe";

@Injectable()
@NgModule({
  imports: [],
  declarations: [
    CurrencyAmountPipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    FormatUnitsPipe,
    LocaleNumberFormatPipe,
    SportSubCodePipe,
    OrderBy,
    OrderByNumber,
    FormatOddsPipe,
    Reverse,
    OrderByCloth,
    KeysPipe,
    ProtocolCheckPipe,
    GroupByPipe,
    PairsPipe,
    DateAgoPipe,
    FollowersPipe
  ],
  exports: [
    CurrencyAmountPipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    FormatUnitsPipe,
    LocaleNumberFormatPipe,
    SportSubCodePipe,
    OrderBy,
    OrderByNumber,
    FormatOddsPipe,
    Reverse,
    OrderByCloth,
    KeysPipe,
    ProtocolCheckPipe,
    GroupByPipe,
    KeysPipe,
    PairsPipe,
    DateAgoPipe,
    FollowersPipe
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    CurrencyAmountPipe,
    CurrencySymbolPipe,
    DateFormatPipe,
    FormatUnitsPipe,
    LocaleNumberFormatPipe,
    SportSubCodePipe,
    OrderBy,
    OrderByNumber,
    FormatOddsPipe,
    Reverse,
    OrderByCloth,
    KeysPipe,
    ProtocolCheckPipe,
    GroupByPipe,
    KeysPipe,
    PairsPipe,
    DateAgoPipe,
    FollowersPipe
  ]
})
export class PipesModule { }
