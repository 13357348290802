import { Component } from '@angular/core';
import { TranslationService} from "@cbcore/services/translation/translation.service";
import {UtilService} from "@cbcore/services/utils/utils/utils.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public ready: boolean | undefined;

  constructor(
    private _translationService: TranslationService,
    private _translateService: TranslateService,
    private utilsService: UtilService,
  ) {
    this.utilsService.setTheme('light');
  }

  ngOnInit() {
    this._translationService.ready().subscribe(
      (data: any) => {
        const qs = (function (a: any) {
          if (a == '') {
            return {};
          }
          let b: any = {};
          for (let i = 0; i < a.length; ++i) {
            let p = a[i].split('=', 2);
            if (p.length == 1) {
              b[p[0]] = '';
            } else {
              b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
            }
          }
          return b;
        })(window.location.search.substr(1).split('&'));


        if (qs['lang']) {
          if (this._translateService.store.currentLang !== qs['lang']) {
            this._translationService.setLanguage(qs['lang']);
          }
        }

        this.ready = true;
      }
    );
  }
}
