import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation/translation.service';

@Pipe({
  name: 'dateFormat',
  pure: false
})

@Injectable()
export class DateFormatPipe implements PipeTransform {
  dayEndings: any = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    21: 'st',
    22: 'nd',
    23: 'rd',
    31: 'st'
  }

  constructor(private _translationService: TranslationService, private datePipe: DatePipe, private _translateService: TranslateService) { }

  getDayEnding(day: number): string {
    if (this.dayEndings[day]) {
      return this.dayEndings[day]
    }
    return 'th';
  }

  transform(date: string, type: string = 'default'): any {
    const locale: string = this._translationService.getLanguage();
    let date_format: string = this._translationService.getDefaultDateFormat();

    // Convert to date object
    const currentDate = new Date(date);

    const tzo = (currentDate.getTimezoneOffset() / 60) * (-1);
    currentDate.setHours(currentDate.getHours() + tzo);
    let tzoString = '';
    try {
      // @ts-ignore
      tzoString = /.*\s(.+)/.exec((currentDate).toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1];
    } catch (e) {
      // @ts-ignore
      tzoString = (currentDate).toTimeString().match(new RegExp('[A-Z](?!.*[\(])', 'g')).join('');
    }
    // Mapping
    const mapDate: any = {
      ddd: this._translateService.instant('datetime.DAY_' + currentDate.getUTCDay()),
      DD: currentDate.getUTCDate(),
      mmm: this._translateService.instant('datetime.MONTH_' + currentDate.getUTCMonth()),
      MM: currentDate.getUTCMonth() + 1,
      YY: currentDate.getUTCFullYear(),
      // tslint:disable-next-line: max-line-length
      TT: (currentDate.getUTCHours() < 10 ? '0' + currentDate.getUTCHours() : currentDate.getUTCHours()) + ':' + (currentDate.getUTCMinutes() < 10 ? '0' + currentDate.getUTCMinutes() : currentDate.getUTCMinutes()),
      TZ: tzoString,
      term: this.getDayEnding(currentDate.getUTCDate())
    };

    switch (type) {
      case 'time':
        date_format = 'TT';
        break;
      case 'longYear':
        date_format = 'ddd DD, mmm YY';
        break;
      case 'day_month':
        date_format = 'ddd, DD mmm';
        break;
      case 'month_day':
        date_format = 'mmm DD';
        break;
      case 'day_hour':
        date_format = 'ddd, TT TZ';
        break;
      case 'full':
        date_format = 'DD/MM/YY TT';
        break;
      case 'comments':
        date_format = 'ddd DD mmm, TT';
        break;
      case 'long':
        date_format = this._translationService.getLongDateFormat();
        break;
      case 'short':
        date_format = this._translationService.getShortDateFormat();
        break;
      default: date_format = this._translationService.getDefaultDateFormat();
    }

    date_format = date_format.replace(/ddd|DD|mmm|MM|TT|TZ|YY|term/gi, function (matched) {
      return mapDate[matched];
    });

    return date_format.toString();
  }
}
