import { NgModule, Injectable } from '@angular/core';

import { StakeService } from './stake.service';

// export * from './stake.service';

export function StakeFactory(): any {
  return new StakeService();
}

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    {
      provide: StakeService,
      useFactory: StakeFactory,
      deps: []
    }
  ]
})
export class StakeModule { }
