import { Component, EventEmitter, Output, ViewChild, ElementRef, Renderer2, Input, NgZone } from '@angular/core';
import * as Croppie from 'croppie';

@Component({
  selector: 'avatar-croppie',
  templateUrl: './avatar-croppie.html'
})

export class AvatarCroppieComponent {

  @ViewChild('cropArea') cropArea!: ElementRef;
  @ViewChild('preview') preview!: ElementRef;

  @Input('file') file!: File;

  @Output() avatarCreated : EventEmitter<any> = new EventEmitter();
  @Output() croppedCanceled : EventEmitter<boolean> = new EventEmitter();


  public imageUrl: any = null;
  public croppie: any = null;
  public applyOrientation: boolean = false;
  public orientation: number = 0;

  constructor(
    public _elementRef: ElementRef,
    public _renderer: Renderer2,
    public _zone: NgZone
  ) { }

  ngOnInit(): void {
    let uagent: string = navigator.userAgent.toLowerCase();
    this.applyOrientation = /iphone/.test(uagent) || /ipod/.test(uagent) || /ipad/.test(uagent) || /android/.test(uagent);
  }

  ngAfterViewInit(): void {
    this.getOrientation(this.file, (orientation: any) => {
      this.reader(orientation ? orientation : 1);
    });
  }

  reader(orientation: any): void {
    this._zone.run(() => {
      this.orientation = orientation;
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onloadend = () => {
        this.imageUrl = reader.result;
        this.croppie = new Croppie(this.cropArea.nativeElement, {
          viewport: { width: 120, height: 120, type: 'circle' },
          boundary: { width: 200, height: 200 },
          showZoomer: true,
          enableOrientation: true
        });
        this.croppie.bind({
          url: this.imageUrl,
          orientation: this.orientation
        });
      };
    })
  }

  getOrientation(file: any, callback: Function): any {

    var reader: any = new FileReader();
    reader.onload = (e: any) => {
      if (!this.applyOrientation) return callback(1);
      let view: any = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xFFD8) return callback(-2);
      let length: any = view.byteLength;
      let offset: any = 2;
      while (offset < length) {
        let marker: any = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
          let little: any = view.getUint16(offset += 8, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          let tags: any = view.getUint16(offset, little);
          offset += 2;
          for (let i: number = 0; i < tags; i++)
            if (view.getUint16(offset + (i * 12), little) === 0x0112)
              return callback(view.getUint16(offset + (i * 12) + 8, little));
        } else if ((marker & 0xFF00) !== 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(0);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }


  createAvatar(): void {
    this.croppie.result({
      type: 'canvas',
      size: 'viewport',
      quality: 1,
      circle: false
    })
      .then((image: any) => {
        this.avatarCreated.emit(image);
      });
  }

  cancelCrop(): void {
    this.croppedCanceled.emit(true);
  }


}
