import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { delay, exhaustMap, filter, take, tap } from 'rxjs/operators';
import { routerNavigatedAction, routerNavigationAction, routerRequestAction } from '@ngrx/router-store';
import { closeSecondaryMenu, previousRoute } from './router.actions';
import { RouterStateUrl } from './router.state';
import { AppState } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
import {Config} from "@cbcore/services/config/config.service";
import {PostMessageService} from "@cbcore/services/utils/postmessage/postmessage.service";
import {ScrollService} from "@cbcore/services/utils/scroll/scroll.service";


const blackListedHistoryPaths = ['/pools/sports', '/pools/racing'];

@Injectable()
export class RouterEffects {
  // General
  routerNavigatedAction = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(propVal =>
        !blackListedHistoryPaths.includes(propVal.payload.event.url) &&
        !propVal.payload.event.url.includes('wallet') &&
        !propVal.payload.event.url.includes('?') || propVal.payload.event.url.includes('invitations')
      ),
      filter(propVal => (this.history.length ? this.history[this.history.length - 1] : '/') !== propVal.payload.event.url),
      tap((propVal) => {
        this.store.dispatch(closeSecondaryMenu());
        this.history = [...this.history, propVal.payload.event.url];
      }));
  }, { dispatch: false });

  previousRoute = createEffect(() => {
    return this.actions$.pipe(
      ofType(previousRoute),
      tap((propVal) => {
        const previousElementInHistory = propVal.customRoute ? propVal.customRoute : (this.history.length > 1 ? this.history[this.history.length - 2] : '/');
        this._router.navigate([previousElementInHistory]);
        this.history.pop();
        this.history.pop();
      }));
  }, { dispatch: false });

  postMessageOnChangeRoute = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerRequestAction),
      tap((propVal) => {
        this._scrollService.scrollTop();
        if (this.allowRouteChangedMessage) {
          this._postMessageService.routeChanged(propVal.payload.routerState.url);
        }
      }));
  }, { dispatch: false });

  getPreviousRoute () {
    return this.history[this.history.length - 1] || '/';
  }

  private history: any[] = [];
  private allowRouteChangedMessage: boolean;

  constructor(
    private store: Store<AppState>,
    private _router: Router,
    private actions$: Actions,
    private _activatedRoute: ActivatedRoute,
    private _config: Config,
    private _scrollService: ScrollService,
    private _postMessageService: PostMessageService,
  ) {
    this.allowRouteChangedMessage = this._config.CONFIG.allowRouteChangedMessage;
   }
}


/*
  * old navigationservice used to save the previousRoute in localstorage
  Maybe as in the header component's case here, it would be better to add it's effects to the component folder.
  qp['terms'] - these params are handled for the app in the processDeepLink effect
  qp.resetpassword - just redirects to the view as if you clicked from the login view on the forgot details button
  qp.reset_password - coming from an email with token in order to actually input a new password

*/
