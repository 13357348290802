import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppState } from '@cbcore/NGRX/core.state';


import { Config} from "@cbcore/services/config/config.service";
import { CbfeCoreModule } from "@cbcore/cbfe-core.module";
import { UICommonModule } from "@cbui/helpers/helpers.module";
import { MenuModule } from "@cbui/components/menu/menu.module";
import { NotificationsModule} from "@cbui/components/notifications/notification.module";

import { MerchantConfig } from './config/config.service';
import { MerchantService } from './services/merchant';
import { ActivityService } from './services/activity.service';

// To add app boostrap where to execute translation loading and some other initial functions
// in order to avoid multiple http calls to same resources
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';

import { MerchantEffects } from "./config/NGRX/merchant/merchant.effects";
import { merchantReducer } from "./config/NGRX/merchant/merchant.reducer";


export function MerchantConfigFactory(store: Store<AppState>): any {
  return new MerchantConfig(store);
}

import { MicrositeModule } from "@cbui/microsite.module";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import { HeaderComponent } from "@app/components/header/header";

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    StoreModule.forFeature('merchant', merchantReducer),
    EffectsModule.forFeature([MerchantEffects]),
    CbfeCoreModule.forRoot(),
    UICommonModule.forRoot(),

    MicrositeModule,
    AppRoutingModule,
    MenuModule,
    NotificationsModule
  ],
  providers: [
    {
      provide: Config,
      useFactory: MerchantConfigFactory,
      deps: [Store]
    },
    MerchantService,
    ActivityService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _activityService: ActivityService) {
    _activityService.init();
  }
}
