import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export const API_DATA: any = {
  'total_items': 223,
  'prefix': 'Avatar_',
  'suffix': '.jpg',
  'default_image': 'default'
};

@Injectable()
export class ImageClient {
  public endpoint: string;
  public _apiData: BehaviorSubject<any>;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.imagesUrl + '/config';
    this._apiData = new BehaviorSubject(API_DATA);
  }

  getConfig(): Observable<any> {
    // return this.http.get(this.endpoint+ '/useravatars.json', { withCredentials: false })
    // .pipe(
    //   map(data => data),
    //   catchError(err => throwError(err))
    // );
    return this._apiData.asObservable();
  }
}
