import { NgModule, Injectable } from '@angular/core';

import { UtilService } from './utils/utils.service';
import { WindowService } from './window/window.service';
import { LocalStorage } from './localstorage/localstorage.service';
import { CookieService } from './cookies/cookies.service';
import { ImageService } from './image/image.service';
import { CordovaService } from './cordova/cordova.service';
import { LinkService } from './links/links.service';
import { TwitterService } from './social-networks/twitter.service';
import { WhatsappService } from './social-networks/whatsapp.service';
import { CalculationsService } from './calculations/calculations.service';
import { ResizeService } from './resize/resize.service';
import { ScrollService } from './scroll/scroll.service';
import { PostMessageService } from './postmessage/postmessage.service';
import { RAFService } from './raf/raf.service';
import { BreakpointNotifier } from './breakpoint-notifier/breakpoint-notifier.service';
import { AppVersionService } from "./app-version/app-version.service";
import { WebLocationService } from './location/location.service';

export * from './utils/utils.service';
export * from './window/window.service';
export * from './localstorage/localstorage.service';
export * from './cookies/cookies.service';
export * from './image/image.service';
export * from './cordova/cordova.service';
export * from './links/links.service';
export * from './social-networks/twitter.service';
export * from './social-networks/whatsapp.service';
export * from './calculations/calculations.service';
export * from './resize/resize.service';
export * from './scroll/scroll.service';
export * from './postmessage/postmessage.service';
export * from './raf/raf.service';
export * from './breakpoint-notifier/breakpoint-notifier.service';
export * from "./app-version/app-version.service";


@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    UtilService,
    WindowService,
    LocalStorage,
    CookieService,
    ImageService,
    CordovaService,
    LinkService,
    TwitterService,
    WhatsappService,
    CalculationsService,
    ResizeService,
    ScrollService,
    PostMessageService,
    RAFService,
    BreakpointNotifier,
    AppVersionService,
    WebLocationService
  ]
})
export class UtilsModule {
  constructor(
    _resizeService: ResizeService,
    _rafService: RAFService,
    _breakpointNotifier: BreakpointNotifier,
    _utilService: UtilService,
    _webLocation: WebLocationService) {
    _resizeService.listen();
    _rafService.init();
    _breakpointNotifier.init();
    _utilService.init();
    _webLocation.initLocation();
  }
}
