import { Injectable } from '@angular/core';

// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/angular";
// for A15 when migration complete
// import * as Sentry from "@sentry/angular-ivy";

import { RewriteFrames } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { Config } from '../config/config.service';
import { LocalStorage } from '../utils/utils.module';

// import { version, name } from "@root/package.json";

@Injectable()
export class SentryService {
  offlineEventsKey: string = '_cb_offline_events';
  previousRequestResponseKey: string = '_cb_previous_response';
  previousRequestResponse: string;

  constructor(
    private _config: Config,
    private _localStorage: LocalStorage
  ) { }

  init() {
    const sentryUrl = this._config.CONFIG.ravenUrl;
    const DSN = sentryUrl;
    // const releaseVersion = name + '@' + version;

    const sentryOptions: any = {
      dsn: DSN,
      defaultIntegrations: false,
      integrations: [
        new RewriteFrames(),

        // optional for tracing
        // new BrowserTracing({
        //   tracingOrigins: ["localhost", "https://yourserver.io/api"],
        //   routingInstrumentation: Sentry.routingInstrumentation,
        // }),
        // tracesSampleRate: 0,
      ],
      tracesSampleRate: 0.1, // 10%
      ignoreErrors: [
        'top.GLOBALS',
        'originalCreateNotification',
        // Facebook borked
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Cannot read property \'scrollIntoView\' of undefined', // Scrolling error
        'Non-Error exception captured with keys: code, errors, info, message, status', // Empty error
        'null is not an object (evaluating \'r[t]\')', // Mouseflow
        'null is not an object (evaluating \'o\')', // Mouseflow
        'o is null', // Mouseflow
        '[big.js] Invalid number', // Big.js
        't[0] is undefined', // ScrollIntoView

      ],
      denyUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /api\.hubapi\.com/i,
        /o2\.mouseflow\.com/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ]
    };

    // Add release management for sentry
    // if (releaseVersion) {
    //   sentryOptions.release = releaseVersion;
    // }

    Sentry.init(sentryOptions);

    this.setTags();

    this.checkOfflineEvents();
  }

  setTags() {
    Sentry.configureScope(scope => {
      scope.setTag('stack', 'FRONTEND');
    });
  }

  logError(error: any, errorInfo: any): void {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  savePreviousRequestResponse(response: string) {
    if (!response) return;
    this.previousRequestResponse = JSON.stringify(response);
    this._localStorage.set(this.previousRequestResponseKey, this.previousRequestResponse);
  }

  getPreviousResponse() {
    return this.previousRequestResponse;
  }

  sendEvent(error: any, hint?: any): void {
    if (!error) return;
    let isConnected = window.navigator.onLine;

    if (isConnected) {
      Sentry.captureEvent(error, hint);
    } else {
      let newOfflineEvents = [error];
      let savedEvents = this._localStorage.get(this.offlineEventsKey);
      newOfflineEvents = newOfflineEvents.concat(savedEvents);
      this._localStorage.set(this.offlineEventsKey, newOfflineEvents);
    }
  }

  checkOfflineEvents() {
    this._localStorage.get(this.offlineEventsKey).then(res => {
        const savedEvents = res;
        if (savedEvents) {
          for (var i = 0; i < savedEvents.length - 1; i++) {
            this.sendEvent(savedEvents[i]);
            this.deleteSavedEvent(savedEvents[i]);
          }
        }
    })
  }

  deleteSavedEvent(error: any) {
    if (!error) return;
    this._localStorage.get(this.offlineEventsKey).then(res => {
        const savedEvents = res;
        let newOfflineEvents = savedEvents.splice(savedEvents.indexOf(error), 1);
        this._localStorage.set(this.offlineEventsKey, newOfflineEvents).then(res => {});
    })
  }
}
