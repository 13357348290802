import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../utils/utils.module';

@Injectable()
export class LegSelectedService {
  private $observable: BehaviorSubject<number>;
  private $selectedRow: BehaviorSubject<number|null>;
  private $sort_by: BehaviorSubject<any>;
  public anyIndex: number = 100;
  public summaryIndex: number = 1000;

  private sort_options: any[] = [
    { type: 'cloth_number', fractional_odds: true, sort_model: ['cloth_number'] },
    { type: 'probability', fractional_odds: false, sort_model: ['probability', 'cloth_number'] },
    { type: 'probability', fractional_odds: true, sort_model: ['probability', 'cloth_number'] }
  ];

  constructor(
    private _locaStorage: LocalStorage
  ) {
    this.$observable = new BehaviorSubject(1);
    this.$sort_by = new BehaviorSubject(this.sort_options[0]);
    this._locaStorage.get('race_sort').then(res => {
      this.$sort_by.next(res || this.sort_options[0])
    })
    this.$selectedRow = new BehaviorSubject<any>(null);
  }

  set(selected_leg: number): number {
    this.$observable.next(selected_leg);
    return this.$observable.value;
  }

  get(): BehaviorSubject<number> {
    return this.$observable;
  }

  reset(): void {
    this.$observable.next(1);
  }

  getSortOptions(): any {
    return this.sort_options;
  }

  setSortBy(sort_by: any): void {
    if (sort_by.type === 'cloth_number') {
      sort_by.fractional_odds = this.$sort_by.value.fractional_odds;
    }
    this._locaStorage.set('race_sort', sort_by);
    this.$sort_by.next(sort_by);
  }

  getSortBy(): BehaviorSubject<string> {
    return this.$sort_by;
  }

  setSelectedRow(selected_row: number | null): number | null {
    this.$selectedRow.next(selected_row);
    return this.$selectedRow.value;
  }

  getSelectedRow(): BehaviorSubject<number|null> {
    return this.$selectedRow;
  }
}
