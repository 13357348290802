<!--<div id="avatar-uploader">-->
<!--  <div class="clearfix">-->
<!--    <div class="col-sm-12 uploader-box">-->
<!--      <div *ngIf="!file" class="drop-area">-->
<!--        <form class="uploader-form">-->
<!--          <i *ngIf="!loading" class="cb-icon-image"></i>-->
<!--          <i *ngIf="loading" class="cb-loader"></i>-->
<!--          <input type="file" id="fileElem" accept="image/*" (change)="handleSelect($event)" >-->
<!--          <label *ngIf="!loading" class="cb-btn cb-btn-tertiary btn-width d-none d-lg-flex align-items-center justify-content-center" for="fileElem" [translate]="'avatar.SELECT_PHOTO'" #inputFile></label>-->
<!--          <label *ngIf="!loading" class="cb-btn cb-btn-primary btn-width d-flex d-lg-none align-items-center justify-content-center" for="fileElem" [translate]="'avatar.SELECT_PHOTO'"></label>-->

<!--        </form>-->
<!--        <div *ngIf="error" class="alert-danger error">-->
<!--          <span [translate]="'avatar.ERROR_' + errorType"></span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <avatar-croppie (avatarCreated)="handleAvatarCropped($event)" (croppedCanceled)="fileCanceled($event)" *ngIf="file && !croppedImage" [file]="file"></avatar-croppie>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div id="avatar-uploader">
  <div class="clearfix">
    <div class="w-full uploader-box">
      <div *ngIf="!file" class="drop-area">
        <form class="uploader-form flex flex-row justify-center items-center">

          <div class="w-full flex flex-row">
            <div class="w-full">
              <i *ngIf="!loading" class="far fa-image"></i>
              <svg *ngIf="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>

              <!--
              <label class="image-upload-container row w-full">
                <span class="btn primary w-button w-full text" [translate]="'buttons.UPLOAD_DOC'"></span>
                <input type="file" #imageInput accept="{{accept}}" (change)="processFile($event)">
              </label>
              -->
              <div class="flex flex-row w-full justify-center items-center">
                <div class="w-8/12 block relative">
                  <input type="file" id="fileElem" accept="image/*" (change)="handleSelect($event)">
                  <label *ngIf="!loading" class="_hidden btn secondary w-button w-49" for="fileElem" [translate]="'avatar.SELECT_PHOTO'" #inputFile></label>
                </div>
  
                <div class="w-4/12 h-12 flex flex-row items-center">
                  <button *ngIf="android" class="btn tertiary w-button w-49" (click)="handleSelectCordova()">Camera</button>
                </div>
              </div>

            </div>
          </div>

        </form>
        <div *ngIf="error" class="bg-error-bg text-error-color p-custom-8 rounded-container mt-custom-12">
          <span [translate]="'avatar.ERROR_' + errorType"></span>
        </div>
      </div>

      <avatar-croppie (avatarCreated)="handleAvatarCropped($event)" (croppedCanceled)="fileCanceled($event)" *ngIf="file && !croppedImage" [file]="file"></avatar-croppie>
    </div>
  </div>
</div>
