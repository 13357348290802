import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppState,
  failMicrositeSessions,
  RouterStateUrl,
  selectConfiguration,
  selectRouter,
  successCoreSettings,
  successMicrositeSessions,
  track
} from '@cbcore/NGRX/core.state';

import { select, Store } from '@ngrx/store';
import {
  followManager,
  getManagersList,
  getManager,
  successManager,
  // successMyManager,
  successManagersList,
  unfollowManager,
  // getMyManager,
  successCreateManager,
  createManager,
  updateManager,
  successUpdateManager,
  getLeaderboard,
  successLeaderboard,
  failManager,
  getTopFollowed,
  successTopFollowed,
  reloadManager,
  getManagersListLoadMore,
  removePinnedSyndicates,
  reloadLeaderboard,
  loadMoreLeaderboard,
  loadLeaderboardWidget,
  loadTopPositiveROI, successTopPositiveROI,
  getManagersSearchListLoadMore
} from "@cbcore/NGRX/manager/manager.actions";
import {catchError, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {ManagerClient} from "@cbcore/client/manager";
import {EMPTY, of} from "rxjs";
import {failPools} from "@cbcore/NGRX/pool/pool.actions";
import { routerNavigatedAction, routerNavigationAction } from '@ngrx/router-store';
import { selectManagersState } from './manager.selectors';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { initialState } from './manager.reducer';
import { callList, loadList } from '../syndicates/syndicates.actions';
import {SentryService} from "@cbcore/services/sentry/sentry.service";

@Injectable()
export class ManagerEffects {

  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    public managerClient: ManagerClient,
    private _router: Router,
    private sentryService: SentryService
  ) {}

  routerNavigationManagerToProfile = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => propVal.payload.event.url.includes('/manager') && !propVal.payload.event.url.includes('group-confirmed')),
      map(propVal => (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.manager_id),
      withLatestFrom(this.store.select(selectManagersState)),
      tap(([managerId, managersState]) => {
        if(Number(managerId) === managersState.my_manager?.id) {
          this._router.navigate(['/', 'syndicates', 'profile', managerId])
        }
      }))
  }, { dispatch: false });

  routerNavigationManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => (propVal.payload.event.url.includes('/manager') || propVal.payload.event.url.includes('/profile'))),
      map(propVal => (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.manager_id),
      filter(managerId => !!managerId),
      exhaustMap((managerId: string) => {
        return of(getManager({id: Number(managerId)}));
      }))
  }, { dispatch: true });

  routerNavigatedManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => (propVal.payload.event.url.includes('/manager') || propVal.payload.event.url.includes('/profile'))),
      map(propVal => (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.manager_id),
      filter(managerId => !!managerId),
      exhaustMap((managerId: string) => {
        return of(loadList({model: { filters: {
          managers: managerId
        }}}));
      }))
  }, { dispatch: true });

  getManagersList = createEffect(() => {
    return this.actions$.pipe(
      ofType(getManagersList),
      exhaustMap((propVal) => {
        const params = {
          page: propVal.model.page || 1,
          per: propVal.model.per || 6,
          filters: {
            search: propVal.model.search,
            followed: propVal.model.followed,
          }
        }

        return this.managerClient.list(params).pipe(
          map(data => {
            // KEPT FOR COMPATIBILITY
            // this._profileMiddleware.updateManagers(data);

            return successManagersList( { response: data, params: params });
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  });

  failMicrositeSession = createEffect(() => {
    return this.actions$.pipe(
      ofType(failMicrositeSessions),
      withLatestFrom(this.store.select(selectRouter)),
      tap(([propVal, routerState]) => {
        try {
          if (routerState.state.url.includes('profile')) {
            this._router.navigate(['/', 'syndicates', 'manager', routerState.state.params.manager_id]);
          }
        } catch(e) {}
      })
    )
  }, {dispatch: false})

  getManagersListLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(getManagersListLoadMore),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([model, managersState]) => {
        return of(getManagersList({
          model: Object.assign({}, managersState.listParams,
            { page: managersState.listParams.page + 1 })
        }))
      })
    )
  });

  getManagersSearchListLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(getManagersSearchListLoadMore),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([model, managersState]) => {
        return of(getManagersList({
          model: Object.assign({}, managersState.listParams,
            { page: model.model.page, search: model.search, followed: false})
        }))
      })
    )
  });

  reloadManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(reloadManager),
      withLatestFrom(this.store.select(selectManagersState)),
      filter(([model, managersState]) => !!managersState.manager || !!managersState.my_manager),
      exhaustMap(([model, managersState]) => {
        // check
        return of(getManager({id: managersState.manager?.id || managersState.my_manager?.id}));
      })
    )
  }, { dispatch: true });

  removePinnedSyndicates = createEffect(() => {
    return this.actions$.pipe(
      ofType(removePinnedSyndicates),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([propVal, managersState]) => {
        const manager = managersState.my_manager;
        const payload: any = {
          id: manager.id,
          manager: {
            picture_url: manager.picture_url,
            twitter_username: manager.twitter_username,
            favourite_syndicates: []
          }
        };
        return of(updateManager({model: payload}))
      })
    )
  });

  getManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(getManager),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([model, managersState]) => {
        return this.managerClient.get(model.id).pipe(
          map(data => {
            // KEPT FOR COMPATIBILITY
            if(data.id === managersState.my_manager?.id) {
              // this._profileMiddleware.updateData(data);
            } else {
              // this._managerMiddleware.updateData(data);
            }
            return successManager( { response: data });
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  });

  successMicrositeSessions = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeSessions),
      exhaustMap((propVal) => {
        return of(getManager({id: propVal.response.manager_id}));
    }))}, { dispatch: true });

  ///

  createManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(createManager),
      switchMap((model: any) => {
        return this.managerClient.create(model).pipe(
          switchMap(data => {
            return [getManager(data.id), successCreateManager( { response: data })]
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  });

  updateManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateManager),
      withLatestFrom(this.store.select(selectManagersState)),
      switchMap(([propVal, managersState]) => {
        const updatePinnedSyndicates = managersState.my_manager.pinnedSyndicates;

        return this.managerClient.update(Object.assign(
          {},
          propVal.model,
          {
            ...propVal.model,
            manager: {
              ...propVal.model.manager,
              favourite_syndicates: updatePinnedSyndicates?.length ? updatePinnedSyndicates
                                    : propVal.model.manager.favourite_syndicates ? propVal.model.manager.favourite_syndicates : []
            }
          })).pipe(
            switchMap(data => {
            return [
              successUpdateManager( { response: data })
            ]
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true });

  successUpdateManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(successUpdateManager),
      delay(3000),
      switchMap((model) => {
        return [
          getManager({id: model.response.id}),
          callList({})
        ]
      })
    )
  }, { dispatch: true });

  trackingSuccessUpdateManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(successUpdateManager),
      withLatestFrom(this.store.select(selectManagersState)),
      tap(([model, managersState]) => {
        let eventsArray: any = [];
        if (managersState.my_manager.bio !== model.response.bio) {
          eventsArray.push({
            eventCategory: 'Manager',
            eventAction: 'Click',
            eventLabel: 'Bio',
            eventValue: managersState.my_manager.username
          })

        }

        if (managersState.my_manager.twitter_username !== model.response.twitter_username) {
          eventsArray.push({
            eventCategory: 'Manager',
            eventAction: 'Click',
            eventLabel: 'Twitter',
            eventValue: managersState.my_manager.username
          })
        }

        if ((managersState.my_manager.picture_url !== model.response.picture_url)/* || this.avatarCropped dontkno*/) {
          eventsArray.push({
            eventCategory: 'Manager',
            eventAction: 'Click',
            eventLabel: 'Avatar',
            eventValue: managersState.my_manager.username
          })
        }

        // TODO: track pinned selections too

        eventsArray.forEach(event => {
          this.store.dispatch(track({event: event}));
        });

      })
    )
  }, { dispatch: false });



  ///
  followManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(followManager),
      switchMap((id: any) =>
        this.managerClient.follow(id).pipe(
          switchMap(response => {
            return [getManager(id)]
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      )
    )
  }, {dispatch: false});

  unfollowManager = createEffect(() => {
    return this.actions$.pipe(
      ofType(unfollowManager),
      switchMap((id: any) =>
        this.managerClient.unfollow(id).pipe(
          switchMap(response => {
            return [getManager(id)]
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      )
    )
  }, {dispatch: false});

  routerNavigationLeaderboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction, successCoreSettings),
      withLatestFrom(this.store.select(selectRouter), this.store.select(selectConfiguration)),
      filter(props => {
        try {
          const allow = props[1].state.url.includes('leaderboard') && !!props[2].coreSettings;
          return allow;
        } catch (e: any) {
          this.sentryService.sendEvent(e, 'routerNavigationLeaderboard');
          return false;
        }
      }),
      exhaustMap((propVal) => {
        const sport = propVal[1].state.params.sport ? propVal[1].state.params.sport.toUpperCase() : 'ALL';
        const sport_category = propVal[2].coreSettings.sport_categories.find(
          (cat: any) => cat.code === sport
        );
        return of(getLeaderboard({model: {
          sport_category: sport_category,
          page: 1
        }}));
      }))
  }, { dispatch: true });

  reloadLeaderboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(reloadLeaderboard),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([propVal, managersState]) => {
        return of(getLeaderboard({model: Object.assign({},
          managersState.leaderboardParams,
          {
            page: 1
          }
          )}))
      })
    )
  });

  loadMoreLeaderboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMoreLeaderboard),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([propVal, managersState]) => {
        return of(getLeaderboard({model: Object.assign({},
          managersState.leaderboardParams,
          {
            page: managersState.leaderboardParams.page + 1
          }
          )}))
      })
    )
  });

  getLeaderboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLeaderboard),
      withLatestFrom(this.store.select(selectManagersState)),
      exhaustMap(([propVal, managersState]) => {

        return this.managerClient.get_leaderboard(
          propVal.model.isWidget ? managersState.leaderboardWidgetParams :
          managersState.leaderboardParams
        ).pipe(
          map(data => {
            return successLeaderboard( { response: data });
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  });

  trackGetLeaderboard = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLeaderboard),
      withLatestFrom(this.store.select(selectManagersState)),
      filter(([propVal, managersState]) => !propVal.model.isWidget),
      exhaustMap(([propVal, managersState]) => {
        const params = managersState.leaderboardParams;

        const eventData = {
          type: params.sport_category.code,
          period: `${params.period.time_value}_${params.period.time_key}`,
          open_syndicates: params.open_syndicates
        }

        return of(track({event: eventData}))
      })
    )
  });


  loadLeaderboardWidget = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadLeaderboardWidget, successCoreSettings),
      withLatestFrom(this.store.select(selectConfiguration), this.store.select(selectManagersState)),
      exhaustMap((propVal) => {
        if(!propVal[1].coreSettings || !propVal[2].leaderboardWidgetParams.sportId) return EMPTY;
        const sport_category = propVal[1].coreSettings.sport_categories.find(
          (cat: any) => cat.id === propVal[2].leaderboardWidgetParams.sportId
        );
        return of(getLeaderboard({model: {
          sport_category: sport_category,
          page: 1,
          per: 4,
          isWidget: true
        }}));
      }))
  }, { dispatch: true });


  loadTopROICaptains = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTopPositiveROI, successCoreSettings),
      withLatestFrom(this.store.select(selectConfiguration), this.store.select(selectManagersState)),
      exhaustMap((propVal) => {
        if(!propVal[1].coreSettings || !propVal[2].leaderboardWidgetParams.sportId) return EMPTY;
        const sport_category = propVal[1].coreSettings.sport_categories.find(
          (cat: any) => cat.id === propVal[2].leaderboardWidgetParams.sportId
        );
        const model ={
          sport_category: sport_category,
          page: 1,
          per: 4,
          period: propVal[2].leaderboardWidgetParams.period,
          sort_by: propVal[2].leaderboardWidgetParams.sort_by,
          open_syndicates: propVal[2].leaderboardWidgetParams.open_syndicates,
          isWidget: true
        };

        return this.managerClient.get_leaderboard(model).pipe(
          map(data => {
            return successTopPositiveROI( { response: data });
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      }))
  }, { dispatch: true });

  getTopFollowed = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTopFollowed),
      exhaustMap((model: any) => {
        return this.managerClient.get_leaderboard(model).pipe(
          map(data => {
            return successTopFollowed( { response: data });
          }),
          catchError(error => {
            return of(failManager({ error: error }))
          })
        )
      })
    )
  });
}
