import { Injectable }  from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Config }      from './../config/config.service';

@Injectable()
export class SyndicatesRouteGuard implements CanActivate {
  rootUrl: string;

  constructor(private _config: Config, private _router: Router) {
    this.rootUrl = this._config.CONFIG.rootUrl;
  }

  canActivate() {
    return this.checkEnabled();
  }

  checkEnabled(): boolean {
    if (this._config.CONFIG.enableSyndicates) { return true; }

    this._router.navigate([this.rootUrl]);
    return false;
  }
}
