import { Injectable }  from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Config }      from './../config/config.service';
import { AppState } from '@cbcore/NGRX/core.state';
import { selectMicrositeSessions } from '../../NGRX/coreAuth/coreAuth.selectors';
import {select, Store} from '@ngrx/store';
import {Subject} from "rxjs";
import {map, take} from "rxjs/operators";

@Injectable()
export class FreeplayRouteGuard implements CanActivate {
  rootUrl: string;
  skipVerifyAge: boolean;
  public ngDestroyed$ = new Subject();

  constructor (
    private _config: Config,
    private _router: Router,
    private store: Store<AppState>
  ) {
    this.rootUrl = this._config.CONFIG.rootUrl;
    this.skipVerifyAge = this._config.CONFIG.skipVerifyAge;
  }

  canActivate() {
    let isAgeVerified = true;

    if (this.skipVerifyAge) {
      return this.checkEnabled(isAgeVerified);
    }

    return this.store.pipe(
      select(selectMicrositeSessions)
    ).pipe(
      map(data => {
        if (!!data.ext_id) {
          isAgeVerified = data.age_verified;
          return this.checkEnabled(isAgeVerified);
        } else {
          return false;
        }
      }),
      take(1)
    )
  }


  checkEnabled(isAgeVerified: any): boolean {
    if (this._config.CONFIG.enableFreeplay && isAgeVerified) { return true; }

    this._router.navigate([this.rootUrl]);
    return false;
  }
}
