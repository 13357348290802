import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';
import { LocaleNumberFormatPipe } from '../locale-number-format/locale-number-format.pipe';
import { CurrencySymbolPipe } from '../currency-symbol/currency-symbol.pipe';

@Pipe({
  name: 'currencyAmount',
  pure: false
})

@Injectable()

export class CurrencyAmountPipe implements PipeTransform {

  constructor (private nf: LocaleNumberFormatPipe, private cs: CurrencySymbolPipe, private _translationService: TranslationService) {}

  transform(amount: any, currencyCode: string = '', precision: any = 2, disableMultiplier: boolean = false): any {
    amount = Number(amount);

    const totalAmount: string = this.nf.transform(amount, precision, disableMultiplier);
    const currency: string = this.cs.transform(currencyCode);

    let position: string = this._translationService.getCurrencyPosition();
    let finalValue

    if (position === 'left') {
      finalValue = currency + totalAmount;
      if(totalAmount.includes('-')) {
        finalValue = finalValue.replace('-', '');
        finalValue = `-${finalValue}`;
      }
      return finalValue;
    }
    return totalAmount + currency;
  }
}
