import { PoolsMenuScope } from '@cbcore/NGRX/pool/pool.models';
import { PoolDisplayGroup } from './pool-display-group';

export class PoolList {
  constructor (
    public big_jackpots: Array<any> = [],
    public up_next: Array<any> = [],
    public pools: Array<PoolsMenuScope.Pool> = [],
    public open_pools: Array<any> = [],
    public free_play_only: Array<any> = [],
    public running_pools: Array<any> = [],
    public official_pools: Array<any> = [],
    public display_groups: Array<PoolDisplayGroup> = [],
    public groups_availables: Array<PoolDisplayGroup> = []
  ) {}
}
