import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pools',
    pathMatch: 'full',
  },
  { path: 'pools', loadChildren: () => import('../cb-ui/modules/pools/pools.module').then(m => m.PoolsModule)},
  { path: 'freeplay', loadChildren: () => import('../cb-ui/modules/freeplay/freeplay.module').then(m => m.FreeplayModule)},
  { path: 'syndicates', loadChildren: () => import('../cb-ui/modules/syndicates/syndicates.module').then(m => m.SyndicatesModule)},
  { path: 'tickets', loadChildren: () => import('../cb-ui/modules/tickets/tickets.module').then(m => m.TicketsModule)},
  { path: 'results', loadChildren: () => import('../cb-ui/modules/results/results.module').then(m => m.ResultsModule)},
  { path: 'help', loadChildren: () => import('../cb-ui/modules/help/help.module').then(m => m.HelpModule) },
  { path: 'errors', loadChildren: () => import('../cb-ui/modules/errors/errors.module').then(m => m.ErrorModule)},
  { path: '**', redirectTo: '/' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
// @ts-ignore
export class AppRoutingModule {}

