import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {AppState, selectCurrentManager, selectFollowers, selectInfo, selectTicket, successMicrositeSessions} from '@cbcore/NGRX/core.state';

import { select, Store } from '@ngrx/store';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from "rxjs/operators";

import * as _ from 'lodash-es';

import {EMPTY, of} from "rxjs";
import { GroupTicketClient } from "@cbcore/client/group_tickets";
import {
  callFollowers,
  callInfo,
  clearFollowers,
  createGT,
  failCreate,
  failFollowers,
  failInfo,
  failVote, partialUpdate,
  successCreate,
  successCreationState,
  successFollowers,
  successInfo,
  successSelectionsState, successShowVotes,
  successVote,
  updateCreationState,
  updateSelectionsState,
  updateShowVotes,
  voteGT
} from "@cbcore/NGRX/group-tickets/group-ticket.actions";
import {GroupTicketsScope} from "@cbcore/NGRX/group-tickets/group-tickets.model";
import {setWebsocketEvent} from "@cbcore/NGRX/coreWebsockets/coreWebsockets.actions";
import {selectCurrentPool} from "@cbcore/NGRX/pool/pool.selectors";
import {callGTTicket, successGTTicket} from "@cbcore/NGRX/tickets/tickets.actions";
import {TicketsScope} from "@cbcore/NGRX/tickets/tickets.model";
import {selectGroupTicket} from "@cbcore/NGRX/tickets/tickets.selector";


@Injectable()
export class GroupTicketEffects {
  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    public groupTicketClient: GroupTicketClient
  ) {}

  successMicrositeSessions = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeSessions),
      exhaustMap((propVal) => {

        if(propVal.response.manager_id) {
          return of(callInfo({ model: { manager_id: propVal.response.manager_id } }));
        }

        return EMPTY;
      })
    )
  }, { dispatch: true });

  getFollowers = createEffect(() => {
    return this.actions$.pipe(
      ofType(callFollowers),
      exhaustMap((propVal) => {
        return this.groupTicketClient.getManagerFollowers(propVal.model).pipe(
          map((data: GroupTicketsScope.ManagerFollowers) => {

            return successFollowers( { response: data });
          }),
          catchError(error => {
            return of(failFollowers({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true });

  clearFollowers = createEffect(() => {
    return this.actions$.pipe(
      ofType(clearFollowers),
      switchMap((propVal: any) => {
        return [successFollowers({response: null})];
      }),
      catchError(error => {
        return of(failFollowers({ error: error }))
      })
    )
  })

  getInfo = createEffect(() => {
    return this.actions$.pipe(
      ofType(callInfo),
      exhaustMap((propVal: any) => {
        return this.groupTicketClient.getGroupTicketsInfo(propVal).pipe(
          map((data: GroupTicketsScope.GroupTicketsInfo) => {

            return successInfo( { response: data });
          }),
          catchError(error => {
            return of(failInfo({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true });

  ///

  createGTTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(createGT),
      exhaustMap((model: any) => {
        return this.groupTicketClient.createGroupTicket(model).pipe(
          map((res: GroupTicketsScope.GroupTicketCreated) => {

            return successCreate({response: res});
          }),
          catchError(error => {
            return of(failCreate({error: error}));
          })
        )
      })
    )
  }, { dispatch: true });


  voteGT = createEffect(() => {
    return this.actions$.pipe(
      ofType(voteGT),
      exhaustMap((model: any) => {
        return this.groupTicketClient.vote(model).pipe(
          map((res: any) => {

            return successVote({response: res});
          }),
          catchError(error => {
            return of(failVote({error: error}));
          })
        )
      })
    )
  });


  /// states

  updateCreationState = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateCreationState),
      switchMap((propVal: any) => {
        return [successCreationState({response: propVal.state})];
      })
    )
  })

  updateSelectionsState = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateSelectionsState),
      switchMap((propVal: any) => {
        return [successSelectionsState({response: propVal.state})];
      })
    )
  })

  updateShowVotesState = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateShowVotes),
      switchMap((propVal: any) => {
        return [successShowVotes({response: propVal.state})];
      })
    )
  })


  /// WS

  setWebsocketEvent = createEffect(() => {
    return this.actions$.pipe(
      ofType(setWebsocketEvent),
      //TODO: Stefan to check, I don't think 'filtering' is needed here since will be done inside to accommodate all cases...
      withLatestFrom(this.store.select(selectInfo), this.store.select(selectGroupTicket), this.store.select(selectCurrentPool), this.store.select(selectCurrentManager)),
      switchMap(([propVal, info, currentTicket, pool, currentManager]) => {
        if (/VOTES_UPDATE/.test(propVal.event)) {
          if (Number(propVal.data.pool_id) === Number(pool.id) && (Number(propVal.data.manager_id) === Number(currentManager.id))) {
            if (propVal.data && currentTicket) {
              const gticket: any = _.cloneDeep(currentTicket);
              gticket.most_voted_selection_string = propVal.data.most_voted_selection_string;
              gticket.votes = propVal.data.votes;
              gticket.voted_count = propVal.data.voted_count;
              return of(successGTTicket({response: gticket}))
            }
          }
        }

        if (/INFO_UPDATE/.test(propVal.event)) {
         if (propVal.data && info) {
           const gt_info: any = _.cloneDeep(info);
           gt_info.invitation_count = propVal.data.invitation_count;
           gt_info.group_ticket_pool_ids = propVal.data.group_ticket_pool_ids;
           gt_info.remaining_group_tickets_count = propVal.data.remaining_group_tickets_count;
           gt_info.group_ticket_syndicate_pool_ids = propVal.data.group_ticket_syndicate_pool_ids;

           return of(successInfo({response: gt_info}));
         }
        }

        if (/(POOL_STATUS_CHANGED|POOL_SHOW|POOL_HIDE|INFO_UPDATE)/.test(propVal.event)) {
          if (pool && currentTicket) {
            // basically this should be run only on pool/ticket
            return of(callGTTicket({pool_id: pool.id, manager_id: currentTicket.manager.id }));
          }
        }

        return EMPTY;
      })
    )
  }, { dispatch: true })
}
