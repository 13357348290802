import { MicrositeTrackingState } from './coreTracking.models';
import { clearPixel, setCampaign, setPixel, track } from './coreTracking.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { routerNavigatedAction } from '@ngrx/router-store';

export const initialState: MicrositeTrackingState = {
  pixel                      : undefined,
  campaign                   : undefined
};

const reducer = createReducer(
  initialState,

  on(track, (state, action) => {
    return {
      ...state,
    } 
  }),

  on(setPixel, (state, action) => {
    return {
      ...state,
      pixel: action.trackingObject,
    } 
  }),

  on(clearPixel, (state, action) => {
    const newParams = {
      fbc: undefined,
      fbp: state.pixel?.fbp
    }

    return {
      ...state,
      pixel: newParams,
    } 
  }),

  on(setCampaign, (state, action) => {
    const newCampaign = {
      ...(action.queryParams.utm_source && { utm_source: action.queryParams.utm_source }),
      ...(action.queryParams.utm_medium && { utm_medium: action.queryParams.utm_medium }),
      ...(action.queryParams.utm_campaign && { utm_campaign: action.queryParams.utm_campaign }),
      ...(action.queryParams.utm_id && { utm_id: action.queryParams.utm_id }),
      ...(action.queryParams.utm_content && { utm_content: action.queryParams.utm_content }),
      ...(action.queryParams.utm_term && { utm_term: action.queryParams.utm_term }),
    }

    return {
      ...state,
      campaign: newCampaign
    } 
  }),  
);

export function micrositeTrackingReducer(
  state: MicrositeTrackingState | undefined,
  action: Action
): MicrositeTrackingState {
  return reducer(state, action);
}
