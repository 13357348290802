import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterEffects } from './router.effects';
import { RouterStateUrl } from './router.state';

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  constructor(
    private _routerEffects: RouterEffects
  ) {

  }

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const previousRoute = this._routerEffects.getPreviousRoute();

    let route = routerState.root;

    let secondary;
    let parsedSecondaryOutlet;

    if (route.children[1]) {
      secondary = route.children[1];
      const {
        params,
        data
      } = secondary;
      const url = secondary.url[0].path
      
      parsedSecondaryOutlet = { url, params, data }
      
    }

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route; 

    
    const serializedRouterState: RouterStateUrl = { url, params, queryParams, wallet: parsedSecondaryOutlet, previousRoute };
    return serializedRouterState;
  }
}

/*

An improvement can be made an recursively get data from child routes

*/