import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TokenClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/tokens';
  }

  getGiveaways(): Observable<any> {
    return this.http.get(this.endpoint + '/freeplays')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getToken(id: string): Observable<any> {
    return this.http.get(this.endpoint + '/' + id)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getPoolToken(id: number): Observable<any> {
    return this.http.get(this.endpoint + '/pools/' + id)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getCodeToken(id: string): Observable<any> {
    return this.http.get(this.endpoint + '/freeplays/codes/' + id)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
