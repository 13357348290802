import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable()
export class CommentaryClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/posts';
  }

  get(model: any): Observable<any> {
    let params = new HttpParams()
      .set('feed_type', model.feed_type)
      .set('model_id', model.model_id)
      .set('page', model.page.toString())
      .set('per', model.per.toString())

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  create(item: object): Observable<any> {
    return this.http.post(this.endpoint, item, { withCredentials: true })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.endpoint + '/' + id)
      .pipe(
        map(data => {
          return data
        }),
        catchError(err => throwError(err))
      );
  }

  update(model: any): Observable<any> {
    const params: any = {
      post: {
        body: model.body
      }
    };
    return this.http.put(this.endpoint +  '/' + model.id, params)
      .pipe(
        map(data => {
          return data
        }),
        catchError(err => throwError(err))
      );
  }
}
