import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class SyndicatePortionClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/syndicate_portions';
  }

  list(model: any = {}): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('type', (model.filters.status === 'live' || model.filters.status === 'offers') ? '1' : '2')
      .set('page', model.page.toString())
      .set('per', model.per.toString())
      .set('filters[with_offer]', model.filters.status === 'offers' ? 'true' : 'false')
      .set('filters[pool_types]', model.filters.pool_types)
      .set('filters[is_joined_syndicate]', model.filters.is_joined_syndicate ? 'true' : 'false')
      .set('filters[is_managed_syndicate]', model.filters.is_managed_syndicate ? 'true' : 'false');

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  get(id: string): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'syndicate.manager,live_offer,offer_transactions');

    return this.http.get(this.endpoint + '/' + id, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
