import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import * as _ from 'lodash-es';
import { LocalStorageService } from './local-storage.service';
import { AppState } from '../../NGRX/core.state';
import { APP_CONFIG } from "src/app/config/mobile.config.model";

export function initStateFromLocalStorage( 
  reducer: ActionReducer<AppState> 
): ActionReducer<AppState> {
  return function (state, action) {
    const newState = reducer(state, action);

    if((action.type === '[Initial State] Set') && (<any>APP_CONFIG).isMobile) {
      return { ...newState, ...(<any>action).initialState };
    }
    
    if ([INIT.toString(), UPDATE.toString()].includes(action.type) && !(<any>APP_CONFIG).isMobile) {
      return { ...Object.assign({}, _.cloneDeep(_.merge(newState, LocalStorageService.loadInitialState())))};
    } 


    return { ...Object.assign({}, _.cloneDeep(_.merge(newState, {})))};
  };
}

/*

 In core.state.ts, the reducer: ActionReducerMap constant key define the specific piece of state. Using the key as prefix, 
 a piece of state can be persistent between sessions and saved in localstorage.

 ex: NgrxLocalStorage.set(<ActionReducerMap state key>, <state value>);

*/