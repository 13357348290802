import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CorrelationsService {
  private poolCorrelations: any = {};
  private _subscriber: BehaviorSubject<any>;

  constructor() {
    this._subscriber = new BehaviorSubject(null);
  }

  setCorrelations(poolId: number, correlations: any) {
    if (!this.poolCorrelations[poolId] && correlations) {
      this.poolCorrelations[poolId] = correlations;
      this._subscriber.next(this.poolCorrelations);
    }
  }

  getCorrelations(poolId: number): BehaviorSubject<any> {
    return this._subscriber;
  }
}
