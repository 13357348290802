import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpParams } from '@angular/common/http';
import { from, interval, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from '../utils/cookies/cookies.service';
import { TranslationService } from '../translation/translation.service';
import { Config } from '../config/config.service';
import * as _ from 'lodash-es';
import {catchError, delay, delayWhen, distinctUntilChanged, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import { SecureWebsocketService } from '../websockets/secure-websocket.service';
import { connectToPrivateChannel } from '@cbcore/NGRX/coreWebsockets/coreWebsockets.actions';
import { Store } from '@ngrx/store';
import {AppState, callMicrositeSessions, selectMicrositeSessions} from '@cbcore/NGRX/core.state';
import {AuthTokenState, MicrositeSessionsState} from "@cbcore/NGRX/coreAuth/coreAuth.models";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  expiredTokens: any[] = [];

  private readonly defaultHeaders: any = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'application/json'
  };
  private requestOptions: any = {
    headers: {},
    withCredentials: String(true)
  };

  private headers = {};
  private timeout: any;
  private rootUrl: string;

  isInitial: boolean = true;
  // failedSessions: boolean = false;

  private authToken: AuthTokenState;
  private micrositeSessionsState$: Observable<MicrositeSessionsState>;

  constructor(
    private store: Store<AppState>,

    private _router: Router,
    private _cookieService: CookieService,
    private _translationService: TranslationService,
    private _config: Config,
  ) {
    // this._config.CONFIG.isMobile = false;

    this.micrositeSessionsState$ = this.store.select(selectMicrositeSessions);
    this.micrositeSessionsState$.pipe(
      distinctUntilChanged()
    ).subscribe(session => {
      this.authToken = session.AuthToken;
    })
  }

  init() {
    this.rootUrl = this._config.CONFIG.rootUrl;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return from(this.setAccessTokenTwo(req, next)).pipe(
      // delay(10),
      // delayWhen(() => {
      //   return interval(Math.random() * 5000)}
      // ),
      // delayWhen(() => {
      //   return from(this.initializeInterceptor())
      // }),
      // delay when session is running and stream value when done
      mergeMap((reqDotHandle) => {
        let authReq;

        const disableInterceptor = this._config.CONFIG.enableNativeHttp;

        if (disableInterceptor) return next.handle(req);

        if (req.url.includes('promotions') || req.url.includes('winners')) {
          authReq = req.clone({
            setHeaders: this.defaultHeaders,
            withCredentials: req.withCredentials
          });
        } else if (req.url.includes('tracking')) {

          let cleanedParams: any = new HttpParams();
          req.params.keys().forEach(x => {
            if (req.params.get(x) != undefined)
              cleanedParams = cleanedParams.append(x, req.params.get(x));
          })

          authReq = req.clone({
            setHeaders: this.requestOptions.headers,
            withCredentials: req.withCredentials,
            params: cleanedParams
          });


        } else {
          authReq = req.clone({
            setHeaders: this.requestOptions.headers,
            withCredentials: req.withCredentials
          });
        }

        return next.handle(authReq)
          .pipe(
            // @ts-ignore
            map(
              (response: HttpResponse<any>) => {

                if (response.headers) {
                  this.setHeaders(response.headers, req.url);
                }

                this.setAcceptLanguageHeader();
                return response;
              }
            ),
            catchError((err) => {
              if(req.url.includes('sessions')) {
                this.removeHeaders().then();
              }
              return throwError(this.errorHandler(err));
            })
          );
      })
    )
  }

  initializeInterceptor(): Promise<any> {
    return new Promise((resolve, reject) => {
      if(this._config.CONFIG.isMobile) {
        this.removeHeaders().then(res => {
          this.isInitial = false;
          resolve(null);
        });
        return;
      }

      if(!this._config.CONFIG.isMobile) {
        this._cookieService.get('cb_auth_headers').then(res => {
          const new_headers = res;
          this.requestOptions.headers = Object.assign(this.requestOptions.headers, this.defaultHeaders, new_headers);
          resolve(null);
        });
        return;
      }
    });
  }

  setHeaders(headers: any, url: string): void {
    try {
      let new_headers: any = {};
      headers.keys().forEach((key: any) => new_headers[key] = headers.get(key));
      if(new_headers['access-token'] && this.expiredTokens.includes(new_headers['access-token'])) {
        this.removeHeaders().then();
      } else if (new_headers['access-token'] && new_headers['client'] && new_headers['uid']) {
        const newIdentity = {
          'access-token': new_headers['access-token'],
          'client': new_headers.client,
          'uid': new_headers.uid,
          'expiry': new_headers.expiry
        }

        this.requestOptions.headers = Object.assign(this.requestOptions.headers, newIdentity);
        this._cookieService.set('cb_auth_headers', newIdentity).then((res: any) => {});

        if (url.includes('sessions')) {
          this.store.dispatch(connectToPrivateChannel({headers: _.cloneDeep(this.requestOptions.headers)}));
        }
      }

      this.setExpiryHeaders(headers.get('expiry'));
    } catch (e: any) {
    }
  }

  setAccessToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    return next.handle(req)
  }

  async setAccessTokenTwo(req: HttpRequest<any>, next: HttpHandler): Promise<Observable<HttpEvent<any>>>{
    if(this.isInitial)
      await this.initializeInterceptor();

    this.requestOptions.headers = Object.assign(this.requestOptions.headers, this.defaultHeaders);
    const access_token = this.authToken?.tokens?.access_token; //this._authTokenService.getAccessToken();

    if (req.withCredentials) {
      const new_headers = await this._cookieService.get('cb_auth_headers');

      if (access_token) {
        this.defaultHeaders['Authorization'] = access_token;
      }

      const newIdentity = {};

      try {
        // Not always these headers exist, basically are CB only
        if (new_headers) {
          newIdentity['access-token'] = new_headers['access-token'] ? new_headers['access-token'] : null;
          newIdentity['client'] = new_headers.client;
          newIdentity['uid'] = new_headers.uid;
          newIdentity['expiry'] = new_headers.expiry;
        }
      } catch (e) {
      }
      this.requestOptions.headers = Object.assign(this.requestOptions.headers, this.defaultHeaders, newIdentity);
    }

    return next.handle(req);
  }

  // looks like it's not used
  // setAuthToken() {
  //   const access_token = this._authTokenService.getAccessToken();
  //   if (access_token) {
  //     this.requestOptions.headers = Object.assign(this.requestOptions.headers, {
  //       'Authorization': access_token
  //     });
  //   }
  // }

  getCurrentHeaders(): any {
    return this.requestOptions.headers;
  }

  setExpiryHeaders(expire_date: any) {
    const self = this;
    if (expire_date) {
      const date_expired: any = new Date(expire_date * 1000);
      const date_now: any = new Date();
      const diff: any = date_expired - date_now - 30000;

      if (diff > 0) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.removeHeaders().then();
          this.sessionExpireRedirect();
        }, diff);
      }
    }
  }

  async removeHeaders(): Promise<any> {
    if(this.requestOptions.headers['access-token']) {
      this.expiredTokens.push(this.requestOptions.headers['access-token']);
    }

    this.requestOptions.headers = {};

    if (this.requestOptions.headers.expiry)
      delete this.requestOptions.headers.expiry;

    if (this.requestOptions.headers['access-token'])
      delete this.requestOptions.headers['access-token'];

    this.requestOptions.headers = Object.assign({}, this.defaultHeaders);
    const res = await this._cookieService.remove('cb_auth_headers');

    return true;
  }

  setAcceptLanguageHeader() {
    this._translationService.getAcceptLanguageHeader();
    this.requestOptions.headers = Object.assign(this.requestOptions.headers, {
      'Accept-Language': this._translationService.getAcceptLanguageHeader(),
      'X-CB-Language': this._translationService.getAcceptLanguageHeader(),
    });
  }

  errorHandler(err: any) {
    this.checkAuth(err);

    try {
      const error = {
        status: err?.error?.status || -1,
        code: err?.error?.error || _.result(err, 'error.errors[0].code', 'DEFAULT'),
        message: _.result(err, 'error.errors[0].message', null),
        errors: err?.error?.errors,
        info: err?.error?.info || {}
      };
      return error;
    } catch (e: any) {
      return {
        status: -1,
        info: {}
      }
    }
  }

  sessionExpireRedirect() {
    this._router.navigate([this.rootUrl || '/']);

    // this._notificationService.sendEvent({
    //   code: 'USER_LOGOUT',
    //   data: null
    // });
    this.store.dispatch(callMicrositeSessions());

    this._router.navigateByUrl(this.rootUrl);
  }

  // LOOKS LIKE IT'S NOT USED
  tokenExpiredMessage() {
    // this._notificationService.sendEvent({
    //   code: 'TOKEN_EXPIRED'
    // });
  }

  checkAuth(err: any) {
    const token_expired_regex = /(tickets|syndicates)/gm;

    if (token_expired_regex.exec(err.url) && err.status === 401) {
      this.tokenExpiredMessage();
    }
    if (!/sessions$|sessions\?|sign_in$|sign_out$|unlock_account$|reset_password$/.test(err.url) && err.status === 401) {
      this.sessionExpireRedirect();
    }

    if (err.status === 401) {
      this.removeHeaders().then();
    }
  }
}
