import { createFeatureSelector, createSelector } from '@ngrx/store'

import { SyndicatesState } from "./syndicates.model";
import { AppState } from '../core.state';

export const selectSyndicatesState = createFeatureSelector<AppState, SyndicatesState>('syndicates');

export const selectList = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.list)

export const selectSyndicate = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.portion)

export const selectFilters = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.list_pagination)

export const selectSyndicateJoinSuccess = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.joinSuccess)

export const selectSyndicateJoinError = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.joinError)

export const selectSyndicateLoading = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.isLoading)

export const selectSingleSyndicateLoading = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.syndicateIsLoading)

export const selectCommentaryStatus = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.commentaryStatus)

export const selectCommentaryList = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.comments);

export const selectWinList = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.wins_list)

export const selectHomeList = createSelector(selectSyndicatesState, (state: SyndicatesState) => state.home_list)
