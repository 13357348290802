import { createAction, props } from '@ngrx/store';
import { ConfigurationScope } from '../configuration/configuration.models';
import { PoolScope, PoolsMenuScope } from './pool.models';

// pool item
export const callPool = createAction('[Pool] Call Pool',
    props<{
        id: any
    }>());

export const successPool = createAction('[Pool] Success Pool',
    props<{
        pool: PoolScope.CurrentPool
    }>());

export const initPoolSelections = createAction('[Pool] Init Selections Pool');
export const clearAllPoolSelections = createAction('[Pool] Clear All Selections');

export const toggleSelection = createAction('[Pool] Toggle Selection',
    props<{
        legId: Number,
        bin: Number,
        context?: string
    }>());

export const markSmartpick = createAction('[Pool] Generate Smartpick',
    props<{
        value?: number,
        context?: string
    }>()); // Client can be added

export const markCrossSell = createAction('[Pool] Generate Cross Sell',
    props<{
        cross_sell_suggestion?: any
    }>());

export const markGroupTicketSmartpick = createAction('[Pool] Generate Group Ticket Smartpick');
export const generateTicket = createAction('[Pool] Generate Ticket',
props<{
    selections: string,
}>());

export const generateGTTicket = createAction('[Pool] Generate GT Ticket',
  props<{
    selections: string,
  }>());

export const clearCurrentLines = createAction('[Pool] Current Clear Lines');
export const removeSelectionsInLeg = createAction('[Pool] Current Clear Selections in Leg',
    props<{
      legId: number,
      context?: string
    }>());
export const removeLastSelectionInLeg = createAction('[Pool] Current Clear Last Selection in Leg',
    props<{
      legId: number,
      context?: string
    }>());

// pool featured
export const callPoolFeatured = createAction('[Pool] Call Featured');
export const successPoolFeatured = createAction('[Pool] Success Featured',
    props<{
        featuredPoolResponse: PoolScope.PoolFeatured
    }>());

export const clearPool = createAction('[Pool] Clear pool');

// pools list
export const callPoolsMenu = createAction('[Pools] Call Menu');
// pools headline
export const callPoolsHeadline = createAction('[Pools] Call Headline');
export const successPoolsHeadline = createAction('[Pools] Success Headline',
    props<{
        poolsHeadlineResponse: PoolsMenuScope.PoolHeadlineItem[],
        coreSettings: ConfigurationScope.coreSettings
    }>());

export const successPoolsMenu = createAction('[Pools] Menu Success',
    props<{
        parsedPoolsMenu: PoolsMenuScope.ParsedPoolsMenu
    }>());

// TODO: check pooListMiddleware clearSportCode
export const setSportCode = createAction('[Pools] Set Sport Code',
    props<{
        sportCode: any
    }>());

export const setPoolsExtraDetails = createAction('[Pools] Set PoolsExtra',
    props<{
        poolsExtraModel: any,
        filters: any
    }>());



export const partialUpdate = createAction('[Pools] Partial update / pool prize of Menu temporarily',
    props<{
        data: any, // can have model for different websocket event's data
        event?: string
    }>());

export const failPools = createAction('[Pools] Fail',
    props<{
        error: any
    }>());
