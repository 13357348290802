export var PARTNER_CONFIG = {
  // apiUrl: "https://whitelabel.staging-colossusbets.com/api",
  localesUrl: "//d18icbgk5z9c1l.cloudfront.net/i18n",
  imagesUrl: "//d20xk4sxen1bs2.cloudfront.net",
  websocketsUrl: "ws-eu.pusher.com",
  websocketsKey: "9e617bab837acfb6f5c2",
  websocketCluster: 'eu',
  enableSyndicates: true,
  enableFreeplay: true,
  enableShare: true,
  allowResizeEvents: true,
  micrositeType: 'SPORTS',
  twitterVia: '@pafbetting',
  sharedTicketTitle: 'Paf Betting',
  scopes: ['locales', 'competitors', 'syndicates', 'sharing', 'merchant/paf'],
  shareListEnabled: true,
  shareListTextDynamic: false,
  rulesDocs: {
    default: '2PACX-1vTsXrW7EkPW4ndtUcFmnMXUkxqlvQRvUbPV4jrwx8WEhZ-t6SHrTeW4jTdPNYv0C6D_Nr5DaseAQ7ZX',
    en: '2PACX-1vTsXrW7EkPW4ndtUcFmnMXUkxqlvQRvUbPV4jrwx8WEhZ-t6SHrTeW4jTdPNYv0C6D_Nr5DaseAQ7ZX',
    es: '2PACX-1vRdL66Z1R2pBFuT_KBAcYj6Jp1JGi_DPaMZvUJPR3dS5RSrT_F9RxKmNp0cabxzEKSD6QOWs5Il09vi',
    fi: '2PACX-1vQU8jG-Xxzc2eYgZdZmIDKvD96ek86nYBVt9qN8AmyTWJ0IEUFBt73vIAa4uzYATX7vMwJEN9erA6qW',
    sv: '2PACX-1vT4zTN9SALYa573cQenz8p-Ibbobbuv9SX8ykddd18u6jYLUgrlF7FsV6MPH4Kd-ogsJEdnCdg5ELGi',
    ru: '2PACX-1vSwfOK9dn6-GKLIFufXBZoQ2jDpKm3AHWtCzQSUEBquKnWVjkFSLmiRpvWPc_DH-GiDaHE5XvF4xvlw',
    et: '2PACX-1vSiQ5ImokWftNBi0bfaXNLzNzcD0DOwU-73p_Xj1MFM88-bFRBoqtl1p_iRc4aLM5rkEdvEvLXoZEoY'
  },
  faqDocs: {
    default: '2PACX-1vR5YJWLIrr0Wrk7q6zceb-5DdveuIDnmmRKAqoFX8DhPxXrVDuKGA49P0KCiUxoIV0pVaw4SLxeJg3F',
    en: '2PACX-1vR5YJWLIrr0Wrk7q6zceb-5DdveuIDnmmRKAqoFX8DhPxXrVDuKGA49P0KCiUxoIV0pVaw4SLxeJg3F',
    es: '2PACX-1vQtP-eFFumxaQ1T_KKiwozW0qdk6Y74zITANmSABmzGJxqj2gRrjGErFH4xoMNLDaFPz2pOOiJ7_NbM',
    fi: '2PACX-1vSkWLdVBP7c8ofBMSnNYDS8vGUsTadRAZdB_74YBT6kYAlXz33rYRwYDb5VcI_gotyjsc2Be3GPaRTd',
    sv: '2PACX-1vQzTjkJNK6YKz8Gbwt4umGzI9q1e7mh1pgjw9BxjWhHLEvFyxmVgvObepAOAADFEjxOPm5TZE3IxE2B',
    ru: '2PACX-1vSB_F4P7qy9trCgVEEakc5bkdM_kP2MycXFv9RzRd-DNaFhKR6P85-Texa6HR9HAfZnZ6595Wianruf',
    et: '2PACX-1vTk9oUDrLiol0-pSBQO9tQHesPYIfpOZSgPgIBwxrkTP_jDQnH1XlGPdrqo8A2FQc4EmKS3YLEXQYWB'
  },
  allowLinkCopy: false,
  templateServiceUrl: 'https://templates.colossusbets.com/?uuid=[UUID_CODE]&merchant=paf',
  enableVideoStreaming: false,
  videoStreamingSport: ['GREYHOUNDS'],
  videoStreamUrl: 'https://v.sis.tv/StreamingService/api/v2/stream?',
  merchant_code: 'PAF',
  streamUrl: '/streams',
  silksUrl: 'https://s3.eu-west-2.amazonaws.com/',
  allowRightSideWidget: false,
  haveSyndicateScroll: false,
  enableSyndicatesLeaderboard: true,
  shareSyndicateDynamicText: true,
  additionalLeftNavMenu: false,
  haveGroupedTickets: true,
  skipVerifyAge: false
};
