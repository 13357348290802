import { Injectable } from '@angular/core';

let DEFAULT_LOCALES = {
  'en': {
    'locale'                 : 'en',
    'translation'            : 'en',
    'accept_language_header' : 'en',
    'rules'                  : 'en',
    'home'                   : 'en',
    'guide'                  : 'en',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'gb',
    'currency_position'      : 'left'
  },
  'en-GB': {
    'locale'                 : 'en-GB',
    'translation'            : 'en',
    'accept_language_header' : 'en',
    'rules'                  : 'en',
    'home'                   : 'en',
    'guide'                  : 'en',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'gb',
    'currency_position'      : 'left'
  },
  'zh-CN': {
    'locale'                 : 'zh-CN',
    'translation'            : 'zh-CN',
    'accept_language_header' : 'zh-CN',
    'rules'                  : 'zh-CN',
    'home'                   : 'zh-CN',
    'guide'                  : 'zh-CN',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'cn',
    'currency_position'      : 'left'
  },
  'zh-TW': {
    'locale'                 : 'zh-TW',
    'translation'            : 'zh-TW',
    'accept_language_header' : 'zh-TW',
    'rules'                  : 'zh-TW',
    'home'                   : 'zh-TW',
    'guide'                  : 'zh-TW',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'cn',
    'currency_position'      : 'left'
  },
  'ja': {
    'locale'                 : 'ja',
    'translation'            : 'ja',
    'accept_language_header' : 'ja',
    'rules'                  : 'ja',
    'home'                   : 'ja',
    'guide'                  : 'ja',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'jp',
    'currency_position'      : 'left'
  },
  'ko-KR': {
    'locale'                 : 'ko-KR',
    'translation'            : 'ko-KR',
    'accept_language_header' : 'ko-KR',
    'rules'                  : 'ko-KR',
    'home'                   : 'ko-KR',
    'guide'                  : 'ko-KR',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'kr',
    'currency_position'      : 'left'
  },
  'id-ID': {
    'locale'                 : 'id-ID',
    'translation'            : 'id-ID',
    'accept_language_header' : 'id-ID',
    'rules'                  : 'id-ID',
    'home'                   : 'id-ID',
    'guide'                  : 'id-ID',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'id',
    'currency_position'      : 'left'
  },
  'th-TH': {
    'locale'                 : 'th-TH',
    'translation'            : 'th-TH',
    'accept_language_header' : 'th-TH',
    'rules'                  : 'th-TH',
    'home'                   : 'th-TH',
    'guide'                  : 'th-TH',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'th',
    'currency_position'      : 'left'
  },
  'vi-VN': {
    'locale'                 : 'vi-VN',
    'translation'            : 'vi-VN',
    'accept_language_header' : 'vi-VN',
    'rules'                  : 'vi-VN',
    'home'                   : 'vi-VN',
    'guide'                  : 'vi-VN',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'vn',
    'currency_position'      : 'left'
  },
  'fr': {
    'locale'                 : 'fr',
    'translation'            : 'fr',
    'accept_language_header' : 'fr',
    'rules'                  : 'fr',
    'home'                   : 'fr',
    'guide'                  : 'fr',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'fr',
    'currency_position'      : 'right'
  },
  'pt': {
    'locale'                 : 'pt',
    'translation'            : 'pt',
    'accept_language_header' : 'pt',
    'rules'                  : 'pt',
    'home'                   : 'pt',
    'guide'                  : 'pt',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'pt',
    'currency_position'      : 'right'
  },
  'es': {
    'locale'                 : 'es',
    'translation'            : 'es',
    'accept_language_header' : 'es',
    'rules'                  : 'es',
    'home'                   : 'es',
    'guide'                  : 'es',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'es',
    'currency_position'      : 'right'
  },
  'es-PE': {
    'locale'                 : 'es-PE',
    'translation'            : 'es-PE',
    'accees_language_header' : 'es-PE',
    'rules'                  : 'es-PE',
    'home'                   : 'es-PE',
    'guide'                  : 'es-PE',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'pe',
    'currency_position'      : 'left'
  },
  'es-CO': {
    'locale'                 : 'es-CO',
    'translation'            : 'es-CO',
    'accees_language_header' : 'es-CO',
    'rules'                  : 'es-CO',
    'home'                   : 'es-CO',
    'guide'                  : 'es-CO',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'co',
    'currency_position'      : 'left'
  },
  'cs': {
    'locale'                 : 'cs',
    'translation'            : 'cs',
    'accees_language_header' : 'cs',
    'rules'                  : 'cs',
    'home'                   : 'cs',
    'guide'                  : 'cs',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'cz',
    'currency_position'      : 'right'
  },
  'ru': {
    'locale'                 : 'ru',
    'translation'            : 'ru',
    'accees_language_header' : 'ru',
    'rules'                  : 'ru',
    'home'                   : 'ru',
    'guide'                  : 'ru',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'ru',
    'currency_position'      : 'right'
  },
  'tr': {
    'locale'                 : 'tr',
    'translation'            : 'tr',
    'accees_language_header' : 'tr',
    'rules'                  : 'tr',
    'home'                   : 'tr',
    'guide'                  : 'tr',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'tr',
    'currency_position'      : 'right'
  },
  'hy': {
    'locale'                 : 'hy',
    'translation'            : 'hy',
    'accees_language_header' : 'hy',
    'rules'                  : 'hy',
    'home'                   : 'hy',
    'guide'                  : 'hy',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd, mmm DD TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'am',
    'currency_position'      : 'right'
  },
  'de': {
    'locale'                 : 'de',
    'translation'            : 'de',
    'accees_language_header' : 'de',
    'rules'                  : 'de',
    'home'                   : 'de',
    'guide'                  : 'de',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'de',
    'currency_position'      : 'right'
  },
  'fi': {
    'locale'                 : 'fi',
    'translation'            : 'fi',
    'accees_language_header' : 'fi',
    'rules'                  : 'fi',
    'home'                   : 'fi',
    'guide'                  : 'fi',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'fi',
    'currency_position'      : 'right'
  },
  'hu': {
    'locale'                 : 'hu',
    'translation'            : 'hu',
    'accees_language_header' : 'hu',
    'rules'                  : 'hu',
    'home'                   : 'hu',
    'guide'                  : 'hu',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd., TT',
    'date_long'              : 'mmm. DD., ddd. TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'hu',
    'currency_position'      : 'right'
  },
  'nb': {
    'locale'                 : 'nb',
    'translation'            : 'nb',
    'accees_language_header' : 'nb',
    'rules'                  : 'nb',
    'home'                   : 'nb',
    'guide'                  : 'nb',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'no',
    'currency_position'      : 'left'
  },
  'pl': {
    'locale'                 : 'pl',
    'translation'            : 'pl',
    'accees_language_header' : 'pl',
    'rules'                  : 'pl',
    'home'                   : 'pl',
    'guide'                  : 'pl',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd mmm DD TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'pl',
    'currency_position'      : 'right'
  },
  'sv': {
    'locale'                 : 'sv',
    'translation'            : 'sv',
    'accees_language_header' : 'sv',
    'rules'                  : 'sv',
    'home'                   : 'sv',
    'guide'                  : 'sv',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'sv',
    'currency_position'      : 'right'
  },
  'ge': {
    'locale'                 : 'ge',
    'translation'            : 'ge',
    'accees_language_header' : 'ge',
    'rules'                  : 'ge',
    'home'                   : 'ge',
    'guide'                  : 'ge',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'ge',
    'currency_position'      : 'right'
  },
  'pt-BR': {
    'locale'                 : 'pt-BR',
    'translation'            : 'pt-BR',
    'accees_language_header' : 'pt-BR',
    'rules'                  : 'pt-BR',
    'home'                   : 'pt-BR',
    'guide'                  : 'pt-BR',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'br',
    'currency_position'      : 'left'
  },
  'en-NL': {
    'locale'                 : 'en-NL',
    'translation'            : 'en',
    'accees_language_header' : 'en',
    'rules'                  : 'en',
    'home'                   : 'en-NL',
    'guide'                  : 'en',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd mmm DD TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'gb',
    'currency_position'      : 'left'
  },
  'nl': {
    'locale'                 : 'nl',
    'translation'            : 'nl',
    'accees_language_header' : 'nl',
    'rules'                  : 'nl',
    'home'                   : 'nl',
    'guide'                  : 'nl',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd mmm DD TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'nl',
    'currency_position'      : 'right'
  },
  'fr-BE': {
    'locale'                 : 'fr-BE',
    'translation'            : 'fr',
    'accees_language_header' : 'fr',
    'rules'                  : 'fr',
    'home'                   : 'fr-BE',
    'guide'                  : 'fr',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ' ',
    'decimal_separator'      : ',',
    'flag_code'              : 'fr',
    'currency_position'      : 'right'
  },
  'el': {
    'locale'                 : 'el',
    'translation'            : 'el',
    'accees_language_header' : 'el',
    'rules'                  : 'el',
    'home'                   : 'el',
    'guide'                  : 'el',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'gr',
    'currency_position'      : 'right'
  },
  'uk-UA': {
    'locale'                 : 'uk-UA',
    'translation'            : 'uk-UA',
    'accees_language_header' : 'uk-UA',
    'rules'                  : 'uk-UA',
    'home'                   : 'uk-UA',
    'guide'                  : 'uk-UA',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'ua',
    'currency_position'      : 'right'
  },
  'et': {
    'locale'                 : 'et',
    'translation'            : 'et',
    'accept_language_header' : 'et',
    'rules'                  : 'et',
    'home'                   : 'et',
    'guide'                  : 'et',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : '.',
    'decimal_separator'      : ',',
    'flag_code'              : 'et',
    'currency_position'      : 'right'
  },
  'am': {
    'locale'                 : 'am-ET',
    'translation'            : 'am',
    'accept_language_header' : 'am',
    'rules'                  : 'am',
    'home'                   : 'am',
    'guide'                  : 'am',
    'date_default'           : 'DD/MM/YY',
    'date_short'             : 'ddd, TT',
    'date_long'              : 'ddd DD mmm TT',
    'thousands_separator'    : ',',
    'decimal_separator'      : '.',
    'flag_code'              : 'am',
    'currency_position'      : 'right'
  }
};


@Injectable()
export class InternalLanguageKeyMap {
  public LOCALES: any = DEFAULT_LOCALES;
}
