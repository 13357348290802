import { Injectable } from '@angular/core';
import { WindowService } from '../window/window.service';

@Injectable()
export class PostMessageService {
  public _window: Window;

  constructor(
    public windowRef: WindowService,
  ) {
    this._window = windowRef.nativeWindow;
  }

  send(message: any): void {
    this._window.parent.postMessage(message, '*');
  }

  balanceReload(data: any): void {
    this.send({
      action: 'RELOAD_BALANCE'
    });
  }

  betPlaced(data: any, isFreeBet: boolean): void {
    this.send({
      action: 'BET_PLACED'
    });
  }

  offerAccepted(data: any): void {
    this.send({
      action: 'OFFER_ACCEPTED'
    });
  }

  resize(data: any): void {
    this.send({
      action: 'resize',
      height: data.height,
      type: 'iframe-change-height'
    });
  }

  scroll(data: any): void {
    this.send({
      action: 'SCROLL',
      offset_x: data.offset_x,
      offset_y: data.offset_y
    });
  }

  routeChanged(data: any) {
    this.send({
      action: 'ROUTE_CHANGED',
      path: data.url
    });
  }

}
