import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface tracking { }

@Injectable()
export class TrackingClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = `${this._config.CONFIG.trackingUrl}`;
  }

  track(
    trackingParams: any
  ): Observable<any> {

    let params = new HttpParams()
      .set('tid', `${this._config.CONFIG.merchantTrackingId}`)

    Object.keys(trackingParams).forEach(param => {
      if (trackingParams[param]) {
        params = params.set(param, trackingParams[param]);
      }
    });



    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  trackPageView(
    dl: string,
    cid: string,
    uid: string,
    ua: string,
    sr: string,
    dr: string,
    ul: string,
    ni: string = 'false',
    uip?: any,
    geoid?: any,
  ): Observable<any> {
    const params = new HttpParams()
      .set('tid', `${this._config.CONFIG.merchantTrackingId}`)

      .set('dl', dl)
      .set('cid', cid)
      .set('uid', uid)
      .set('ua', ua)
      .set('sr', sr)
      .set('dr', dr)
      .set('ul', ul)
      .set('ni', ni)
      .set('uip', uip)
      .set('geoid', geoid);

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  trackCampaign(
    utm_source: string,
    utm_medium: string,
    utm_campaign: string,
    utm_term?: any,
    utm_content?: any,
    utm_id?: any
  ): Observable<any> {
    if (!utm_source && !utm_medium && !utm_campaign && !utm_term && !utm_content && !utm_id)
      return throwError(false);

    const params = new HttpParams()
      .set('tid', `${this._config.CONFIG.merchantTrackingId}`)

      .set('utm_source', utm_source)
      .set('utm_medium', utm_medium)
      .set('utm_campaign', utm_campaign)
      .set('utm_termua', utm_term)
      .set('utm_content', utm_content)
      .set('utm_id', utm_id)

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  trackTransaction(
    transactionId: string,
    sku: string,
    name: string,
    category: string,
    price: string,
    quantity: string,
    transactionTotal: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('tid', `${this._config.CONFIG.merchantTrackingId}`)

      .set('transactionId', transactionId)
      .set('sku', sku)
      .set('name', name)
      .set('category', category)
      .set('price', price)
      .set('quantity', quantity)
      .set('transactionTotal', transactionTotal)

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  trackEvent(
    eventAction: string,
    eventCategory: string,
    eventLabel: string,
    eventValue: string
  ): Observable<any> {
    if (!eventAction && !eventCategory && !eventLabel && !eventValue)
      return throwError(false);

    const params = new HttpParams()
      .set('tid', `${this._config.CONFIG.merchantTrackingId}`)

      .set('eventAction', eventAction)
      .set('eventCategory', eventCategory)
      .set('eventLabel', eventLabel)
      .set('eventValue', eventValue)

    return this.http.get(this.endpoint, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getEndpoint(): string {
    return this.endpoint;
  }
}
