import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PostMessageService } from '../services/utils/postmessage/postmessage.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OfferClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config, public _postMessageService: PostMessageService) {
    this.endpoint = _config.CONFIG.apiUrl + '/offers';
  }

  get(): Observable<any> {
    return this.http.get(this.endpoint)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  accept(id: string, offer: object): Observable<any> {
    return this.http.post(this.endpoint + '/' + id + '/accept', offer)
      .pipe(
        map(data => {
          this._postMessageService.offerAccepted(data)
          return data
        }),
        catchError(err => throwError(err))
      );
  }

  reserve(offerId: string, body: object): Observable<any> {
    return this.http.post(this.endpoint + '/' + offerId + '/unauth_accept', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
