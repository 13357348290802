import { Injectable } from '@angular/core';
import { WindowService } from './../window/window.service'
import { CordovaService } from './../cordova/cordova.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class LinkService {
  public _window: Window;
  public $subscriber: BehaviorSubject<any> = new BehaviorSubject(null);


  constructor(private windowRef: WindowService, private cordova: CordovaService) {
    this._window = windowRef.nativeWindow;
  }

  openLink(url: any): void {
    // TODO!: check this in app - it's overriden and most probably can be removed
    if (this.cordova.isApp()) {
      const message = {
        action: 'browse',
        url: url,
        target: '_system',
        options: 'locations=yes,toolbar=no,clearcache=yes'
      };
      this._window.parent.postMessage(message, '*');
    } else {
      this._window.open(url, '_blank');
    }
  }

  async openRedirect(url: any) {
    return
  }

  normalizeUrl(url: string) {
    return url;
  }

  watchQueryParams(): BehaviorSubject<any> {
    return this.$subscriber;
  }

  clearQueryParams(): void {
    this.$subscriber.next(null);
  }
}
