import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { TrackingClient } from '../../client/tracking';

import { CookieService } from "../../services/utils/cookies/cookies.service";
import { WindowService } from "../../services/utils/window/window.service";

import { TranslationService } from '../../services/translation/translation.service';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../services/config/config.service';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import * as _ from 'lodash-es';

interface TrackingObject extends DefaultData, Campaign, Event, Transaction, AppData {
  t: 'pageview' | null
}

// Sent along pageviews, events, transactions
interface DefaultData {

  /** Anonymous id **/
  // cid: string | null,

  /** Unique id **/
  uid: string,

  /** Page path **/
  dl: string | null,

  /** Browser user-agent **/
  ua: string | null,

  /** Screen resolution **/
  sr: string,

  /** Viewport size **/
  vp: string,

  /** Referre\ nullr **/
  dr: string | null,

  /** Device language or the one set by the user on the website if given the ooption **/
  ul: string,

  /** Page title **/
  dt: string | null,

  /** Geolocation - either from ip or gps **/
  geoid: string
}

// Query params coming from emails, ads
interface Campaign {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_id: string
  utm_content: string
  utm_term: string
}

// Events for different user actions
interface Event {
  event?: 'MicrositeEvent' // should bbe contained by every event
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue: string
}

// Ecommerce, mainly used by ga
interface Transaction {
  transactionId: string
  sku: string
  name: string
  category: string
  price: string
  quantity: string
  transactionTotal: string
}

interface AppData {
  /** Binary version **/
  av: string,

  /** com.colossusbets or com.colossusbets.android **/
  aid: string,

  /** Colossus - name from AppStore **/
  an: string
}

@Injectable()
export class TrackingMiddleware {
  private enableInternalTracking: boolean = true;
  private enableGA: boolean = true;
  private blackListedPageviewPaths: String[] = ['/pools/sports', '/pools/racing'];
  private testUrl: string = 'https://www.staging-colossusbets.com/env.config.js';
  public campaignCookieKey: any = '_cb_campaign';
  public campaignExpiry: any = 2 * 60 * 60 * 1000; // 2h

  private que: String[] = [];
  public currentEvent: any = null;
  public currentTransaction: any = null;

  private _window: any;
  private dataLayer: any;

  // data needed to persist in order to not fetch multiple times
  public currentLocation: any = null;
  public userId: any;
  public newVisitorUUID: any;
  public newVisitorUUIDCookieKey: any = '_cb_new_visitor_id';
  public newVisitorExpiry: any = Number.MAX_SAFE_INTEGER;
  public utmParameters: Campaign;
  public geoId: any;

  public appData: any;

  constructor(
    private _trackingClient: TrackingClient,
    private _translationService: TranslationService,
    private _cookieService: CookieService,
    private _windowService: WindowService,
    private _titleService: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _config: Config
  ) {
    // LEGACY
    this.dataLayer = (<any>window).dataLayer || [];
    this.blackListedPageviewPaths = [...this.blackListedPageviewPaths, ...(this._config.CONFIG.blackListedPageviewPaths || [])];
    this.enableInternalTracking = this._config.CONFIG.enableInternalTracking;
    this.enableGA = this._config.CONFIG.enableGA;
    this.testUrl = this._config.CONFIG.testUrl || this.testUrl;
  }

  public initTracking(): void {
    this.setCampaign(null, null, null, null, null, null);
    this.initializeRouterEvent();
  }

  public async track(event?: any): Promise<void> {
    // LEGACY

    this.enableInternalTracking = this._config.CONFIG.enableInternalTracking;
    this.enableGA = this._config.CONFIG.enableGA;

    if (this.enableGA) {
      this.sendGA(event);
    }

    if(!this.enableInternalTracking) return;

    const defaultData: DefaultData = await this.getDefaultData();
    const campaignData: Campaign = this.getCampaign();
    const eventOrTransaction: Event | Transaction | {} = event ? this.getEventOrTransaction(event) : {};
    const appData: AppData = await this.getAppData();

    const trackingObject: TrackingObject = Object.assign(
      defaultData,
      campaignData,
      eventOrTransaction,
      appData,
      {
        t: _.isEmpty(eventOrTransaction) ? 'pageview' : null
      });

    // this.clearEvents();
    this._trackingClient.track(trackingObject).subscribe(
      data => { },
      err => {
        // this.restoreEvents(eventOrTransaction);
      }
    )

  }

  public async getDefaultData(): Promise<DefaultData> {
    // asynchronous data we have to wait for in order to start sending events
    // const anonymousId = await this.getAnonymousId();
    // synchronous data - if neccessary for app overrides, can be made asynchronous
    const userAgent = this.getUserAgent();
    const windowProperties = this.getWindowProperties();
    const pageTitle = this.getTitle();
    const currentLocation = this.getCurrentLocation();
    const userId = this.getUserId();
    const geoId = this.getGeolocation();
    const documentProperties = this.getDocumentProperties();

    const trackingObject: DefaultData = {
      // cid: anonymousId,
      uid: userId,
      dl: currentLocation ? encodeURIComponent(currentLocation) : null,
      ua: userAgent ? encodeURIComponent(userAgent) : null,
      sr: windowProperties.screenResolution,
      vp: windowProperties.viewportSize,
      dr: documentProperties.referrer ? encodeURIComponent(documentProperties.referrer) : null,
      ul: documentProperties.language,
      dt: pageTitle ? encodeURIComponent(pageTitle) : null,
      geoid: geoId
    }

    return trackingObject
  }

  // ROUTING
  public initializeRouterEvent(): void {
    const $queryParams = this._activatedRoute.queryParams;
    const $navigationEnd = this._router.events.pipe(filter(event => event instanceof NavigationEnd));

    $queryParams.subscribe(params => {
      if (params['utm_source'] ||
        params['utm_medium'] ||
        params['utm_campaign'] ||
        params['utm_id'] ||
        params['utm_content'] ||
        params['utm_term'])

        this.setCampaign(params['utm_source'],
          params['utm_medium'],
          params['utm_campaign'],
          params['utm_id'],
          params['utm_content'],
          params['utm_term']).then();

    });

    // @ts-ignore
    $navigationEnd.subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.currentLocation = urlAfterRedirects;
      if(!this.isBlackListedPath(this.currentLocation)) {
        this.track().then();
      }
    });
  }

  public getCurrentLocation(): any {
    return this.currentLocation;
  }

  public async setCampaign(utm_source: any, utm_medium: any, utm_campaign: any, utm_id: any, utm_content: any, utm_term: any): Promise<any> {
    const newCampaign = {
      ...(utm_source && { utm_source: utm_source }),
      ...(utm_medium && { utm_medium: utm_medium }),
      ...(utm_campaign && { utm_campaign: utm_campaign }),
      ...(utm_id && { utm_id: utm_id }),
      ...(utm_content && { utm_content: utm_content }),
      ...(utm_term && { utm_term: utm_term }),
    }

    const previousSessionCampaign = await this._cookieService.get(this.campaignCookieKey);

    this.utmParameters = !_.isEmpty(previousSessionCampaign) ? Object.assign(previousSessionCampaign, newCampaign) : newCampaign ? newCampaign : null;

    if (!_.isEmpty(newCampaign)) {
      await this._cookieService.set(this.campaignCookieKey, this.utmParameters, this.campaignExpiry);
    }

    if (!_.isEmpty(this.utmParameters)) {
      this.setMouseFlowCampaign();
    }

    return true;
  }

  public getCampaign(): Campaign {
    return this.utmParameters;
  }

  // APP RELATED
  public setAppData(data: AppData): void {
    this.appData = data;
  }

  public async getAppData(): Promise<AppData> {
    return this.appData;
  }

  // EVENTS & TRANSACTIONS
  public getEventOrTransaction(data: any): Event | Transaction | {} {
    if (data.event !== 'micrositePurchase') {
      return this.getEvent(
        data.eventCategory || null,
        data.eventAction || null,
        data.eventLabel || null,
        data.eventValue || null
      )
    }

    if (data.transactionId) {
      return this.getTransaction(
        data.transactionId,
        data.transactionProducts[0].sku,
        data.transactionProducts[0].name,
        data.transactionProducts[0].category,
        data.transactionProducts[0].price,
        data.transactionProducts[0].quantity,
        data.transactionTotal
      )
    }

    return {};
  }

  public getEvent(
    eventAction: string,
    eventCategory: string,
    eventLabel: string,
    eventValue: string
  ): Event | {} {
    if (eventAction || eventCategory || eventLabel || eventValue) {
      return {
        eventAction: eventAction,
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventValue: eventValue
      }
    }
    return {};
  }

  public getTransaction(
    transactionId: string,
    sku: string,
    name: string,
    category: string,
    price: string,
    quantity: string,
    transactionTotal: string
  ): Transaction | {} {
    if (transactionId || sku || name || category || price || quantity || transactionTotal) {
      return {
        transactionId: transactionId,
        sku: sku,
        name: name,
        category: category,
        price: price,
        quantity: quantity,
        transactionTotal: transactionTotal
      }
    }
    return {};
  }

  // PERSISTENT DATA
  // public async getAnonymousId(): Promise<string | null> {
  //   let fp, result;
  //
  //   try {
  //     this.newVisitorUUID = await this._cookieService.get(this.newVisitorUUIDCookieKey);
  //   } catch (error) {}
  //
  //   if(this.newVisitorUUID) return this.newVisitorUUID;
  //
  //   try {
  //     fp = await FingerprintJS.load({ token: 'tyBRPn18mUtmlIgkCWx5', region: 'eu', endpoint: 'https://fjs.colossusbets.com' })
  //   } catch (error) {
  //     return null;
  //   }
  //
  //   try {
  //     result = await fp.get();
  //     const visitorId = result.visitorId;
  //     this.newVisitorUUID = visitorId;
  //     await this._cookieService.set(this.newVisitorUUIDCookieKey, this.newVisitorUUID, this.newVisitorExpiry);
  //     return this.newVisitorUUID;
  //   } catch (error) {
  //     return null;
  //   }
  // }

  public getDocumentProperties(): any {
    const referrer = !document.referrer ? null : this.isBlackListedReferrer(document.referrer) ? 'https://www.colossusbets.com/' : document.referrer;
    const language = document.documentElement.lang || this._translationService.getLanguage();
    return {
      referrer: referrer,
      language: language
    }
  }

  public getWindowProperties(): any {
    this._window = this._windowService.nativeWindow;
    const screenResolution = `${this._window.screen.width * this._window.devicePixelRatio}x${this._window.screen.height * this._window.devicePixelRatio}`;

    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const viewportSize = `${vw}x${vh}`;

    return {
      screenResolution,
      viewportSize
    }
  }

  public getUserAgent(): any {
    return navigator.userAgent || navigator.vendor || (<any>window).opera;
  }

  public getTitle(): any {
    return this._titleService.getTitle();
  }

  public setUserId(ext_id: any): void {
    this.userId = ext_id;
    this.setUserIdGa(ext_id);
  }

  public getUserId(): any {
    return this.userId;
  }

  public getGeolocation(): any {
    return this.geoId;
  }

  public setGeoId(geoId: any): void {
    this.geoId = geoId;
  }

  // UTILS
  public isBlackListedPath(path: any): Boolean {
    if(!path) return true;
    const regex = /(\/pools\/[0-9]+\/solo$|\/pools\/[0-9]+\/join$|\/pools\/[0-9]+\/create$|\/results\/[0-9]+$|\(wallet)/m;
    return regex.test(path) || this.blackListedPageviewPaths.includes(path) || path.includes('wallet');
  }

  public isBlackListedReferrer(path: any): Boolean {
    const regex = /trustly|neteller|skrill|paysafe|paysafecard|envoytransfers|paypal/m;
    return regex.test(path);
  }

  // MOUSEFLOW
  setMouseFlowCampaign(): void {
    setTimeout(() => {
      (<any>window)._mfq = (<any>window)._mfq || [];
      Object.keys(this.utmParameters).forEach(key => {
        (<any>window)._mfq.push(["setVariable", key, this.utmParameters[key]]);
      });
    }, 1000);
  }

  // GAå
  public async setVirtualPageView(path: any): Promise<any> {
    if(this.isBlackListedPath(path)) return;

    this.sendGA({
      'event': 'pageviewCustomEvent',
      'pagePath': path,
      'pageTitle': 'Colossus Bets'
    });
  }

  public setUserIdGa(userId: any) {
    if (!this.enableGA) return;

    this.sendGA({
      'userId': userId
    })
  }

  public sendGA(data: any): void {
    if (!this.enableGA || !data)
      return;

    const dt = Object.assign(
      {},
      {
        event: data.event,
        eventCategory: data.eventCategory || null,
        eventAction: data.eventAction || null,
        eventLabel: data.eventLabel || null,
        eventValue: data.eventLabel ? ((data.eventLabel === 'Step_3' && data.eventValue) ? 10 : data.eventValue) : null,
        pagePath: data.pagePath,
        pageTitle: data.pageTitle
      }
    )

    const event = {
      ...(dt.event && { event: dt.event }),
      ...(dt.eventCategory && { eventCategory: dt.eventCategory }),
      ...(dt.eventAction && { eventAction: dt.eventAction }),
      ...(dt.eventLabel && { eventLabel: dt.eventLabel }),
      ...(dt.eventValue && { eventValue: dt.eventValue }),
      ...(dt.pagePath && { pagePath: dt.pagePath }),
      ...(dt.pageTitle && { pageTitle: dt.pageTitle })
    }

    if (data.event && data.event !== 'micrositePurchase') {
      this.dataLayer.push(event);
    } else {
      this.dataLayer.push(data);
    }
  }
}


