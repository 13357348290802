<!--<div id="ticketshare" class="w-full min-w-share-container" *ngIf="!shareError && sharingEnabled && socialNetworks">-->
<!--  <div class="clearfix col-space" *ngIf="!shareSuccess">-->
<!--    <button class="btn w-button tertiary items-center" (click)="share()" [loading]="shareLoading">-->
<!--      <i class="fas fa-share-alt"></i>-->
<!--      <span class="ml-custom-4 share-ticket-text" [translate]="'buttons.SHARE_TICKET'"></span>-->
<!--      <span class="ml-custom-4 share-cashout-text" [translate]="'buttons.SHARE_CASHOUT'"></span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="flex flex-row justify-between links" *ngIf="shareSuccess">-->
<!--    <a class="social-network-link whatsapp" (click)="whatsapp()" *ngIf="isMobileDevice() && socialNetworks.whatsapp">-->
<!--      <i class="fab fa-whatsapp"></i>-->
<!--    </a>-->
<!--    <a class="social-network-link twitter" target="_blank" (click)="openLink(shareData.twitterUrl)" *ngIf="socialNetworks.twitter">-->
<!--      <i class="fab fa-twitter"></i>-->
<!--    </a>-->
<!--    <a class="social-network-link facebook" target="_blank" (click)="openLink(shareData.facebookUrl)" *ngIf="socialNetworks.facebook">-->
<!--      <i class="fab fa-facebook-f"></i>-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->



<div id="ticketshare" *ngIf="!shareError && sharingEnabled && sharingEnabled && socialNetworks">
  <div class="clearfix" *ngIf="!shareSuccess">
    <button class="direct-url-share" (click)="share()" [loading]="shareLoading">
      <i class="fas fa-share mr-custom-8"></i>
      <span class="ml-custom-4 share-ticket-text" [translate]="'buttons.SHARE_TICKET'"></span>
      <span class="ml-custom-4 share-cashout-text" [translate]="'buttons.SHARE_CASHOUT'"></span>
    </button>
  </div>
  <div class="sharing-buttons" *ngIf="shareSuccess">
    <div class="sharing-button-item wa"  (click)="whatsapp()" *ngIf="isMobileDevice() && socialNetworks.whatsapp">
      <i class="fab fa-whatsapp"></i>
    </div>
    <div class="sharing-button-item twitter" target="_blank" (click)="openLink(shareData.twitterUrl)" *ngIf="socialNetworks.twitter">
      <i class="fa-brands fa-x-twitter"></i>
    </div>
    <div class="sharing-button-item fb" target="_blank" (click)="openLink(shareData.facebookUrl)" *ngIf="socialNetworks.facebook">
      <i class="fab fa-facebook-f"></i>
    </div>
    <div class="sharing-button-item link" [ngClass]="{'completed': linkCopied}" (click)="copyLink($event)">
      <i *ngIf="!linkCopied" class="far fa-link link-icon mr-custom-6"></i>
      <span *ngIf="!linkCopied" [translate]="'syndicateJoin.COPY_LINK'"></span>

      <span *ngIf="linkCopied" [translate]="'syndicateJoin.LINK_COPIED'"></span>
    </div>
  </div>
</div>
