import { Injectable } from '@angular/core';

import { EMPTY, of } from 'rxjs';
import { tap, exhaustMap, withLatestFrom, delay, take, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { ofType, createEffect, Actions } from '@ngrx/effects';

import { AppState } from '@cbcore/NGRX/core.state';
import { selectRouter } from '../router/router.selector';

import { clearPixel, replaceState, setCampaign, setPixel, track } from './coreTracking.actions';

import {CookieService} from "@cbcore/services/utils/cookies/cookies.service";
import {TrackingMiddleware} from "@cbcore/middlewares/tracking/tracking.middleware";
import {Config} from "@cbcore/services/config/config.service";

import { routerNavigatedAction, routerNavigationAction, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { selectTracking } from './coreTracking.selectors';
import { RouterStateUrl } from '@cbcore/NGRX/router/router.state';
import { successMicrositeSessions } from '../coreAuth/coreAuth.actions';
import { LocalStorageService } from '../meta-reducers/local-storage.service';

const blackListedHistoryPaths = ['/pools/sports', '/pools/racing'];
const CORE_TRACKING_STATE_KEY = 'walletUsersRegistration';


@Injectable()
export class TrackingEffects {
  track = createEffect(() => {
    return this.actions$.pipe(
      ofType(track),
      withLatestFrom(this.store.pipe(select(selectRouter))),
      tap(([propVal, routerState]) => {
        this._trackingMiddleware.track(propVal.event);
    }))}, { dispatch: false });

  replaceState = createEffect(() => {
      return this.actions$.pipe(
        ofType(replaceState),
        tap((propVal) => {
          this._trackingMiddleware.setVirtualPageView(propVal.url);
      }))}, { dispatch: false });


  // TODO: finish expiry for state storage
  // routerNavigatedActionTracking = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(routerNavigatedAction),
  //     filter(propVal =>
  //       !blackListedHistoryPaths.includes(propVal.payload.event.url) &&
  //       !propVal.payload.event.url.includes('wallet') &&
  //       !!(
  //         (<any>propVal.payload.routerState).queryParams['utm_source'] ||
  //         (<any>propVal.payload.routerState).queryParams['utm_medium'] ||
  //         (<any>propVal.payload.routerState).queryParams['utm_campaign'] ||
  //         (<any>propVal.payload.routerState).queryParams['utm_id'] ||
  //         (<any>propVal.payload.routerState).queryParams['utm_content'] ||
  //         (<any>propVal.payload.routerState).queryParams['utm_term']
  //       )
  //     ),
  //     exhaustMap((propVal) => {
  //       return of(setCampaign({queryParams: (<any>propVal.payload.routerState).queryParams}));
  //     }));
  // }, { dispatch: true });

  // setCampaign = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(setCampaign),
  //     withLatestFrom(this.store.pipe(select(selectTracking))),
  //     tap(([propVal, trackingState]) => {
  //       this.ngrxLocalStorage.setItem(CORE_TRACKING_STATE_KEY, { campaign: trackingState.campaign });
  //     }));
  // }, { dispatch: false });



  // @cbfe/core
  successMicrositeSessionsInitTracking = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeSessions),
      tap((propVal) => {
        if(propVal.response) {
          this._trackingMiddleware.setUserId(propVal.response.ext_id);
        }
        return EMPTY;
    }))}, { dispatch: false });

  isMobile: boolean;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private _config: Config,
    private _trackingMiddleware: TrackingMiddleware,
    private _cookieService: CookieService,
    private ngrxLocalStorage: LocalStorageService
  ) {
    this.isMobile = this._config.CONFIG.isMobile;
  }
}

/*

-effect that replaces AuthService functionality
successMicrositeSessionsInitTracking

-when migrating the core, a separate effect can be used for tracking the route. this functionality stays for now in the middleware
pageView = createEffect(
  () => () =>
    this.router.events.pipe(
      filter(
        (event): event is NavigationEnd => event instanceof NavigationEnd
      ),
      tap((event: NavigationEnd) => {
        // (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // (<any>window).ga('send', 'pageview');
      })
    ),
{ dispatch: false }

*/


