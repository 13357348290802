import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { select, Store } from '@ngrx/store';

import {catchError, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import { TokenClient } from "@cbcore/client/token";
import { GiveawayClient} from "@cbcore/client/giveaway";

import {EMPTY, of} from "rxjs";
import {AppState} from "@cbcore/NGRX/core.state";
import {
  callFreeplayList, claimToken, failClaimToken,
  failFreeplayList,
  getPoolToken, loadToken,
  successFreeplayList, successToken
} from "@cbcore/NGRX/freeplay/freeplay.actions";

@Injectable()
export class FreeplayEffects {
  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    public tokenClient: TokenClient,
    public giveawayClient: GiveawayClient
  ) {
  }

  callFreeplayList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callFreeplayList),
      exhaustMap(() => {
        return this.tokenClient.getGiveaways().pipe(
          map(data => {
            return successFreeplayList({list: data});
          }),
          catchError(error => {
            return of(failFreeplayList({error: error}));
          })
        )
      })
    )
  })

  getPoolToken = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPoolToken),
      exhaustMap( (model: any) => {
        return this.tokenClient.getPoolToken(model.id).pipe(
          map(data => {
            return successToken({token: data});
          }),
          catchError(error => {
            return of(failClaimToken({error: error}));
          })
        )
      })
    )
  })

  loadToken = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadToken),
      exhaustMap( (model: any) => {
        return this.tokenClient.getToken(model.token_id).pipe(
          map(data => {
            return successToken({token: data});
          }),
          catchError(error => {
            return of(failClaimToken({error: error}));
          })
        )
      })
    )
  })

  claimToken = createEffect(() => {
    return this.actions$.pipe(
      ofType(claimToken),
      exhaustMap( (model: any) => {
        return this.giveawayClient.claim(model.giveaway_id).pipe(
          map(data => {
            return successToken({token: data});
          }),
          catchError(error => {
            return of(failClaimToken({error: error}));
          })
        )
      })
    )
  })


}
