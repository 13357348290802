import {Injectable, ModuleWithProviders, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
// import { CbfeCoreModule } from '@cbcore/public-api';
// import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ButtonLoaderDirective } from './directives/loading.directive';
import { TrackingDirective } from './directives/tracking.directive';
import { PulseAnimationDirective } from './directives/pulse.directive';
import { ImagesLazyLoadDirective } from "./directives/images-lazy-load";
import { AutosizeDirective } from './directives/textarea-autosize.directive';
import { SliderComponent } from './components/slider/slider';
import { ContentSwitchComponent } from './components/content-switch/content-switch';
import { CountdownAnimationComponent } from './components/countdown/countdown';
import { SportSubCodeComponent } from './components/sport-sub-code/sport-sub-code';
import { PoolTitleComponent } from './components/pool-title/pool-title';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { SvgIconLibraryModule } from './components/svg-icon-library/svg-icon-library.module';

import { EmojiPickerModule } from "@cbui/helpers/components/emoji-picker/emoji-picker.module";
import { AvatarUploaderModule } from "@cbui/helpers/components/avatar-uploader/avatar-uploader.module";

import { CommonModule} from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "@cbcore/pipes/pipes.module";
import { BrowserModule } from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {CbModalModule} from "@cbui/helpers/components/modal/modal.module";
// import { CbfeCoreModule } from '@cbcore/cbfe-core.module';

export * from './directives/loading.directive';
export * from './directives/tracking.directive';
export * from './directives/pulse.directive';
export * from './directives/images-lazy-load';
export * from './directives/textarea-autosize.directive';

export * from './components/slider/slider';
export * from './components/content-switch/content-switch';
export * from './components/countdown/countdown';
export * from './components/sport-sub-code/sport-sub-code';
export * from './components/pool-title/pool-title';


export * from './components/svg-icon-library/svg-icon-library.module';

@Injectable()
@NgModule({
  imports: [
    // CbfeCoreModule,
    RouterModule,
    DragDropModule,
    // BrowserModule,
    MatExpansionModule,
    MatIconModule,
    SvgIconLibraryModule,
    EmojiPickerModule,
    AvatarUploaderModule,


    CommonModule,
    TranslateModule,
    PipesModule,
    //BrowserModule,
    FormsModule,
    CbModalModule
  ],
  declarations: [
    ButtonLoaderDirective,
    TrackingDirective,
    PulseAnimationDirective,
    ImagesLazyLoadDirective,
    AutosizeDirective,
    SliderComponent,
    ContentSwitchComponent,
    CountdownAnimationComponent,
    SportSubCodeComponent,
    PoolTitleComponent,
    //LibCbVideoPlayerComponent,
    //OneSignalModalComponent
  ],
  providers: [
    // CbModalService,
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {
        expandedHeight: '58px'
      }
    }
  ],
  exports: [
    // CbfeCoreModule,
    RouterModule,
    DragDropModule,
    MatExpansionModule,
    MatIconModule,
    SvgIconLibraryModule,
    SliderComponent,
    ButtonLoaderDirective,
    TrackingDirective,
    PulseAnimationDirective,
    ImagesLazyLoadDirective,
    AutosizeDirective,
    ContentSwitchComponent,
    CountdownAnimationComponent,
    SportSubCodeComponent,
    PoolTitleComponent,
    //LibCbVideoPlayerComponent,
    //OneSignalModalComponent,
    EmojiPickerModule,
    AvatarUploaderModule,

    CommonModule,
    //BrowserModule,
    TranslateModule,
    PipesModule,
    FormsModule,
    CbModalModule
  ]
})
export class UICommonModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: UICommonModule,
      providers: [
        {
          provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
          useValue: {
            expandedHeight: '58px'
          }
        }
      ]
    }
  }
}
