import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()

@Pipe({ name: 'orderByCloth', pure: false })

export class OrderByCloth implements PipeTransform {
  // array - array
  // config - parameter
  // order - asc / desc

  transform(array: any, params: any, order?: any): any {

    let ordered = _.orderBy(array, params, order);
    let tmp: any = [];
    let tmpNegative: any = [];

    _.map(ordered, function (item: any) {
      if (item.cloth_number < 0) {
        tmpNegative.push(item);
      } else {
        tmp.push(item);
      }
    });
    // tmp = _.reverse(tmp);
    let result = tmp.concat(tmpNegative);

    return result;
  }
}
