import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  AppState, selectMicrositeSessions,
  selectMicrositeSessionsIsLoggedIn,
  track
} from '@cbcore/NGRX/core.state';
import {Config} from "@cbcore/services/config/config.service";
import {GroupTicketClient} from "@cbcore/client/group_tickets";
import { Store } from '@ngrx/store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, first, skip, takeUntil} from 'rxjs/operators';
import {resolve} from "@angular/compiler-cli/src/ngtsc/file_system";

enum RedirectedPages {
  freeplay = '/freeplay',
  pools_list = '/pools',
  pools_solo = '/pools/[POOL_ID]/solo',
  tickets_list = '/tickets/list',
  tickets_single_solo = '/tickets/solo/[TICKET_ID]/pools/[POOL_ID]',
  ticket_syndicates_solo = '/tickets/syndicate/[SYNDICATE_ID]/pools/[POOL_ID]',
  syndicates_manager = 'syndicates/manager/[MANAGER_ID]',
  syndicates_solo = 'syndicates/join/pool/[POOL_ID]/[SYNDICATE_ID]',
  syndicates_list = '/syndicates/join',
  syndicates_leaderboard = '/syndicates/leaderboard/[LEADERBOARD_TYPE]/[PERIOD]',
  group_ticket_view = '/pools/[POOL_ID]/group/view/[MANAGER_ID]',
  group_ticket_create = '/pools/[POOL_ID]/group',
  group_ticket_vote = '/tickets/group/[MANAGER_ID]/pools/[POOL_ID]',
  rebate = '/rebate',
  homepage = '/home'
}
@Component({
  selector: 'lib-centralised-routing',
  template: `<div class="main col"></div>`
})
export class CentralisedRoutingComponent implements OnInit, OnDestroy {
  public $subscribers: any = {}; // needed
  public isLoggedIn: boolean;
  public params: any;

  public hadToLogin: boolean;

  private isLoggedIn$: Observable<boolean>;
  private ngDestroyed$ = new Subject();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _config: Config,
    // private _trackingService: TrackingService,
    private groupTicketClient: GroupTicketClient,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    const session$ = this.store.select(selectMicrositeSessions);
    const queryParams$ = this._activatedRoute.queryParams;

    combineLatest([session$, queryParams$]).pipe(
      takeUntil(this.ngDestroyed$),
      distinctUntilChanged()
    ).subscribe(([session, queryParams]) => {
      if (session && !session.isLoading && queryParams) {
        if (queryParams.page) {
          this.params = queryParams;
          if (!!session.ext_id) {
            this.handleLoggedIn(this.params);
          } else {
            this.handleLoggedOut(this.params);
          }
        }
      }
    })
  }



  private handleLoggedIn(params: any): void {
    this.ngDestroyed$.next();
    this.isLoggedIn = true;
    this.hadToLogin = false;
    this.processRedirect(params);
  }

  private handleLoggedOut(params: any): void {
    this.isLoggedIn = false;
    if (this.shouldPerformLogin()) {
      this.performLogin();
    } else {
      this.ngDestroyed$.next();
      this.processRedirect(params);
    }
  }

  private shouldPerformLogin(): boolean {
    const validPages = ['tickets', 'rebate', 'group', 'freeplay', 'homepage'];
    return validPages.includes(this.params.page);
  }

  private performLogin(): void {
    this._config.login();

    const trackingObject = {
      'event': 'micrositeEvent',
      'eventCategory': 'User',
      'eventAction': 'Click',
      'eventLabel': 'Login_Open',
      'eventValue': 'Centralised_Routing'
    }

    this.store.dispatch(track({ event: trackingObject }));

    this.hadToLogin = true;
  }

  ngOnDestroy(): void {
    // for (const key in this.$subscribers) {
    //   if (this.$subscribers[key]) {
    //     this.$subscribers[key].unsubscribe();
    //   }
    // }

    // this.ngDestroyed$.next();
  }

  processRedirect(params: any) {
    const pool_id = params.pool_id || null;
    const ticket_id = params.ticket_id || null;
    const syndicate_id = params.syndicate_id || null;
    const manager_id = params.manager_id || null;
    const leaderboard_type = params.leaderboard_type || 'all';
    const page = params.page;
    const type = params.type || null;
    const match = params.match || null;
    const period = params.period || null;

    switch (page) {
      case 'freeplay':
        this.navigateTo(RedirectedPages.freeplay);
        break;
      case 'rebate':
        this.navigateTo(RedirectedPages.rebate);
        break;
      case 'pools':
        if (type === 'list') {
          let redirectedRoute: string = RedirectedPages.pools_list;
          if (match) {
            redirectedRoute = redirectedRoute + '/' + match;
          }
          this.navigateTo(redirectedRoute);
        }
        if (type === 'solo') {
          let redirectedRoute: string = RedirectedPages.pools_solo;
          if (pool_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to pools list
            this.navigateTo(RedirectedPages.pools_list);
          }
        }
        break;
      case 'group':
        if (type === 'view') {
          let redirectedRoute: string = RedirectedPages.group_ticket_view;
          if (pool_id && manager_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            redirectedRoute = redirectedRoute.replace('[MANAGER_ID]', manager_id);
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to pools list
            this.navigateTo(RedirectedPages.pools_list);
          }
        }
        if (type === 'create') {
          let redirectedRoute: string = RedirectedPages.group_ticket_create;
          if (pool_id && manager_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to pools list
            this.navigateTo(RedirectedPages.pools_list);
          }
        }
        break;
      case 'tickets':
        if (type === 'list') {
          let redirectedRoute: string = RedirectedPages.tickets_list;
          if (match === 'syndicates') {
            redirectedRoute = redirectedRoute + '/syndicates';
            this.navigateTo(redirectedRoute);
          } else {
            this.navigateTo(redirectedRoute);
          }
        }
        if (type === 'solo') {
          let redirectedRoute: string = RedirectedPages.tickets_single_solo;
          if (pool_id && ticket_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            redirectedRoute = redirectedRoute.replace('[TICKET_ID]', ticket_id);
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to tickets list
            this.navigateTo(RedirectedPages.tickets_list);
          }
        }
        if (type === 'syndicate') {
          let redirectedRoute: string = RedirectedPages.ticket_syndicates_solo;
          if (pool_id && syndicate_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            redirectedRoute = redirectedRoute.replace(
              '[SYNDICATE_ID]',
              syndicate_id
            );
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to tickets list
            this.navigateTo(RedirectedPages.tickets_list);
          }
        }
        if (type === 'vote') {
          let redirectedRoute: string = RedirectedPages.group_ticket_vote;
          if (pool_id && manager_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            redirectedRoute = redirectedRoute.replace('[MANAGER_ID]', manager_id);
            setTimeout(() => {
              this.navigateTo(redirectedRoute, true);
            }, 500);
          } else {
            // Fallback to tickets list
            this.navigateTo(RedirectedPages.tickets_list);
          }
        }
        break;
      case 'syndicates':
        if (type === 'list') {
          this.navigateTo(RedirectedPages.syndicates_list);
        }
        if (type === 'solo') {
          let redirectedRoute: string = RedirectedPages.syndicates_solo;
          if (pool_id && syndicate_id) {
            redirectedRoute = redirectedRoute.replace('[POOL_ID]', pool_id);
            redirectedRoute = redirectedRoute.replace(
              '[SYNDICATE_ID]',
              syndicate_id
            );
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to syndicates list
            this.navigateTo(RedirectedPages.syndicates_list);
          }
        }
        if (type === 'manager') {
          let redirectedRoute: string = RedirectedPages.syndicates_manager;
          if (manager_id) {
            redirectedRoute = redirectedRoute.replace(
              '[MANAGER_ID]',
              manager_id
            );
            this.navigateTo(redirectedRoute);
          } else {
            // Fallback to syndicates list
            this.navigateTo(RedirectedPages.syndicates_list);
          }
        }
        if (type === 'leaderboard') {
          let redirectedRoute: string = RedirectedPages.syndicates_leaderboard;
          redirectedRoute = redirectedRoute.replace(
            '[LEADERBOARD_TYPE]',
            leaderboard_type
          );
          if (period) {
            redirectedRoute = redirectedRoute.replace('[PERIOD]', period);
          } else {
            redirectedRoute = redirectedRoute.replace('[PERIOD]', '');
          }
          this.navigateTo(redirectedRoute);
        }
        break;
      case 'homepage':
        this.navigateTo(RedirectedPages.homepage);
        break;
      default:
        this.navigateTo(RedirectedPages.homepage);
        break;
    }
  }

  navigateTo(route: any, shouldCheckGT = false): void {
    if (this.hadToLogin) {
      this.$subscribers.navEnd = this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (shouldCheckGT) {
            this.customGroupTicketNotificationNavigateTo(route);
          } else {
            // subscribing to NavigationEnd which is about to happen
            this._router.navigate([route]);
            this.unsubscribeNavEnd();
          }
        }
      });
    } else {
      if (shouldCheckGT) {
        this.customGroupTicketNotificationNavigateTo(route);
      } else {
        this._router.navigate([route]);
      }
    }
  }

  customGroupTicketNotificationNavigateTo(route: any): void {
    this.groupTicketClient.getGroupTicket({manager_id: this.params.manager_id, pool_id: this.params.pool_id}).toPromise().then((data: any) => {
      if (data) {
        if (data.syndicate_id) {
          let redirectedRoute: string = RedirectedPages.syndicates_solo;
          redirectedRoute = redirectedRoute.replace('[POOL_ID]', this.params.pool_id);
          redirectedRoute = redirectedRoute.replace(
            '[SYNDICATE_ID]',
            data.syndicate_id
          );
          this._router.navigate([redirectedRoute]);
          this.unsubscribeNavEnd();
        } else {
          if (!data.votable) {
            // user can not vote anymore, pool not open or whatever
            this._router.navigate(['/', 'errors', 'vote-closed']);
            this.unsubscribeNavEnd();
          } else {
            this._router.navigate([route]);
            this.unsubscribeNavEnd();
          }
        }
      }
    }).catch((error: any) => {
      this.navigateTo(RedirectedPages.tickets_list);
      this.unsubscribeNavEnd();
    })
  }

  unsubscribeNavEnd(): void {
    if(this.$subscribers?.navEnd?.unsubscribe)
      this.$subscribers.navEnd.unsubscribe()
  }
}
