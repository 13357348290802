import {
  Input, Output, OnInit, OnDestroy, Renderer2, Component, EventEmitter, HostListener,
  ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';

import { CbModalService } from './modal.service';

@Component({
  selector: 'cb-modal',
  template: `
    <div *ngIf="overlayVisible"
         [style.z-index]="visible ? layerPosition-1 : -1"
         [ngClass]="{'transparent':!backdrop, 'overlay':true, 'cb-overlay-open':openedClass}"
         (click)="dismiss($event)" id="nsmOverlay" #nsmOverlay>
      <div [style.z-index]="visible ? layerPosition : -1"
           [ngClass]="['cb-dialog', customClass, openedClass ? 'cb-dialog-open': 'cb-dialog-close']" id="nsmDialog" #nsmDialog>
        <div class="cb-content" id="nsmContent" #nsmContent>
          <div class="cb-body">
            <ng-content></ng-content>
          </div>
          <button type="button" *ngIf="closable" (click)="close()" aria-label="Close" class="cb-dialog-btn-close">
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNS45NDMsNi4wNThjLTguMDc3LTguMDc3LTIxLjE3Mi04LjA3Ny0yOS4yNDksMEw2LjA1OCw0NzYuNjkzYy04LjA3Nyw4LjA3Ny04LjA3NywyMS4xNzIsMCwyOS4yNDkgICAgQzEwLjA5Niw1MDkuOTgyLDE1LjM5LDUxMiwyMC42ODMsNTEyYzUuMjkzLDAsMTAuNTg2LTIuMDE5LDE0LjYyNS02LjA1OUw1MDUuOTQzLDM1LjMwNiAgICBDNTE0LjAxOSwyNy4yMyw1MTQuMDE5LDE0LjEzNSw1MDUuOTQzLDYuMDU4eiIgZmlsbD0iIzAwMDAwMCIvPgoJPC9nPgo8L2c+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNS45NDIsNDc2LjY5NEwzNS4zMDYsNi4wNTljLTguMDc2LTguMDc3LTIxLjE3Mi04LjA3Ny0yOS4yNDgsMGMtOC4wNzcsOC4wNzYtOC4wNzcsMjEuMTcxLDAsMjkuMjQ4bDQ3MC42MzYsNDcwLjYzNiAgICBjNC4wMzgsNC4wMzksOS4zMzIsNi4wNTgsMTQuNjI1LDYuMDU4YzUuMjkzLDAsMTAuNTg3LTIuMDE5LDE0LjYyNC02LjA1N0M1MTQuMDE4LDQ5Ny44NjYsNTE0LjAxOCw0ODQuNzcxLDUwNS45NDIsNDc2LjY5NHoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
          </button>
        </div>
      </div>
    </div>
  `
})
export class CbModalComponent implements OnInit, OnDestroy {
  @Input() public closable: boolean = true;
  @Input() public escapable: boolean = true;
  @Input() public dismissable: boolean = true;
  @Input() public identifier: string = '';
  @Input() public customClass: string = 'cb-dialog-animation-fade';
  @Input() public visible: boolean = false;
  @Input() public backdrop: boolean = true;
  @Input() public force: boolean = true;
  @Input() public hideDelay: number = 500;
  @Input() public autostart: boolean = false;
  @Input() public target: any;

  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onClose: EventEmitter<any> = new EventEmitter();
  @Output() public onCloseFinished: EventEmitter<any> = new EventEmitter();
  @Output() public onDismiss: EventEmitter<any> = new EventEmitter();
  @Output() public onDismissFinished: EventEmitter<any> = new EventEmitter();
  @Output() public onAnyCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() public onAnyCloseEventFinished: EventEmitter<any> = new EventEmitter();
  @Output() public onOpen: EventEmitter<any> = new EventEmitter();
  @Output() public onEscape: EventEmitter<any> = new EventEmitter();
  @Output() public onDataAdded: EventEmitter<any> = new EventEmitter();
  @Output() public onDataRemoved: EventEmitter<any> = new EventEmitter();

  public layerPosition: number = 1041;
  public overlayVisible: boolean = false;
  public openedClass: boolean = false;

  private _data: any;

  @ViewChild('nsmContent') private nsmContent: ElementRef | undefined;
  @ViewChild('nsmDialog') private nsmDialog: ElementRef | undefined;
  @ViewChild('nsmOverlay') private nsmOverlay: ElementRef | undefined;

  constructor(
    private _renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef,
    private _cbModalService: CbModalService
  ) {
  }

  public ngOnInit(): void {
    if (!!this.identifier && this.identifier.length) {
      this.layerPosition += this._cbModalService.getModalStackCount();
      this._cbModalService.addModal({ id: this.identifier, modal: this }, this.force);

      if (this.autostart) {
        this._cbModalService.open(this.identifier);
      }
    } else {
      throw new Error('identifier field isn’t set. Please set one before calling <ngx-smart-modal> in a template.');
    }
  }

  public ngOnDestroy(): void {
    this._cbModalService.removeModal(this.identifier);
    window.removeEventListener('keyup', this.escapeKeyboardEvent);
    if (!this._cbModalService.getModalStack.length) {
      this._renderer.removeClass(document.body, 'dialog-open');
    }
  }

  public open(top?: boolean): void {
    if (top) {
      this.layerPosition = this._cbModalService.getHigherIndex();
    }

    this._renderer.addClass(document.body, 'dialog-open');
    this.overlayVisible = true;
    this.visible = true;

    setTimeout(() => {
      this.openedClass = true;

      if (this.target) {
        this.targetPlacement();
      }

      const customClass = this._cbModalService.getCustomClass()
      // @ts-ignore
      const bodyRect: any = this.nsmOverlay.nativeElement.getBoundingClientRect();
      // @ts-ignore
      const nsmDialogRect: any = this.nsmDialog.nativeElement.getBoundingClientRect();
      // @ts-ignore
      const nsmContentRect: any = this.nsmContent.nativeElement.getBoundingClientRect();

      if(!this.customClass) {
        let topOffset = (bodyRect.height <= nsmContentRect.height) ? '100%' : `${nsmContentRect.height}px`;
        // @ts-ignore
        this._renderer.setStyle(this.nsmDialog.nativeElement, 'top', `calc(100% - ${topOffset})`);
      } else {
        // @ts-ignore
        this._renderer.addClass(this.nsmDialog.nativeElement, customClass);
      }

      this._changeDetectorRef.markForCheck();
    });

    this.onOpen.emit(this);

    if (this.escapable) {
      window.addEventListener('keyup', this.escapeKeyboardEvent);
    }
  }

  public close(): void {
    const me: any = this;

    this.openedClass = false;
    this.onClose.emit(this);
    this.onAnyCloseEvent.emit(this);

    if (this._cbModalService.getOpenedModals().length < 2) {
      this._renderer.removeClass(document.body, 'dialog-open');
    }

    setTimeout(() => {
      me.visibleChange.emit(me.visible);
      me.visible = false;
      me.overlayVisible = false;
      me._changeDetectorRef.markForCheck();
      me.onCloseFinished.emit(me);
      me.onAnyCloseEventFinished.emit(me);
    }, this.hideDelay);

    window.removeEventListener('keyup', this.escapeKeyboardEvent);
  }

  public dismiss(e: any): void {
    const me: any = this;

    if (!this.dismissable) {
      return;
    }

    if (e.target.classList.contains('overlay')) {
      this.openedClass = false;
      this.onDismiss.emit(this);
      this.onAnyCloseEvent.emit(this);

      if (this._cbModalService.getOpenedModals().length < 2) {
        this._renderer.removeClass(document.body, 'dialog-open');
      }

      setTimeout(() => {
        me.visible = false;
        me.visibleChange.emit(me.visible);
        me.overlayVisible = false;
        me._changeDetectorRef.markForCheck();
        me.onDismissFinished.emit(me);
        me.onAnyCloseEventFinished.emit(me);
      }, this.hideDelay);

      window.removeEventListener('keyup', this.escapeKeyboardEvent);
    }
  }

  public toggle(top?: boolean): void {
    if (this.visible) {
      this.close();
    } else {
      this.open(top);
    }
  }

  public addCustomClass(className: string): void {
    if (!this.customClass.length) {
      this.customClass = className;
    } else {
      this.customClass += ' ' + className;
    }
  }

  public removeCustomClass(className?: string): void {
    if (className) {
      this.customClass = this.customClass.replace(className, '').trim();
    } else {
      this.customClass = '';
    }
  }

  public isVisible(): boolean {
    return this.visible;
  }

  public hasData(): boolean {
    return this._data !== undefined;
  }

  public setData(data: any, force?: boolean): any {
    if (!this.hasData() || (this.hasData() && force)) {
      this._data = data;
      this.onDataAdded.emit(this._data);
      this._changeDetectorRef.markForCheck();
    }
  }

  public getData(): any {
    return this._data;
  }

  public removeData(): void {
    this._data = undefined;
    this.onDataRemoved.emit(true);
    this._changeDetectorRef.markForCheck();
  }

  public escapeKeyboardEvent = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.onEscape.emit(this);
      this._cbModalService.closeLatestModal();
    }
  }

  @HostListener('window:resize')
  public targetPlacement(): void {
    if (!this.nsmDialog || !this.nsmContent || !this.nsmOverlay || !this.target) {
      return;
    }

    const targetElementRect: any = document.querySelector(this.target).getBoundingClientRect();
    const bodyRect: any = this.nsmOverlay.nativeElement.getBoundingClientRect();


    const nsmContentRect: any = this.nsmContent.nativeElement.getBoundingClientRect();
    const nsmDialogRect: any = this.nsmDialog.nativeElement.getBoundingClientRect();

    const marginLeft: any = parseInt(getComputedStyle(this.nsmContent.nativeElement).marginLeft as any, 10);
    const marginTop: any = parseInt(getComputedStyle(this.nsmContent.nativeElement).marginTop as any, 10);

    let offsetTop: number = targetElementRect.top - nsmDialogRect.top - ((nsmContentRect.height - targetElementRect.height) / 2);
    let offsetLeft: number = targetElementRect.left - nsmDialogRect.left - ((nsmContentRect.width - targetElementRect.width) / 2);

    if (offsetLeft + nsmDialogRect.left + nsmContentRect.width + (marginLeft * 2) > bodyRect.width) {
      offsetLeft = bodyRect.width - (nsmDialogRect.left + nsmContentRect.width) - (marginLeft * 2);
    } else if (offsetLeft + nsmDialogRect.left < 0) {
      offsetLeft = -nsmDialogRect.left;
    }

    if (offsetTop + nsmDialogRect.top + nsmContentRect.height + marginTop > bodyRect.height) {
      offsetTop = bodyRect.height - (nsmDialogRect.top + nsmContentRect.height) - marginTop;
    }

    if (offsetTop < 0) {
      offsetTop = 0;
    }

    this._renderer.setStyle(this.nsmContent.nativeElement, 'top', `calc(100% - ${nsmContentRect.height})` + 'px');
    this._renderer.setStyle(this.nsmContent.nativeElement, 'left', offsetLeft + 'px');
  }
}
