import { Injectable } from '@angular/core';
import { DEFAULT_CONFIG } from './config.model';

@Injectable()
export class Config {
  public CONFIG:any = DEFAULT_CONFIG;

  login(): void {}
  register(): void {}
}
