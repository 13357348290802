import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PostMessageService } from '../services/utils/postmessage/postmessage.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class TicketClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config, public _postMessageService: PostMessageService) {
    this.endpoint = _config.CONFIG.apiUrl + '/tickets';
  }

  list(model: any = {}): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('type', (model.filters.status === 'live' || model.filters.status === 'offers') ? '1' : '2')
      .set('page', model.page.toString())
      .set('per', model.per.toString())
      .set('filters[is_solo_bet]', 'true')
      .set('filters[with_offer]', model.filters.status === 'offers' ? 'true' : 'false')
      .set('filters[pool_types]', model.filters.pool_types);

    return this.http.get(this.endpoint, { params, withCredentials: true })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  get(id: string): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'syndicate.manager,live_offer,offer_transactions');

    return this.http.get(this.endpoint + '/' + id, { params, withCredentials: true })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getInstoreTicket(merchant_ref: string, security_code?: string): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('includes', 'syndicate,live_offer,offer_transactions');

    if (security_code)
      params = params.set('security_code', security_code);

    return this.http.get(this.endpoint + '/' + merchant_ref, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  create(item: object): Observable<any> {
    return this.http.post(this.endpoint, item, { withCredentials: true })
      .pipe(
        map(data => {
          let isFreePlayBet: boolean = (item["ticket"].free_play_id !== null) ? true : false;
          this._postMessageService.betPlaced(data, isFreePlayBet);
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  share(body: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'syndicate.manager,live_offer,offer_transactions,pool.legs.sport_event,pool.legs.selections,pool.prizes');
    return this.http.post(this.endpoint + '/' + body.id + '/share', body, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getShared(uuid: string): Observable<any> {
    return this.http.get(this.endpoint + '/shared/' + uuid)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getFilters(): Observable<any> {
    return this.http.get(this.endpoint + '/filter_options')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getCrossSell(id: string): Observable<any> {
    return this.http.get(this.endpoint + '/' + id + '/cross_sell')
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  getSummary(id: string, token: string, siteID: string, isSyndicate: boolean): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'syndicate.manager,live_offer,offer_transactions,pool.legs.sport_event,pool.history.sport_event_history,pool.legs.selections.unit_distributions,pool.prizes')
      .set('token', token)
      .set('siteID', siteID)
      .set('isSyndicate', String(isSyndicate));

    return this.http.get(this.endpoint + '/summary_view/' + id, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  unauthTicket(merchant_ref: string, securityCode: string): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('includes', 'live_offer,offer_transactions')
      .set('security_code', securityCode);

    return this.http.get(this.endpoint + '/' + merchant_ref + '/unauth', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  //
  // void(id : string, merchantRef : string, item : TicketItem) : Observable<any> {
  //   return this.http.post(this.endpoint + '/' + id + '/' + merchantRef + '/void', item)
  //           .map((res: Response) => res.json())
  //           .catch((error: any) => throwError(error.json().error || 'Server error'));
  // }
  //
  // recreate(id : string, merchantRef : string, item : TicketItem) : Observable<any> {
  //   return this.http.post(this.endpoint + '/' + id + '/' + merchantRef + '/recreate', item)
  //           .map((res: Response) => res.json())
  //           .catch((error: any) => throwError(error.json().error || 'Server error'));
  // }
  //
  //
  // markAsPaid(id : string, merchantRef : string, item : TicketItem) : Observable<any> {
  //   return this.http.post(this.endpoint + '/' + id + '/' + merchantRef + '/mark_as_paid', item)
  //           .map((res: Response) => res.json())
  //           .catch((error: any) => throwError(error.json().error || 'Server error'));
  // }
  //
  // markAsUnpaid(id : string, merchantRef : string, item : TicketItem) : Observable<any> {
  //   return this.http.post(this.endpoint + '/' + id + '/' + merchantRef + '/mark_as_paid', item)
  //           .map((res: Response) => res.json())
  //           .catch((error: any) => throwError(error.json().error || 'Server error'));
  // }

  markAllPaid(merchant_ref: string, body: any): Observable<any> {
    return this.http.post(this.endpoint + '/' + merchant_ref + '/mark_all_paid', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  updateSecurityCode(merchant_ref: string, body: any): Observable<any> {
    return this.http.post(this.endpoint + '/' + merchant_ref + '/update_security_code', body)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
