import { Injectable } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CentralisedRoutingComponent } from './centralised-routing/centralised-routing';
// import { CentralisedRoutingRouteGuard } from '@cbcore/public-api';

// const appRoutes: Routes = [
//   {
//     path: '',
//     component: CentralisedRoutingComponent,
//     data: { scroll: true }
//   }
// ];

const appRoutes: Routes = [
  {
    path: 'routing',
    component: CentralisedRoutingComponent,
    data: { scroll: true }
  }
];



@Injectable()
@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  declarations: [CentralisedRoutingComponent],
  exports: []
})
export class CentralisedRoutingModule { }
