import { createAction, props } from '@ngrx/store';
import {ManagersScope} from "./manager.model";


export const getManagersList = createAction( '[Managers] Get managers list',
  props<{
    model?: any
  }>());

export const getManagersListLoadMore = createAction( '[Managers] Get managers list load more',
  props<{
    model?: any
  }>());
export const getManagersSearchListLoadMore = createAction( '[Managers] Get managers search list load more',
props<{
  search: string,
  model?: any
}>());

export const successManagersList = createAction('[Managers] Get managers list Success',
  props<{
    response: ManagersScope.ManagersListResponse | undefined,
    params?: ManagersScope.ManagersListParams
  }>());

export const setPinnedSyndicates = createAction( '[Manager] Set pinned Syndicates',
  props<{
    id: number
  }>());

// export const savePinnedSyndicates = createAction( '[Manager] Save pinned Syndicates',
//   props<{
//     model?: any
//   }>());

export const removePinnedSyndicates = createAction( '[Manager] Remove pinned Syndicates');

export const getManager = createAction( '[Manager] Get manager by ID',
  props<{
    id: number
  }>());

export const reloadManager = createAction( '[Manager] Reload manager');

export const successManager = createAction('[Managers] Get manager Success',
  props<{
    response: ManagersScope.Manager
  }>());

// export const getMyManager = createAction( '[Manager] Get My manager',
//   props<{
//     id: number
//   }>());

// export const successMyManager = createAction('[Managers] Get my manager Success',
//   props<{
//     response: ManagersScope.Manager
//   }>());

export const createManager = createAction( '[Manager] Create a new manager',
  props<{
    model?: any
  }>());

export const successCreateManager = createAction('[Managers] Create my manager Success',
  props<{
    response: ManagersScope.ManagerCreated
  }>());

export const updateManager = createAction( '[Manager] Update a manager',
  props<{
    model?: any
  }>());

export const successUpdateManager = createAction('[Managers] Update my manager Success',
  props<{
    response: ManagersScope.ManagerCreated
  }>());

// ---
export const followManager = createAction( '[Manager] Follow a manager',
  props<{
    id: string
  }>());

export const unfollowManager = createAction( '[Manager] Unfollow a manager',
  props<{
    id: string
  }>());


// ---
export const subscribeToAManager = createAction( '[Manager] Subscribe to a manager',
  props<{
    id: string | number
  }>());

export const unsubscribeToAManager = createAction( '[Manager] Unsubscribe to a manager',
  props<{
    id: string | number
  }>());

// ---

export const share = createAction( '[Manager] Share my manager',
  props<{
    model?: any
  }>());

// ---

export const getLeaderboard = createAction( '[Leaderboard] Get leaderboard',
  props<{
    model?: any
  }>());

export const reloadLeaderboard = createAction( '[Leaderboard] Reload leaderboard');
export const successLeaderboard = createAction('[Leaderboard] Get leaderboard Success',
  props<{
    response: ManagersScope.ManagersListResponse
  }>());

export const loadLeaderboardWidget = createAction( '[Leaderboard] Load leaderboard widget',
  props<{
    sportId: number
  }>());

// Deprecated (not used anywhere)
// export const getLeaderboardPeriods = createAction( '[Leaderboard] Get leadreboard periods');

//

export const getTopFollowed = createAction( '[Manager] Get top followed managers');
export const loadMoreLeaderboard = createAction( '[Manager] Load More Leaderboard');

export const successTopFollowed = createAction('[Manager] Get top followed Success',
  props<{
    response: ManagersScope.ManagersListResponse
  }>());


//
export const loadTopPositiveROI = createAction('[Manager] Get top 4 captains with positive ROI',
props<{
  sportId: number
}>());

export const successTopPositiveROI = createAction('[Manager] Get top 4 captains with positive ROI Success',
  props<{
    response: ManagersScope.ManagersListResponse
  }>());


////

export const failManager = createAction('[Manager] Fail',
  props<{
    error: any
  }>());
