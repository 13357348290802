import { MicrositeSessionsState, PromotionPeriods, SafeUserData } from './coreAuth.models';
import { callMicrositeSessions, failMicrositeSessions, setMicrositeAuthTokenAuthorizationCode, successMicrositeSessions, setOptionalData, setCustomMultiplier, successMicrositeRefreshTokens } from './coreAuth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import * as Big from 'big.js';

export const initialState: MicrositeSessionsState = {
  id                      : 0,
  ext_id                  : undefined,
  has_offers              : undefined,
  currency_code           : undefined,
  country_code            : undefined,
  auth_tokens             : undefined,
  freeplays               : 0,
  manager_id              : undefined,

  additional_info         : undefined,
  permissions             : undefined,
  setting                 : undefined,
  free_play_session       : undefined,

  isLoading               : false,
  balance                 : undefined,
  user_name               : undefined,
  age_verified            : undefined,
  review_verification     : undefined,
  actionable_promotions_count: undefined,
  period_status           : null,
  currency_multiplier     : null, //1,

  optionalData            : undefined,

  AuthToken : {
    authorization_code: undefined,
    tokens: {
      access_token : undefined,
      refresh_token: undefined,
      expires_at   : undefined
    }
  }
};

const reducer = createReducer(
  initialState,

  on(callMicrositeSessions, (state, action) => {

    return {
      ...state,
      isLoading: true
    }
  }),

  on(setOptionalData, (state, action) => {
    if(action.optionalData) {
      const data = action.optionalData;
      const optionalData: SafeUserData = {
        ...(data.user_preferences && { user_preferences: data.user_preferences }),
        ...(data.registered_at && { registered_at: data.registered_at }),
        ...(data.wallet && { wallet: data.wallet }),
      }

      const finalOptionalData = Object.assign({}, state.optionalData, optionalData);

      return {
        ...state,
        optionalData: finalOptionalData
      }
    } else {
      return {
        ...state
      }
    }
  }),

  on(successMicrositeSessions, (state, action) => {
    const data: any = action.response;

    if(!data) return { ...state };

    const AuthToken = {
      authorization_code: undefined,
      tokens: action.response?.auth_tokens
    }

    const { id,
            ext_id,
            has_offers,
            currency_code,
            country_code,
            auth_tokens,
            freeplays,
            manager_id,

            additional_info,
            permissions,
            setting,
            free_play_session,
          } = data;

    const optionalData = {
      ...((data.additional_info || {}).age_verified && { age_verified: data.additional_info?.age_verified }),
      ...(((data || {}).free_play_session || {}).new_player_bonus && { new_player_bonus: ((data || {}).free_play_session || {}).new_player_bonus }),
      period_status: parseNPB(((data || {}).free_play_session || {}).new_player_bonus, data.currency_code)
    }


    const finalOptionalData = Object.assign({}, state.optionalData, optionalData);

    return {
      ...state,

      // full response
      id,
      ext_id,
      has_offers,
      currency_code,
      country_code,
      auth_tokens,
      freeplays,
      manager_id,

      additional_info,
      permissions,
      setting,
      free_play_session,

      // computed
      isLoading: false,
      balance: data?.additional_info?.balance,
      user_name: data?.additional_info?.user_name,
      age_verified: data?.additional_info?.age_verified,
      addireview_verification: data?.additional_info?.review_verification,
      actionable_promotions_count: data?.free_play_session?.actionable_promotions_count,
      currency_multiplier: !state.currency_multiplier ? (data?.additional_info?.curr_multiplier || 1) : state.currency_multiplier,

      // UserDataMiddleware
      optionalData: finalOptionalData,
      period_status: finalOptionalData.period_status,
      // AuthTokenService
      AuthToken
    }
  }),

  on(setCustomMultiplier, (state, action) => {
    return {
      ...state,
      currency_multiplier: action.curr_mult
    }
  }),

  on(setMicrositeAuthTokenAuthorizationCode, (state, action) => {

    const AuthToken = {
      authorization_code: action.authorization_code,
      tokens: state.AuthToken.tokens
    }

    return {
      ...state,
      AuthToken: AuthToken
    }
  }),

  on(successMicrositeRefreshTokens, (state, action) => {

    const AuthToken = {
      authorization_code: state.AuthToken.authorization_code,
      tokens: action.response
    }

    return {
      ...state,
      AuthToken: AuthToken
    }
  }),

  on(failMicrositeSessions, (state, action) => {
    return {
      ...state,
      ext_id                  : undefined,
      has_offers              : undefined,
      currency_code           : undefined,
      country_code            : undefined,
      auth_tokens             : undefined,
      freeplays               : 0,
      manager_id              : undefined,

      additional_info         : undefined,
      permissions             : undefined,
      setting                 : undefined,
      free_play_session       : undefined,

      optionalData            : undefined,

      isLoading               : false,

      balance                 : undefined,
      user_name               : undefined,
      age_verified            : undefined,
      addireview_verification : undefined,
      actionable_promotions_count:undefined,
      period_status: null
    }
  }),
);

export function micrositeSessionsReducer(
  state: MicrositeSessionsState | undefined,
  action: Action
): MicrositeSessionsState {
  return reducer(state, action);
}


const parseNPB = (new_player_bonus: any, currency_code: any): PromotionPeriods | undefined => {

  if (!new_player_bonus) { return PromotionPeriods.NotExistent; }

  const expire_date = new Date(new_player_bonus.end_date);
  const current_date = new Date(Date.now());

  const isBonusPeriodExpired = expire_date.getTime() ? current_date.getTime() >= expire_date.getTime() : false;
  const isAlmostCompleted = checkAlmostCompleted(expire_date);

  const thresholds = new_player_bonus.thresholds.filter((threshold: any) => threshold.currency === currency_code);
  const threshold = thresholds[0];
  const isCompleted = new Big.Big(new_player_bonus.spend_amount || 0).gt(threshold.min_amount) || new Big.Big(new_player_bonus.spend_amount || 0).eq(threshold.min_amount)

  if (isBonusPeriodExpired) {
    return PromotionPeriods.Expired;
  }

  if (new_player_bonus.state === 'eligible') {
    return PromotionPeriods.Eligible;
  }


  if (new_player_bonus.state === 'paid') {
    return PromotionPeriods.Completed;
  }

  if (new_player_bonus.state === 'opted_in') {
    if (isCompleted) {
      return PromotionPeriods.Completed;
    } else {
      if (!isAlmostCompleted) {
        return PromotionPeriods.Begun;
      } else {
        return PromotionPeriods.Ending;
      }
    }
  }

  return;
}

const checkAlmostCompleted = (expire_date: any): boolean => {
  const timeLeft = 1000 * 12 * 60 * 60;
  const now = new Date(Date.now()).getTime();
  const expiry = new Date(expire_date).getTime();
  return (expiry - now) <= timeLeft;
}


/*

  failMicrositeSessions doesn't remove the 'id'. WebSockets feature use that to unsubscribe from the private channel
  optionalData represents wallet data needed in core

*/
