import { Component } from '@angular/core';
import { DefaultNotificationComponent } from '../default';

@Component({
  selector: 'freeplay-placed-notification-component',
  templateUrl: './freeplay-placed.html'
})
export class FreeplayPlacedNotificationComponent extends DefaultNotificationComponent {
  constructor() {
    super();
  }
}
