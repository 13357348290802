import { Injectable } from '@angular/core';
import { WindowService } from './../window/window.service';
import { Config } from './../../config/config.service';
import { Observable, BehaviorSubject, of, throwError, timer } from 'rxjs';
import { delay, repeat, mergeMap, retryWhen, map } from 'rxjs/operators';

@Injectable()

export class TwitterService {
  private _window: any;
  private $subscriber: BehaviorSubject<any> = new BehaviorSubject(null);
  private maxRetryAttempts: number = 4;
  private retryDelay: number = 1500;
  private haveTwitterWidgets = false;

  constructor(private windowRef: WindowService, private _config: Config) {

  }

  init(): void {
    this.haveTwitterWidgets = this._config.CONFIG.haveTwitterWidgets;
    this._window = this.windowRef.nativeWindow;
    // This needs to be loaded everytime
    (<any>window).twttr = (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).twttr || {};
      if (d.getElementById(id)) {
        return t;
      }
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = 'https://platform.twitter.com/widgets.js';
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, 'script', 'twitter-wjs'));

    if (undefined !== this._window.twttr && this.haveTwitterWidgets) {
      this.loadWidgets();
      return this._window.twttr;
    }
  }

  ready(): Observable<boolean> {
    return this.$subscriber;
  }

  loadWidgets() {
    const checkWidget = () => { return (((<any>window).twttr || {}).widgets || {}).load ? true : false }

    const $widgets = of(((<any>window).twttr || {}).widgets).pipe(
      map(() => {
        if(!checkWidget()) {
          throw 'no_twttr_script'
        } else {
          return ((<any>window).twttr.widgets);
        }
      }),
      retryWhen(error => error.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          return (retryAttempt > this.maxRetryAttempts) ? throwError(`${error}_attempt_${retryAttempt}`) : timer(this.retryDelay);
        })
      )),
      delay(this.retryDelay),
      repeat(this.maxRetryAttempts)
    )

    $widgets.subscribe(
      widgets => this.$subscriber.next(widgets),
      subscriptionResultError => this.$subscriber.next(null)
    )
  }
}
