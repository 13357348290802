<div class="notification-box" *ngFor="let note of _notes">
  <div class="title">
    <span [translate]="'newOffer.TITLE'"></span>
    <span class="float-right">
        <i class="fal fa-times" (click)="hide(note)"></i>
    </span>
  </div>
  <div class="content-links">
    <a class="btn tertiary w-button small _31 mr-custom-7" (click)="hide(note);">
      <span [translate]="'buttons.CLOSE'"></span>
    </a>
    <a class="btn secondary w-button small _31" (click)="hide(note); navigateToTickets()">
      <span [translate]="'buttons.VIEW'"></span>
    </a>
  </div>
</div>
