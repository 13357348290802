import { Directive, ElementRef, Input, OnChanges, SimpleChanges, InjectionToken } from '@angular/core';
export let pulseElement: any = new InjectionToken('pulseEl');

@Directive({
  selector: '[pulse]'
})

export class PulseAnimationDirective implements OnChanges {
  @Input('pulse') value: any;
  pulseEl: HTMLElement;
  pulseClass: string = 'pulse-animation';

  constructor(el: ElementRef) {
    this.pulseEl = el.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value.currentValue) {
      if ((changes.value.previousValue !== changes.value.currentValue) && !changes.value.firstChange) {
        this.pulseEl.classList.add(this.pulseClass);
        setTimeout(() => {
          this.pulseEl.classList.remove(this.pulseClass);
        }, 500);
      }
    }
  }
}
