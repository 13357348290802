import { PoolSelections } from '../../services/selections/selections.model';
import * as _ from 'lodash-es';
import * as bigIntProxy from 'big-integer';
const bigInt: any = (<any>bigIntProxy) || bigIntProxy;

export const getTotalLines = (selections: string): number => {
    return _.reduce(selections.split('/'), (lines, line) => {
        return lines *= (_.size(line.split(',')));
    }, Number(1));
}

export const clearLines = (poolSelections: PoolSelections): any => {
    poolSelections.legs = {};
    poolSelections.valid_legs = {};
    poolSelections.smartpick = 0;
    poolSelections.cross_sell = 0;
    // poolUpdate was called too
    return poolSelections;
}

export const calculateLines = (poolSelections: PoolSelections): any => {
    let index: number = 0;
    let lines: number = 0;

    _.forEach(poolSelections.legs, (value: any) => {
        // First iteration, set the number of lines to 1
        if (lines === 0)
            lines = 1;
        // Updated the number of lines
        lines *= value.length;
        index++;
    });

    poolSelections.legs_with_selections = _.size(poolSelections.legs);
    poolSelections.total_lines = (poolSelections.legs_with_selections === poolSelections.leg_num) ? lines : 0;
    return poolSelections;
}

export const calculateSelectionsString = (poolSelections: PoolSelections): any => {
    if (poolSelections.total_lines <= 0)
        poolSelections.selections_string = '';

    // if number of lines is bigger than 0, then generate the selection string
    if (poolSelections.total_lines > 0) {
        let str: string = '';
        _.forEach(poolSelections.legs, function (leg, key) {
            str += '/';
            _.forEach(leg, function (bin) {
                str += bin;
                if (_.last(leg) !== bin) {
                    str += ',';
                }
            })
        });
        poolSelections.selections_string = str.substring(1);
    }

    return poolSelections;
}

// group votes
export const calculateSelectionsStringVotes = (poolSelections: PoolSelections): any => {
    let str: string = '';
    let tempLegs = poolSelections.legs;
    for (let i = 1; i <= poolSelections.leg_num; i++) {
        if (tempLegs[i]) {
            _.forEach(tempLegs[i], function (bin) {
                str += bin;
                if (_.last(tempLegs[i]) !== bin) {
                    str += ',';
                }
            })
            if (i !== poolSelections.leg_num) {
                str += '/';
            }
        } else {
            if (i !== poolSelections.leg_num) {
                str += '/';
            }
        }
    }
    poolSelections.votes_string = str;
    return poolSelections;
}

export const updateValidLegs = (poolSelections: PoolSelections): PoolSelections => {
    if (poolSelections.type_code === 'RACE_ORDER')
        return calculateValidLines(poolSelections);

    if (poolSelections.type_code === 'MATCH')
        return calculateValidLineSEP(poolSelections);

    return poolSelections;
}

/* START FECTA */

export const calculateValidLines = (poolSelections: PoolSelections): PoolSelections => {
    if (poolSelections.legs_with_selections === poolSelections.leg_num) {
        let fecta: any = calculateFecta(
            poolSelections.selections_string,
            poolSelections.leg_num
        );

        poolSelections.selections_string = fecta.selections_string;
        poolSelections.total_lines = fecta.total_lines;
        poolSelections.valid_legs = generateValidLegs(fecta.selections_string);
    }
    return poolSelections;
}

// ### //

export const generateValidLegs = (selections_string: string): any => {
    let valid_legs: any = {};
    let legs: any = selections_string.split('/');
    _.each(legs, function (leg, index) {
        var bins = leg.split(',');
        valid_legs[index + 1] = [];
        _.each(bins, function (bin) {
            valid_legs[index + 1].push(Number(bin));
        });
    });
    return valid_legs;
}

export const calculateFecta = (selections_string: string, num_legs: number): any => {
    let t0: any = new Date();

    const scope: any = createScope(selections_string, num_legs);
    getTicket(scope); // might not update through reference(try also let) // log values for racing (cloned scope before and after), do compatibility in effect
    let valid_selections_string = get_final_ticket(scope);

    let t1: any = new Date();
    return {
        selections_string: valid_selections_string,
        total_lines: scope.total_selections // must be updated
    };
}

export const createScope = (selections_string: string, num_legs: number): any => {
    const scope: any = {};

    var x1 = selections_string.split('/');
    scope.selections = new Array(x1.length);

    for (var i = 0; i < x1.length; i++) {
        var x2 = x1[i].split(',');
        scope.selections[i] = x2;
        for (var j = 0; j < x2.length; j++) {
            scope.selections[i][j] = Number(x2[j]);
        }
    }

    scope.total_selections = 0;
    scope.perm_selec = new Array(scope.selections.length);
    scope.selec_per_place = new Array(scope.selections.length);

    return scope;
}

export const getTicket = (scope: any, leg = 0, perm_bin = bigInt(0)): any => {
    if (leg == scope.selections.length) {
        ++scope.total_selections;

        for (var i = 0; i < scope.perm_selec.length; ++i) {
            scope.selec_per_place[i] = bigInt(scope.selec_per_place[i]).or(bigInt(1).shiftLeft(scope.perm_selec[i] - 1));
        }
    }
    else {
        for (var i = 0; i < scope.selections[leg].length; ++i) {
            scope.perm_selec[leg] = scope.selections[leg][i];
            // Conversion not needed here
            let y = bigInt(1).shiftLeft((scope.selections[leg][i] - 1));
            let x = bigInt(perm_bin).or(y);

            if (!x.eq(bigInt(perm_bin))) {
                getTicket(scope, leg + 1, x);
            }
            // if ((perm_bin | 1 << (scope.selections[leg][i] - 1)) != perm_bin)
            //   this.getTicket(scope, leg + 1, perm_bin | 1 << (scope.selections[leg][i] - 1));
        }
    }
}

export const get_final_ticket = (scope: any): any => {
    var ss = '';
    var selec = 0;
    var first_place = true, first_selec = false;

    scope.selec_per_place.forEach(function (el: any) {
        el = bigInt(el);
        if (first_place) {
            first_place = false;
        }
        else {
            ss += '/';
        }
        selec = 0;
        first_selec = true;
        while (el.greater(bigInt.zero)) {
            var sLeft = bigInt(1).shiftLeft(selec);
            if (el.and(sLeft).notEquals(0)) {
                if (first_selec) {
                    first_selec = false;
                }
                else {
                    ss += ',';
                }
                ss += selec + 1;
                el = el.minus(bigInt(1).shiftLeft(selec));
            }
            ++selec;
        }
    });
    return ss;
}


/* END FECTA */


/* START SEP */

export const calculateValidLineSEP = (poolSelections: PoolSelections): any => {
    if (poolSelections.legs_with_selections === poolSelections.leg_num) {
        const sep = calculateSep(
            poolSelections.selections_string,
            poolSelections.leg_num,
            poolSelections.sep_correlations
        );
        poolSelections.selections_string = sep.selections_string;
        poolSelections.total_lines = sep.total_lines;
        poolSelections.valid_legs = generateValidLegs(sep.selections_string);
    }
    return poolSelections;
}

// #### //

export const calculateSep = (
    selections_string: string,
    num_legs: number,
    sep_correlations: any
): any => {
    const t0: any = new Date();

    const scope: any = {};
    scope.total_lines = 0;

    let linii = 0; // linii - lines, number of correct lines that user is having valid selections
    const new_selections_string_object = {};
    for (let idx = 0; idx < num_legs; idx++) {
        new_selections_string_object[idx] = [];
    }

    const x1 = selections_string.split('/');
    scope.selections = new Array(x1.length);
    for (let i = 0; i < x1.length; i++) {
        const x2 = x1[i].split(',');
        scope.selections[i] = x2;
        for (let j = 0; j < x2.length; j++) {
            scope.selections[i][j] = Number(x2[j]);
        }
    }

    const permutations = generateSelectionsCombos(scope.selections); // recurring stuff too

    // Explanation of below code
    // You pass thru correlations, and for each [key_lvl_1, key_lvl_2[, ley_lvl_3]] you check if the bin the selection string at
    // position[key_lvl_1, key_lvl_2[, ley_lvl_3]] is within the blacklisted correlations array
    for (let p = 0; p < permutations.length; p++) {
        const line: any = permutations[p];
        let valid = true;

        for (const [oj_key, oj_value] of Object.entries(sep_correlations)) {
            if (valid) {
                for (const [io_key, io_value] of Object.entries(sep_correlations[oj_key])) {
                    const lvl2_value: any = io_value; // Need to force cast it as object
                    const lvl2_keys = Object.keys(lvl2_value);
                    lvl2_keys.forEach(key => {
                        // We check if it's array or object. If it's object, we use the 3 selections blacklist
                        if (typeof (lvl2_value[key] === 'object') && !(lvl2_value[key].constructor == Array)) {
                            const toCheck = JSON.stringify([permutations[p][oj_key], permutations[p][io_key], permutations[p][Number(key)]]);
                            const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key][key]);
                            const check = blacklistedSelections.indexOf(toCheck);
                            if (check != -1) {
                                valid = false;
                            }
                        } else {
                            if (lvl2_value[key].length > 0) { // We check only if there are any correlations
                                const toCheck = JSON.stringify([permutations[p][oj_key], permutations[p][io_key]]);
                                const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key]);
                                // const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key].correlations);
                                const check = blacklistedSelections.indexOf(toCheck);
                                if (check != -1) {
                                    valid = false;
                                }
                            }
                        }
                    });
                }
            }
        }

        if (valid) {
            line.reduce(function (result: any, item: any, index: any, array: any) {
                new_selections_string_object[index].push(item);
            }, {});
            linii++;
        }
    }

    for (let idx = 0; idx < num_legs; idx++) {
        new_selections_string_object[idx] = _.uniq(new_selections_string_object[idx]);
    }

    scope.total_lines = linii;
    scope.selection_string = calculateSepSelectionsString(new_selections_string_object, scope);
    const t1: any = new Date();
    console.log('Total time: ' + (t1 - t0) / 1000);
    return {
        selections_string: scope.selection_string,
        total_lines: scope.total_lines
    };
}

export const generateSelectionsCombos = (
    list: any, n = 0,
    result: any = [],
    current: any = []
): any => {
    if (n === list.length) {
        result.push(current);
    } else {
        list[n].forEach((item: any) => generateSelectionsCombos(list, n + 1, result, [...current, item]));
    }

    return result;
}

export const calculateSepSelectionsString = (
    selectionObj: any,
    scope: any
): any => {
    let valid_selection_string = '';
    if (scope.total_lines > 0) {
        let str = '';
        _.forEach(selectionObj, function (leg, key) {
            str += '/';
            _.forEach(leg, function (bin) {
                str += bin;
                if (_.last(leg) !== bin) {
                    str += ',';
                }
            });
        });
        valid_selection_string = str.substr(1);
    } else {
        valid_selection_string = '';
    }
    return valid_selection_string;
}

/* END SEP */
