import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class UserClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/users';
  }

  register(credentials: object): Observable<any> {
    return this.http.post(this.endpoint, credentials)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  share(body: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('raf_code', body.shareable_data.raf_code)

    return this.http.post(this.endpoint + '/' + body.id + '/share', body, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
