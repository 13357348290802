import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, Input} from '@angular/core';
// Lazy load images in-view as you scroll
// Only for big lists eg: syndicates list & tickets

import {environment} from '@environment/environment';

@Directive({
  selector: 'img[imgLazyLoad]',
})
export class ImagesLazyLoadDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;

  constructor(public el: ElementRef) {}

  ngAfterViewInit() {
    if ('web' in environment) {
      this.el.nativeElement.src = this.src;
    } else {
      this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
    }
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage() {
    // @ts-ignore
    this.srcAttr = (this.src && this.src.includes('https')) ? this.src : `https:${this.src}`;
    // this.cdr.detectChanges();
  }

}
