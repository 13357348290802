import { createAction, props } from '@ngrx/store';
import { DisplayState } from './configuration.models';

export const callCoreSettings = createAction('[Configuration] Call Core');
export const callFxRates = createAction('[Configuration] Call Fx Rates');
export const successFxRates = createAction('[Configuration] Success Fx Rates', 
    props<{
        response: any // add model
    }>());

export const successCoreSettings = createAction('[Configuration] Success Core', 
    props<{
        response: any // add model
    }>());

export const setDisplayState = createAction('[Configuration] Set Display State', 
    props<{
        displayState: DisplayState // add model
    }>());

export const setLocalSettings = createAction('[Configuration] Set Local Settings', 
    props<{
        localSettings: any // add model
    }>());
    

export const failConfiguration = createAction('[Configuration] Fail', 
    props<{
        error: any
    }>());