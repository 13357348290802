import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import {CordovaService} from "@cbcore/services/utils/cordova/cordova.service";
import {ImageService} from "@cbcore/services/utils/image/image.service";

@Component({
  selector: 'avatar-uploader',
  templateUrl: './avatar-uploader.html'
})

export class AvatarUploaderComponent {

  public file: File | null = null;

  public error : boolean = false;
  public errorType!: string;
  public loading: boolean = false;

  public croppedImage: boolean = false;
  public android: boolean = false;

  @ViewChild('inputFile') inputFile!: ElementRef;
  @Output()fileDetected : EventEmitter<any> = new EventEmitter();
  @Output()isCropping : EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _imageService: ImageService,
    private _cordovaService: CordovaService
  ) { }

  ngOnInit(): void {
    this.android = this._cordovaService.isAndroid();
  }

  handleSelect(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const file: File = event.target.files[0];
    this.checkFile(file);
  }

  handleSelectCordova(): void {
    this._imageService.uploadImage('BASE').then((imageData) => {
      this.checkFile(imageData?.file?.name[0]);
    });
  }

  checkFile(file: any): void {
    this.error = false;

    if (file && file.type) {
      const type: string = file.type.split('/')[1];
      const size: number = file.size;

      if (type !== 'jpeg' && type !== 'png') {
        this.error = true;
        this.errorType = 'TYPE';
      } else if (size > 2100000) {
        this.error = true
        this.errorType = 'SIZE';
      } else {
        this.file = file;
        this.isCropping.emit(true);
      }
    }
    this.loading = false;
  }

  handleAvatarCropped(event: any): void {
    this.croppedImage = true;
    this.file = null;
    this.fileDetected.emit(event);
    this.isCropping.emit(false);
  }

  fileCanceled(e: Event): void {
    this.file = null;
    this.isCropping.emit(false);
  }

  ngOnDestroy(): void {
    this.loading = false;
  }
}
