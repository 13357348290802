import { NgModule, Injectable } from '@angular/core';

import { SelectionService } from './selections.service';
import { FectaService } from './fecta.service';
import { SepService } from './sep.service';
import { LegSelectedService } from './legs.service';
import { CorrelationsService } from './correlations.service';

export * from './selections.service';
export * from './fecta.service';
export * from './legs.service';

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    SelectionService,
    FectaService,
    SepService,
    LegSelectedService,
    CorrelationsService
  ]
})
export class SelectionsModule { }
