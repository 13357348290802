import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '../config/config.service';
import { InternalLanguageKeyMap } from './locale.constants';
import { ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash-es';
import { ReadyService } from './ready.service';

@Injectable()
export class TranslationService {
  // private $ready: Observable<any>;
  // @ts-ignore
  private $ready: ReplaySubject<any> = new ReplaySubject<any>(null);
  private language: any;
  private fallbackLanguage: string = 'en';
  private defaultLanguage: string | undefined;
  private supportedLanguages: Array<string> | any;
  private isLanguagePassedThroughtParams: boolean | undefined;

  constructor(
    private _translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private _config: Config,
    private _internalKeyMap: InternalLanguageKeyMap,
    @Inject(DOCUMENT) private _document: any,
    private _readyService: ReadyService
  ) {}

  onInit(): void {
    this.defaultLanguage = this._config.CONFIG.defaultLanguage;
    this.supportedLanguages = this._config.CONFIG.supportedLanguages;
    this.isLanguagePassedThroughtParams = this._config.CONFIG.languageThroughParams;
    const fallBackLanguage = this._config.CONFIG.fallbackLanguage || this.fallbackLanguage;
    let languageParameter = this.getParameterByName('lang');
    // @ts-ignore
    languageParameter = this._config.CONFIG.languageMapping[languageParameter] || languageParameter;
    const urlLanguage = languageParameter && this.isSupportedLanguage(languageParameter) ? languageParameter : null;

    this._translateService.addLangs(this.supportedLanguages);
    this._translateService.setDefaultLang(fallBackLanguage);
    this.setLanguage(urlLanguage || this.defaultLanguage || fallBackLanguage);
  }

  getLanguage(): string {
    return this.language;
  }

  ready(): ReplaySubject<any> {
    return this._readyService.ready();
  }

  isSupportedLanguage(lang: string): boolean {
    return _.includes(this.supportedLanguages, lang);
  }

  setLanguage(lang: string): void {
    const tmp_lang = this._config.CONFIG.languageMapping[lang] || lang;

    if (this.isSupportedLanguage(tmp_lang)) {
      this.language = tmp_lang;
      this._translateService.use(tmp_lang).subscribe(() => {});
      this._document.documentElement.lang = this.language;
    }
  }

  setUrlLanguage() {
    const urllang: any = this.getParameterByName('lang') || undefined;
    const lang = this._config.CONFIG.languageMapping[urllang] || urllang;
    if (lang) {
      this.setLanguage(lang);
    }
  }

  getParameterByName = (name: any) => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return results[2];
  };

  // Locale specific returns

  getAcceptLanguageHeader() {
    return (
      (this._internalKeyMap.LOCALES[this.language] || {})
        .accept_language_header || 'en'
    );
  }

  getDefaultDateFormat() {
    return this._internalKeyMap.LOCALES[this.language].date_default;
  }

  getShortDateFormat() {
    return this._internalKeyMap.LOCALES[this.language].date_short;
  }

  getLongDateFormat() {
    return this._internalKeyMap.LOCALES[this.language].date_long;
  }

  getThousandSeparator() {
    return this._internalKeyMap.LOCALES[this.language].thousands_separator;
  }

  getDecimalSeparator() {
    return this._internalKeyMap.LOCALES[this.language].decimal_separator;
  }

  getFlagCode() {
    return this._internalKeyMap.LOCALES[this.language].flag_code;
  }

  getCurrencyPosition() {
    return this._internalKeyMap.LOCALES[this.language].currency_position;
  }
}
