import { Component, Injectable, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Injectable()
@Component({
  selector: 'default-notification',
  template: ''
})
export class DefaultNotificationComponent implements OnInit {
  @Input() event: any;
  @Output() clearEvent = new EventEmitter<boolean>();


  public _notes: Array<any> = [];
  public _currentNote: any;


  ngOnInit(): void {
    if (this._notes.length === 0) {
      this._currentNote = { type: event };
      this._notes.push(this._currentNote);
    }
  }

  hide(note: any): void {
    const index: number = this._notes.indexOf(note);
    if (index >= 0) {
      this._notes.splice(index, 1);
      this.clearParentNotifications();
    }
  }

  clearParentNotifications(): void {
    this.clearEvent.next(true);
  }

}
