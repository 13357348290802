import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { AvatarCroppieComponent } from './avatar-croppie';
export * from './avatar-croppie';

@Injectable()
@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    AvatarCroppieComponent
  ],
  exports: [
    AvatarCroppieComponent
  ]
})
export class AvatarCroppieModule { }
