import {Component} from '@angular/core';
import { AppState } from '@cbcore/NGRX/core.state';
import { selectMicrositeWebsocketsEvent } from '@cbcore/NGRX/coreWebsockets/coreWebsockets.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'component-notification',
  templateUrl: './notification.html'
})

export class NotificationComponent {
  public event!: string | undefined;

  constructor(
    private store: Store<AppState>
  ) {

    // _notificationService.listenEvents().subscribe(socket => {
    //   if (socket.event === 'USER_NEW_OFFER' || socket.event === 'FREEPLAY_PLACED') {
    //     this.event = socket.event;
    //   }
    //   if (socket.event === 'USER_REMOVE_OFFER') {
    //     this.clearNotifications();
    //   }
    // });

    this.store.select(selectMicrositeWebsocketsEvent).subscribe(socket => {
      if (socket?.event === 'USER_NEW_OFFER' || socket?.event === 'FREEPLAY_PLACED') {
        this.event = socket.event;
      }
      if (socket?.event === 'USER_REMOVE_OFFER') {
        this.clearNotifications();
      }
    });

  }

  clearNotifications(): void {
    this.event = undefined;
  }

  clearFromChild(param: boolean): void {
    if (param) {
      this.clearNotifications();
    }
  }
}
