import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingsClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/settings';
  }

  get(): Observable<any> {
    return this.http.get(this.endpoint)
      .pipe(
        map(data => {
          return data
        }),
        catchError(err => {
          return throwError(err)
        })
      );
  }
}
