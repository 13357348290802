import { Injectable } from '@angular/core';

import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, tap, withLatestFrom, filter, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { ofType, createEffect, Actions } from '@ngrx/effects';

import { AppState } from '../../NGRX/core.state';
import { selectConfiguration } from '../../NGRX/configuration/configuration.selectors';

import { failConfiguration, callCoreSettings, successCoreSettings, setDisplayState, callFxRates, successFxRates } from './configuration.actions';

import {Config} from "@cbcore/services/config/config.service";
import {FxRatesClient} from "@cbcore/client/fx_rates";
import {SettingsClient} from "@cbcore/client/settings";

import { DisplayState } from './configuration.models';
import { setWebsocketEvent } from '../coreWebsockets/coreWebsockets.actions';
import { ws } from './configuration.reducer';


@Injectable()
export class ConfigurationEffects {
  callCoreSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(callCoreSettings),
      exhaustMap((propVal) => {
        return this._settingsClientCore.get().pipe(
          map(data => {
            // this._settingsMiddleware.loadSettings(_.cloneDeep(data));
            return successCoreSettings({ response: data });
          }),
          catchError(error => {
            return of(failConfiguration({ error: error }))
          })
        )
      }))
  }, { dispatch: true });

  callFxRates = createEffect(() => {
    return this.actions$.pipe(
      ofType(callFxRates),
      // ASK
      filter(intent => this._config.CONFIG.enableSyndicatesLeaderboard),
      exhaustMap((propVal) => {
        return this._fxRatesClient.get().pipe(
          map(data => {
            // KEPT FOR COMPATIBILITY
            // this._fxRatesMiddleware.updateData(data);
            return successFxRates({ response: data });
          }),
          catchError(error => {
            return of(failConfiguration({ error: error }))
          })
        )
      }))
  }, { dispatch: true });

  successCoreSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(successCoreSettings),
      withLatestFrom(this.store.select(selectConfiguration)),
      exhaustMap(([propVal, configurationState]) => {
        return of(setDisplayState({
          displayState: {
            microsite_type: configurationState.localSettings.micrositeType,
            view: configurationState.localSettings.micrositeType
          }
        }))
      }))
  }, { dispatch: true });

  // KEPT FOR COMPATIBILITY
  // setDisplayState = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(setDisplayState),
  //     tap((propVal) => {
  //       let displayState: DisplayState = {
  //         view: propVal.displayState.view
  //       }
  //       if (propVal.displayState.microsite_type) displayState.microsite_type = propVal.displayState.microsite_type;
  //       this._settingsMiddleware.setDisplayState(_.cloneDeep(displayState));
  //     }))
  // }, { dispatch: false });

  ///// LIVE UPDATE
  setWebsocketEvent = createEffect(() => {
    return this.actions$.pipe(
      ofType(setWebsocketEvent),
      // filter(propVal => ws.RELOAD_EVENTS.concat(Object.keys(ws)).includes(propVal.event)),
      // withLatestFrom(this.store.select(selectPools)),
      switchMap((propVal) => {
        if (ws.RELOAD_EVENTS.includes(propVal.event)) {
          return [
            callFxRates()
          ];
        }

        return EMPTY
      })
    )
  }, { dispatch: true })


  constructor(
    private actions$: Actions,
    private _settingsClientCore: SettingsClient,
    private _fxRatesClient: FxRatesClient,
    private _config: Config,
    private store: Store<AppState>
  ) {
    setTimeout(() => {
      this.store.dispatch(callFxRates());
      this.store.dispatch(callCoreSettings());
    }, 100);
  }
}


