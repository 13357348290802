import { createAction, props } from '@ngrx/store';
import { MicrositeWebsocketsScope, MicrositeWebsocketsState } from './coreWebsockets.models';


export const connectToPrivateChannel = createAction('[Microsite Websockets] Connect To Private Channel', 
    props<{
        headers: any
    }>());

export const setWebsocketEvent = createAction('[Microsite Websockets] Set websocket event', 
    props<{
        event: string,
        data?: any
    }>());

export const setWebsocketBalance = createAction('[Microsite Websockets] Set websocket event', 
    props<{
        balance: MicrositeWebsocketsScope.Balance
    }>());


