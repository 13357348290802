import { Injectable } from '@angular/core';
import * as packageJson from "../../../../../package.json";

@Injectable()

export class AppVersionService {
  constructor() { }

  getAppVersion(): any {
      return `v${(<any>packageJson).default.version}`;
  }
}
