import { createAction, props } from '@ngrx/store';

export const track = createAction('[Tracking] Track - Send parts of state to Tracking api', 
    props<{
        event?: any
    }>());

export const replaceState = createAction('[Tracking] Replace State', 
    props<{
        url: string
    }>());

export const setCampaign = createAction('[Tracking] Set Campaign', 
    props<{
        queryParams: any
    }>());

export const setPixel = createAction('[Tracking] Set Pixel', 
    props<{
        trackingObject: any
    }>());

export const clearPixel = createAction('[Tracking] Clear Pixel');

/*

not used currently, functionality is left in the middleware
export const setUserUnauth = createAction('[Tracking] Set User Unauth');
export const setUserAuth = createAction('[Tracking] Set User Auth');
export const setDeviceFingerprint = createAction('[Tracking] Set Device Fingerprint');
export const setCampaign = createAction('[Tracking] Set Campaign');

*/