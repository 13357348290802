import {MissingTranslationHandlerParams, MissingTranslationHandler} from '@ngx-translate/core';
import * as _ from 'lodash-es';

export class MyCustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
      if (params) {
        if (!_.isUndefined(params?.interpolateParams)) {
          if (!_.isUndefined((<any>params.interpolateParams)?.default)) {
            return params.translateService.instant((<any>params?.interpolateParams)?.default, params?.interpolateParams);
          } else if (<any>params?.interpolateParams) {
            if (!_.isUndefined((<any>params?.interpolateParams)?.defaultValue)) {
              return (<any>params?.interpolateParams)?.defaultValue;
            }
          }
        }
      }
    }
}
