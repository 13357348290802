import { Injectable } from '@angular/core';
import { exhaustMap, filter, tap, withLatestFrom } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { ofType, createEffect, Actions } from '@ngrx/effects';

import {AppState, selectConfiguration, selectRouter, selectSyndicate} from '@cbcore/NGRX/core.state';

import {Config} from "@cbcore/services/config/config.service";
import {SecureWebsocketService} from "@cbcore/services/websockets/secure-websocket.service";
import {WebsocketService} from "@cbcore/services/websockets/websockets.service";
import { failMicrositeSessions, successMicrositeSessions } from '../coreAuth/coreAuth.actions';
import { selectMicrositeSessions } from '../coreAuth/coreAuth.selectors';
import { connectToPrivateChannel, setWebsocketEvent } from './coreWebsockets.actions';
import { EMPTY, of } from 'rxjs';
import { callSyndicate, loadComments, successSyndicate } from '../syndicates/syndicates.actions';

@Injectable()
export class MicrositeWebsocketsEffects {
  successMicrositeSessions = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeSessions),
      withLatestFrom(this.store.pipe(select(selectRouter))),
      exhaustMap(([propVal, routerState]) => {
        // TODO: check with Aurel after merge
        try {
          if (this._config.CONFIG.fallbackSecureWSnonCB) {
            this._websocketServiceBalance.connect({uid: propVal?.response?.ext_id});
          }
        } catch(e: any) {
          console.log("Error subscribing secure WS for a non CB microsite");
        }
        this._websocketService.subscribeChannel('user-' + propVal?.response?.id);
        this._websocketService.bindAllChannelEvents('user-' + propVal?.response?.id);

        if (propVal?.response?.has_offers) {
          if (!routerState.state.queryParams?.utm_campaign || routerState.state.queryParams?.utm_campaign !== 'Cash_out' || this.isMobile) {
            if (!this.isMobile) {
              return of(setWebsocketEvent({
                event: 'USER_NEW_OFFER',
                data: null
              }));
            }
          }
        }

        return EMPTY;

      }))}, { dispatch: true });

  failMicrositeSessions = createEffect(() => {
    return this.actions$.pipe(
      ofType(failMicrositeSessions),
      withLatestFrom(this.store.pipe(select(selectMicrositeSessions))),
      tap(([propVal, micrositeSessions]) => {
        if(!micrositeSessions) return;
        this._websocketService.unSubscribeChannel('user-' + micrositeSessions.id);
  }))}, { dispatch: false });

  loadComments = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadComments),
      withLatestFrom(this.store.pipe(select(selectMicrositeSessions)), this.store.pipe(select(selectConfiguration))),
      tap(([propVal, micrositeSessions, config]) => {
        if(!propVal.params || !propVal.params.model_id) return;
        if (!config.commentarySettings) return;

        this._websocketServiceBalance.subscribeChannel('private-syndicate-' + propVal.params.model_id);
        this._websocketServiceBalance.bindAllChannelEvents('private-syndicate-' + propVal.params.model_id);
  }))}, { dispatch: false });

  connectToPrivateChannel = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectToPrivateChannel),
      tap((propVal) => {
        this._websocketServiceBalance.connect(propVal.headers);
  }))}, { dispatch: false });

  // callSyndicate = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(successSyndicate, connectToPrivateChannel),
  //     withLatestFrom(this.store.select(selectSyndicate)),
  //     tap(([action, syndicate]) => {
  //       if(syndicate && this._websocketServiceBalance.socket) {
  //         this._websocketServiceBalance.subscribeChannel('private-syndicate-' + syndicate.id);
  //         this._websocketServiceBalance.bindAllChannelEvents('private-syndicate-' + syndicate.id);
  //       }
  // }))}, { dispatch: false });


  /* AUTH EVENTS */

  micrositeAuthenticationRefresh = createEffect(() => {
    return this.actions$.pipe(
      // ofType(successWalletSignIn, successWalletSignOut, successWalletUsersStep),
      // filter(propVal =>
      //   propVal.type === successWalletSignIn.type ||
      //   propVal.type === successWalletSignOut.type ||
      //   (propVal.type === successWalletUsersStep.type && propVal.step === 3)
      // ),
      ofType(successMicrositeSessions, failMicrositeSessions),
      exhaustMap((propVal) => {
        return of(setWebsocketEvent({
          event: 'MICROSITE_AUTHENTICATION_REFRESH',
          data: {}
        }));
      }))
  }, { dispatch: true });

  isMobile: boolean;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private _websocketService: WebsocketService,
    private _websocketServiceBalance: SecureWebsocketService,
    private _config: Config
  ) {
    // to test
    // const testEventData = {
    //   id: 44349,
    //   headline_prize: '1400000'
    // }

    // // prev: headline_prize: "2500.0"

    // setTimeout(() => {
    //   this.store.dispatch(setWebsocketEvent({
    //     event: 'POOL_INFO_CHANGED',
    //     data: testEventData
    //   }));
    // }, 3000);

    this.isMobile = this._config.CONFIG.isMobile;

    // THIS - BASICALLY SETS WEBSOCKETS
    // this._notificationService.listenEvents().subscribe((socket: any) => {
    //   this.store.dispatch(setWebsocketEvent({
    //     event: socket.event,
    //     data: socket?.data?.data
    //   }));
    // })
  }
}

