import { createReducer, on, Action } from '@ngrx/store';
import {FreeplayState} from "../../NGRX/freeplay/freeplay.model";
import * as _ from 'lodash-es';
import {
  addGiveaway,
  callFreeplayList, claimToken, clearToken, failClaimToken, failFreeplayList,
  getPoolToken,
  loadToken,
  successFreeplayList, successToken
} from "../../NGRX/freeplay/freeplay.actions";

export const initialState: FreeplayState = {
  list: undefined,
  token: undefined,
  is_loading: false,
  list_error: undefined,
  token_error: undefined
}

const reducer = createReducer(
  initialState,
  on(callFreeplayList, (state, action) => {
    return {
      ...state,
      action,
      is_loading: true,
      list_error: undefined
    }
  }),
  on(successFreeplayList, (state, action) => {
    return {
      ...state,
      list: action.list,
      is_loading: false,
      list_error: undefined
    }
  }),
  on(addGiveaway, (state, action) => {
    const giveaways = _.cloneDeep(state.list);
    if (giveaways) {
      giveaways.freeplays = giveaways.freeplays.concat(action.giveaway.freeplays);
      giveaways.freeplays = _.uniqWith(giveaways.freeplays, _.isEqual);
    }
    return {
      ...state,
      list: giveaways
    }
  }),
  on(getPoolToken, (state, action) => {
    return {
      ...state,
      action,
      token_error: undefined
    }
  }),
  on(loadToken, (state, action) => {
    return {
      ...state,
      action,
      token_error: undefined
    }
  }),
  on(claimToken, (state, action) => {
    return {
      ...state,
      action,
      token_error: undefined
    }
  }),
  on(clearToken, (state, action) => {
    return {
      ...state,
      token: undefined,
      token_error: undefined
    }
  }),
  on(successToken, (state, action) => {
    return {
      ...state,
      token: action.token,
      token_error: undefined
    }
  }),
  on(failFreeplayList, (state, action) => {
    return {
      ...state,
      list_error: action.error
    }
  }),
  on(failClaimToken, (state, action) => {
    return {
      ...state,
      token_error: action.error
    }
  }),
)

export function freeplayReducer(
  state: FreeplayState | undefined,
  action: Action
): FreeplayState {
  return reducer(state, action);
}

