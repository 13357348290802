import { Injectable, NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { EmojiPickeromponent } from './emoji-picker';
export * from './emoji-picker';

@Injectable()
@NgModule({
  imports: [CommonModule],
  declarations: [EmojiPickeromponent],
  exports: [EmojiPickeromponent]
})
export class EmojiPickerModule {
}
