import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as Big from 'big.js';

@Pipe({
  name: 'formatOdds',
  pure: false
})

@Injectable()
export class FormatOddsPipe implements PipeTransform {

  transform(value: any, decimals: number = 2): any {
    const scale = decimals || 2;
    if (Math.round(value) != value) {
      if (Math.pow(0.1, scale) > value) {
        return 0;
      }
      const sign = Math.sign(value);
      const arr = ('' + Math.abs(value)).split('.');
      if (arr.length > 1) {
        if (arr[1].length > scale) {
          let integ = +arr[0] * Math.pow(10, scale);
          let dec = integ + (+arr[1].slice(0, scale) + Math.pow(10, scale));
          let proc = +arr[1].slice(scale, scale + 1);
          if (proc >= 5) {
            dec = dec + 1;
          }
          dec = sign * (dec - Math.pow(10, scale)) / Math.pow(10, scale);
          return dec;
        }
      }
    }

    return new Big.Big(value).toFixed();
  }
}
