import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Config } from './../config/config.module';

@Injectable()
export class PoolsDefaultRouteGuard implements CanActivate {
  constructor(
    private _config: Config,
    private _router: Router
  ) {}
  canActivate() {
    const micrositeType: string = this._config.CONFIG.micrositeType;
    if (micrositeType === 'ALL' || micrositeType === 'SPORTS') {
      this._router.navigate(['/pools/sports']);
      return false;
    } else {
      this._router.navigate(['/pools/'+micrositeType.toLowerCase()]);
      return false;
    }
    return true;
  }
}
