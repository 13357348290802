import { PoolDisplayGroup } from '../../models/pool-display-group';
import { PoolFilters } from '../../models/pool-filters';
import { PoolList } from '../../models/pool-list';
import * as Big from 'big.js';
import * as _ from 'lodash-es';
import { ConfigurationScope } from '../configuration/configuration.models';
import { PoolCategories, PoolsMenuScope } from './pool.models';

export const setPools = (pools: PoolsMenuScope.Pool[], coreSettings: ConfigurationScope.coreSettings, localSettings: ConfigurationScope.localSettings): PoolsMenuScope.ParsedPoolsMenu => {
    const pool_list: PoolsMenuScope.ParsedPoolsMenu = {
        pools: _.keyBy(pools, 'id')
    };
    _.map(coreSettings.sport_categories, category => {
        pool_list[category.code] = setPoolsByCategory(pools, category.code, coreSettings, localSettings);
    });
    return pool_list;
}

export const setPoolsByCategory = (pools: Array<PoolsMenuScope.Pool>, pool_category_code: PoolCategories, coreSettings: any, localSettings: any): PoolList => {
    const pool_category: ConfigurationScope.SportCategory = _.find(coreSettings.sport_categories, { code: pool_category_code });
    const poolTypesOrder = Object.assign(
        localSettings.poolTypesOrder,
        localSettings.overridesPoolTypesOrder
    );

    const pool_list = new PoolList();
    const pools_by_type = _.filter(pools, pool => {
        return _.includes(
            _.result(pool_category.description, 'sport_codes', []),
            pool.sport_code
        );
    });
    const open_pools_object = _.filter(pools_by_type, {
        status: 'OPEN',
        free_play_only: false
    });
    const running_pools_object = _.filter(pools_by_type, {
        status: 'IN_PLAY',
        free_play_only: false
    });
    const official_pools_object = _.filter(pools_by_type, {
        status: 'OFFICIAL',
        free_play_only: false
    });

    pool_list.free_play_only = _.map(
        _.orderBy(
            _.filter(pools_by_type, { status: 'OPEN', free_play_only: true }),
            pool => {
                return Number(pool.headline_prize);
            },
            'desc'
        ),
        'id'
    );
    pool_list.open_pools = _.map(open_pools_object, 'id');
    pool_list.running_pools = _.map(
        _.orderBy(
            _.filter(pools_by_type, { status: 'IN_PLAY' }),
            pool => {
                return Number(pool.headline_prize);
            },
            'desc'
        ),
        'id'
    );
    pool_list.official_pools = _.map(
        _.orderBy(
            _.filter(pools_by_type, { status: 'OFFICIAL' }),
            pool => {
                return Number(pool.headline_prize);
            },
            'desc'
        ),
        'id'
    );
    pool_list.up_next = _.map(
        _.take(_.orderBy(open_pools_object, 'sched_start'), 4),
        'id'
    );
    pool_list.big_jackpots = _.map(
        _.take(
            _.orderBy(
                open_pools_object,
                pool => {
                    return Number(pool.headline_prize);
                },
                'desc'
            ),
            4
        ),
        'id'
    );

    const result: any = [];

    const running_groups: any = _.map(
        _.groupBy(running_pools_object, pool => {
            return pool.sport_code + '.' + pool.type_code;
        }),
        (value, key) => {
            const spec = _.map(key.split('.'), fragment => {
                return _.size(fragment) ? fragment : null;
            });
            const pools_map = _.map(
                _.orderBy(
                    value,
                    [
                        pool => {
                            return pool.sched_start;
                        },
                        pool => {
                            return Number(pool.headline_prize);
                        }
                    ],
                    ['desc', 'desc']
                ),
                'id'
            );
            return new PoolDisplayGroup(
                key,
                spec[0],
                spec[1],
                spec[2],
                [],
                pools_map
            );
        }
    );
    _.forEach(running_groups, function (value: any) {
        const key = _.find(result, { code: value.code });
        if (key) {
            key.running_pools = value.running_pools;
        } else {
            result.push(value);
        }
    });

    const official_groups: any = _.map(
        _.groupBy(official_pools_object, pool => {
            return pool.sport_code + '.' + pool.type_code;
        }),
        (value, key) => {
            const spec = _.map(key.split('.'), fragment => {
                return _.size(fragment) ? fragment : null;
            });
            const pools_map = _.map(
                _.orderBy(
                    value,
                    [
                        pool => {
                            return pool.sched_start;
                        },
                        pool => {
                            return Number(pool.headline_prize);
                        }
                    ],
                    ['desc', 'desc']
                ),
                'id'
            );
            return new PoolDisplayGroup(
                key,
                spec[0],
                spec[1],
                spec[2],
                [],
                [],
                pools_map
            );
        }
    );

    _.forEach(official_groups, (value) => {
        const key = _.find(result, { code: value.code });
        if (key) {
            key.official_pools = value.official_pools;
        } else {
            result.push(value);
        }
    });

    const display_groups: any = _.map(
        _.groupBy(open_pools_object, pool => {
            return pool.sport_code + '.' + pool.type_code;
        }),
        (value, key) => {
            const spec = _.map(key.split('.'), fragment => {
                return _.size(fragment) ? fragment : null;
            });
            const pools_map = _.map(
                _.orderBy(
                    value,
                    [
                        pool => {
                            return pool.sched_start;
                        },
                        pool => {
                            return Number(pool.headline_prize);
                        }
                    ],
                    ['asc', 'desc']
                ),
                'id'
            );
            return new PoolDisplayGroup(key, spec[0], spec[1], spec[2], pools_map);
        }
    );

    _.forEach(display_groups, function (value) {
        const key: any = _.find(result, { code: value.code });
        if (key) {
            key.pools = value.pools;
        } else {
            result.push(value);
        }
    });

    pool_list.display_groups = _.orderBy(
        result,
        group => {
            return poolTypesOrder[group.code];
        },
        'asc'
    );

    // const open_filter = _.filter(pools_by_type, pool => {
    //     return /^(OPEN)$/.test(pool.status);
    // });

    // const live_filter = _.filter(pools_by_type, pool => {
    //     return /^(OPEN|IN_PLAY)$/.test(pool.status);
    // });

    // setFilterByCategory(open_filter, type, 'OPEN', coreSettings, localSettings);
    // setFilterByCategory(live_filter, type, 'LIVE', coreSettings, localSettings);
    // setFilterByCategory(live_filter, type, 'ALL', coreSettings, localSettings);

    return pool_list;
}

export const setPoolsFilters = (pools: Array<PoolsMenuScope.Pool>, coreSettings: ConfigurationScope.coreSettings, localSettings: ConfigurationScope.localSettings): PoolsMenuScope.PoolFiltersByCategory => {

    let filterObject: PoolsMenuScope.PoolFiltersByCategory = {};

    _.map(coreSettings.sport_categories, sport_category => {
        const category_type = sport_category.code;
        filterObject[category_type] ? filterObject[category_type] : (filterObject[category_type] = {});

        const category = _.find(coreSettings.sport_categories, { code: category_type });

        // setPoolsByCategory
        const pools_by_type = _.filter(pools, pool => {
            return _.includes(
                _.result(category.description, 'sport_codes', []),
                pool.sport_code
            );
        });

        const open_filter = _.filter(pools_by_type, pool => {
            return /^(OPEN)$/.test(pool.status);
        });

        const live_filter = _.filter(pools_by_type, pool => {
            return /^(OPEN|IN_PLAY)$/.test(pool.status);
        });

        filterObject[category_type]['OPEN'] = setFilterByCategory(open_filter, category_type, coreSettings, localSettings);
        filterObject[category_type]['LIVE'] = setFilterByCategory(live_filter, category_type, coreSettings, localSettings);
        filterObject[category_type]['ALL'] = setFilterByCategory(live_filter, category_type, coreSettings, localSettings);
    });

    return filterObject;
}

export const setPoolsExtra = (pools: Array<PoolsMenuScope.Pool>, currentPoolsExtraModel: PoolsMenuScope.PoolsExtraModel, coreSettings: ConfigurationScope.coreSettings, localSettings: ConfigurationScope.localSettings): PoolsMenuScope.PoolsExtraModel => {
    let poolsExtraModel: PoolsMenuScope.PoolsExtraModel = _.cloneDeep(currentPoolsExtraModel);

    _.map(coreSettings.sport_categories, sport_category => {
        const type = sport_category.code;
        const category = _.find(coreSettings.sport_categories, { code: type });

        poolsExtraModel[sport_category.code] = category.pools_count;

        // setPoolsByCategory
        const pools_by_type = _.filter(pools, pool => {
            return _.includes(
                _.result(category.description, 'sport_codes', []),
                pool.sport_code
            );
        });

        let stake = 0;
        const open_filter = _.filter(pools_by_type, pool => {
            try {
            if (
                pool.status === 'OPEN' &&
                (type === PoolCategories.SPORTS /* confused whether the term HORSE_RACING should be used || type === PoolsMenuScope.PoolCategories.RACING*/ || PoolCategories.RACING)
            ) {
                const stakes = pool.stake_sizes[0].stakes;
                const minStake = stakes[stakes.length - 1];
                stake = stakes.reduce(
                (max: any, current: any) => (Number(current) > max ? current : max),
                minStake
                );

                const temp_value = 0;
                poolsExtraModel.POOLS_VALUE = poolsExtraModel.POOLS_VALUE.plus(
                new Big.Big(temp_value).plus(new Big.Big(pool.headline_prize).times(stake))
                );
            }
            } catch (e) {
            console.log('Stake is missing for pool: ' + pool.id);
            }

            try {
            if (
                pool.status === 'OPEN' &&
                type === 'RACING' &&
                pool.best_dividend_guarantee
            ) {
                poolsExtraModel.BDG.push(pool);
            }
            } catch (e) {
            console.log('No BDG pool available at the moment');
            }

            return /^(OPEN)$/.test(pool.status);
        });
    });

    return poolsExtraModel;
}

export const setFilterByCategory = (
    open_pools_object: Array<PoolsMenuScope.Pool>,
    category_type: PoolCategories,
    coreSettings: ConfigurationScope.coreSettings,
    localSettings: ConfigurationScope.localSettings
): PoolFilters => {
    const category: ConfigurationScope.SportCategory = _.find(coreSettings.sport_categories, {
        code: category_type
    });
    const poolTypesOrder = Object.assign(
        localSettings.poolTypesOrder,
        localSettings.overridesPoolTypesOrder
    );

    const filters: PoolFilters = new PoolFilters();

    filters.all =
        _.join(_.result(category.description, 'sport_codes', []), ',') ||
        undefined;
    filters.view = _.result(category.description, 'sport_codes', []);
    filters.sport = _.uniq(
        _.map(open_pools_object, pool => {
            return pool.sport_code;
        })
    );
    filters.pool_type = _.orderBy(
        _.uniq(
            _.map(open_pools_object, pool => {
                return pool.sport_code + '.' + pool.type_code;
            })
        ),
        filter => {
            return poolTypesOrder[filter];
        },
        'asc'
    );
    filters.pool = _.uniq(
        _.map(open_pools_object, pool => {
            return pool.sport_code + '.' + pool.type_code + '.' + pool.leg_num;
        })
    );
    filters.sport_sub_code = _.uniq(_.map(open_pools_object, 'sport_sub_code'));
    filters.sport_sub_code = _.orderBy(
        filters.sport_sub_code,
        sport_sub_code => {
            const tmp_sub_code = sport_sub_code.split('_');
            return (tmp_sub_code[1] || ' ') + tmp_sub_code[0];
        }
    );

    // filters[category_type] ? filters[category_type] : (filters[category_type] = {});
    // filters[category_type][status_type] = filters;
    return filters;
}

// const all_filter = _.filter(pools_by_type, (pool) => {
//   return /^(OPEN|IN_PLAY|COMPLETED|OFFICIAL)$/.test(pool.status);
// });

