import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()

@Pipe({ name: 'orderBy', pure: false })

export class OrderBy implements PipeTransform {

  transform(input: any, config: any, order?: any): any {
    return _.orderBy(input, config, order);
  }
}
