<div id="syndicate_profile_create">
    <ng-container *ngIf="step === 1">
      <div class="w-full content widget captain-create">
        <div class="component-header-row">
          <div class="widget-header">
            <span [translate]="'profile.CREATE_TITLE'"></span>
          </div>
        </div>
        <div class="captain-create__content">
          <div class="text-14 text-container-color">
            <span [translate]="'profile.CREATE_SUBTITLE'"></span>
          </div>
        </div>
        <div class="captain-create__inputs">
          <span class="input-icon">
            <i class="fal fa-user-circle text-16"></i>
          </span>
          <input class="text icon-before" [(ngModel)]="defaultManager.username" placeholder="{{'profile.NAME_PLACEHOLDER' | translate}}" maxlength="15" [disabled]="defaultManager.id" #username>
        </div>

        <div class="captain-create__actions">
          <a class="btn primary w-button _48" *ngIf="!defaultManager.id" (click)="createManager()">
            <span [translate]="'buttons.CREATE_PROFILE'"></span>
          </a>
          <a class="btn tertiary w-button _48" (click)="cancelCreate()">
            <span [translate]="'buttons.CANCEL'"></span>
          </a>
        </div>

        <div class="captain-create__errors" *ngIf="error">
          <div class="error">
            <span [translate]="'errors.MANAGER_' + error" [translateParams]="{defaultValue: ('errors.MANAGER_UNKNOWN'|translate)}"></span>
          </div>
        </div>
      </div>
        <!-- END PROFILE CREATE ERRORS -->
    </ng-container>

    <ng-container class="row step2 d-flex flex-column" *ngIf="step === 2">
      <div class="w-full content widget captain-create">
        <div class="component-header-row">
          <div class="widget-header">
            <span [translate]="'profile.SUCCESS'"></span>
          </div>
        </div>
        <div class="captain-create__content">
          <div class="text-14 text-container-color">
            <span [translate]="'profile.UPDATE_TITLE'"></span>
          </div>
        </div>

        <div class="captain-create__image-list" [ngClass]="{'cropping': isCropping }">
          <div class="profile_img_content" *ngIf="!showSelector">
            <div class="flex flex-row justify-center items-center">
              <span class="image-placeholder" (click)="showSelector = !showSelector">
                <div class="edit-image" *ngIf="!showSelector">
                  <i class="text" [translate]="'avatar.EDIT'"></i>
                </div>
                <img [src]="defaultManager.picture_url" loading="lazy" imgLazyLoad (error)="setFallbackImage()"/>
              </span>
            </div>
          </div>

          <div class="image-customizer" *ngIf="showSelector">
            <div class="segmented-control w-tab-menu reverse mb-custom-16">
              <a class="tab-link w-inline-block reverse" (click)="viewList = false" [ngClass]="{'w--current' : !viewList}">
                <span [translate]="'avatar.UPLOAD_IMAGE'"></span>
              </a>
              <a class="tab-link w-inline-block reverse" (click)="viewList = true" [ngClass]="{'w--current' : viewList}">
                <span [translate]="'avatar.SELECT_IMAGE'"></span>
              </a>
            </div>

            <div class="flex flex-row w-full overflow-x-auto" *ngIf="viewList">
              <a class="image-link" *ngFor="let image of imageList" (click)="selectImage(image, true)" [ngClass]="{'ring-opacity-50': image === defaultManager.picture_url}">
                <img [src]="image" class="preset-avatar">
              </a>
            </div>
            <div class="flex flex-row w-full" *ngIf="viewList">
              <span [translate]="'profile.SCROLL_MSG'"></span>
            </div>

            <div class="image-list" *ngIf="!viewList">
              <avatar-uploader (isCropping)="manageCropping($event)" (fileDetected)="setCroppedImage($event)" class="w-full"></avatar-uploader>
            </div>

          </div>
        </div>


        <div class="captain-create__actions centered">
          <a class="btn primary w-button _48" *ngIf="!showSelector" (click)="updateManager()">
            <span [translate]="'buttons.CONFIRM'"></span>
          </a>
          <a class="btn tertiary w-button _48" *ngIf="showSelector" (click)="showSelector = false">
            <span [translate]="'buttons.CANCEL'"></span>
          </a>
        </div>
      </div>
    </ng-container>
</div>
