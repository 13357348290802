import { createReducer, on, Action } from '@ngrx/store';
import { CommentaryListModel, SyndicatesScope, SyndicatesState} from "./syndicates.model";
import {
  callList,
  callSyndicate, clearCreateSuccess,
  createSyndicate,
  failCreateComment,
  failSyndicate,
  joinSyndicate,
  listLoadMore,
  loadComments,
  loadList,
  partialUpdate,
  successComments,
  successCreateComment,
  successCreateSyndicate,
  // successFilters,
  successJoinSyndicate,
  successList,
  successSyndicate, successSyndicatePublic, successSyndicateVoidToSolo, successSyndicateWins,
  successUpdateSyndicate,
  updateListItem
} from "../../NGRX/syndicates/syndicates.actions";
import * as _ from 'lodash-es';

export const initialState: SyndicatesState = {
  portion: undefined,
  joinSuccess: undefined,
  joinError: undefined,
  isLoading: false, // also for list
  syndicateIsLoading: false,

  shouldRefreshCommentary: false,
  comments: undefined,
  commentaryParams: {
    page: 1,
    per: 20,
    feed_type: null,
    model_id: null
  },
  commentaryStatus: {
    shouldRefresh: false,
    loading: false,
    success: false,
    error: undefined
  },

  list: undefined,
  list_pagination: {
    page: 1,
    per: 25,
    sort_by: 'percentage_filled',
    perform_total_count: false,
    filters: {
      status: 'available_to_fund',
      manager_limit: true
    },
  },

  wins_list: undefined,
  home_list: undefined
};

const reducer = createReducer(
  initialState,
  on(callSyndicate, (state, action) => {
    return {
      ...state,
      portion: undefined,
      joinSuccess: undefined,
      joinError: undefined
    }
  }),
  on(successSyndicate, (state, action) => {
    return {
      ...state,
      portion: Object.assign({}, state.portion, action.portion),
    }
  }),
  on(updateListItem, (state, action) => {
    if(!state.list) return state;

    const updatedList = _.cloneDeep(state.list);
    const syndicate = _.find(updatedList.items, { id: action.syndicate.id });
    if (syndicate) {
      syndicate.amount_funded = action.syndicate.amount_funded;
      syndicate.amount_owned_customer = action.syndicate.amount_owned_customer;
      syndicate.total_portions = action.syndicate.total_portions;
      syndicate.customer_portion = action.syndicate.customer_portion;
      syndicate.is_public = action.syndicate.is_public;
    }

    return {
      ...state,
      list: updatedList
    }
  }),

  // 1. call with new filter modifying existing ones
  // 2. reloading current filters resetting the page only
  // 3. loading initially on page load
  // 4. load more

  // this does 3
  on(loadList, (state, action) => {
    return {
      ...state,
      list_pagination: {
        ...initialState.list_pagination,
        filters: Object.assign({}, initialState.list_pagination.filters, action.model?.filters),
      },
      isLoading: true
    }
  }),

  // this does either 1 or 2
  on(callList, (state, action) => {

    let filters = null;
    let sort_by = null;

    if(action.model?.filters) {
      filters = Object.assign({}, state.list_pagination.filters, action.model?.filters);

      const prevSort = state.list_pagination.sort_by;

      sort_by =
      action.model?.status === 'live'
        ? 'cash_out'
        : action.model?.status === 'historic'
          ? 'most_recent'
          : action.model?.status === 'placed'
            ? 'starting_soon'
            : action.model?.status === 'void'
              ? 'most_recent'
              : !action.model?.status
                ? prevSort
                : 'percentage_filled';

    }


    if(action.model?.sort_by) {
      sort_by = action.model.sort_by;
    }

    const list_pagination = _.isEmpty(action.model) ?
    Object.assign({}, state.list_pagination, { page: 1 }) :

    Object.assign({},
      state.list_pagination,
      action.model,
      { sort_by: sort_by || state.list_pagination.sort_by },
      { filters: filters || state.list_pagination.filters } )

    return {
      ...state,
      list_pagination: list_pagination,
      isLoading: true
    }
  }),

  // this does 4
  on(listLoadMore, (state, action) => {
    const list_pagination: SyndicatesScope.Pagination = _.cloneDeep(state.list_pagination) || _.cloneDeep(initialState.list_pagination);

    return {
      ...state,
      list_pagination: Object.assign({}, _.cloneDeep(list_pagination), { page: ++list_pagination.page }),
      isLoading: true
    }
  }),

  on(successList, (state, action) => {
    return {
      ...state,
      list: action.response,
      isLoading: false
    }
  }),

  on(successUpdateSyndicate, (state, action) => {
    return {
      ...state,
      portion: action.response,
      syndicateIsLoading: false
    }
  }),
  on(joinSyndicate, (state, action) => {
    return {
      ...state,
      syndicateIsLoading: true
    }
  }),

  on(createSyndicate, (state, action) => {
    return {
      ...state,
      syndicateIsLoading: true
    }
  }),

  on(successCreateSyndicate, (state, action) => {
    return {
      ...state,
      joinSuccess: action.response,
      syndicateIsLoading: false
    }
  }),
  on(successJoinSyndicate, (state, action) => {
    const syndicate = !!(<any>action.response.syndicate).raw ? (<any>action.response.syndicate).raw : action.response.syndicate
    const joinSuccess = {
      ...action.response,
      ticketUrl: `/tickets/syndicate/${syndicate.stringified_id}/pools/${syndicate.pool_id}`
    }

    return {
      ...state,
      joinSuccess: joinSuccess,
      syndicateIsLoading: false
    }
  }),

  on(clearCreateSuccess, (state, action) => {
    return {
      ...state,
      joinSuccess: undefined,
      joinError: undefined,
      syndicateIsLoading: false
    }
  }),

  on(failSyndicate, (state, action) => {
    return {
      ...state,
      syndicateIsLoading: false,
      joinError: action.error
    }
  }),

  on(successSyndicateWins, (state, action) => {
    return {
      ...state,
      wins_list: action.response
    }
  }),

  on(successCreateSyndicate, (state, action) => {
    return {
      ...state,
      portion: action.response
    }
  }),

  // Commentary
  on(loadComments, (state, action) => {
    // page logic
    return {
      ...state,
      commentaryParams: Object.assign({}, state.commentaryParams, action.params),
      commentaryStatus: {
        shouldRefresh: false,
        loading: true,
        success: false,
        error: undefined
      }
    }
  }),

  on(successComments, (state, action) => {
    const comments = action.response;

    return {
      ...state,
      comments: comments,
      commentaryStatus: {
        shouldRefresh: false,
        loading: false,
        success: false,
        error: undefined
      }
    }
  }),

  on(successCreateComment, (state, action) => {
    const commentsList: CommentaryListModel = _.cloneDeep(state.comments);

    let existingComment = false;
    for (let i = 0; i < commentsList.items.length; i++) {
      if (commentsList.items[i]['id'] === action.response['id']) {
        existingComment = true;
        commentsList.items[i].body = action.response['body'];
      }
    }

    if(!existingComment) {
      const arr = []
      arr.push(action.response)

      commentsList.items = arr.concat(commentsList.items)
      commentsList.total_items = commentsList.total_items + 1;
    }

    return {
      ...state,
      comments: commentsList,
      commentaryStatus: {
        shouldRefresh: false,
        loading: false,
        success: true,
        error: undefined
      }
    }
  }),

  on(failCreateComment, (state, action) => {
    return {
      ...state,
      commentaryStatus: {
        shouldRefresh: false,
        loading: false,
        success: false,
        error: action.error
      }
    }
  }),

  // LIVE UPDATES
  on(partialUpdate, (state, action) => {
    return ws[action.event](state, action)
  }),
);

// TODO: check the false case
export const ws = {
  SOCIAL_CREATE: (state, action) => {
    return {
      ...state,
      commentaryStatus: {
        shouldRefresh: true,
        loading: false,
        success: false,
        error: action.error
      }
    }
  },
  RELOAD_EVENTS: [
    'MICROSITE_AUTHENTICATION_REFRESH', 'USER_REGISTER', 'USER_LOGIN', 'USER_LOGOUT'
  ]
}

export function syndicatesReducer(
  state: SyndicatesState | undefined,
  action: Action
): SyndicatesState {
  return reducer(state, action);
}
