import { createFeatureSelector, createSelector } from '@ngrx/store'

import { GroupTicketState } from "../../NGRX/group-tickets/group-tickets.model";
import { AppState } from "../../NGRX/core.state";

export const selectGTState = createFeatureSelector<AppState, GroupTicketState>('groupTickets');


export const selectFollowers = createSelector(selectGTState, (state: GroupTicketState) => state.followers);
export const selectInfo = createSelector(selectGTState, (state: GroupTicketState) => state.info);
export const selectCreationState = createSelector(selectGTState, (state: GroupTicketState) => state.in_creation);
export const selectSelectionsState = createSelector(selectGTState, (state: GroupTicketState) => state.disableSelections);
export const selectShowVotes = createSelector(selectGTState, (state: GroupTicketState) => state.showVotes);


