import {Injectable} from '@angular/core';
import * as Big from 'big.js';
import * as _ from 'lodash-es';

@Injectable()

export class CalculationsService {
  constructor() { }

  /**
   * Returns maximum stake from an array of stakes
   * @param stakes [Array]
   */
  getMaxStake(stakes: any): string {
    if (!stakes || !stakes.length) {
      // @ts-ignore
      return;
    }
    const minStake = stakes[stakes.length - 1];
    return stakes.reduce((max: number, current: any) => Number(current) > max ? current : max, minStake);
  }
  /**
      Function to calculate real stake
      @param string currency Pool currency
      @param any fxRates Fx Rates (value of the unit) for that pool
      @param any stake Default stake or user selected stake
      @param boolean fixed Fixed prize (does not change based on stake change)
      @param any giveaway Giveaway object if it's freeplay
      */
  calculateRealStake(currency: string, fxRates: any, stake: any, fixed: boolean, giveaway: any): string {
    let real_stake: string = stake;
    const fx_rate: any = _.find(fxRates, { 'currency': currency });

    if (fixed) {
      return fx_rate.rate;
    }

    if (new Big.Big(stake).gte(fx_rate.rate)) {
      real_stake = fx_rate.rate;
    }

    if (giveaway) {
      if (new Big.Big(giveaway.stake_size).gte(fx_rate.rate)) {
        real_stake = fx_rate.rate;
      } else {
        real_stake = giveaway.stake_size;
      }
    }

    return real_stake;
  }


  // Calculations

  calculatePrize(amount: any, stake: any, decimalPlaces?: any, ticket_ownership?: any) {
    let proportion = new Big.Big(1);

    if (typeof ticket_ownership !== 'undefined') {
      proportion = new Big.Big(ticket_ownership);
    }

    if (typeof decimalPlaces === 'undefined') {
      decimalPlaces = 2;
    }

    amount = new Big.Big(amount);
    stake = new Big.Big(stake);

    return amount.times(proportion).times(stake).toFixed(decimalPlaces);
  }

  // Ticket specific

  getCustomerPercentage(amount_owed: any) {
    const custPercentage = new Big.Big(amount_owed);
    return custPercentage.times(100).toFixed();
  }

  getSyndicatePercentage(amount_owed: any) {
    const custPercentage = new Big.Big(amount_owed);
    return custPercentage.times(100).round(2, 3).toFixed();
  }

  /**
   * Reverse selections string to array - recreate selections array from selection string
   * @param  number poolId
   * @param  array  legs
   * @param  string selectionsString
   * @return [object]
   */
  reverseSelectionsString(legs: any, selectionsString: string) {
    // initialize the object and add the pool id as an object item
    const newSelectionsArray: any = {};
    // add empty legs object to pool and split selections by '/' (basically by leg)
    newSelectionsArray.legs = {};
    const selectionsArray: any = _.split(selectionsString, '/');
    // pass through all legs and foreach leg we add inside the array the bin
    _.each(legs, function (leg, key) {
      newSelectionsArray.legs[leg.id] = [];
      const legSelections = _.split(selectionsArray[key], ',');
      _.each(legSelections, function (selection, selKey) {
        newSelectionsArray.legs[leg.id].push(Number(selection));
      });
    });

    return newSelectionsArray;
  }

  getUserSelections(legs: any, selectionString: any) {
    // *** KILL ME *** //
    const parsedSelections: any = {};
    const userSelectionsList: any = [];
    parsedSelections.legs = {};

    const selectionsArray: any = _.split(selectionString, '/');

    _.each(legs, function (leg, key) {
      parsedSelections.legs[leg.id] = [];
      const legSelections = _.split(selectionsArray[key], ',');
      const _legSel: any = [];

      _.each(leg.selections, function (sel, selIdx) {
        _.each(legSelections, function (activeSel, idx) {
          if (activeSel == sel.bin) {
            _legSel.push(sel);
          }
        });
      });
      userSelectionsList.push(_legSel);
    });
    return userSelectionsList;
  }


  /**
   * Count the number of incorrect legs for prize calculator
   * @param  number poolId           [description]
   * @param  any    legs             [description]
   * @param  string selectionsString [description]
   * @return [type]                  [description]
   */
  countIncorrectLegs(legs: any, selectionsString: string): number {
    let incorrectCounter = 0;
    const ticketSelections = this.reverseSelectionsString(legs, selectionsString);

    _.each(legs, function (leg, pos) {
      let correct_selection_flag = false;
      const legSelections = ticketSelections.legs[leg.id];
      const winning_selections: any = _.result(leg, 'winning_selections[0].selections');
      if (winning_selections) {
        _.each(legSelections, function (sel, idx) {
          if (_.includes(winning_selections, sel)) {
            correct_selection_flag = true;
          } else {
            const favourite = _.find(leg.sport_event.sport_event_competitors, { 'favourite': true });
            if (favourite) {
              const non_runner = _.find(leg.sport_event.sport_event_competitors, {
                'cloth_number': sel,
                'withdrawn': true
              });
              // Check if Non Runner is a winning selections
              if ((non_runner || sel === 0) && !correct_selection_flag) {
                correct_selection_flag = _.includes(winning_selections, favourite.cloth_number);
              }
            }
          }
        });

        if (!correct_selection_flag) {
          incorrectCounter++;
        }
      }
    });
    return incorrectCounter;
  }

  getPlayingForPrize(ticket: any, pool: any) {
    const incorrectLegCounter = ticket.is_live_win ? 0 : this.countIncorrectLegs(pool.legs, ticket.selections);
    let prizeAmount = 0;

    if (incorrectLegCounter === 0) {
      return pool.headline_prize;
    } else {
      const prize_code = 'CON_N' + incorrectLegCounter;
      const prize = _.find(pool.prizes, function (prize_object) {
        return prize_object.prize_type_code === prize_code;
      });
      prizeAmount = prize ? prize.amount : 0;
    }
    return prizeAmount;
  }

  setTicketPrize(ticket: any, pool: any) {
    const prizeAmount = this.getPlayingForPrize(ticket, pool);
    const stake = ticket.stake;

    const ticket_prize = this.calculatePrize(prizeAmount, stake, 1, ticket.amount_owned_customer);
    return ticket_prize;
  }

  getTicketPlayingFor(ticket: any, pool: any, isFixed?: boolean) {
    const prizeAmount = this.getPlayingForPrize(ticket, pool);
    const stake = (!isFixed) ? ticket.stake : this.getMaxStake(pool.stake_sizes[0].stakes);
    // deprecated for now
    // let pool_split = this.getPoolSplit(pool);
    // prizeAmount = new Big.Big(prizeAmount).div(pool_split);
    return this.calculatePrize(prizeAmount, stake, 1, ticket.amount_owned_customer);
  }

  getPoolSplit(pool: any) {
    let pool_split = 1;
    _.each(pool.legs, (leg) => {
      if (leg.official) {
        const leg_split = _.filter(leg.sport_event.sport_event_competitors, (competitor) => {
          return competitor.result === 1;
        });
        pool_split *= (leg_split.length || 1);
      }
    });
    return pool_split;
  }

  getSyndicateUsePlayingFor(ticket: any, pool: any, isFixed?: boolean) {
    const prizeAmount = this.getPlayingForPrize(ticket, pool);
    const stake = (!isFixed) ? ticket.syndicate.stake : this.getMaxStake(pool.stake_sizes[0].stakes);
    const percentage = new Big.Big(ticket.syndicate.amount_owned_customer).times(ticket.syndicate_portion).toFixed();
    return this.calculatePrize(prizeAmount, stake, 1, percentage);
  }

  getSyndicatePlayingFor(ticket: any, pool: any, isFixed?: boolean) {
    const prizeAmount = this.getPlayingForPrize(ticket, pool);
    const stake = (!isFixed) ? ticket.syndicate.stake : this.getMaxStake(pool.stake_sizes[0].stakes);
    return this.calculatePrize(prizeAmount, stake, 1, ticket.syndicate.amount_owned_customer);
  }

  calculateRemainingProportion(amount_owned_customer: any, percentCashedOut: any) {
    const amount_owned = new Big.Big(amount_owned_customer);
    const cashoutPercentage = new Big.Big(percentCashedOut);
    return Number(amount_owned.minus(cashoutPercentage.div(100)).round(7, 1).round(2, 0).toFixed(2));
  }

  /** offer transactions **/
  getOfferPercentage(fraction: any) {
    if (typeof fraction === 'string') {
      fraction = new Big.Big(fraction);
    }
    return (fraction * 100);
  }

  totalOfferPercentage(customer_percentage: any) {
    const totalPercentageCashed = new Big.Big(customer_percentage);
    return new Big.Big(100).minus(totalPercentageCashed.times(100)).toFixed();
  }

  getSyndicateTotalPercentage(amount_owned_customer: any) {
    const totalPercent = new Big.Big(amount_owned_customer);
    return totalPercent.times(100).round(2, 3).toFixed();
  }

  isOnlyAOX(selections: any) {
    let isAOX = false;
    for (const leg in selections.legs) {
      for (const legBins of selections.legs[leg]) {
        if (legBins === 1 || legBins === 10 || legBins === 17) {
          isAOX = true;
        } else {
          isAOX = false;
          break;
        }
      }
      if (!isAOX) {
        break;
      }
    }
    return isAOX;
  }

  isAllSelected(selections: any, selections_length: any) {
    for (const key in selections.legs) {
      if (selections.legs[key].length !== selections_length) {
        return;
      }
    }
    return true;
  }

  breakdownPrizesCalculations(prizes: any, ticket: any, leg_num: number): any[] {
    let pool_dividend: any = [];
    try {
      _.filter(prizes, function (prize: any): any {
        const p: any = _.cloneDeep(prize);
        const key: string = (p.prize_type_code === 'WIN') ? 'value_win' : 'value_n' + p.prize_type_code.replace(/CON_N/i, '');
        const adjutment: number = (ticket.syndicate && !ticket.portion_as_solo) && ticket.syndicate_portion > 0 ? ticket.syndicate_portion : 1;
        const cd_calculated_dividend: Big.Big = new Big.Big(ticket[key]).times(ticket.fx_rate).times(ticket.amount_owned_customer).div(adjutment);
        if (cd_calculated_dividend.toString() !== '0') {
          p.level = (p.prize_type_code === 'WIN') ? leg_num : (leg_num - p.prize_type_code.replace(/CON_N/i, ''));
          const temp_lines: Big.Big = new Big.Big(ticket[key]).div(ticket.units_per_line).div(p.pay_out);
          p.calculated_lines = temp_lines.toFixed(0);
          p.calculated_dividend = new Big.Big(cd_calculated_dividend).toFixed(2);
          p.calculated_dividend_per_line = (cd_calculated_dividend.toString() === '0' && temp_lines.toString() === '0') ? 0 : new Big.Big(cd_calculated_dividend).div(temp_lines).round(2, 0).toFixed(2);
          pool_dividend.push(p);
        }
      });
      pool_dividend = _.orderBy(pool_dividend, ['level'], ['desc']);
      return pool_dividend;
    } catch(e: any) {
      console.error(e);
      return pool_dividend;
    }
  }

  calculateSyndicateWinnings(ticket: any) {
    // TODO: Will be deprecated with new API
    const adjutment: number = (ticket.syndicate && !ticket.portion_as_solo) && ticket.syndicate_portion > 0 ? ticket.syndicate_portion : 1;
    return new Big.Big(ticket.value_win).plus(ticket.value_n1).plus(ticket.value_n2).plus(ticket.value_n3).plus(ticket.value_n4).plus(ticket.value_n5).times(ticket.fx_rate).times(ticket.amount_owned_customer).div(adjutment).toFixed(2);
  }

  calculateSyndicatePayout(ticket: any) {
    // TODO: Will be deprecated with new API
    const adjutment: number = (ticket.syndicate && !ticket.portion_as_solo) && ticket.syndicate_portion > 0 ? ticket.syndicate_portion : 1;
    return new Big.Big(ticket.value_win).plus(ticket.value_n1).plus(ticket.value_n2).plus(ticket.value_n3).plus(ticket.value_n4).plus(ticket.value_n5).times(ticket.fx_rate).times(ticket.amount_owned_customer).plus(ticket.offers_accepted).div(adjutment).toFixed(2);
  }

  calculateSyndicateOffersAccepted(ticket: any) {
    const adjutment: number = (ticket.syndicate && !ticket.portion_as_solo) && ticket.syndicate_portion > 0 ? ticket.syndicate_portion : 1;
    return new Big.Big(ticket.offers_accepted).div(adjutment).toFixed(2);
  }

  calculateAmountSold(amount_sold: number, ticket: any) {
    const adjutment: number = (ticket.syndicate && !ticket.portion_as_solo) && ticket.syndicate_portion > 0 ? ticket.syndicate_portion : 1;
    return new Big.Big(amount_sold).div(adjutment).toFixed(2);
  }


  // UPPER PART REQUIRES REFACTORING

  // GLOBAL

  uiGlobalRoundDownValue(value: any): any {
    if (new Big.Big(value).lt(90)) {
      const x: any = new Big.Big(value).times(100).toFixed(2);
      return new Big.Big(x).round(1, 0);
    }
    return value;
  }

  uiGlobalCalculateManagerRank(manager: any, rank_type: any): string {
    if (manager.manager_rankings[0]['old_rank_' + rank_type] > 100 || manager.manager_rankings[0]['old_rank_' + rank_type] === null) {
      return 'rank_up';
    } else {
      if (manager.manager_rankings[0]['rank_' + rank_type] === manager.manager_rankings[0]['old_rank_' + rank_type]) {
        return 'rank_same';
      }
      if (manager.manager_rankings[0]['rank_' + rank_type] < manager.manager_rankings[0]['old_rank_' + rank_type]) {
        return 'rank_up';
      } else {
        return 'rank_down';
      }
    }
  }

  uiGlobalCopyTextToClipboard(text: string): boolean {
    let txtArea: any;
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      txtArea = document.createElement('input');
    } else {
      txtArea = document.createElement('textarea');
    }
    txtArea.id = 'txt';
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;

    document.body.appendChild(txtArea);
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      const editable: any = txtArea.contentEditable;
      const readOnly: any = txtArea.readOnly;
      txtArea.contentEditable = true;
      txtArea.readOnly = false;
      const range: any = document.createRange();
      range.selectNodeContents(txtArea);
      const sel: any = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      txtArea.setSelectionRange(0, 999999);
      txtArea.contentEditable = editable;
      txtArea.readOnly = readOnly;
    } else {
      txtArea.select();
    }

    try {
      const successful: any = document.execCommand('copy');
      if (successful) {
        return true;
      }
    } catch (err) {
      console.error('Unable to copy link');
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }

  // Get culture code (sharing related)
  uiInternalGetCultureCode(): string {
    const code: string = window.document.referrer;
    let culture = 'en-GB';
    const countryCodeISORegex: any = /^[a-z]{2}(-[A-Z]{2})?$/gm;
    if (code) {
      const parser: any = document.createElement('a');
      parser.href = code;
      const culture_code: any = parser.pathname.split('/');
      _.forEach(culture_code, function (part: any, index: any): any {
        if (countryCodeISORegex.exec(part)) {
          culture = part;
        }
      });
    }
    return culture;
  }

  // Get timezone offset (sharing related)
  uiGetTimezoneOffset(): string {
    const offset: any = new Date().getTimezoneOffset();
    const o: any = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
  }

  // Get redirect URL (sharing related) - LIST ONLY
  uiGetListRedirectUrl(utmParams: any, managerId: any, affiliateUrl: any, language: any) {
    const redirect_url: any = `/syndicates/profile/${managerId}${utmParams}`;

    let url: any = affiliateUrl ? affiliateUrl + redirect_url : (window.location.protocol + '//' + window.location.host + redirect_url);

    url = url.replace('[REDIRECT_URL]', redirect_url);
    url = url.replace('[CULTURE_CODE]', this.uiInternalGetCultureCode());
    url = url.replace('[LANG]', language);
    return url;
  }

  // Get redirect URL (sharing related) - TICKET ONLY
  uiGetTicketRedirectUrl(utmParams: any, poolId: any, syndicateId: any, affiliateUrl: any, language: any) {
    const redirect_url: any = '/syndicates/join/pool/' + poolId + '/' + syndicateId + utmParams;

    let url: any = affiliateUrl ? affiliateUrl + redirect_url : (window.location.protocol + '//' + window.location.host + redirect_url);

    url = url.replace('[REDIRECT_URL]', redirect_url);
    url = url.replace('[CULTURE_CODE]', this.uiInternalGetCultureCode());
    url = url.replace('[LANG]', language);
    return url;
  }

  // Get redirect URL (sharing related) - USERS ONLY
  uiGetUsersRedirectUrl(utmParams: any, affiliateUrl: any, language: any) {
    const redirect_url: any = '/pools/sports' + utmParams;
    let url: any = affiliateUrl ? affiliateUrl + redirect_url : (window.location.protocol + '//' + window.location.host + redirect_url);

    url = url.replace('[REDIRECT_URL]', redirect_url);
    url = url.replace('[CULTURE_CODE]', this.uiInternalGetCultureCode());
    url = url.replace('[LANG]', language);
    return url;
  }

  // 1. DYNAMIC HOMEPAGE
  // 1.1 BDG PROMO

  uiBDGPromoExtractPool(data: any) {
    if (data?.BDG?.length <= 0) {
      return undefined;
    }
    const pools = _.orderBy(
      data.BDG,
      ['headline_prize', 'sched_start'],
      ['desc', 'asc']
    );
    return pools[0];
  }

  // 1.2 FREEPLAY LOYALTY

  uiFPLoyaltyCalculateTiers(tiers: any) {
    const copyTiers = _.cloneDeep(tiers);
    const arrLength = copyTiers.length;
    for (let i = 0; i < arrLength; i++) {
      copyTiers[i]['string_tier'] =
        copyTiers[i].description !== 'Tier 0'
          ? copyTiers[i].description.match(/\((.*?)\)/)[1].toUpperCase()
          : null;
      if (copyTiers[i]['string_tier'] === 'GOLD') {
        copyTiers[i]['to_threshold'] = 999999;
      }
    }
    return copyTiers;
  }

  uiFPLoyaltyCalculateRate(amount: number, fxRate: any) {
    try {
      return amount * fxRate;
    } catch (e) {
      return 1;
    }
  }

  uiFPLoyaltyCalculateBarWidth(rebateReached: any, nextRebate: any, rebate: any) {
    if (rebateReached && !nextRebate) {
      return 100;
    } else {
      return this.internalFPBarPercentageCalculation(rebate, nextRebate);
    }
  }

  internalBarPercentageCalculation(rebate: any, nextRebate: any) {
    const percentage = (rebate.weekly_spent_units * 100) / nextRebate.from_threshold_units || 0;
    return percentage < 100 ? percentage : 100;
  }

  internalFPBarPercentageCalculation(rebate: any, nextRebate: any) {
    const percentage = (rebate.weekly_spend_units * 100) / nextRebate.from_threshold || 0;
    return percentage < 100 ? percentage : 100;
  }

  uiFPLoyaltyGetNextRebate(rebate: any, tiers: any) {
    const copyTiers = _.cloneDeep(tiers);
    copyTiers.sort((a: any, b: any) => a.from_threshold - b.from_threshold);
    const nextRebate = copyTiers
      .filter((el: any) => el.description !== 'No Rebate')
      .find((el: any) => Number(rebate) < Number(el.from_threshold));
    return nextRebate;
  }

  uiFPLoyaltyGetRebateReached(rebate: number, tiers: any) {
    const copyTiers = _.cloneDeep(tiers);
    copyTiers.sort((a: any, b: any) => a.from_threshold - b.from_threshold);
    const rebateReached = copyTiers
      .filter((el: any) => el.description !== 'No Rebate')
      .find(
        (el: any) =>
          Number(rebate) >= Number(el.from_threshold) &&
          Number(rebate) < Number(el.to_threshold)
      );
    return rebateReached;
  }

  uiFPLoyaltyGetCurrentRebate(rebate: any, tiers: any) {
    return tiers.find((tier: any) => tier.id === rebate.rebate_tier_id);
  }

  uiFPLoyaltyGetPreviousRebate(tiers: any, rebateReached: any) {
    let previousRebate;
    if (!rebateReached) {
      return previousRebate;
    }
    _.forEach(tiers, (el: any) => {
      if (Number(rebateReached.from_threshold) >= Number(el.from_threshold)) {
        previousRebate = el;
      }
    });
    return previousRebate;
  }

  // 1.3 SYNDICATE WINS

  uiSWCalculateROI(item: any): number | string {
    const fraction = new Big.Big(item.total_dividend)
      .plus(item.total_cashed_out)
      .toFixed(2);
    return new Big.Big(fraction)
      .div(item.cost)
      .minus(1)
      .times(100)
      .toFixed(2);
  }

  uiSWCalculatePayout(item: any): number | string {
    return new Big.Big(item.total_dividend).plus(item.total_cashed_out).toFixed(2);
  }

  uiSWhaveROI(item: any, LIMIT: number): boolean {
    return new Big.Big(item.total_dividend)
      .plus(item.total_cashed_out)
      .gte(0);
  }

  uiSWCalculateMonetaryValue(item: any): number | string {
    return new Big.Big(item.total_dividend).plus(item.total_cashed_out).toFixed(0);
  }

  uiSWSortItems(items: any, MODEL: any, LIMIT: number): any[] {
    const sortedItems = [];
    const arrLength = items.length;

    for (let i = 0; i < arrLength; i++) {
      items[i].haveROI = this.uiSWhaveROI(items[i], LIMIT);
      items[i].calculated_roi = this.uiSWCalculateROI(
        items[i]
      );
      items[i].calculated_payout = this.uiSWCalculatePayout(
        items[i]
      );
      items[i].monetary_value = this.uiSWCalculateMonetaryValue(
        items[i]
      );
    }
    for (let i = 0; i < arrLength; i++) {
      if (MODEL.filters['syndicate_ids'].includes(items[i].id)) {
        sortedItems.push(items[i]);
      }
    }
    // Removed undefined from values
    return sortedItems.filter(x => {
      return x !== undefined;
    });
  }

  // 2. WIDGETS


  // 3. MAIN COMPONENTS

  // Used in ticketSummary and ticketResults for WRC
  uiMapCompetitorsWRC(selections: any, leg: any) {
    const competitors = _.map(selections, (selection) => {
      return _.find(leg.sport_event.sport_event_competitors, { 'id': selection.sport_event_competitor_id });
    });
    return competitors;
  }

  // Used in ticketSummary and ticketResults for RACING
  uiMapCompetitorsRACING(selections: any, leg: any) {
    const competitors = _.map(selections, (selection) => {
      return _.find(leg.sport_event.sport_event_competitors, { 'id': selection.sport_event_competitor_id });
    }).sort((selectionA, selectionB) => selectionA.cloth_number > selectionB.cloth_number ? 1 : -1);
    return competitors;
  }

  // Used in ticketSummary and ticketResults for vertical style coupons (WRC, HORSE RACING, GREYHOUNDS)
  uiIsSelectionWinner(bin: number, leg: any): boolean {
    if (leg.sport_event.status === 'ABANDONED') {
      return true;
    }
    if (leg.winning_selections) {
      if (leg.winning_selections.length > 0) {
        return _.includes(leg.winning_selections[0].selections, bin);
      }
    }
    return false;
  }

  // Used in ticketSummary and ticketResults for horizontal style coupons (SPORTS, excluding: WRC, HORSE RACING, GREYHOUNDS)
  uiSelectionResult(position: number | string, leg: any): any {
    if (leg.sport_event.status === 'ABANDONED') {
      return 'success';
    }
    if (leg.official) {
      if (leg.winning_selections) {
        if (leg.winning_selections.length > 0) {
          const successSelections: any = leg.winning_selections[0].selections;
          if (_.includes(successSelections, position)) {
            return 'success';
          }
          return 'error';
        }
      }
    }
  }

  // Generate legs with streaming for the ticket page
  uiGenerateLegsWithStreamingTicketPage(legs: any) {
    const legsWithStreaming: any = [];
    try {
      legs.forEach((leg: any) => {
        if (leg.sport_event.status !== 'OFFICIAL') {
          if (leg.sport_event.add_info_json.stream_type === 2) {
            legsWithStreaming.push(leg);
          } else if (leg.sport_event.add_info_json.stream_type === 1) {
            legsWithStreaming.push(leg);
          }
        }
      });
      return legsWithStreaming;
    } catch (e: any) {
      console.error(e);
      return legsWithStreaming;
    }
  }

  // Check if have video stream on the ticket page
  uiCheckForVideoStreamonTicketPage(videoStreamSport: any, enableVideoStreaming: boolean, poolInfo: any, selectedLeg: number): boolean {
    const legsWithStreaming = this.uiGenerateLegsWithStreamingTicketPage(poolInfo.legs);
    if ((videoStreamSport.indexOf(poolInfo.sport_code) >= 0) &&
      enableVideoStreaming &&
      (legsWithStreaming.length > 0) &&
      (legsWithStreaming[selectedLeg - 1].sport_event.add_info_json.stream_type !== 0) &&
      (poolInfo.status !== 'OFFICIAL')) {
      return true;
    }
    return false;
  }

  // Generate data for opening video stream on the tickets page
  uiOpenVideoStreamTicketPage(legsWithStreaming: any, userId: any) {
    const selectedLeg = 0;
    const startDate = (legsWithStreaming[selectedLeg].sport_event.add_info_json.stream_type === 1) ? undefined : legsWithStreaming[selectedLeg].sport_event.sched_start;
    const data = {
      state: true,
      user_id: userId,
      stream_id: legsWithStreaming[selectedLeg].sport_event.add_info_json.stream_id,
      start_date: startDate
    };
    return data;
  }

  // Return ticket status
  uiGetTicketStatus(ticket: any): string {
    if (
      ticket.status === 'PLACED' &&
      ticket.syndicate.amount_funded < 1
    ) {
      return 'PENDING';
    } else {
      if (ticket.customer_winnings !== '0.0') {
        return 'HISTORIC';
      } else {
        return 'PLACED';
      }
    }
  }

  // Return if a user can change the public status of a syndicate
  uiGetCanChangeSyndicatePublicStatus(ticket: any, pool: any, loggedIn: boolean, sessionManagerId: number): boolean {
    return (
      !ticket.syndicate.is_public &&
      loggedIn &&
      pool.status === 'OPEN' &&
      sessionManagerId === ticket.syndicate.manager.id
    );
  }

  // Return if a user can contribute to a syndicate ticket
  uiGetCanContributeToTicket(ticket: any): boolean {
    return (
      ticket.status === 'PLACED' &&
      ticket.syndicate.amount_funded < 1 &&
      !new Big.Big(ticket.syndicate.customer_portion).gte(0.5)
    );
  }

  // Return if a user can change the void to solo status of a syndicate
  uiGetCanChangeVoidToSoloStatus(ticket: any, pool: any, loggedIn: boolean, sessionManagerId: number): boolean {
    const isComplete = (Number(ticket.syndicate.amount_funded) === 1);
    return (
      loggedIn &&
      !isComplete &&
      ticket.status === 'PLACED' &&
      pool.status === 'OPEN' &&
      sessionManagerId === ticket.syndicate.manager.id
    );
  }

  // Return if the breakdown can be displayed on a ticket
  uiCanDisplayBreakdown(ticket: any, poolStatus: string): boolean {
    const ticketDate: Date = new Date(ticket.created_at);
    const thresohldDate: Date = new Date('2018/01/01');
    return (
      poolStatus === 'OFFICIAL' &&
      !ticket.is_live &&
      (ticket.offer_transactions.length > 0 ||
        ticket.customer_winnings !== '0.0') &&
      ticketDate > thresohldDate
    );
  }

  // Return if a user can have visible the sharing options on the ticket page
  uiCanShowSharingOptionsTicketPage(ticket: any, poolStatus: string): boolean {
    return (
      ticket.status === 'PLACED' &&
      ticket.syndicate.customer_portion > 0 &&
      (poolStatus === 'OPEN' ||
        (poolStatus === 'OFFICIAL' &&
          ticket.customer_winnings !== '0.0'))
    );
  }

  // Sydnicate profile rebate calculate bar width
  uiSydnicateRebateCalculateBarWidth(rebate: any, nextRebate: any) {
    return this.internalBarPercentageCalculation(rebate, nextRebate);
  }

  // Syndicate profile rebate calculate next rebate
  uiSyndicateRebateGetNextRebate(rebate: number, tiers: any) {
    tiers.slice().sort((a: any, b: any) => a.from_threshold_units - b.from_threshold_units);
    const nextRebate = tiers
      .filter((el: any) => el.description !== 'No Rebate')
      .find((el: any) => (Number(rebate) < Number(el.from_threshold_units)));
    return nextRebate;
  }

  // Syndicate profile rebate calculate rebate reached
  uiSyndicateRebateGetRebateReached(rebate: number, tiers: any) {
    tiers.slice().sort((a: any, b: any) => a.from_threshold_units - b.from_threshold_units);
    const rebateReached = tiers
      .filter((el: any) => el.description !== 'No Rebate')
      .find((el: any) => (
        Number(rebate) >= Number(el.from_threshold_units)
        && Number(rebate) < Number(el.to_threshold_units)
      ));
    return rebateReached;
  }

  // Syndicate profile rebate get current rebate
  uiSyndicateRebateGetCurrentRebate(rebate: any, tiers: any) {
    return tiers.slice().find((tier: any) => tier.id === rebate.rebate_tier_id);
  }

  // Syndicate profile list sort options visibility
  uiSyndicateProfileSortOptionsVisibility(filterStatus: string, sortOption: any): boolean {
    // TODO: Check and simplify logic to be more understandable
    return filterStatus === 'historic'
      ? (sortOption === 'percentage_filled' || sortOption === 'starting_soon' || sortOption === 'cash_out')
      : filterStatus === 'avalilable_to_fund'
        ? (sortOption === 'most_recent' || sortOption === 'payout' || sortOption === 'cash_out')
        : filterStatus === 'placed'
          ? (sortOption === 'percentage_filled' || sortOption === 'most_recent' || sortOption === 'payout' || sortOption === 'cash_out')
          : filterStatus === 'void'
            // tslint:disable-next-line: max-line-length
            ? (sortOption === 'percentage_filled' || sortOption === 'payout' || sortOption === 'started_soon' || sortOption === 'contributors' || sortOption === 'starting_soon' || sortOption === 'cost' || sortOption === 'cash_out')
            : filterStatus === 'live'
              ? (sortOption === 'percentage_filled' || sortOption === 'starting_soon' || sortOption === 'most_recent' || sortOption === 'payout')
              : (sortOption === 'payout' || sortOption === 'most_recent' || sortOption === 'cash_out');
  }

  // Reorder sort options
  uiSyndicateProfileSortOptionsReorder(sortOptions: any, sortBy: any) {
    const first: any = sortOptions.filter((item: any) => item === sortBy);
    const others: any = sortOptions.filter((item: any) => item !== sortBy);
    sortOptions = [...first, ...others];
    return sortOptions;
  }

  // Syndicate leaderboard get last update
  uiSyndicateLeaderabordGetLastUpdated(): Date {
    const todayDate = new Date();
    const lastTuesDate = new Date();
    lastTuesDate.setDate(lastTuesDate.getDate() - 1);
    while (lastTuesDate.getDay() !== 2) {
      lastTuesDate.setDate(lastTuesDate.getDate() - 1);
    }
    if (todayDate.getDay() === 2) {
      return todayDate;
    } else {
      return lastTuesDate;
    }
  }

  // Syndicate leaderboard get time periods
  uiSyndicateLeaderboardGetTimePeriods(settings: any) {
    let weekPeriod = null;
    if (settings && settings.periods) {
      settings.periods.forEach((p: any) => {
        if (p.time_value === 7 && p.time_key === 'day') {
          weekPeriod = p;
          return;
        }
      });
    }
    return weekPeriod;
  }

  // Unit distribution competitor mapping for RACING
  uiUnitDristributionRacingCompetitorsMapping(competitors: any, leg: any) {
    const mapped = _.map(competitors, (competitor) => {
      return Object.assign(competitor, {
        selection: _.find(leg.selections, { 'sport_event_competitor_id': competitor.id })
      });
    });
    return mapped;
  }

  // Pool summary generator - racing & greyhounds
  uiGeneratePoolSummary(selections: any, legs: any) {
    const selectedCompetitor: any = {};
    try {
      const display_legs: any = _.isEqual(selections.legs_with_selections, _.size(selections.valid_legs)) ? selections.valid_legs : selections.legs;
      _.each(display_legs, (bins, leg_order) => {
        selectedCompetitor[leg_order] = _.orderBy(_.map(bins, (bin) => {
          const leg: any = _.find(legs, { 'leg_order': Number(leg_order) });
          const sport_event_competitors: any = _.result(leg, 'sport_event.sport_event_competitors', []);
          return _.find(sport_event_competitors, { 'cloth_number': Number(bin) });
        }), (sport_event_competitors) => sport_event_competitors ? sport_event_competitors.cloth_number : 0);
      });
      return selectedCompetitor;
    } catch (e: any) {
      return selectedCompetitor;
    }
  }

  uiGenerateSportsPoolSummary(selections: any, legs: any) {
    const selectedCompetitor: any = {};
    try {
      const display_legs: any = _.isEqual(selections.legs_with_selections, _.size(selections.valid_legs)) ? selections.valid_legs : selections.legs;
      _.each(display_legs, (bins, leg_order) => {
        selectedCompetitor[leg_order] = _.orderBy(_.map(bins, (bin) => {
          const leg: any = _.find(legs, { 'leg_order': Number(leg_order) });
          const sport_event_competitors: any = _.result(leg, 'selections', []);
          return _.find(sport_event_competitors, { 'bin': Number(bin) });
        }), (sport_event_competitors) => sport_event_competitors ? sport_event_competitors.display_order : 0);
      });
      return selectedCompetitor;
    } catch (e: any) {
      return selectedCompetitor;
    }
  }

  // Pool results 'Not same day' split logic
  uiPoolResultsNotSameDay(pools: any, previousPool: any, currentPool: any) {
    if (!previousPool && !currentPool) {
      return false;
    }

    const prev: Date = new Date(pools[previousPool].sched_start);
    const current: Date = new Date(pools[currentPool].sched_start);

    return prev.getDate() !== current.getDate() || prev.getMonth() !== current.getMonth() || prev.getFullYear() !== current.getFullYear();
  }

  // Freeplay expiration date calculator
  uiGetFreeplayExpirationDate(giveaway: any) {
    let expiration: any;
    if (giveaway) {
      const now: any = new Date();
      const givewayExpiry: any = new Date(giveaway.expiration_date);

      const diffMs = (givewayExpiry - now); // milliseconds between now & Christmas
      const days = Math.floor(diffMs / 86400000); // days
      const hours = Math.floor((diffMs % 86400000) / 3600000); // hours
      const minutes = Math.round(((diffMs % 86400000) % 3600000) / 60000); //

      if (days < 1) {
        if (hours < 1 && minutes > 0) {
          expiration = {
            value: minutes,
            type: 'MINUTES'
          };
        }
        if (hours === 1) {
          expiration = {
            value: hours,
            type: 'HOUR'
          };
        }
        if (hours > 1) {
          expiration = {
            value: hours,
            type: 'HOURS'
          };
        }
      } else {
        if (days === 1) {
          expiration = {
            value: days,
            type: 'DAY'
          };
        } else {
          expiration = {
            value: days,
            type: 'DAYS'
          };
        }
      }
    }
    return expiration;
  }

  // Pool leg information get winner for WRC & FANTASY
  uiPoolLegInformationGetWRCWinner(leg: any) {
    let winner;
    if (leg.sport_event.status === 'OFFICIAL') {
      const winning_selection = _.result(leg, 'winning_selections[0].selections[0]', null);
      winner = _.find(leg.sport_event.sport_event_competitors, function (competitor) {
        return competitor.cloth_number === winning_selection;
      });
    }
    return winner;
  }

  // Pool leg information get winner HORSE RACING and GREYHOUNDS
  uiPoolLegInformationGetRACINGWinner(leg: any) {
    let winner;
    if (leg.sport_event.status === 'OFFICIAL' || leg.sport_event.status === 'COMPLETED') {
      const winning_selection: any = _.result(leg, 'winning_selections[0].selections', null);

      winner = _.filter(leg.sport_event.sport_event_competitors, function (competitor: any): any {
        return _.includes(winning_selection, competitor.cloth_number);
      });
    }
    return winner;
  }

  // Pool History get Unit proportion as percentage
  uiPoolHistoryUnitProportion(poolUnits: any, units: any) {
    if (poolUnits > 0) {
      const totalLog: number = Math.max(0.5, Math.log(poolUnits) + 3);
      const unitsLog: number = Math.max(0.5, Math.log(units) + 3);
      return (unitsLog / totalLog) * 100;
    } else {
      return 0;
    }
  }

  // Pool History get teams
  uiPoolHistoryGetTeams(legs: any, sport_event_id: number) {
    const teams: any = {};

    _.filter(legs, function (leg: any): any {
      if (leg.sport_event.id === sport_event_id) {
        teams.home = leg.sport_event.add_info_json.home_short_name;
        teams.away = leg.sport_event.add_info_json.away_short_name;
      }
    });

    return teams;
  }

  // Pool History Get runners
  uiPoolHistoryGetRunners(legs: any, runners: any, sport_event_id: number) {
    let places: any[] = [];
    try {
      _.filter(legs, function (leg: any): any {
        if (leg.sport_event.id === sport_event_id) {
          if (leg.sport_event.status === 'OFFICIAL' || leg.sport_event.status === 'COMPLETED') {
            const winning_selection: any = _.result(leg, 'winning_selections[0].selections', null);
            places = _.filter(leg.sport_event.sport_event_competitors, function (competitor: any): any {
              return _.includes(winning_selection, competitor.cloth_number);
            });
          }
        }
      });
      return places;
    } catch (e: any) {
      return places;
    }
  }

  // Pool History - HORSE RACING - check if there's an official result
  uiPoolHistoryCheckHORSERACINGOfficialResult(eventHistory: any, event: any) {
    const result: any = {
      show: false,
      official: false,
      started: false
    };
    if (event.event_code === 'STARTED') {
      result.started = true;
    }
    _.forEach(eventHistory, function (o: any): any {

      if ((o.sport_event_history.sport_event_id === event.sport_event_history.sport_event_id) && (o.event_code !== event.event_code)) {
        if (event.event_code === 'COMPLETED' || event.event_code === 'STARTED') {
          result.show = true;
          if (o.event_code === 'OFFICIAL') {
            result.official = true;
          }
        }
      } else {
        if (event.event_code === 'COMPLETED' || event.event_code === 'STARTED') {
          result.show = true;
        }
      }
    });

    return result;
  }

  // Pool History - GREYHOUNDS - check if there's an official result
  uiPoolHistoryCheckGREYHOUNDSOfficialResult(eventHistory: any, event: any) {
    const result: any = {
      show: false,
      official: false,
      started: false
    };
    if (event.event_code === 'STARTED') {
      result.started = true;
    }
    _.forEach(eventHistory, function (o: any): any {

      if ((o.sport_event_history.sport_event_id === event.sport_event_history.sport_event_id) && (o.event_code !== event.event_code)) {
        if (event.event_code === 'COMPLETED' || event.event_code === 'STARTED') {
          result.show = true;
          if (o.event_code === 'OFFICIAL') {
            result.official = true;
          }
        }
      } else {
        if (event.event_code === 'COMPLETED' || event.event_code === 'STARTED') {
          result.show = true;
        }
      }
    });

    return result;
  }

  // Pool History - DARTS - check if there's an official result
  uiPoolHistoryCheckDARTSOfficialResult(eventHistory: any, event: any) {
    const result: any = {
      show: false,
      official: false,
      started: false
    };
    if (event.event_code === 'STARTED') {
      result.started = true;
    }

    _.forEach(eventHistory, function (o: any): any {

      if ((o.sport_event_history.sport_event_id === event.sport_event_history.sport_event_id)) {
        result.show = event.event_code === 'GOAL' && o.event_code !== 'OFFICIAL';
        if (event.event_code === 'OFFICIAL') {
          result.show = true;
          result.official = true;
        }
        if (event.event_code === 'ABANDONED' || event.event_code === 'AMENDMENT_END' || event.event_code === 'STARTED') {
          result.show = true;
          if (o.event_code === 'OFFICIAL') {
            result.official = true;
          }
        }
      }
    });

    return result;
  }

  // Pool header stream initialisation
  uiPoolHeaderStreamInit(model: any): any {
    const streamData: any = {
      daily_stream_data: undefined,
      no_stream_available: false,
      leg_stream_data: []
    };

    if (model.enableVideoStreaming && (model.videoStreamingSport.indexOf(model.sport_code) >= 0)) {
      let have_defaults = false;

      for (let i = 0; i < model.legs.length; i++) {
        if (model.legs[i].sport_event.status !== 'OFFICIAL') {
          const stream_type: any = model.legs[i].sport_event.add_info_json.stream_type;

          if (stream_type === 1 && !have_defaults) {
            streamData.daily_stream_data = {
              stream_type: model.legs[i].sport_event.add_info_json.stream_type,
              stream_id: model.legs[i].sport_event.add_info_json.stream_id,
              stream_date: model.legs[i].sport_event.add_info_json.stream_start_date
            };
            have_defaults = true;
            break;
          }

          if (stream_type === 0 && !have_defaults) {
            streamData.no_stream_available = true;
            break;
          }

          if (stream_type === 2 && !have_defaults && !streamData.no_stream_available) {
            streamData.leg_stream_data[i] = {
              stream_type: model.legs[i].sport_event.add_info_json.stream_type,
              stream_id: model.legs[i].sport_event.add_info_json.stream_id,
              start_date: model.legs[i].sport_event.add_info_json.stream_start_date
            };
          }
        }
      }
    }

    return streamData;
  }

  // Check if a pool can and is allowed to have video streaming
  uiPoolHeaderCheckForVideoStream(model: any): boolean {
    if (!model.enableVideoStreaming) {
      return false;
    }
    try {
      if (
        model.userId &&
        (model.videoStreamingSport.indexOf(model.sport_code) >= 0) &&
        (model.selectedLeg === 1000 || model.daily_stream_data || (model.leg_stream_data.length > 0)) &&
        !model.no_stream_available &&
        (model.status !== 'OFFICIAL')
      ) {
        const now: any = new Date();
        const streamStart: any = new Date(model.daily_stream_data.stream_date);
        const diffMs = (streamStart - now);
        return diffMs < 1;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  // Check if merchant have settlement commission and can display it
  uiHaveSettlementCommission(settlement_commission: any, ticket: any): boolean {
    if (settlement_commission) {
      if (ticket) {
        if (ticket.customer_payout === '0.0') {
          return false;
        }
        const ticketCurrency = ticket.currency;
        const thereshold = settlement_commission.currency_threshold[
          ticketCurrency
        ];
        const payout = new Big.Big(ticket.customer_payout).plus(ticket.offers_accepted).toFixed(2);
        if (new Big.Big(payout).gt(thereshold)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  // Calculate settlement commission - merchant specific
  uiCalculateSettlementCommission(gross: number, settlement_commission: any, ticket: any): any {
    if (settlement_commission) {
      if (ticket) {
        const user_percentage =
          1 - settlement_commission.settlement_threshold_commission;
        return new Big.Big(gross)
          .times(user_percentage)
          .plus(ticket.offers_accepted)
          .toFixed(2);
      }
    }
  }

  /**
   * Return the coupon type use for templates
   * @param type_code
   */
  uiReturnCouponType(type_code: string): string {
    let coupon_model: any;

    function isSports() {
      return (!['RACE_WIN', 'RACE_ORDER', 'RACE_PLACE', 'MVP', 'EVENT_WIN', 'MATCH', 'MATCH_50', 'MATCH_20'].includes(type_code) ? 'SPORTS' : null);
      // return (type_code !== 'RACE_WIN' && type_code !== 'RACE_ORDER' && type_code !== 'RACE_PLACE' && type_code !== 'MVP' && type_code !== 'EVENT_WIN'  && type_code !== 'MATCH') ? 'SPORTS' : null;
    }
    function isRacing() {
      // tslint:disable-next-line:max-line-length
      return (['RACE_WIN', 'RACE_ORDER', 'RACE_PLACE', 'EVENT_WIN'].includes(type_code) ? 'RACING' : null);
      // return (type_code === 'RACE_WIN' || type_code === 'RACE_ORDER' || type_code === 'RACE_PLACE' || type_code === 'EVENT_WIN') ? 'RACING' : null;
    }
    function isMvp() {
      return (['MVP'].includes(type_code) ? 'MVP' : null);
      // return (type_code === 'MVP') ? 'MVP' : null;
    }
    function isSpa() {
      return (['MATCH'].includes(type_code) ? 'SPA' : null);
      // return (type_code === 'MATCH') ? 'SPA' : null;
    }
    function isCricket() {
      return (['MATCH_50', 'MATCH_20'].includes(type_code) ? 'CRICKET' : null)
    }

    coupon_model  = isSports() || isRacing() || isMvp() || isSpa() || isCricket();

    return coupon_model;
  }

  /**
   * If it's a MATCH pool, add a specific key `fe_grouping` on each leg: GROUP_90_MIN, GROUP_CORNERS, GROUP_CARDS
   * @param pool
   */
  uiAddGroupingKeyOnLegs(pool: any): any {
    // we use constants internally only
    const GROUP_90MIN = [
      '',
      'MATCH_RESULT',
      'CORRECT_SCORE',
      'BTTS',
      'OVER_UNDER',
      'HT_FT',
      'TOT_GOALS',
      'PENALTY_TAKEN',
      'VAR_OVERTURN',
      'RED_CARD',
      'RED_OR_PENALTY',
      'TIME_FIRST_GOAL',
      'GOAL_FH'
    ];

    const GROUP_CORNERS = [
      '',
      'HOME_CORNERS',
      'AWAY_CORNERS',
      'HOME_CORNERS_OU',
      'AWAY_CORNERS_OU',
      'TOT_CORNERS',
      'FH_CORNERS',
      'SH_CORNERS',
      'TOT_CORNERS_OU'
    ];

    const GROUP_CARDS = [
      '',
      'HOME_CARDS',
      'AWAY_CARDS',
      'TOT_CARDS_OU',
      'TOT_BP',
      'HOME_BP',
      'AWAY_BP',
      'FH_CARDS',
      'SH_CARDS'
    ];

    const GROUP_CRICKET = [
      '',
      'CKT_MATCH_RESULT',
      'CKT_OP_PARTNERSHIP',
      'CKT_OP_PARTNERSHIP',
      'CKT_1INN_WICKET',
      'CKT_2INN_WICKET',
      'CKT_MATCH_SIXES',
      'CKT_WICKETS_OU',
      'CKT_PROP_RUNS_50',
      'CKT_PROP_WICKETS',
      'CKT_FST_SCR_SHOT',
      'CKT_PROP_RUNS_20'
    ]

    if (pool.type_code === 'MATCH') {
      for (let i = 0; i <= pool.legs.length - 1; i++) {
        if ([...GROUP_90MIN].indexOf(pool.legs[i].type_code) > 0) {
          pool.legs[i].fe_grouping = 'GROUP_90_MIN';
        }
        if ([...GROUP_CORNERS].indexOf(pool.legs[i].type_code) > 0) {
          pool.legs[i].fe_grouping = 'GROUP_CORNERS';
        }
        if ([...GROUP_CARDS].indexOf(pool.legs[i].type_code) > 0) {
          pool.legs[i].fe_grouping = 'GROUP_CARDS';
        }
      }
    }

    if (pool.type_code === 'MATCH_20' || pool.type_code === 'MATCH_50') {
      for (let i = 0; i <= pool.legs.length - 1; i++) {
        if ([...GROUP_CRICKET].indexOf(pool.legs[i].type_code) > 0) {
          pool.legs[i].fe_grouping = 'CRICKET';
        }
      }
    }
    return pool;
  }

  uiExtractEvent(pool: any): any {
    const _pool = _.cloneDeep(pool);
    if (!_pool.type_code.includes('MATCH')) {
      return null;
    }
    const GROUP_90MIN = [
      '',
      'MATCH_RESULT',
      'CORRECT_SCORE',
      'BTTS',
      'OVER_UNDER',
      'HT_FT',
      'TOT_GOALS',
      'PENALTY_TAKEN',
      'VAR_OVERTURN',
      'RED_CARD',
      'RED_OR_PENALTY',
      'TIME_FIRST_GOAL',
      'GOAL_FH'
    ];

    const GROUP_CRICKET = [
      '',
      'CKT_MATCH_RESULT'
    ]
    try {
      let leg_with_event: any;
      if (_pool.type_code === 'MATCH') {
        leg_with_event = _.find(_pool.legs, leg => {
          return ([...GROUP_90MIN].indexOf(leg.type_code) > 0);
        });
      } else {
        leg_with_event = _.find(_pool.legs, leg => {
          return ([...GROUP_CRICKET].indexOf(leg.type_code) > 0);
        });
        if (leg_with_event.sport_event && leg_with_event.result) {
          leg_with_event.sport_event['result'] = leg_with_event.result;
        }
      }
      return leg_with_event?.sport_event;
    } catch (e: any) {
      return null;
    }
  }

  /*
  Generates an index on each leg - required for Match pools grouping
   */
  uiAddTicketLegsIndex(pool: any): any {
    const _pool = _.cloneDeep(pool);
    for (let i = 0; i < _pool.legs.length; i++) {
      _pool.legs[i].leg_idx = i;
    }
    return _pool;
  }
}
