import { createReducer, on, Action } from '@ngrx/store';
import {TicketsScope, TicketsState} from "../../NGRX/tickets/tickets.model";
import {
  callGTList, callGTTicket, callSoloTicket, callSyndicateTicket,
  callSyndicateTicketList,
  callTicketList, failTicket, gtTicketListLoadMore,
  listLoadMore, loadGTTicketList, loadListDispatcher, loadSyndicateTicketList, loadTicketList,
  successCreateTicket,
  successGTTicket, successList,
  successShareTicket,
  successTicket, syndicateListLoadMore
} from "../../NGRX/tickets/tickets.actions";
import * as _ from 'lodash-es';
import {callSyndicate} from "../../NGRX/syndicates/syndicates.actions";

export const initialState: TicketsState = {
  list: undefined,
  current_ticket: undefined,
  group_ticket: undefined,
  pagination: {
    type: 'all',
    page: 1,
    per: 20,
    filters: {
      is_solo_bet: true,
      with_offer: false,
      status: 'live'
    }
  },
  isLoading: false,
  isSyndicate: false,
  unauthSecurityCode: undefined,
  ticketLoadError: false
}

const reducer = createReducer(
  initialState,
  on(callSyndicate, (state, action) => {
    return {
      ...state,
      current_ticket: undefined
    }
  }),
  on(successCreateTicket, (state, action) => {
    return {
      ...state,
      response: action.model
    }
  }),
  on(successShareTicket, (state, action) => {
    return {
      ...state,
      response: action.model
    }
  }),
  // ticket
  on(successTicket, (state, action) => {
    return {
      ...state,
      current_ticket: action.response
    }
  }),
  on(callGTList, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isSyndicate: false,
      pagination: {
        ...initialState.pagination,
        filters: Object.assign({}, initialState.pagination.filters, action.model?.filters)
      }
    }
  }),
  on(loadGTTicketList, (state, action) => {
    let filters: any = {};


    if (action.model?.type) {
      filters.filters = {};
      filters.filters.type = action.model?.type;
    }

    const pagination = _.isEmpty(action.model) ?
    Object.assign({}, state.pagination, { page: 1 }) :
    Object.assign({}, state.pagination, action.model, filters || {filters:  state.pagination.filters});

    return {
      ...state,
      list: undefined,
      pagination: pagination
    }
  }),
  on(successGTTicket, (state, action) => {
    return {
      ...state,
      isLoading: false,
      group_ticket: action.response
    }
  }),

  on(gtTicketListLoadMore, (state, action) => {
    const pagination: TicketsScope.Pagination = state.pagination || initialState.pagination;
    return {
      ...state,
      isLoading: true,
      pagination: Object.assign({}, pagination, {page: pagination.page + 1})
    }
  }),

  /* ticket list things */
  // before going to other list, clear the current list as the data doesn't match with the components
  on(loadListDispatcher, (state, action) => {
    return {
      ...state,
      list: {
        items: [],
        total_items: 0
      },
      isLoading: true,
    }
  }),

  on(loadTicketList, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isSyndicate: false,
      pagination: {
        ...initialState.pagination,
        filters: Object.assign({}, initialState.pagination.filters, action.model?.filters)
      }
    }
  }),
  on(callTicketList, (state, action) => {
      let filters = null;
      // const _state = Object.assign({}, state);
      // _state.list = undefined;
      if (action.model?.filters) {
        filters = Object.assign({}, state.pagination.filters, action.model?.filters);
      }

      const pagination = _.isEmpty(action.model) ?
        Object.assign({}, state.pagination, {page: 1}) :
        Object.assign({}, state.pagination, action.model, {filters: filters || state.pagination.filters});

      return {
        ...state,
        pagination: pagination
      }
  }),
  on(listLoadMore, (state, action) => {
    const pagination: any = state.pagination || initialState.pagination;
    return {
      ...state,
      isLoading: true,
      pagination: Object.assign({}, pagination, {page: pagination.page + 1})
    }
  }),
  // syndicate ticket list
  on(loadSyndicateTicketList, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isSyndicate: true,
      pagination: {
        ...initialState.pagination,
        filters: Object.assign({}, initialState.pagination.filters, action.model?.filters)
      }
    }
  }),
  on(callSyndicateTicketList, (state, action) => {
    let filters = null;
    if (action.model?.filters) {
      filters = Object.assign({}, state.pagination.filters, action.model?.filters);
    }

    const pagination = _.isEmpty(action.model) ?
      Object.assign({}, state.pagination, {page: 1}) :
      Object.assign({}, state.pagination, action.model, {filters: filters || state.pagination.filters});

    return {
      ...state,
      pagination: pagination
    }
  }),
  on(syndicateListLoadMore, (state, action) => {
    const pagination: TicketsScope.Pagination = state.pagination || initialState.pagination;
    return {
      ...state,
      pagination: Object.assign({}, pagination, {page: pagination.page + 1})
    }
  }),
  on(successList, (state, action) => {
    const oldList = _.cloneDeep(state.list);

    if(state.pagination.page > 1) {
      oldList.items = (<any[]>oldList.items).concat(action.response.items);
      oldList.total_items = action.response.total_items;
    }
    return {
      ...state,
      isLoading: false,
      list: state.pagination.page > 1 ? oldList : action.response
    }
  }),
  on(failTicket, (state, action) => {
    return {
      ...state,
      isLoading: false,
      ticketLoadError: true
    }
  }),

  // not applicable for tickets
  // on(partialUpdate, (state, action) => {
  //   return ws[action.event](state, action)
  // }),




  // to clear data between calls

  on(callSoloTicket, (state, action) => {
    return {
      ...state,
      isLoading: true,
      current_ticket: undefined
    }
  }),

  on(callSyndicateTicket, (state, action) => {
    return {
      ...state,
      isLoading: true,
      current_ticket: undefined
    }
  }),

  on(callGTTicket, (state, action) => {
    return {
      ...state,
      isLoading: true,
      group_ticket: undefined
    }
  })
)

export const ws = {
  // not applicable for tickets
  // POOL_INFO_CHANGED


  RELOAD_EVENTS: [
    // for TicketMiddleware
    'POOL_STATUS_CHANGED', 'UNIT_DISTRIBUTION_CHANGED', 'POOL_UPDATED',
    'USER_NEW_OFFER', 'USER_REMOVE_OFFER',
    'MICROSITE_AUTHENTICATION_REFRESH'
  ]
}


export function ticketReducer(
  state: TicketsState | undefined,
  action: Action
): TicketsState {
  return reducer(state, action);
}
