import { Injectable, NgModule } from '@angular/core';

import { UICommonModule } from '../../helpers/helpers.module';

import { SyndicateProfileRebateComponent } from './syndicate-profile-rebate';
import { SyndicateShareModule } from '../syndicate-share/syndicate-share.module';
export * from './syndicate-profile-rebate';

@Injectable()
@NgModule({
    imports: [
        UICommonModule,
        SyndicateShareModule
    ],
    declarations: [
        SyndicateProfileRebateComponent,
    ],
    exports: [
        SyndicateProfileRebateComponent,
        SyndicateShareModule
    ]
})

export class SyndicateProfileRebateModule {}
