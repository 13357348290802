import { Injectable } from '@angular/core';
import { WindowService } from '../window/window.service';
import { PostMessageService } from '../postmessage/postmessage.service';

@Injectable()
export class ScrollService {
  public _window: Window;

  constructor(private windowRef: WindowService, private _postMessageService: PostMessageService) {
    this._window = windowRef.nativeWindow;
  }

  scrollTop() {
    document.body.scrollTop = 0;
    this._window.scrollTo(0, 0);
    this._postMessageService.scroll({
      offset_x: 0,
      offset_y: 0
    });
  }
}
