import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, startWith, map, tap, filter } from 'rxjs/operators';


enum ScreenWidths {
  MobileMax = 500,//767, // make config for the app
  TabletMin = 500, //768,
  TabletMax = 991
}

@Injectable()
export class BreakpointNotifier {
  private screenWidths = ScreenWidths;
  private viewportMode$: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentScreen: string | undefined;

  constructor() { }

  init(): void {
    const obs = fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        filter((width: any) => width && width >= 320), //320px is iphone 4, all other are bigger
        startWith(window.innerWidth),
        tap(width => this.setBreakPoint(width)),
      );
    obs.subscribe(() => { });
  }

  setBreakPoint(width: any): void {
    if ((width > 300 && width < this.screenWidths.MobileMax) && this.currentScreen !== 'mobile') {
      this.currentScreen = 'mobile';
      this.viewportMode$.next(this.currentScreen);
    } else if ((width >= this.screenWidths.TabletMin && width <= this.screenWidths.TabletMax) && this.currentScreen !== 'tablet') {
      this.currentScreen = 'tablet';
      this.viewportMode$.next(this.currentScreen);
    } else if (width > this.screenWidths.TabletMax && this.currentScreen !== 'desktop') {
      this.currentScreen = 'desktop';
      this.viewportMode$.next(this.currentScreen);
    }
  }

  getBreakpoint(): BehaviorSubject<any> {
    return this.viewportMode$;
  }

}
