import { Component, Input, OnInit } from '@angular/core';
import {Config} from "@cbcore/services/config/config.service";

// import { P } from '@angular/core/src/render3';

@Component({
  selector: 'sport-sub-code',
  templateUrl: './sport-sub-code.html'
})
export class SportSubCodeComponent implements OnInit {
  @Input('pool') pool: any;
  @Input('flag') flag: boolean;
  @Input('showtime') showtime: boolean;
  @Input('racingTrack') racingTrack: boolean = true;

  public imagesUrl: string;

  constructor(private _config: Config) { }

  ngOnInit(): void {
    this.imagesUrl = this._config.CONFIG.imagesUrl;
  }

  getWeek(): string {
    let split: any = this.pool.sport_sub_code.split('_');
    let idx: number = (split.length > 0) ? (split.length - 1) : 0;
    return split[idx];
  }
}
