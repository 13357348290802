import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppState,
  RouterStateUrl,
  selectConfiguration,
  selectFilters, selectRouter,
  selectTicketFilters
} from '@cbcore/NGRX/core.state';

import { select, Store } from '@ngrx/store';
import {catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";

import {EMPTY, of} from "rxjs";

import {TicketClient} from "@cbcore/client/ticket";
import {GroupTicketClient} from "@cbcore/client/group_tickets";


import * as _ from 'lodash-es';
import { TicketsScope } from "@cbcore/NGRX/tickets/tickets.model";
import {
  callTicketList,
  successList,
  createTicket,
  callGTTicket,
  callSoloTicket,
  callSyndicateTicket,
  successCreateTicket,
  successTicket,
  successGTTicket,
  shareTicket,
  successShareTicket,
  failTicket,
  failTicketList,
  failGTTicketList,
  callGTList,
  successGTList,
  callUnAuthTicket,
  loadTicketList,
  loadSyndicateTicketList,
  callSyndicateTicketList,
  loadGTTicketList,
  loadListDispatcher,
  listLoadMore,
  syndicateListLoadMore,
  gtTicketListLoadMore,
  loadMoreDispatcher
} from "@cbcore/NGRX/tickets/tickets.actions";
import {initialState} from "@cbcore/NGRX/syndicates/syndicates.reducer";
import {selectPoolFilters, selectPoolsMenu} from "@cbcore/NGRX/pool/pool.selectors";
import {routerNavigatedAction, routerNavigationAction} from '@ngrx/router-store';
import {callPool, setPoolsExtraDetails} from '../pool/pool.actions';
import { SyndicatePortionClient } from "@cbcore/client/syndicate_portions";
import { Router } from '@angular/router';
import { setWebsocketEvent } from '../coreWebsockets/coreWebsockets.actions';
import { ws } from './tickets.reducer';
import { selectTicketState } from './tickets.selector';
import {empty} from "rxjs/internal/Observer";
import { loadComments } from '../syndicates/syndicates.actions';
import { callFollowers } from '../group-tickets/group-ticket.actions';


export function parseTicketsList(pools: any, groupTickets: any): any {
  const groupTicketItems = groupTickets;
  _.forEach(groupTicketItems.items, (item: any) => {
    if (pools[item.pool_id]) {
      item['pool'] = pools[item.pool_id];
    } else {
      console.info('The group ticket ' + item.group_ticket_id + ' doesn\'t have an associated pool.');
    }
  })
  return groupTicketItems;
}

@Injectable()

export class TicketsEffects {
  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    public ticketClient: TicketClient,
    public syndicatePortionClient: SyndicatePortionClient,
    public groupTicketClient: GroupTicketClient,
    private _router: Router
  ) {
  }

  //

  routernavigationTicketsList = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction, setPoolsExtraDetails),
      withLatestFrom(this.store.select(selectPoolFilters), this.store.select(selectConfiguration), this.store.select(selectRouter)),
      exhaustMap(([routerProps, poolFilters, config, router]) => {
        if (!router || !router.state.url.includes('/tickets/list')) {
          return EMPTY;
        } else {
          // to fix the error when display state is undefined
          try {
            const extra = poolFilters[config.displayState.view].LIVE.all;
            return of(loadListDispatcher({extra: extra}))
          } catch (error: any) {
            return EMPTY;
          }
        }
      })
    )
  }, {dispatch: true})

  loadListDispatcher = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadListDispatcher),
      withLatestFrom(this.store.select(selectRouter)),
      exhaustMap(([propVal, router]) => {
        if(!router) return EMPTY;

        const filters: any = {};
        if (propVal.extra.pool_types) {
          Object.assign(filters, propVal.extra);
        } else {
          filters.pool_types = propVal.extra;
        }

        const type = router.state.url.includes('/tickets/list/single') ?
          'single' : router.state.url.includes('/tickets/list/syndicates') ?
            'syndicates': router.state.url.includes('/tickets/list/group') ?
              'group' : 'single';

        switch(type) {
          case 'single':
            if (propVal.extra) {
              return of(loadTicketList({model: {filters: filters}}));
            }
            return of(loadTicketList({}));

          case 'syndicates':
            if (propVal.extra) {
              return of(loadSyndicateTicketList({model: {filters: filters}}));
            }
            return of(loadSyndicateTicketList({}));

          case 'group':
            if (router.state.url.includes('/tickets/list/group?show=invitations')) {
              return of(loadGTTicketList({model: {type: 'invited'}}))
            }
            return of(loadGTTicketList({model: {type: 'all'}}))
          default:
            return of(loadTicketList({model: {filters: filters}}));
        }
      })
    )
  }, {dispatch: true})

  loadMoreDispatcher = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMoreDispatcher),
      withLatestFrom(this.store.select(selectRouter)),
      exhaustMap(([propVal, router]) => {
        if(!router) return EMPTY;
        const type = router.state.url.includes('/tickets/list/single') ?
          'single' : router.state.url.includes('/tickets/list/syndicates') ?
            'syndicates': router.state.url.includes('/tickets/list/group') ?
              'group' : 'single';

        switch(type) {
          case 'single':
            return of(listLoadMore());

          case 'syndicates':
            return of(syndicateListLoadMore());

          case 'group':
            return of(gtTicketListLoadMore())
          default:
            return of(listLoadMore());
        }
      })
    )
  })

  /** load more effects */
  listLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(listLoadMore),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => of(callTicketList({ model: { page: pagination.page } })))
    )
  }, {dispatch: true})

  syndicateListLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(syndicateListLoadMore),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => of(callSyndicateTicketList({ model: { page: pagination.page } })))
    )
  }, {dispatch: true})

  gtTicketListLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(gtTicketListLoadMore),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => of(callGTList({ model: { page: pagination.page } })))
    )
  }, {dispatch: true})
  /** load more effects */

  loadList = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTicketList),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => of(callTicketList({ model: { filters: pagination.filters } })))
    )
  }, {dispatch: true})

  loadSyndicateTicketList = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSyndicateTicketList),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => of(callSyndicateTicketList({ model: { filters: pagination.filters } })))
    )
  }, {dispatch: true})

  loadGTTicketList = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadGTTicketList),
      withLatestFrom(this.store.select(selectTicketFilters)),
      exhaustMap(([propVal, pagination]) => {
        if (pagination.type) {
          return of(callGTList({ model: { filters: {type: pagination.type}, page: pagination.page, per: pagination.per } }))
        }
        return EMPTY;
      })
    )
  }, {dispatch: true})

  callList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callTicketList),
      withLatestFrom(this.store.select(selectTicketFilters)),
      switchMap(([propVal, filters]) => {
        return this.ticketClient.list(filters).pipe(
          switchMap(data => {
            return [successList({response: data})]
          })
        )
      }),
      catchError(error => {
        return of(failTicketList({error: error}));
      })
    )
  }, {dispatch: true})

  callSyndicateTicketList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callSyndicateTicketList),
      withLatestFrom(this.store.select(selectTicketFilters)),
      switchMap(([propVal, filters]) => {
        return this.syndicatePortionClient.list(filters).pipe(
          switchMap(data => {
            return [successList({response: data})]
          })
        )
      }),
      catchError(error => {
        return of(failTicketList({error: error}));
      })
    )
  }, {dispatch: true})

  // getList = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(callList),
  //     withLatestFrom(this.store.select(selectFilters)),
  //     switchMap(([propVal, filters]) => {
  //       return this.ticketClient.list(propVal.model).pipe(
  //         switchMap(data => {
  //           return [successList( { response: data })];
  //         }),
  //         catchError(error => {
  //           return of(failTicketList({ error: error }))
  //         })
  //       )
  //     })
  //   )
  // });

  /// aici tre vazut cu stefan ca am ceva combinatii in middleware

  getGTList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callGTList),
      withLatestFrom(this.store.select(selectPoolsMenu)),
      switchMap(([propVal, poolsMenu]) => {
        return this.groupTicketClient.getGroupTickets(propVal.model).pipe(
          switchMap(data => {
            const parsedList = parseTicketsList(poolsMenu.pools, data);
            return [successList({response: parsedList})]
          }),
          catchError(error => {
            return of(failTicketList({ error: error }))
          })
        )
      })
    )
  });


  ///
  // setFilters = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(setFilters),
  //     withLatestFrom(this.store.select(selectFilters)),
  //     switchMap(([propVal, filters]) => {
  //       const newFilters: any = _.cloneDeep(Object.assign({}, filters));
  //       newFilters.filters = propVal.model;
  //       return [successFilters({response: newFilters}), callList({model: newFilters})]
  //     })
  //   )
  // })
  //
  // clearFilters = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(clearFilters),
  //     switchMap((propVal) => {
  //       const newFilters: any = _.cloneDeep(Object.assign({}, initialState.list_pagination));
  //       return [successFilters({response: newFilters}), callList({model: newFilters})]
  //     })
  //   )
  // })

  ///

  callSoloTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(callSoloTicket),
      exhaustMap((propVal: any) => {
        return this.ticketClient.get(propVal.ticket_id).pipe(
          map((data: TicketsScope.TicketItem) => {
            return successTicket( { response: data });
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  })

  callSyndicateTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(callSyndicateTicket),
      exhaustMap((propVal: any) => {
        return this.syndicatePortionClient.get(propVal.portion_id).pipe(
          map((data: TicketsScope.TicketItem) => {
            return successTicket( { response: data });
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  })

  callUnAuthTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(callUnAuthTicket),
      switchMap((propVal: any) => {
        return this.ticketClient.unauthTicket(propVal.merchant_ref, propVal.security_code).pipe(
          switchMap((data: TicketsScope.TicketItem) => {
            return [
              callPool({
                id: data.pool_id
              }),
              successTicket( { response: data })
            ];
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true })

  failTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(callUnAuthTicket),
      tap((propVal: any) => {
        this._router.navigate(['/', 'errors', 'ticketnotfound']);
      })
    )
  }, { dispatch: false })

  // KEPT FOR COMPATIBILITY
  successTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(successTicket),
      exhaustMap((propVal) => {
        // era la loadSummaryTicket
        //       if (data.curr_mult) {
        //         this.store.dispatch(setCustomMultiplier({curr_mult: data.curr_mult}));
        //       }
        // this.ticketMiddleWare.updateData(propVal.response);

        if (propVal?.response?.syndicate && propVal.response.syndicate.customer_portion !== '0.0') {

          return of(loadComments({params: {
              page: 1,
              model_id: String(propVal.response.syndicate.id),
              feed_type: 'syndicate'
            }}))
        }

        return EMPTY;
      })
    )
  }, { dispatch: true })

  getGTTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(callGTTicket),
      withLatestFrom(this.store.select(selectRouter)),
      switchMap(([propVal, routerState]) => {
        // console.log('no state 13', routerState);

        return this.groupTicketClient.getGroupTicket(propVal).pipe(
          switchMap((data: TicketsScope.GroupTicketItem) => {
            return [
              callFollowers({model: {pool_id: routerState.state.params['pool_id'], group_ticket_id: data.id}}),
              successGTTicket( { response: data })
            ];
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true })

  // KEPT FOR COMPATIBILITY
  // successGTTicket = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(successGTTicket),
  //     tap((propVal) => {
  //       this.grouptTicketMiddleware.updateData(propVal.response);
  //     })
  //   )
  // }, { dispatch: false })

  ///
  // what's this?
  createTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(createTicket),
      switchMap((model: any) => {
        return this.ticketClient.create(model).pipe(
          switchMap(data => {
            return [successCreateTicket( { model: data })]
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  }, { dispatch: false });

  ////

  shareTicket = createEffect(() => {
    return this.actions$.pipe(
      ofType(shareTicket),
      switchMap((model: any) => {
        return this.ticketClient.create(model).pipe(
          switchMap(data => {
            return [successShareTicket( { model: data })]
          }),
          catchError(error => {
            return of(failTicket({ error: error }))
          })
        )
      })
    )
  });

  /// item router events

  routerNavigationTicketItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => propVal.payload.event.url.includes('/tickets')),
      switchMap((routerProps) => {

        const routerState: RouterStateUrl = (routerProps.payload.routerState as unknown as RouterStateUrl);

        // /tickets

        // TicketsSoloView - solo/:ticket_id/pools/:pool_id
        if (routerState.url.includes('/tickets/solo')) {
          const pool_id = routerState.params.pool_id;
          const ticket_id = routerState.params.ticket_id;

          const token: string = routerState.queryParams.token;
          const siteId = routerState.queryParams.siteId;

          if (token && siteId) {
            return [
              callPool({id: pool_id}),
              // ticketMiddleware.loadSummaryTicket(ticket_id, token, siteId)
              // ticket_type = 'SUMMARY'
            ];
          } else {
            return [
              callPool({id: pool_id}),
              // ticketMiddleware.loadTicket(ticket_id)
              // ticket_type = 'TICKET'
              callSoloTicket({
                ticket_id: ticket_id
              })
            ];
          }
        }

        // TicketsGroupView - group/:manager_id/pools/:pool_id
        // inside is TicketsGroupFullView, which gets the ticket, some ws events and redirects to syndicate or results or error?
        if (routerState.url.includes('/tickets/group')) {
          const pool_id = routerState.params.pool_id;
          const manager_id = routerState.params.manager_id;

          return [
            callPool({id: pool_id}),
            // also loaded on: PoolsViewGroupView & PoolCouponComponent for some reason. loadGroupTicketInfo is something else
            // groupTicketMiddleware.loadGroupTicket({
            //   manager_id: manager_id,
            //   pool_id: pool_id
            // })
            callGTTicket({
              pool_id: pool_id,
              manager_id: manager_id
            })
          ];
        }

        // TicketsSyndicateItemView - syndicate/:syndicate_id/pools/:pool_id
        if (routerState.url.includes('/tickets/syndicate')) {
          const pool_id = routerState.params.pool_id;
          const syndicate_id = routerState.params.syndicate_id;
          return [
            callPool({id: pool_id}),
            callSyndicateTicket({
              portion_id: syndicate_id
            })
            // ticketMiddleware.loadSyndicateTicket(syndicate_id)
            // TODO: when authentication changes, loadSyndicateTicket is called again, can be called again of ws event
            // ticket_type === 'SYNDICATE'
          ]
        }

        // TicketsUnauthItemView - unauth/:ticket_ref/code/:security_code
        if (routerState.url.includes('/unauth') && routerState.url.includes('/code')) {
          const ticket_ref = routerState.params.ticket_ref;
          const security_code = routerState.params.security_code;

          return [
            // ticketMiddleware.loadUnauthTicket(ticket_ref, ticket_code)
            callUnAuthTicket({
              merchant_ref: ticket_ref,
              security_code: security_code,
            })
          ]
        }

        // what's the point for this?
        // TicketsSummaryView - :ticket_id/pools/:pool_id
        // if (routerState.url.includes('/pools/') && (routerState.url.includes('syndicateonly') || routerState.url.includes('ticketonly'))) {
        //   const pool_id = routerState.params.pool_id;
        //   const ticket_id = routerState.params.ticket_id;

        //   const token: string = routerState.queryParams.token;
        //   const siteId: string = routerState.queryParams.siteID;
        //   const isSyndicate = routerState.url.includes('syndicateonly') && !routerState.url.includes('ticketonly'); // TicketsSummaryIndex does ticketMiddleware.getTicket() and if it gets a value, navigates to 'ticketonly'

        //   if (token && siteId) {
        //     return [
        //       callPool({id: pool_id}),
        //       // ticketMiddleware.loadSummaryTicket(ticket_id, token, siteId, isSyndicate);
        //       // ticket_type = 'SUMMARY'
        //       // in some cases: poolMiddlware.loadPoolSummary(data.pool)
        //     ]
        //   } else if (isSyndicate) {
        //     return [
        //       callPool({id: pool_id}),
        //       // ticketMiddleware.loadSyndicateTicket(ticket_id)
        //     ]
        //   } else {
        //     return [
        //       callPool({id: pool_id}),
        //       // ticketMiddleware.loadTicket(ticket_id)
        //     ]
        //   }
        // }

        return EMPTY;

      }))
  }, { dispatch: true });

  ///// LIVE UPDATE
  setWebsocketEvent = createEffect(() => {
    return this.actions$.pipe(
      ofType(setWebsocketEvent),
      filter(propVal => ws.RELOAD_EVENTS.concat(Object.keys(ws)).includes(propVal.event)),
      withLatestFrom(this.store.select(selectTicketState)),
      switchMap(([propVal, ticketsState]) => {
        const isSyndicate = !!ticketsState?.current_ticket?.syndicate;
        // for TicketMiddleware
        try {
          if (ticketsState.current_ticket && ws.RELOAD_EVENTS.includes(propVal.event) &&
            (('MICROSITE_AUTHENTICATION_REFRESH' === propVal.event) || (
              Number(ticketsState.current_ticket.pool_id) === Number(propVal.data.object_id) ||
              Number(ticketsState.current_ticket.pool_id )=== Number(propVal.data.pool_id)
            ))) {
            return [
              isSyndicate ?
                callSyndicateTicket({
                  portion_id: Number(ticketsState.current_ticket.syndicate.stringified_id)
                }) :
                callSoloTicket({
                  ticket_id: ticketsState.current_ticket.stringified_id
                })
            ].filter(action => !!action);
          }
        } catch (e: any) {
          return EMPTY;
        }

        // if (ticketsState.current_ticket && ('MICROSITE_AUTHENTICATION_REFRESH' === propVal.event)) {
        //   return [
        //     isSyndicate ?
        //     callSyndicateTicket({
        //       portion_id: Number(ticketsState.current_ticket.syndicate.id)
        //     }) :
        //     callSoloTicket({
        //       ticket_id: ticketsState.current_ticket.id
        //     })
        //   ].filter(action => !!action);
        // }

        // For TicketsList

        return EMPTY;
        // not applicable for ticket
        // return of(partialUpdate({
        //   data: propVal.data,
        //   event: propVal.event
        // }))
      })
    )
  }, { dispatch: true })

}
