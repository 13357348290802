export const LANGUAGE_MAPPING: any = {
  'en-AU' : 'en',
  'en-BZ' : 'en',
  'en-CA' : 'en',
  'en-CB' : 'en',
  'en-IE' : 'en',
  'en-JM' : 'en',
  'en-NZ' : 'en',
  'en-PH' : 'en',
  'en-ZA' : 'en',
  'en-TT' : 'en',
  'en-GB' : 'en',
  'en-US' : 'en',
  'en-ZW' : 'en',
  'et-EE' : 'et',
  'fr-FR' : 'fr',
  'fr-CA' : 'fr',
  'fr-LU' : 'fr',
  'fr-MC' : 'fr',
  'fr-CH' : 'fr',
  'cs-CZ' : 'cs',
  'ru-RU' : 'ru',
  'tr-TF' : 'tr',
  'hy-AM' : 'hy',
  'br-BR' : 'pt-BR',
  'pl-PL' : 'pl',
  'de-DE' : 'de',
  'sv-SE' : 'sv',
  'fi-FI' : 'fi',
  'hu-HU' : 'hu',
  'nn-NO' : 'nb',
  'no-NO' : 'nb',
  'nb-NO' : 'nb',
  'no'    : 'nb',
  'es-ES' : 'es',
  'es-PE' : 'es-PE',
  'es-CO' : 'es-CO',
  'pt-PT' : 'pt',
  'en-NL' : 'en-NL',
  'nl-BE' : 'nl',
  'fr-BE' : 'fr-BE',
  'ger'   : 'de',
  'gre'   : 'el',
  'eng'   : 'en',
  'est'   : 'et',
  'spa'   : 'es',
  'fre'   : 'fr',
  'geo'   : 'ge',
  'hun'   : 'hu',
  'arm'   : 'hy',
  'jpn'   : 'ja',
  'kor'   : 'ko-KR',
  'nor'   : 'nb',
  'dut'   : 'nl',
  'pol'   : 'pl',
  'por'   : 'pt',
  'pt-br' : 'pt-BR',
  'rus'   : 'ru',
  'swe'   : 'sv',
  'tur'   : 'tr',
  'ukr'   : 'uk-UA',
  'chi'   : 'zh-TW',
  'zho'   : 'zh-TW',
  'zhh'   : 'zh-CN',
  'am'    : 'am'
};
