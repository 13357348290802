import { createReducer, on, Action } from '@ngrx/store';
import { ManagersScope, ManagersState} from "./manager.model";
import {
  getLeaderboard,
  getManager,
  loadLeaderboardWidget, loadTopPositiveROI,
  removePinnedSyndicates,
  setPinnedSyndicates,
  successLeaderboard,
  successManager,
  successManagersList,
  // successMyManager,
  successTopFollowed, successTopPositiveROI,
  successUpdateManager
} from "../../NGRX/manager/manager.actions";
import {successCoreSettings} from "@cbcore/NGRX/configuration/configuration.actions";
import {successMicrositeSessions} from "@cbcore/NGRX/coreAuth/coreAuth.actions";
import * as _ from 'lodash-es';

export const initialState: ManagersState = {
  list: undefined,
  listParams: {
    per: 6,
    page: 1,
    filters: {
      followed: true
    }
  },
  manager: undefined,

  my_manager: undefined,

  leaderboardParams: {
    per: 20,
    page: 1,
    sort_by: 'roi',
    open_syndicates: false
  },
  leaderboardWidgetParams: {
    per: 4,
    page: 1,
    sort_by: 'roi',
    open_syndicates: false
  },
  leaderboard: undefined,
  top_followed: undefined,
  top_positive_roi: undefined
};

const reducer = createReducer(
  initialState,
  on(successManagersList, (state, action) => {
    const data = action.response;

    if(!data) return {
      ...state,
      list: undefined
    };

    const managers: ManagersScope.ManagersListParsed = {
      total: data.total_items,
      syndicates: _.orderBy(_.filter(data.items, (e) => e.open_syndicates_number > 0), ['open_syndicates_number'], ['desc']),
      noSyndicates: _.orderBy(_.filter(data.items, (e) => e.open_syndicates_number < 1), [user => user.username.toLowerCase()], ['asc'])
    };

    if(action.params.page > 1) {
      managers.syndicates = state.list.syndicates.concat(managers.syndicates);
      managers.noSyndicates = state.list.noSyndicates.concat(managers.noSyndicates);
    }

    return {
      ...state,
      list: managers
    }
  }),
  on(successMicrositeSessions, (state, action) => {
    return {
      ...state,
      my_manager: {
        ...state.my_manager,
        id: action.response.manager_id
      }
    }
  }),
  on(successManager, (state, action) => {
    const loadedManager = action.response;
    const isMyManager = (state.my_manager?.id === action.response?.id);
    return {
      ...state,
      my_manager: isMyManager ?  loadedManager : state.my_manager,
      manager: !isMyManager ?  loadedManager : state.manager,
    }
  }),
  // on(successMyManager, (state, action) => {
  //   return {
  //     ...state,
  //     manager: action.response
  //   }
  // }),
  on(successUpdateManager, (state, action) => {
    return {
      ...state,
      my_manager: {
        ...state.my_manager,
        justUpdated: true
      }
    }
  }),
  on(getManager, (state, action) => {
    return {
      ...state,
      my_manager: {
        ...state.my_manager,
        justUpdated: false
      }
    }
  }),
  on(successTopFollowed, (state, action) => {
    return {
      ...state,
      top_followed: action.response
    }
  }),
  // on(successTopFollowed, (state, action) => {
  //   return {
  //     ...state,
  //     top_followed: action.response
  //   }
  // }),

  on(removePinnedSyndicates, (state, action) => {
    return {
      ...state,
      my_manager: {
        ...state.my_manager,
        pinnedSyndicates: []
      }
    }
  }),

  on(setPinnedSyndicates, (state, action) => {
    return {
      ...state,
      my_manager: {
        ...state.my_manager,
        pinnedSyndicates: action.id ? [action.id] : []
      }
    }
  }),

  // LEADERBOARD
  on(successCoreSettings, (state, action) => {
    return {
      ...state,
      leaderboardParams: {
        ...state.leaderboardParams,
        period: state.leaderboardParams?.period || _.head(action.response.periods)
      },
      leaderboardWidgetParams: {
        ...state.leaderboardWidgetParams,
        period: state.leaderboardParams?.period || _.head(action.response.periods)
      }
    }
  }),

  on(getLeaderboard, (state, action) => {
    if(!action.model.isWidget) {
      return {
        ...state,
        leaderboardParams: _.isEmpty(action.model) ? Object.assign({}, initialState.leaderboardParams, { period: state.leaderboardParams.period }) : Object.assign({}, state.leaderboardParams, action.model)
      }
    } else {
      return {
        ...state,
        leaderboardWidgetParams: _.isEmpty(action.model) ? Object.assign({}, initialState.leaderboardWidgetParams, { period: state.leaderboardWidgetParams.period }) : Object.assign({}, state.leaderboardWidgetParams, action.model)
      }
    }
  }),

  on(successLeaderboard, (state, action) => {
    const nextPageLeaderboard = state.leaderboardParams.page !== 1 ? {
      items: _.uniq(state.leaderboard.items.concat(action.response.items)),
      total_items: action.response.total_items
    } : null;

    return {
      ...state,
      leaderboard: nextPageLeaderboard ? nextPageLeaderboard : action.response
    }
  }),

  on(loadLeaderboardWidget, (state, action) => {
    return {
      ...state,
      leaderboardWidgetParams: {
        ...state.leaderboardWidgetParams,
        sportId: action.sportId
      }
    }
  }),

  on(loadTopPositiveROI, (state, action) => {
    return {
      ...state,
      leaderboardWidgetParams: {
        ...state.leaderboardWidgetParams,
        sportId: action.sportId
      }
    }
  }),

  on(successTopPositiveROI, (state, action) => {
    return {
      ...state,
      top_positive_roi: action.response
    }
  }),
);

export function managersReducer(
  state: ManagersState | undefined,
  action: Action
): ManagersState {
  return reducer(state, action);
}
