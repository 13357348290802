
import { ActionReducerMap, createFeatureSelector, MetaReducer, StoreModule } from '@ngrx/store';
import { RouterReducerState, StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { APP_CONFIG } from 'src/app/config/mobile.config.model';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { LocalStorageService } from './meta-reducers/local-storage.service';

import { RouterStateUrl } from './router/router.state';
import { CustomSerializer } from './router/custom-serializer';

import { micrositeSessionsReducer } from './coreAuth/coreAuth.reducer';
import { MicrositeSessionsState } from './coreAuth/coreAuth.models';

import { configurationReducer } from './configuration/configuration.reducer';
import { ConfigurationState } from './configuration/configuration.models';

import { micrositeTrackingReducer } from './coreTracking/coreTracking.reducer';
import { MicrositeTrackingState } from './coreTracking/coreTracking.models';

import { micrositeWebsocketsReducer } from './coreWebsockets/coreWebsockets.reducer';
import { MicrositeWebsocketsState } from './coreWebsockets/coreWebsockets.models';

import { micrositePoolsReducer } from './pool/pool.reducer';
import { MicrositePoolsState } from './pool/pool.models';


// export const selectMicrositeSessionsState = createFeatureSelector<
//   AppState,
//   MicrositeSessionsState
// >('micrositeSessions');

// export const selectMicrositeNotificationState = createFeatureSelector<
//   AppState,
//   MicrositeNotificationState
// >('micrositeNotification');

// export const selectMicrositeWebsocketsState = createFeatureSelector<
//   AppState,
//   MicrositeWebsocketsState
// >('micrositeWebsockets');

// export const selectMicrositeTrackingState = createFeatureSelector<
//   AppState,
//   MicrositeTrackingState
// >('micrositeTracking');

// export const selectManagersState = createFeatureSelector<AppState, ManagersState>('managers');


/* ROUTER */
// export const selectRouterState = createFeatureSelector<
//   AppState,
//   RouterReducerState<RouterStateUrl>
// >('router');

/* CONFIGURATION */
// export const selectConfigurationState = createFeatureSelector<
//   AppState,
//   ConfigurationState
// >('configuration');

export const reducers: ActionReducerMap<AppState> = {
  micrositePools: micrositePoolsReducer,

  micrositeWebsockets: micrositeWebsocketsReducer,
  micrositeTracking: micrositeTrackingReducer,
  micrositeSessions: micrositeSessionsReducer,
  // micrositeNotification: micrositeNotificationReducer,

  router: routerReducer,
  configuration: configurationReducer,
  managers: managersReducer,
  syndicates: syndicatesReducer,
  tickets: ticketReducer,
  groupTickets: groupTicketReducer,
  freeplay: freeplayReducer


  // walletModal: walletModalReducer,
  // wallet: walletReducer,
  // walletUsersRegistration: walletUsersRegistrationReducer,
  // walletPayments: walletPaymentsReducer,
  // walletProtection: walletProtectionReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if(APP_CONFIG.enableRxDebug) {
  metaReducers.unshift(debug);
}

export interface AppState {
  micrositePools: MicrositePoolsState

  micrositeWebsockets: MicrositeWebsocketsState
  micrositeTracking: MicrositeTrackingState
  micrositeSessions: MicrositeSessionsState
  // micrositeNotification: MicrositeNotificationState

  router: RouterReducerState<RouterStateUrl>
  configuration: ConfigurationState,
  managers: ManagersState,
  syndicates: SyndicatesState,
  tickets: TicketsState,
  groupTickets: GroupTicketState,
  freeplay: FreeplayState
}

///////// EXPORTS /////////

import { selectRouter } from './router/router.selector';
import { closeSecondaryMenu, previousRoute } from './router/router.actions';
import { selectConfiguration, selectConfigurationCore, selectConfigurationLocal } from './configuration/configuration.selectors';
import { callCoreSettings, failConfiguration, successCoreSettings } from './configuration/configuration.actions';
// import { userLogin } from './coreNotifications/coreNotifications.actions';
import { selectMicrositeWebsockets } from './coreWebsockets/coreWebsockets.selectors';
import { selectTracking } from './coreTracking/coreTracking.selectors';
import { clearPixel, replaceState, setPixel, track } from './coreTracking/coreTracking.actions';
// import { selectCurrentPool, selectPools, selectPoolsMenu } from './pool/pool.selectors';
// import { callPoolsMenu, callWalletMyAccount, failPools, successPoolsMenu, toggleBalance } from './pool/pool.actions';
import { selectMicrositeSessions, selectMicrositeSessionsIsLoggedIn, selectMicrositeSessionsLoading } from './coreAuth/coreAuth.selectors';
import { callMicrositeRefreshTokens, callMicrositeSessions, failMicrositeSessions, setCustomMultiplier, setMicrositeAuthTokenAuthorizationCode, setOptionalData, successMicrositeRefreshTokens, successMicrositeSessions, triggerRegistration, triggerSignIn } from './coreAuth/coreAuth.actions';
// Managers
import { ManagersState} from "@cbcore/NGRX/manager/manager.model";
import {managersReducer} from "@cbcore/NGRX/manager/manager.reducer";
import {selectManagersList, selectCurrentManager, selectMyManager, selectLeaderboard, selectTopFollowed} from "@cbcore/NGRX/manager/manager.selectors";
// Syndicates
import { SyndicatesState } from "@cbcore/NGRX/syndicates/syndicates.model";
import { syndicatesReducer } from "@cbcore/NGRX/syndicates/syndicates.reducer";
import { selectSyndicate, selectFilters, selectList, selectWinList } from "@cbcore/NGRX/syndicates/syndicates.selectors";
// Tickets
import { selectTicketsList, selectTicket, selectTicketFilters } from "@cbcore/NGRX/tickets/tickets.selector";
import {ticketReducer} from "@cbcore/NGRX/tickets/tickets.reducer";
import {TicketsState} from "@cbcore/NGRX/tickets/tickets.model";
import {GroupTicketState} from "@cbcore/NGRX/group-tickets/group-tickets.model";
import {groupTicketReducer} from "@cbcore/NGRX/group-tickets/group-ticket.reducer";
import { selectFollowers, selectInfo, selectCreationState, selectSelectionsState, selectShowVotes } from "@cbcore/NGRX/group-tickets/group-ticket.selectors";
//Freeplay
import {freeplayReducer} from "@cbcore/NGRX/freeplay/freeplay.reducer";
import {FreeplayState} from "@cbcore/NGRX/freeplay/freeplay.model";
import { selectFreeplayList, selectFreeplayToken, selectFreeplayListError, selectFreeplayTokenError, freeplayListLoading} from "@cbcore/NGRX/freeplay/freeplay.selectors";
// micrositeSessions store
export {
    // selectors
    selectMicrositeSessions,
    selectMicrositeSessionsLoading,
    selectMicrositeSessionsIsLoggedIn,

    // actions
    callMicrositeSessions,
    successMicrositeSessions,
    setOptionalData,
    setCustomMultiplier,
    callMicrositeRefreshTokens,
    successMicrositeRefreshTokens,
    setMicrositeAuthTokenAuthorizationCode,
    failMicrositeSessions,
    triggerSignIn,
    triggerRegistration,
  };

  // micrositeNotification store
  // export {
    // selectors
    // not implemented

    // actions
    // userLogin // not sure if used
  // };

  // micrositeWebsockets store
  export {
    // selectors
    selectMicrositeWebsockets,

    // actions
    // only effects used
  };

  // micrositeTracking store
  export {
    // selectors
    selectTracking,

    // actions
    track,
    replaceState,
    setPixel,
    clearPixel
  };

  // micrositePools store
  export {
    // selectors
    // selectPools,
    // selectPoolsMenu,
    // selectCurrentPool,

    // actions
    // callWalletMyAccount,
    // toggleBalance,
    // callPoolsMenu,
    // successPoolsMenu,
    // failPools
  };

  ///////////////////// CONFIGURATION & AUTH ////////////////////////

  // configuration store
  export {
    // selectors
    selectConfiguration,
    selectConfigurationCore,
    selectConfigurationLocal,

    // actions
    callCoreSettings,
    successCoreSettings,
    failConfiguration
  };

  // router store
  export {
    // models
    RouterStateUrl,

    // selectors
    selectRouter,

    // actions
    previousRoute,
    closeSecondaryMenu
  };

// managers Store
export {
  selectManagersList, selectCurrentManager, selectMyManager, selectLeaderboard, selectTopFollowed
}
// syndicates store
export {
  selectSyndicate, selectFilters, selectList, selectWinList
}

export {
  selectTicketsList, selectTicket, selectTicketFilters
}

export {
  selectFollowers, selectInfo, selectCreationState, selectSelectionsState, selectShowVotes
}

export {
  selectFreeplayList, selectFreeplayToken, selectFreeplayListError, selectFreeplayTokenError, freeplayListLoading
}

// import { NgModule, Injectable } from '@angular/core';
// import { EffectsModule } from '@ngrx/effects';
// import { MicrositeWebsocketsEffects } from './coreWebsockets/coreWebsockets.effects';
// import { TrackingEffects } from './coreTracking/coreTracking.effects';
// import { MicrositeNotificationEffects } from './coreNotifications/coreNotifications.effects';
// import { MicrositeSessionsEffects } from './coreAuth/coreAuth.effects';
// import { RouterEffects } from './router/router.effects';
// import { ConfigurationEffects } from './configuration/configuration.effects';
// import { MicrositePoolsEffects } from './pool/pool.effects';


// @Injectable()
// @NgModule({
//   declarations: [],
//   imports: [
//     StoreModule.forRoot(reducers),
//     StoreRouterConnectingModule.forRoot({
//       serializer: CustomSerializer
//     }),
//     EffectsModule.forRoot([
//       MicrositePoolsEffects,

//       MicrositeWebsocketsEffects,
//       TrackingEffects,
//       MicrositeNotificationEffects,
//       MicrositeSessionsEffects,

//       RouterEffects,
//       ConfigurationEffects
//     ])
//   ],
//   exports: [

//   ],
//   providers: []
// })
// export class CoreStateModule { }

