import { Injectable }  from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Config } from './../config/config.service';
import {selectMicrositeSessionsIsLoggedIn} from "@cbcore/NGRX/coreAuth/coreAuth.selectors";
import {filter, take, switchMap, catchError, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";

@Injectable()
export class TicketsRouteGuard implements CanActivate {
  rootUrl: string;

  constructor(
    private _config: Config,
    private _router: Router,
    private store: Store
  ) {
    this.rootUrl = this._config.CONFIG.rootUrl;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getAuthState().pipe(
      switchMap(() => of(true)),
      catchError(() => {
        return of(false);
      })
    );
  }

  private getAuthState() {
    return this.store.select(selectMicrositeSessionsIsLoggedIn).pipe(
      tap(data => this.prefetch(data)),
      filter(data => !!data),
      take(1)
    );
  }

  prefetch(data: boolean) {
    if (!data) {
      this._router.navigate([this.rootUrl]);
      this._config.login();
    }
  }
}
