import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  private prefix = 'cb_';

  constructor() { }

  private getKey(key: any) {
    return `${this.prefix}${key}`;
  }

  public callGetKey(key: any) {
    return this.getKey(key);
  }

  public async get(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        let value = c.substring(cookieName.length, c.length);
        value = decodeURIComponent(value);
        try {
          return JSON.parse(value);
        } catch (err) {
          return value;
        }
      }
    }
    return null;
  }

  public async remove(name: any) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
  }

  public async set(name: string, value: any, expireDays: number = 30) {
    value = encodeURIComponent(JSON.stringify(value));
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires: string = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires + '; path=/';
  }
}
