import {NgModule, Injectable, ModuleWithProviders} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
// import { BrowserModule } from "@angular/platform-browser";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ConfigModule } from './services/config/config.module';
import { GuardsModule } from './services/guards/guards.module';
import { ClientModule } from './services/client/client.module';
import { TranslationModule } from './services/translation/translation.module';
import { WebsocketsModule } from './services/websockets/websockets.module';
import { SelectionsModule } from './services/selections/selections.module';
import { StakeModule } from './services/stake/stake.module';
// import { AuthModule } from './services/auth/auth.module';
import { UtilsModule } from './services/utils/utils.module';
import { MiddlewareModule } from './middlewares/middleware.module';
import { PipesModule } from './pipes/pipes.module';
import { SentryModule } from "./services/sentry/sentry.module";

// NGRX
import { ActionReducerMap, createFeatureSelector, MetaReducer, StoreModule } from '@ngrx/store';
import { RouterReducerState, StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { APP_CONFIG } from 'src/app/config/mobile.config.model';
import { debug } from './NGRX/meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './NGRX/meta-reducers/init-state-from-local-storage.reducer';
import { LocalStorageService } from './NGRX/meta-reducers/local-storage.service';

import { RouterStateUrl } from './NGRX/router/router.state';
import { CustomSerializer } from './NGRX/router/custom-serializer';

import { micrositeSessionsReducer } from './NGRX/coreAuth/coreAuth.reducer';
import { MicrositeSessionsState } from './NGRX/coreAuth/coreAuth.models';

// import { micrositeNotificationReducer } from './NGRX/coreNotifications/coreNotifications.reducer';
// import { MicrositeNotificationState } from './NGRX/coreNotifications/coreNotifications.models';

import { configurationReducer } from './NGRX/configuration/configuration.reducer';
import { ConfigurationState } from './NGRX/configuration/configuration.models';

import { micrositeTrackingReducer } from './NGRX/coreTracking/coreTracking.reducer';
import { MicrositeTrackingState } from './NGRX/coreTracking/coreTracking.models';

import { micrositeWebsocketsReducer } from './NGRX/coreWebsockets/coreWebsockets.reducer';
import { MicrositeWebsocketsState } from './NGRX/coreWebsockets/coreWebsockets.models';
import { EffectsModule } from '@ngrx/effects';
import { MicrositeWebsocketsEffects } from './NGRX/coreWebsockets/coreWebsockets.effects';
import { TrackingEffects } from './NGRX/coreTracking/coreTracking.effects';
// import { MicrositeNotificationEffects } from './NGRX/coreNotifications/coreNotifications.effects';
import { MicrositeSessionsEffects } from './NGRX/coreAuth/coreAuth.effects';
import { RouterEffects } from './NGRX/router/router.effects';
import { ConfigurationEffects } from './NGRX/configuration/configuration.effects';
import { micrositePoolsReducer } from './NGRX/pool/pool.reducer';
import { MicrositePoolsState } from './NGRX/pool/pool.models';
import { selectRouter } from './NGRX/router/router.selector';
import { previousRoute } from './NGRX/router/router.actions';
import { selectConfiguration, selectConfigurationCore, selectConfigurationLocal } from './NGRX/configuration/configuration.selectors';
import { callCoreSettings, failConfiguration, successCoreSettings } from './NGRX/configuration/configuration.actions';
// import { userLogin } from './NGRX/coreNotifications/coreNotifications.actions';
import { selectMicrositeWebsockets } from './NGRX/coreWebsockets/coreWebsockets.selectors';
import { selectTracking } from './NGRX/coreTracking/coreTracking.selectors';
import { clearPixel, replaceState, setPixel, track } from './NGRX/coreTracking/coreTracking.actions';
// import { selectCurrentPool, selectPools, selectPoolsMenu } from './NGRX/pool/pool.selectors';
import { callPoolsMenu, failPools, successPoolsMenu } from './NGRX/pool/pool.actions';
import { selectMicrositeSessions, selectMicrositeSessionsLoading } from './NGRX/coreAuth/coreAuth.selectors';
import { callMicrositeRefreshTokens, callMicrositeSessions, failMicrositeSessions, setMicrositeAuthTokenAuthorizationCode, setOptionalData, successMicrositeRefreshTokens, successMicrositeSessions, triggerRegistration, triggerSignIn } from './NGRX/coreAuth/coreAuth.actions';
import { MicrositePoolsEffects } from './NGRX/pool/pool.effects';
import { ManagerEffects } from './NGRX/manager/manager.effects';


import {
  reducers,
  metaReducers,
  // CoreStateModule,
} from './NGRX/core.state';
import { SyndicateEffects } from './NGRX/syndicates/syndicates.effects';
import { GroupTicketEffects } from './NGRX/group-tickets/group-ticket.effect';
import { TicketsEffects } from './NGRX/tickets/tickets.effects';
import {FreeplayEffects} from "./NGRX/freeplay/freeplay.effects";

@Injectable()
@NgModule({
  declarations: [],
  imports: [



    // NGRX
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    EffectsModule.forRoot([
      MicrositePoolsEffects,

      MicrositeWebsocketsEffects,
      TrackingEffects,
      // MicrositeNotificationEffects,
      MicrositeSessionsEffects,
      ManagerEffects,
      SyndicateEffects,

      TicketsEffects,
      GroupTicketEffects,

      RouterEffects,
      ConfigurationEffects,
      FreeplayEffects
    ]),

    // CoreStateModule,

    // BrowserModule,
    SentryModule,
    ConfigModule,
    GuardsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ClientModule,
    TranslationModule,
    WebsocketsModule,
    SelectionsModule,
    StakeModule,
    // AuthModule,
    UtilsModule,
    MiddlewareModule,
    PipesModule
  ],
  exports: [
    // BrowserModule,
    ConfigModule,
    GuardsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ClientModule,
    TranslationModule,
    WebsocketsModule,
    SelectionsModule,
    StakeModule,
    // AuthModule,
    UtilsModule,
    MiddlewareModule,
    PipesModule,
    // LocalStorageService
  ],
  providers: [LocalStorageService]
})
export class CbfeCoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CbfeCoreModule,
      providers: [LocalStorageService]
    }
  }
}

/*

After declaring the metaReducers array, depending on the environment, the debug reducer can be added to the array

if (!environment.production) {
  if (!environment?.test) {
    metaReducers.unshift(debug); // try this to see if you get action payload on debug reducer
  }
}

Errors are not touched at all in terms of state and models. All are <any>

Saving details in MyAccount in wallet-account-details is not ngrx at all (also acc protection)

*/
