export interface MicrositeSessionsState {
  id: number // getUserId
  ext_id: string | undefined // getExtId
  has_offers: boolean | undefined
  currency_code: string | undefined // getCurrencyCode
  country_code: string | undefined
  auth_tokens: any | undefined
  freeplays: number | undefined // getFreePlays
  manager_id: number | undefined // getManagerId

  additional_info: SessionsState.AdditionalInfo | undefined
  permissions: SessionsState.Permissions | undefined // getPermissions
  setting: SessionsState.Setting | undefined
  free_play_session: SessionsState.FreePlaySession | undefined // getFreeplayLoyalty

  // computed
  isLoading: boolean
  balance?: string // getBalance - additional_info - probably not used, mainly wallet one is used
  user_name?: string, // getUserName - additional_info
  age_verified?: boolean, // getAgeVerified - additional_info
  review_verification?: boolean, // getUnderReview - additional_info
  actionable_promotions_count?: number, // getDisplays - free_play_session
  period_status?: number | null
  currency_multiplier?: number | null

  // from UserData middleware
  optionalData: SafeUserData | undefined

  // AuthTokenService state
  AuthToken: AuthTokenState

}

export declare module SessionsState {
  export interface AdditionalInfo {
    balance: string;
    email: string;
    username: string;
    age_verified: boolean;
    review_verification: boolean;
    state: string;
  }

  export interface Setting {
    id: number;
    user_id: number;
    phone_number?: any;
    threshold: number;
    default_cash_out: number;
    created_at: Date;
    updated_at: Date;
  }

  export interface Permissions {
  }

  export interface FreePlayLoyaltySession {
    id: number;
    weekly_spend_units: string;
    effective_start_date: Date;
    effective_end_date: Date;
    free_play_tier_id?: any;
  }

  export interface FreePlaySession {
    actionable_promotions_count: number;
    free_play_loyalty_session: FreePlayLoyaltySession;
    new_player_bonus?: any;
  }
}

export enum PromotionPeriods {
  Expired = 0,
  Eligible = 1,
  Begun = 2,
  Ending = 3,
  Completed = 4,
  Unauthenticated = 5,
  NotExistent = 6
}

export interface SafeUserData {
  age_verified?: any | undefined
  new_player_bonus?: any | undefined
  period_status?: any | undefined
  user_preferences?: any | undefined // can add model
  registered_at?: any | undefined
  wallet?: Wallet | undefined
}

export interface Wallet {
  balance: string
  bonus: string
  cash: string
  currency: string
}

export interface AuthTokenState {
  authorization_code: string | undefined
  tokens: Tokens;
}

export interface Tokens {
  access_token: undefined,
  refresh_token: undefined,
  expires_at: undefined
}

/*

  PromotionPeriods | SafeUserData - models used previously in the deactivated UserDataMiddleware
  AuthTokenState | Tokens - models used previously in the deactivated AuthTokenService

  */
