import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sportSubCode'
})

@Injectable()
export class SportSubCodePipe implements PipeTransform {
  private sport_sub_code_reg = new RegExp(/_([0-9]{2})([A-Z]+)([0-9]{4})$/g);
  private defaultSportSubCodes: any = {
    'DEFBUND'     : 'DEFBUND',
    'ESFLIGA'     : 'ESFLIGA',
    'FRFLIG1'     : 'FRFLIG1',
    'ITFSERA'     : 'ITFSERA',
    'FRFLIGCP'    : 'FRFLIGCP',
    'GBFFACP'     : 'GBFFACP',
    'GBFPREM'     : 'GBFPREM',
    'EURC'        : 'EURC',
    'AFFCP'       : 'AFFCP',
    'OLYMPICS'    : 'OLYMPICS',
    'ESFCPDELRAY' : 'ESFCPDELRAY',
    'ITFCP'       : 'ITFCP',
    'US_NBA'      : 'US_NBA',
    'US_NCAA'     : 'US_NCAA',
    'US_NFL'      : 'US_NFL',
    'OLYMPIC_M'   : 'OLYMPIC',
    'OLYMPIC_W'   : 'OLYMPIC',
    'WIMBLE_F'    : 'WIMBLE',
    'WIMBLE_M'    : 'WIMBLE',
    'US_OPEN_M_S' : 'US_OPEN',
    'US_OPEN_F_S' : 'US_OPEN',
    'US_OPEN_M_D' : 'US_OPEN',
    'US_OPEN_F_D' : 'US_OPEN',
    'US_OPEN_MX_D': 'US_OPEN',
    'AUS_OPEN_M_S': 'AUS_OPEN',
    'AUS_OPEN_F_S': 'AUS_OPEN',
    'AUS_OPEN_M_D': 'AUS_OPEN',
    'AUS_OPEN_F_D': 'AUS_OPEN',
    'AUS_OPEN_MX_D': 'AUS_OPEN',
    'AUS_OPEN'    : 'AUS_OPEN',
    'FRENCH_OPEN' : 'FRENCH_OPEN',
    'US_OPEN'     : 'US_OPEN',
    'WIMBLE'      : 'WIMBLE'
  };


  transform(sport_sub_code: string): any {

    if(this.sport_sub_code_reg.test(sport_sub_code)) {
      sport_sub_code = sport_sub_code.replace(this.sport_sub_code_reg,'');
    }

    if (typeof this.defaultSportSubCodes[sport_sub_code] === 'undefined') {
      return sport_sub_code;
    }

    return this.defaultSportSubCodes[sport_sub_code];
  }
}
