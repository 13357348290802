import { Pipe, PipeTransform } from '@angular/core';
import { GroupTicketsScope } from '@cbcore/NGRX/group-tickets/group-tickets.model';

@Pipe({name: 'followersFilter'})
export class FollowersPipe implements PipeTransform {
  /**
   * Followers in, followers out that contain all the terms in the filterText
   *
   * @param {GroupTicketsScope.Follower[]} followers
   * @param {string} filterText
   * @returns {GroupTicketsScope.Follower[]}
   */
  transform(followers: any, filterText: string): any {
    if (!followers) {
      return [];
    }
    if (!filterText) {
      return followers;
    }

    return followers.filter((follower: GroupTicketsScope.Follower) => {
      return this.followerContainsFilterText(follower, filterText);
    });
  }

  private followerContainsFilterText(follower: GroupTicketsScope.Follower, filterText: string): boolean {
    filterText = filterText.toLocaleLowerCase();
    const filterTerms = filterText.split(' ');
    for (const filterTerm of filterTerms) {
      const hasFilterTerm = this.followerContainsFilterTerm(follower, filterTerm);
      if (hasFilterTerm === false) {
        return false;
      }
    }

    return true;
  }

  private followerContainsFilterTerm(bookmark: GroupTicketsScope.Follower, filterTerm: string) {
    return bookmark.username.toLocaleLowerCase().includes(filterTerm);
  }
}

