// tslint:disable:max-line-length
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';

import {Config} from "@cbcore/services/config/config.service";
import {CalculationsService} from "@cbcore/services/utils/calculations/calculations.service";
import {CordovaService} from "@cbcore/services/utils/cordova/cordova.service";
import {CurrencyAmountPipe} from "@cbcore/pipes/currency-amount/currency-amount.pipe";
import {LinkService} from "@cbcore/services/utils/links/links.service";
import {RAFService} from "@cbcore/services/utils/raf/raf.service";
import {TicketClient} from "@cbcore/client/ticket";
import {TranslationService} from "@cbcore/services/translation/translation.service";
import {WhatsappService} from "@cbcore/services/utils/social-networks/whatsapp.service";
import {WindowService} from "@cbcore/services/utils/window/window.service";

import { AppState, track } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'syndicate-share-ticket',
  templateUrl: './ticket-share.html'
})
export class TicketShareComponent {
  public $subscribers: any = {};

  @Input('poolId') poolId: string;
  @Input('ticketId') ticketId: string;
  @Input('syndicateId') syndicateId: string;
  @Input('state') state: string;
  @Input('pool') pool: any;
  @Input('manager') manager: any;

  public ticket!: any;
  public shareData!: any;
  public _window!: any;

  public shareSuccess: boolean = false;
  public shareError: boolean = false;
  public shareLoading: boolean = false;
  public linkCopied: boolean = false;

  public sharingEnabled!: boolean;
  public socialNetworks!: any;
  public currentLang!: string;

  public sharingTextOptions!: number;
  public shareWithTwitterHandler!: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _ticketClient: TicketClient,
    private _whatsappService: WhatsappService,
    private _cordovaService: CordovaService,
    private _config: Config,
    private _translateService: TranslateService,
    private _currencyAmountPipe: CurrencyAmountPipe,
    private _windowService: WindowService,
    private _translationService: TranslationService,
    private _rafService: RAFService,
    private _linkService: LinkService,
    private _calculationsService: CalculationsService,
    private store: Store<AppState>
    // private _trackingService: TrackingService
  ) {
  }

  ngOnInit(): void {
    this.shareWithTwitterHandler = this._config.CONFIG.shareWithTwitterHandler;
    this.sharingEnabled =
      this._config.CONFIG.enableShare &&
      !this._activatedRoute.snapshot.data.sharingdisabled;
    this.socialNetworks = this._config.CONFIG.socialNetworks;
    this._window = this._windowService.nativeWindow;
    this.currentLang = this._translationService.getLanguage();
    this.sharingTextOptions =
      this._config.CONFIG.numberOfSharingTextVersions || 1;
  }

  share(): void {
    this.shareLoading = true;

    let serviceUrl: string =
      this._config.CONFIG.templateServiceUrl +
      '&tz=' +
      encodeURIComponent(this.getTimezoneOffset()) +
      '&lang=' +
      this._translationService.getLanguage() ||
      this._window.location.protocol +
      '//' +
      this._window.location.host +
      '/sharedtickets/[UUID_CODE]';
    let body: any = {
      id: this.ticketId,
      share_type: this.getShareType(),
      affiliate_redirect_url: this.getRedirectUrl(),
      site_name: this._config.CONFIG.twitterVia,
      shared_ticket_title: this._config.CONFIG.sharedTicketTitle,
      shared_ticket_description: this._translateService.instant(
        'share.CARD_DESCRIPTION'
      ),
      no_serializer: true,
      template_service_url: serviceUrl
    };

    this.$subscribers.share = this._ticketClient.share(body).subscribe(
      data => {
        this.shareSuccess = true;
        this.shareLoading = false;
        this.updateData(data);
      },
      err => {
        this.shareError = true;
        this.shareLoading = false;
      }
    );
  }

  openLink(url: string): void {
    const eventValue = {
      platform: url.includes('twitter') ? 'twitter' : url.includes('facebook') ? 'facebook' : 'whatsapp',
      syndicate_status: this.state,
      syndicate_id: this.shareData.snapshot.syndicate.id
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'Syndicates',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));
    this._linkService.openLink(url);
  }

  updateData(data: any): void {
    if (data.snapshot) {
      this.ticket = data.snapshot;

      this.pool = data.snapshot.pool;
      this.shareData = data;

      this.shareData.message = this.composeText();
      this.shareData.messageWs = this.composeWhatsapp()
        ? this.composeWhatsapp()
        : this.shareData.message;
      this.shareData.twitterUrl =
        'https://www.twitter.com/intent/tweet?text=' +
        this.shareData.message +
        '&url=' +
        encodeURIComponent(
          this.shareData.short_url + '?p=' + this.shareData.platforms.twitter
        );
      this.shareData.whatsappUrl =
        'whatsapp://send?text=' +
        this.shareData.messageWs +
        ' ' +
        encodeURIComponent(
          this.shareData.short_url + '?p=' + this.shareData.platforms.whatsapp
        );
      this.shareData.facebookUrl =
        'https://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(
          this.shareData.short_url + '?p=' + this.shareData.platforms.facebook
        );
    } else {
      this.shareError = true;
    }
  }

  composeText(): any {
    let message: any = undefined;
    let randomValue: number =
      Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;
    if (this.ticket.syndicate) {
      if (this._config.CONFIG.shareSyndicateDynamicText) {
        if (this.sharingTextOptions > 1) {
          message = this._translateService.instant('socialSharing.MESSAGE_' + randomValue + '_' + this.state);
        } else {
          message = this._translateService.instant('socialSharing.MESSAGE_' + this.state);
        }
      } else if (this._config.CONFIG.shareSyndicateText) {
        message = this._config.CONFIG.shareSyndicateText[_.random(this._config.CONFIG.shareSyndicateText.length - 1)];
      }
    }
    message = this.replaceValues(message);
    return message;
  }

  composeWhatsapp(): any {
    let messageKey: any = undefined;
    let randomValue: number =
      Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;

    if (this.sharingTextOptions > 1) {
      messageKey = 'socialSharing.MESSAGE_' + randomValue + '_' + this.state + '_WS';
    } else {
      messageKey = 'socialSharing.MESSAGE_' + this.state + '_WS';
    }
    // tslint:disable-next-line:typedef
    const hasMessage = () =>
      this._translateService.instant(messageKey) !== messageKey &&
      this._config.CONFIG.shareSyndicateDynamicText;

    if (!hasMessage()) return null;

    let message: any;
    message = this._translateService.instant('socialSharing.MESSAGE_' + this.state + '_WS');
    message = this.replaceValues(message);
    return message;
  }

  replaceValues(message: string): any {
    message = message.replace(
      '[pool_name]',
      this._translateService.instant(
        'poolNames.' + this.pool.sport_code + '_' + this.pool.type_code + '_' + this.pool.leg_num,
        { default: 'poolNames.DEFAULT', legs: this.pool.leg_num })
    );

    message = message.replace(
      '[pool_type]',
      this._translateService.instant(
        'poolNames.' + this.pool.sport_code + '_' + this.pool.type_code + '_' + this.pool.leg_num,
        { default: 'poolNames.DEFAULT', legs: this.pool.leg_num })
    );

    message = message.replace(
      '[prize]',
      this._currencyAmountPipe.transform(this.calculatePrize(), this.pool.currency, 0, false)
    );
    message = message.replace('[via]', this._config.CONFIG.twitterVia);
    message = message.replace(
      '[syndicate_ref]',
      this.ticket.syndicate ? this.ticket.syndicate.reference_code : ''
    );
    message = this.ticket.offer_amount
      ? message.replace(
        '[cash_out]',
        this._currencyAmountPipe.transform(this.ticket.offer_amount, this.pool.currency, 0, false)
      )
      : '';

    if (
      this.ticket.syndicate.manager.twitter_username &&
      this.state !== 'CREATED' &&
      this.state !== 'PLACED' &&
      this.shareWithTwitterHandler
    ) {
      const twitterHandle =
        this.ticket.syndicate.manager.twitter_username.substring(0, 1) === '@'
          ? this.ticket.syndicate.manager.twitter_username
          : '@' + this.ticket.syndicate.manager.twitter_username;

      message = message.replace('[handle]', twitterHandle ? twitterHandle : '');
    } else {
      message = message.replace('[handle]', '');
      message = message.replace('  ', ' ');
    }

    message = encodeURIComponent(message);
    return message;
  }

  getRedirectUrl(): any {
    return this._calculationsService.uiGetTicketRedirectUrl(this.getUtmParams(this.pool), this.poolId, this.syndicateId, this._config.CONFIG.affiliatesUrl, this._translationService.getLanguage());
  }

  getShareType(): string {
    return this.syndicateId ? 'SYNDICATE' : 'FREE_PLAY';
  }

  getTimezoneOffset(): string {
    return this._calculationsService.uiGetTimezoneOffset();
  }

  calculatePrize(): any {
    let stake: any = this.ticket.syndicate
      ? this.ticket.syndicate.stake
      : this.ticket.stake;
    if (this.pool.type_code === 'RACE_PLACE') {
      stake = this._calculationsService.getMaxStake(this.pool.stake_sizes[0].stakes);
    }
    return this._calculationsService.calculatePrize(this.pool.headline_prize, stake);
  }

  isMobileDevice(): boolean {
    return this._cordovaService.isAndroid() || this._cordovaService.isIOS();
  }

  whatsapp(): void {
    const eventValue = {
      platform: 'whatsapp',
      syndicate_id: this.shareData.snapshot.syndicate.id
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'Syndicates',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));
    this._whatsappService.shareLink(this.shareData);
  }

  copyLink($event: any): void {
    // tslint:disable-next-line:max-line-length
    let url: any = this.getRedirectUrl();
    let result: any = this._calculationsService.uiGlobalCopyTextToClipboard(url);
    if (result) {
      this.linkCopied = true;
    }
  }

  getUtmParams(pool: any): string {
    let utm_campaign = pool ? `${pool.type_code}_${pool.leg_num}` : null;
    utm_campaign = utm_campaign ? encodeURIComponent(utm_campaign) : utm_campaign;

    let utm_medium = this.manager || null; //
    utm_medium = utm_medium ? encodeURIComponent(utm_medium): utm_medium;

    let cb_ref_code: string = this._rafService.getCode();

    let utm_term = this.getShareType();
    utm_term = utm_term ? encodeURIComponent(utm_term) : utm_term;

    const utmParameters = {
      ...(cb_ref_code && {cb_ref_code: cb_ref_code}),
      ...(utm_term && {utm_term: utm_term}),
      ...(utm_medium && {utm_medium: utm_medium}),
      ...(utm_campaign && {utm_campaign: utm_campaign})
    };

    return (
      '?' +
      Object.keys(utmParameters)
        .map(key => {
          return key + '=' + utmParameters[key].toLowerCase();
        })
        .join('&')
    );
  }
}
