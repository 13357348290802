import {ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Config} from "@cbcore/services/config/config.service";
import { WindowService } from "@cbcore/services/utils/window/window.service";
import {MenuComponent} from "@cbui/components/menu/menu";
import { Store } from '@ngrx/store';
import { AppState, selectMicrositeSessionsIsLoggedIn } from '@cbcore/NGRX/core.state';
import { MenuRouteService } from './menu.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'desktop-fixed-menu',
  templateUrl: './desktop-fixed-menu.html',
  host: {'class': 'left-nav'}
})

export class DesktopFixedMenuComponent extends MenuComponent implements OnDestroy {
  merchant_code: string;
  public isLoggedIn!: boolean;
  private isLoggedIn$: Observable<boolean>;
  public ngDestroyed$ = new Subject();

  constructor(
    public _activatedRoute: ActivatedRoute,
    public _router: Router,
    public _config: Config,
    public _cdr: ChangeDetectorRef,
    protected windowRef: WindowService,
    public store: Store<AppState>,
    public menuRouteService: MenuRouteService,
    @Inject(DOCUMENT) protected _document: any
  )
  {
    super(_activatedRoute, _router, _config, _cdr, windowRef, store, menuRouteService, _document);
    this.merchant_code = this._config.CONFIG.merchant_code;
    
    this.isLoggedIn$ = this.store.select(selectMicrositeSessionsIsLoggedIn);
    this.isLoggedIn$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
  }
}
