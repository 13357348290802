import { NgModule, Injectable } from '@angular/core';

import { Config } from './config.service';

export * from './config.service';

// export function ConfigFactory(): any {
//   return new Config();
// }

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    Config
    // {
    //   provide: Config,
    //   useFactory: ConfigFactory,
    //   deps: []
    // }
  ]
})
export class ConfigModule { }
