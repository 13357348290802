import { Injectable, NgModule } from '@angular/core';

import { UICommonModule } from '../../helpers/helpers.module';
import { TicketShareComponent } from './types/ticket-share/ticket-share';
import { ListShareComponent } from './types/list-share/list-share';
import { UsersShareComponent } from "./types/users-share/users-share";

export * from './types/ticket-share/ticket-share';
export * from './types/list-share/list-share';
export * from './types/users-share/users-share';

@Injectable()
@NgModule({
  imports: [
    UICommonModule
  ],
  declarations: [
    TicketShareComponent,
    ListShareComponent,
    UsersShareComponent
  ],
  exports: [
    UICommonModule,
    TicketShareComponent,
    ListShareComponent,
    UsersShareComponent
  ]
})
export class SyndicateShareModule { }
