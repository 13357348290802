export class PoolDisplayGroup {
  constructor (
    public code: string | null = null,
    public sport_code: string | null = null,
    public pool_type: string | null = null,
    public sport_sub_code: string | null = null,
    public pools: Array<any> = [],
    public running_pools: Array<any> = [],
    public official_pools: Array<any> = [],
    public groups: Array<any> = []
  ) {}
}
