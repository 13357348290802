import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PostMessageService } from '../services/utils/postmessage/postmessage.service';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class SyndicateClient {
  public endpoint: string;

  constructor(
    public http: HttpClient,
    public _config: Config,
    public _postMessageService: PostMessageService
  ) {
    this.endpoint = _config.CONFIG.apiUrl + '/syndicates';
  }

  list(model: any): Observable<any> {
    let params = new HttpParams()
      .set('page', model.page.toString())
      .set('no_serializer', 'true')
      .set('per', model.per.toString())
      .set('perform_total_count', 'false')
      .set('sort_by', model.sort_by ? (model.sort_by.join || model.sort_by.profile || model.sort_by) : null);

      Object.keys(model.filters).forEach((key, index) => {
      if (model.filters[key]) {
        params = params.set(`filters[${key}]`, model.filters[key]);
      }
    });

    params = params
      .set('filters[status]', model.filters.status || 'available_to_fund')
      .set(
        'filters[manager_limit]',
        model.filters.managers ? String(false) : String(true)
      );

    if (model.filters.count) {
      params = params.set(
        'filters[manager_limit_count]',
        String(model.filters.count)
      );
    }

    return this.http.get(this.endpoint, { params }).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  get(id: number): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('no_serializer', 'true')
      .set('includes', 'manager');

    return this.http.get(this.endpoint + '/' + id, { params }).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  create(syndicate: object): Observable<any> {
    return this.http.post(this.endpoint, syndicate, {withCredentials: true}).pipe(
      map(data => {
        this._postMessageService.betPlaced(data, false);
        return data;
      }),
      catchError(err => throwError(err))
    );
  }

  join(id: string, syndicate: object): Observable<any> {
    return this.http.post(this.endpoint + '/' + id + '/join', syndicate).pipe(
      map(data => {
        this._postMessageService.betPlaced(data, false);
        return data;
      }),
      catchError(err => throwError(err))
    );
  }

  acceptOffer(id: string, model: any): Observable<any> {
    return this.http
      .post(this.endpoint + '/' + id + '/accept_offer', model)
      .pipe(
        map(data => {
          this._postMessageService.offerAccepted(data);
          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  updateSyndicate(id: string, model: any) {
    return this.http.put(this.endpoint + '/' + id, model).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  highlighted(): Observable<any> {
    return this.http.get(this.endpoint + '/highlighted').pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  listSyndicateWins(model: any): Observable<any> {
    let params = new HttpParams()
      .set('no_serializer', 'true')
      .set('per', '6')
      .set('perform_total_count', 'false');

    Object.keys(model.filters).forEach((key, index) => {
      if (model.filters[key]) {
        params = params.set(`filters[${key}]`, model.filters[key]);
      }
    });

    return this.http.get(this.endpoint, { params }).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }
}
