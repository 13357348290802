import { Injectable, NgModule } from '@angular/core';
import { SyndicatesRouteGuard } from './syndicate.guard';
import { CashoutRouteGuard } from './cashout.guard';
import { FreeplayRouteGuard } from './freeplay.guard';
import { HomepageRouteGuard } from './homepage.guard';
import { NoContentRouteGuard } from './no-content.guard';
import { PoolsDefaultRouteGuard } from './poolsroute.guard';
import {TicketsRouteGuard} from "./tickets.guard";

// export * from './syndicate.guard';
// export * from './cashout.guard';
// export * from './freeplay.guard';
// export * from './homepage.guard';
// export * from './no-content.guard';
// export * from './poolsroute.guard';

@Injectable()
@NgModule({
  imports: [],
  providers: [
    SyndicatesRouteGuard,
    FreeplayRouteGuard,
    CashoutRouteGuard,
    HomepageRouteGuard,
    NoContentRouteGuard,
    PoolsDefaultRouteGuard,
    TicketsRouteGuard
  ]
})
export class GuardsModule { }
