<div class="content segmented-control pools-list w-tab-menu" *ngIf="displayState.microsite_type === 'ALL'">
    <a class="tab-link w-inline-block"
              [ngClass]="{'w--current': (displayState.view === 'SPORTS')}"
              (click)="changeDisplayState('SPORTS')">
      <span [translate]="'contentType.SPORTS'"></span>
    </a>
    <a class="tab-link w-inline-block"
            [ngClass]="{'w--current': (displayState.view === 'RACING')}"
            (click)="changeDisplayState('RACING')">
      <span [translate]="'contentType.RACING'"></span>
    </a>
</div>
