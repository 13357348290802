import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@cbcore/NGRX/core.state';
import { callMicrositeRefreshTokens, callMicrositeSessions, failMicrositeSessions, setMicrositeAuthTokenAuthorizationCode, successMicrositeRefreshTokens, successMicrositeSessions } from './coreAuth.actions';
import { selectMicrositeSessions } from './coreAuth.selectors';

import { of } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import {Config} from "@cbcore/services/config/config.service";
import {SessionClient} from "@cbcore/client/session";
import { routerNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { CookieService } from '@cbcore/services/utils/utils.module';

@Injectable()
export class MicrositeSessionsEffects {
  // AuthService
  callMicrositeSessions = createEffect(() => {
      return this.actions$.pipe(
        ofType(callMicrositeSessions),
        withLatestFrom(this.store.pipe(select(selectMicrositeSessions))),
        exhaustMap(([propVal, micrositeSessionState]) => {
            const model: any = {
              authorization_code: micrositeSessionState.AuthToken.authorization_code
            };
            return this.sessionClient.session(model).pipe(
              map(data => {
                return successMicrositeSessions({response: data});
              }),
              catchError(error => {
                return of(failMicrositeSessions({error: error}))
              })
            )
      }))}, { dispatch: true });


  successMicrositeSessions = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeSessions),
      tap((propVal) => {
        this.initTokens(propVal.response?.auth_tokens);
    }))}, { dispatch: false });

  // AuthTokenService
  routerNavigatedAction = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      exhaustMap((propVal) => {
        return of(setMicrositeAuthTokenAuthorizationCode({authorization_code: (<any>propVal).payload.routerState.queryParams.authorization_code}));
      }))}, { dispatch: true });

  callMicrositeRefreshTokens = createEffect(() => {
    return this.actions$.pipe(
      ofType(callMicrositeRefreshTokens),
      withLatestFrom(this.store.pipe(select(selectMicrositeSessions))),
      exhaustMap(([propVal, micrositeSessionState]) => {
        const body: any = {
          refresh_token: micrositeSessionState.AuthToken.tokens.refresh_token
        };
        return this.sessionClient.refresh(body).pipe(
          map(data => {
            return successMicrositeRefreshTokens({response: data});
          })
        )
    }))}, { dispatch: true });

  successMicrositeRefreshTokens = createEffect(() => {
    return this.actions$.pipe(
      ofType(successMicrositeRefreshTokens),
      tap((propVal) => {
        this.initTokens(propVal.response);
    }))}, { dispatch: false });

  initTokens(new_tokens: any) {
    if (new_tokens) {
      // this._authTokenService.setAccessTokens(new_tokens);
      let time_now = new Date();
      let token_expire = new Date(new_tokens.expires_at);
      let expiration = token_expire.getTime() - time_now.getTime() - 30000;

      clearTimeout(this.$timeout);
        this.$timeout = setTimeout(() => {
        this.store.dispatch(callMicrositeRefreshTokens());
      }, expiration);
    }
  }


  isMobile: boolean;
  private $timeout: any;

  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    private sessionClient: SessionClient,
    private _config: Config,
    private _cookieService: CookieService
  ) {
    this.isMobile = this._config.CONFIG.isMobile;

    setTimeout(() => {
      this.store.dispatch(callMicrositeSessions());
    }, 50);
  }
}

/*

  Entire service has been migrated to this feature. Document what actions can replace it's methods
  customMultiplier - was not implemented
*/

