import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppState,
  callMicrositeSessions,
  RouterStateUrl, selectConfiguration,
  selectList,
  selectMicrositeSessions,
  selectMicrositeSessionsIsLoggedIn,
  selectRouter,
  track
} from '@cbcore/NGRX/core.state';

import { select, Store } from '@ngrx/store';
import {catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from "rxjs/operators";

import {EMPTY, of} from "rxjs";
import {SyndicateClient} from "@cbcore/client/syndicate";
import {CalculationsService} from "@cbcore/services/utils/calculations/calculations.service";
import {
  acceptOffer,
  callHighlighted,
  callList,
  callSyndicate,
  callSyndicateWins,
  // clearFilters,
  createComment,
  createSyndicate,
  deleteComment,
  failCreateComment,
  failSyndicate,
  failSyndicateList,
  failSyndicateOffer,
  joinSyndicate,
  listLoadMore,
  loadComments,
  loadList,
  setSyndicatePublic,
  setSyndicateVoidToSolo,
  successAcceptOffer,
  successComments,
  successCreateComment,
  successCreateSyndicate,
  // successFilters,
  successJoinSyndicate,
  successList,
  successSyndicate,
  successSyndicatePublic,
  successSyndicateVoidToSolo,
  successSyndicateWins,
  successUpdateSyndicate, updateComment,
  // updateFilters,
  updateListItem,
  updateSyndicate
} from "@cbcore/NGRX/syndicates/syndicates.actions";
import {selectFilters, selectSyndicate, selectSyndicatesState} from "@cbcore/NGRX/syndicates/syndicates.selectors";
import { initialState, ws } from "@cbcore/NGRX/syndicates/syndicates.reducer";
import * as _ from 'lodash-es';
import {SyndicatesScope} from "@cbcore/NGRX/syndicates/syndicates.model";
import { setWebsocketEvent } from '../coreWebsockets/coreWebsockets.actions';
import { CommentaryClient } from '@cbcore/client/commentary';
import { routerNavigationAction, RouterReducerState } from '@ngrx/router-store';
import { callPool, partialUpdate } from '../pool/pool.actions';
import { callSoloTicket, callSyndicateTicket } from '../tickets/tickets.actions';
import { selectTicketState } from '../tickets/tickets.selector';

const HIGHLIGHTS_MODEL = {
  filters: {}
};
const ROI_LIMIT = 1000;
@Injectable()
export class SyndicateEffects {

  constructor(
    public actions$: Actions,
    public store: Store<AppState>,
    public syndicatesClient: SyndicateClient,
    public calculationService: CalculationsService,
    // private _ticketMiddleware: TicketMiddleware,
    private _commentaryClient: CommentaryClient
    ) {
  }

  routerNavigationSyndicateItem = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => propVal.payload.event.url.includes('/syndicates/join/pool')),
      map(propVal => {
        return {
          pool_id: (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.pool_id,
          syndicate_id: (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.syndicate_id
        }
      }),
      filter(params => !!params.pool_id && !!params.syndicate_id),
      switchMap((routerProps) => {
        return [
          callPool({id: routerProps.pool_id}),
          callSyndicate({id: routerProps.syndicate_id})
        ]
      }))
  }, { dispatch: true });

  routerNavigationSyndicatesList = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter((routerProps) => (routerProps.payload.event.url.includes('/syndicates/join') && !routerProps.payload.event.url.includes('/syndicates/join/pool'))),
      exhaustMap((routerProps) => {
        return of(loadList({ model: {
          pool_id: (<any>routerProps.payload.routerState).queryParams.pool_id,
          search: (<any>routerProps.payload.routerState).queryParams.search
        }}));
      }))
  }, { dispatch: true });

  loadList = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadList),
      withLatestFrom(this.store.select(selectFilters)),
      exhaustMap(([propVal, list_pagination]) => of(callList({ model: { filters: list_pagination.filters } })))
    )
  }, { dispatch: true });

  callList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callList),
      withLatestFrom(this.store.select(selectFilters), this.store.select(selectList)),
      switchMap(([propVal, filters, list]) => {
        return this.syndicatesClient.list(filters).pipe(
          switchMap(data => {
            let have_items = true;
            // for pagination on syndicates
            if (data.items.length === 0 || data.items.length < filters.per) {
              have_items = false;
            }
            if(filters.page === 1) {
              const combinedList = {items: data.items, have_items: have_items};
              return [
                successList( { response: combinedList })
              ];
            } else {
              const combinedList = {items: _.uniq(list.items.concat(data.items)), have_items: have_items};
              return [
                successList( { response: combinedList })
              ];
            }
          }),
          catchError(error => {
            return of(failSyndicateList({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true });

  listLoadMore = createEffect(() => {
    return this.actions$.pipe(
      ofType(listLoadMore),
      withLatestFrom(this.store.select(selectFilters)),
      exhaustMap(([propVal, filters]) => of(callList({model: filters})))
    )
  }, { dispatch: true });

  getSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(callSyndicate),
      withLatestFrom(this.store.select(selectFilters)),
      switchMap(([propVal, pagination]) => {
        return this.syndicatesClient.get(propVal.id).pipe(
          switchMap((data: SyndicatesScope.Syndicate) => {
            // KEPT FOR COMPATIBILITY
            // this._syndicateMiddleware.updateData(data);
            const actions: any[] = [
              successSyndicate( { portion: data }),
              updateListItem({syndicate: data})
            ];

            const hasPortion = (Number(data.customer_portion) > 0);
            // if (hasPortion && (pagination?.status === 'live' || pagination?.status === 'historic'))
              // this._ticketMiddleware.loadSyndicateTicket(this.syndicate.stringified_id);
              actions.push(callSyndicateTicket({
                portion_id: Number(data.stringified_id)
              }));

            return actions;
          }),
          catchError(error => {
            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  }, { dispatch: true });

  ///

  createSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(createSyndicate),
      withLatestFrom(this.store.select(selectMicrositeSessions)),
      switchMap(([propVal, micrositeSessions]) => {
        return this.syndicatesClient.create({syndicate: propVal.model}).pipe(
          switchMap(data => {
            // KEPT FOR COMPATIBILITY
            // this._syndicateMiddleware.updateData(data);
            // this._syndicateListMiddleware.reloadSyndicateList();

            // TO BE MOVED - once tickets are done
            if((<any>propVal.model).group_ticket_id) {
              // this._groupTicketInfoMiddleware.loadGroupTicketsInfo({'manager_id': micrositeSessions.manager_id});
            }
            return [
              callMicrositeSessions(),
              successCreateSyndicate( { response: Object.assign({}, data, { is_public: propVal.model.is_public }) }),
              callList({})
            ]
          }),
          catchError(error => {
            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  });

  trackSuccessCreateSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(successCreateSyndicate),
      exhaustMap((propVal) => {
        // deprecated, previously used by GA
        // this._trackingService.send({
        //   'event': 'micrositePurchase',
        //   'eventCategory': 'MICROSITE',
        //   'transactionId': this.betSuccess.first_portion_id,
        //   'transactionAffiliation': this.merchant,
        //   'transactionTotal': model.syndicate.cost,
        //   'transactionProducts': [{
        //     'sku': this.poolInfo.type_code + '_' + this.poolInfo.leg_num,
        //     'name': 'SYNDICATE_CREATE',
        //     'category': this.poolInfo.sport_code,
        //     'price': model.syndicate.cost,
        //     'quantity': 1
        //   }]
        // });

        const event = {
          event: 'micrositeEvent',
          eventCategory: 'bet',
          eventAction: 'microsite',
          eventLabel: 'create',
          eventValue: propVal.response.first_portion_id
        }

        return of(track({event}))
      })
    )
  });

  updateSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateSyndicate),
      withLatestFrom(this.store.select(selectSyndicate)),
      switchMap(([propVal, currentSyndicate]) => {
        console.log('trigger');
        return this.syndicatesClient.updateSyndicate(String(propVal.id), propVal.model).pipe(
          switchMap(data => {
            return [
              successUpdateSyndicate( { response: data }),
              // callSyndicate({id: propVal.id})
            ]
          }),
          catchError(error => {
            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  });

  joinSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(joinSyndicate),
      switchMap((propVal) => {
        return this.syndicatesClient.join(propVal.id, propVal.model).pipe(
          switchMap(data => {
            return [
              callMicrositeSessions(),
              successJoinSyndicate( { response: data }),
              callSyndicate({id: Number(propVal.id)})
            ]
          }),
          catchError(error => {

            // DEPRECATED
            // this._trackingService.send({
            //   'event': 'micrositeEvent',
            //   'eventCategory': 'ERRORS',
            //   'eventAction': 'ERROR_JOIN_SYNDICATE',
            //   'eventLabel': 'ERROR_JOIN_SYNDICATE'
            // });

            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  });


  successJoinSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(successJoinSyndicate),
      withLatestFrom(this.store.select(selectSyndicate)),
      exhaustMap(([propVal, currentSyndicate]) => {
        // KEPT FOR COMPATIBILITY
        const betSuccess: any = _.cloneDeep(propVal.response);
        betSuccess['syndicate'].stringified_id = currentSyndicate.stringified_id;
        // this._syndicateMiddleware.$joinedSubscriber.next(betSuccess);
        // this._syndicateListMiddleware.updateOnlyOne(currentSyndicate);

        // DEPRECATED - previously used with GA
        // this._trackingService.send({
        //   'event': 'micrositePurchase',
        //   'eventCategory': 'MICROSITE',
        //   'transactionId': this.betSuccess.id,
        //   'transactionAffiliation': this.merchant,
        //   'transactionTotal': model.syndicate.cost,
        //   'transactionProducts': [{
        //     'sku': 'SyndicateJoin' + this.pool.type_code + this.pool.sport_code + this.pool.leg_num,
        //     'name': this.pool.name,
        //     'category': 'Syndicate/Join/' + this.pool.type_code + '/' + this.pool.sport_code,
        //     'price': model.syndicate.cost,
        //     'quantity': 1
        //   }]
        // });

        const event = {
          event: 'micrositeEvent',
          eventCategory: 'bet',
          eventAction: 'microsite',
          eventLabel: 'join',
          eventValue: betSuccess.id
        };

        return of(track({event: event}));

        //TODO: simulate here update only one or in reducer !!!!!

      })
    )
  }, {dispatch: true});

  setSyndicatePublic = createEffect(() => {
    return this.actions$.pipe(
      ofType(setSyndicatePublic),
      withLatestFrom(this.store.select(selectSyndicate), this.store.select(selectRouter), this.store.select(selectTicketState)),
      switchMap(([propVal, currentSyndicate, routerState, ticketsState]) => {
        return this.syndicatesClient.updateSyndicate(propVal.id.toString(), propVal.model).pipe(
          switchMap((data: SyndicatesScope.Syndicate) => {
            // KEPT FOR COMPATIBILITY
            // this._syndicateMiddleware.updateData(Object.assign(_.cloneDeep(currentSyndicate), { is_public: true }));
            const actions: any[] =  [
              successSyndicatePublic({response: data}),
              successSyndicate({portion: Object.assign(_.cloneDeep(currentSyndicate || data), { is_public: true })}),
              updateListItem({syndicate: Object.assign(_.cloneDeep(currentSyndicate || data), { is_public: true })}),
            ];
            if(routerState && routerState.state.url.includes('/tickets')) {
              // this._ticketMiddleware.reloadTicket();
              const isSyndicate = !!ticketsState?.current_ticket?.syndicate;
              // const isSyndicate = !!ticketsState?.current_ticket?.syndicate;

              if(isSyndicate) {
                actions.push(callSyndicateTicket({
                  portion_id: Number(ticketsState.current_ticket.syndicate.id)
                }))
              } else {
                actions.push(callSoloTicket({
                  ticket_id: ticketsState.current_ticket.stringified_id
                }))
              }
            }

            return actions;
          }),
          catchError(error => {
            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  }, {dispatch: true});

  trackSuccessSyndicatePublic = createEffect(() => {
    return this.actions$.pipe(
      ofType(successSyndicatePublic),
      withLatestFrom(this.store.select(selectSyndicate)),
      exhaustMap(([propVal, currentSyndicate]) => {
        const event = {
          event: 'micrositeEvent',
          eventCategory: 'Syndicates',
          eventAction: 'Click',
          eventLabel: 'Public_Status',
          eventValue: currentSyndicate?.id || propVal.response.id
        }

        return of(track({event: event}));
      })
    )
  }, {dispatch: true});

  setSyndicateVoidToSolo = createEffect(() => {
    return this.actions$.pipe(
      ofType(setSyndicateVoidToSolo),
      withLatestFrom(this.store.select(selectSyndicate), this.store.select(selectRouter), this.store.select(selectTicketState)),
      switchMap(([propVal, currentSyndicate, routerState, ticketsState]) => {
        return this.syndicatesClient.updateSyndicate(String(propVal.id), propVal.model).pipe(
          switchMap((data: SyndicatesScope.Syndicate) => {
            // KEPT FOR COMPATIBILITY
            // this._syndicateMiddleware.updateData(data);


            const actions: any[] = [
              successSyndicateVoidToSolo( { response: data, model: propVal.model }),
              updateListItem({syndicate: Object.assign({}, (currentSyndicate || data), { void_to_solo: propVal.model.void_to_solo })}),
              successSyndicate({portion: (<SyndicatesScope.Syndicate>{ void_to_solo: propVal.model.void_to_solo })})
            ];

            // console.log('no state 10', routerState);
            if(routerState && routerState.state.url.includes('/tickets')) {
              // this._ticketMiddleware.reloadTicket();
              const isSyndicate = !!ticketsState?.current_ticket?.syndicate;

              if(isSyndicate) {
                actions.push(callSyndicateTicket({
                  portion_id: Number(ticketsState.current_ticket.syndicate.id)
                }))
              } else {
                actions.push(callSoloTicket({
                  ticket_id: ticketsState.current_ticket.stringified_id
                }))
              }
            }

            return actions;
          }),
          catchError(error => {
            return of(failSyndicate({ error: error }))
          })
        )
      })
    )
  }, {dispatch: true});

  trackSuccessSyndicateVoidToSolo = createEffect(() => {
    return this.actions$.pipe(
      ofType(successSyndicateVoidToSolo),
      withLatestFrom(this.store.select(selectSyndicate)),
      exhaustMap(([propVal, currentSyndicate]) => {
        const eventValue = {
          void_to_solo: propVal.model.void_to_solo,
          syndicate_id: currentSyndicate?.id || propVal.response.id
        }

        const event = {
          event: 'micrositeEvent',
          eventCategory: 'Syndicates',
          eventAction: 'Click',
          eventLabel: 'Void_To_Solo',
          eventValue: encodeURIComponent(JSON.stringify(eventValue))
        }

        return of(track({event: event}));
      })
    )
  }, {dispatch: true});

  initWinsList = createEffect(() => {
    return this.actions$.pipe(
      ofType(callHighlighted),
      switchMap(() => {
        return this.syndicatesClient.highlighted().pipe(
          switchMap((data: any) => {
            HIGHLIGHTS_MODEL.filters = {
              filters: {
                status: 'historic',
                syndicate_ids: data.syndicate_ids
              }
            }
            return [callSyndicateWins({model: HIGHLIGHTS_MODEL.filters})]
          }),
          catchError(error => {
            return of(failSyndicateList({error: error}));
          })
        )
      })
    )
  })

  syndicateWins = createEffect(() => {
    return this.actions$.pipe(
      ofType(callSyndicateWins),
      switchMap((propVal: any) => {
        return this.syndicatesClient.listSyndicateWins(propVal.model).pipe(
          switchMap((data: any) => {
            const parsedData = this.calculationService.uiSWSortItems(data.items, propVal.model, ROI_LIMIT);
            return [successSyndicateWins({response: parsedData})]
          }),
          catchError(error => {
            return of(failSyndicateList({error: error}));
          })
        )

      })
    )
  })

  acceptOffer = createEffect(() => {
    return this.actions$.pipe(
      ofType(acceptOffer),
      exhaustMap((model: any) => {
        return this.syndicatesClient.acceptOffer(model.id, model.payload).pipe(
          map((data: any) => {
            return successAcceptOffer({response: data})
          }),
          catchError(error => {
            return of(failSyndicateOffer({error: error}))
          })
        )
      })
    )
  })

  // Commentary

  successSyndicate = createEffect(() => {
    return this.actions$.pipe(
      ofType(successSyndicate),
      filter(propVal => !!propVal.portion?.id),
      exhaustMap((propVal) => {
        if (propVal?.portion?.customer_portion && propVal.portion.customer_portion !== '0.0') {
          return of(loadComments({params: {
              page: 1,
              model_id: String(propVal.portion.id),
              feed_type: 'syndicate'
            }}))
        }
        return EMPTY;
      })
    )
  }, {dispatch: true})

  // clear all comment on this one?
  loadComments = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadComments),
      withLatestFrom(this.store.select(selectSyndicatesState), this.store.select(selectMicrositeSessionsIsLoggedIn), this.store.select(selectConfiguration)),
      exhaustMap(([propVal, syndicatesState, isLoggedIn, config]) => {
        if(!isLoggedIn) return EMPTY;
        if (!config.commentarySettings) return EMPTY;
        return this._commentaryClient.get(syndicatesState.commentaryParams).pipe(
          map((data: any) => {
            // KEPT FOR COMPATIBILITY
            // this._commentaryMiddleware.updateData(data);

            return successComments({response: data})
          }),
          catchError(error => {
            return of(failSyndicate({error: error}))
          })
        )
      })
    )
  }, {dispatch: true})

  createComment = createEffect(() => {
    return this.actions$.pipe(
      ofType(createComment),
      withLatestFrom(this.store.select(selectSyndicatesState)),
      switchMap(([propVal, syndicateState]) => {
        return this._commentaryClient.create({post: propVal.model}).pipe(
          switchMap((data: any) => {
            // KEPT FOR COMPATIBILITY
            // this._commentaryMiddleware.updateWithNewMessage(data);

            return [
              successCreateComment({response: data}),
              loadComments({params: Object.assign({}, {feed_type: 'syndicate', page: 1})})
            ];
          }),
          catchError(error => {
            return of(failCreateComment({error: error}))
          })
        )
      })
    )
  }, { dispatch: true })

  updateComment = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateComment),
      switchMap((propVal) => {
        return this._commentaryClient.update(propVal.model).pipe(
          switchMap((data: any) => {
            // KEPT FOR COMPATIBILITY
            // this._commentaryMiddleware.updateExistingMessage(propVal.model);

            return [
              successCreateComment({response: data}),
              loadComments({params: Object.assign({}, {feed_type: 'syndicate', page: 1})})
            ];
          }),
          catchError(error => {
            return of(failSyndicate({error: error}))
          })
        )
      })
    )
  }, {dispatch: true})

  trackSuccessCreateComment = createEffect(() => {
    return this.actions$.pipe(
      ofType(successCreateComment),
      withLatestFrom(this.store.select(selectSyndicatesState)),
      exhaustMap(([propVal, syndicateState]) => {
        const ID = syndicateState?.portion?.id || syndicateState.commentaryParams.model_id;
        const eventValue = {
          model_id      : ID,
          model_name : 'syndicate'
        };

        const event = {
          eventCategory: 'comment',
          eventAction: 'microsite',
          eventLabel: 'create',
          eventValue: encodeURIComponent(JSON.stringify(eventValue))
        }

        return of(track({event: event}));
      })
    )
  }, {dispatch: true})

  deleteComment = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteComment),
      withLatestFrom(this.store.select(selectSyndicatesState)),
      exhaustMap(([propVal, syndicateState]) => {
        return this._commentaryClient.delete(String(propVal.commentaryId)).pipe(
          map((data: any) => {
            // KEPT FOR COMPATIBILITY
            // this._commentaryMiddleware.updateData(data);
            return loadComments({params: Object.assign({}, syndicateState.commentaryParams, { page: 1 })})
          }),
          catchError(error => {
            return of(failSyndicate({error: error}))
          })
        )
      })
    )
  }, { dispatch: true })

  ///// LIVE UPDATE
  setWebsocketEvent = createEffect(() => {
    return this.actions$.pipe(
      ofType(setWebsocketEvent),
      filter(propVal => ws.RELOAD_EVENTS.concat(Object.keys(ws)).includes(propVal.event)),
      withLatestFrom(this.store.select(selectSyndicate), this.store.select(selectMicrositeSessions)),
      switchMap(([propVal, portion, micrositeSessions]) => {
        if (ws.RELOAD_EVENTS.includes(propVal.event)) {
          return [
            callList({}),
            (portion?.stringified_id ? callSyndicate({ id: Number(portion?.stringified_id) }) : null),
          ].filter(action => !!action);
        }

        if(
          !!propVal.data &&
          micrositeSessions.ext_id &&
          (micrositeSessions.ext_id === propVal.data.customer_id) &&
          String(portion.id) === String(propVal.data.model_id)
        ) {
          return of(partialUpdate({
            data: propVal.data,
            event: propVal.event
          }))
        }

        return EMPTY
      })
    )
  }, { dispatch: true })

}
