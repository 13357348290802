import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppState, selectConfiguration } from "@cbcore/NGRX/core.state";
import { selectCurrentPool } from "@cbcore/NGRX/pool/pool.selectors";
import { Config } from "@cbcore/services/config/config.service";
import { Store } from "@ngrx/store";
import {distinctUntilChanged, filter} from "rxjs/operators";
import * as _ from "lodash-es";


@Injectable({
  providedIn: 'root'
})
export class MenuRouteService {
  currentRoute: string;
  displayStateView: string = 'ALL';
  currentPoolSportCode: string = null;
  rootUrl: string = null;

  constructor(
    private _router: Router,
    private store: Store<AppState>,
    private _config: Config
  ) {
    this.rootUrl = this._config.CONFIG.rootUrl || '';

    const navigationEnd$ = _router.events.pipe(filter(event => event instanceof NavigationEnd));
    navigationEnd$.pipe(
      distinctUntilChanged((prev, curr) => _.isEqual(prev, curr))
    ).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.currentRoute = urlAfterRedirects;
    });

    const configuration$ = store.select(selectConfiguration).pipe(filter(configuration => !!configuration?.displayState?.view));
    configuration$.pipe(
      distinctUntilChanged((prev, curr) => _.isEqual(prev, curr))
    ).subscribe(configuration => {
      this.displayStateView = configuration.displayState.view;
    });

    const pool$ = store.select(selectCurrentPool);
    pool$.pipe(
      distinctUntilChanged((prev, curr) => _.isEqual(prev, curr))
    ).subscribe(pool => {
      this.currentPoolSportCode = (pool?.type_code && pool.type_code.includes('RACE')) ? 'RACING' : null;
    });
  }

  activated(route): any {

    if (!this.currentRoute) {
      return false;
    }

    const isNotSpecialRoute = !(
      this.currentRoute.includes('tickets') ||
      this.currentRoute.includes('syndicates') ||
      this.currentRoute === '/' ||
      this.currentRoute.includes('freeplay'));


    let activated: boolean = false;

    if(route === '' && this.currentRoute === '/') {
      return true;
    }

    if(!this.currentRoute) return;

    switch (route) {
      case '/pools/racing':
        if(isNotSpecialRoute) {
          if (this.displayStateView == 'ALL' && this.currentPoolSportCode) {
            activated = this.currentPoolSportCode.includes('RACING');
          } else {
            activated = this.currentRoute.includes('pools') && (this.displayStateView === 'RACING');
          }
          return activated;
        }
        break;

      case '/pools/sports':
        if(isNotSpecialRoute) {
          if (this.displayStateView == 'ALL') {
            activated = !this.currentPoolSportCode || !this.currentPoolSportCode.includes('RACING');
          } else {
            activated = this.currentRoute.includes('pools') && (this.displayStateView === 'SPORTS')
          }
        }
        break;

      case '/syndicates':
        if(!this.currentRoute.includes('tickets')){
          activated = this.currentRoute.includes('syndicates')
        }
        break;

      case '/tickets':
        activated = this.currentRoute.includes('tickets')
        break;

      case '/no-content':
        activated = (this.currentRoute === '/no-content');
        break;

      case '/freeplay':
        activated = (this.currentRoute === '/freeplay');
        break;

      default:
        break;
    }

    return activated;
  }

  activate(route: string): void {
    this._router.navigate([route]);
  }
}
