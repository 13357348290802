import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, throwError } from 'rxjs';

@Injectable()

export class CordovaService {
  // @ts-ignore
  public $subscriber: ReplaySubject<any> = new ReplaySubject<any>(null);
  public capabilities: any = {};
  public uagent: any;

  constructor() {
    this.detectBrowser();
  }

  detectBrowser(): void {
    const uagent = navigator.userAgent.toLowerCase();
    this.uagent = uagent;

    this.capabilities.ios = /iphone/.test(uagent) || /ipod/.test(uagent) || /ipad/.test(uagent);
    this.capabilities.android = /android/.test(uagent);
    this.capabilities.app = /colossusbetsapp/.test(uagent);
    this.capabilities.virtual = false;
  }

  isAndroid(): boolean {
    return this.capabilities.android;
  }

  isIOS(): boolean {
    return this.capabilities.ios;
  }

  isApp(): boolean {
    return this.capabilities.app;
  }

  isCordova(): boolean {
    return this.capabilities.app;
  }

  isVirtual(): boolean {
    return this.capabilities.virtual;
  }
}
