import {Injectable, NgModule} from '@angular/core';
import {Config} from "@cbcore/services/config/config.service";
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
@NgModule({})

export class SvgIconLibraryModule {
  constructor(private _config: Config,
              private _matIconRegistry: MatIconRegistry,
              private _sanitizer: DomSanitizer) {
    const path = this._config.CONFIG.imagesUrl;

    this._matIconRegistry.addSvgIcon('icon-group_90_min', this._sanitizer.bypassSecurityTrustResourceUrl(path + '/assets/svg-icons/coupon-90mins.svg'));
    this._matIconRegistry.addSvgIcon('icon-group_cards', this._sanitizer.bypassSecurityTrustResourceUrl(path + '/assets/svg-icons/coupon-cards.svg'));
    this._matIconRegistry.addSvgIcon('icon-group_corners', this._sanitizer.bypassSecurityTrustResourceUrl(path + '/assets/svg-icons/coupon-corners.svg'));
  }
}
