import { MerchantState } from './merchant.models';
import { testActionOne, testActionTwo } from './merchant.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: MerchantState = {
  merchant_field             : undefined,
  additional_info            : undefined
};

const reducer = createReducer(
  initialState,

  // on(successWalletSignIn, (state, action) => {
  //   return {
  //     ...state,
  //     // field: 'new',
  //   }
  // }),
);

export function merchantReducer(
  state: MerchantState | undefined,
  action: Action
): MerchantState {
  return reducer(state, action);
}
