import { Injectable } from '@angular/core';
import { AppState } from '@cbcore/NGRX/core.state';
import { setWebsocketEvent } from '@cbcore/NGRX/coreWebsockets/coreWebsockets.actions';
import { Store } from '@ngrx/store';
import Pusher from 'pusher-js';
import { Config } from '../config/config.service';

const EVENTS = ["show", "hide", "trading_enabled", "trading_disabled", "cycle_update", "external_db_update", "status_change", "new_offer", "remove_offer"];

@Injectable()
export class WebsocketService {
  private socket: any;
  private channels: any = {};
  private websocketsUrl: any;
  private websocketsKey: any;
  private apiUrl: any;

  constructor(
    private _config: Config,
    private store: Store<AppState>
  ) {
    this.apiUrl = this._config.CONFIG.apiUrl;
    this.websocketsKey = this._config.CONFIG.websocketsKey;
    this.websocketsUrl = this._config.CONFIG.websocketsUrl;
  }

  connect(): void {
    if (!this.isConnected()) {
      this.socket = new Pusher(this._config.CONFIG.websocketsKey, {
        wsHost: this._config.CONFIG.websocketsUrl,
        cluster: this._config.CONFIG.websocketCluster,
        disableStats: true,
        enabledTransports: ['ws']
      });

      this.subscribeChannel('pools');
      this.bindAllChannelEvents('pools');
    }
  }

  subscribe(channel: string) {
    this.socket.subscribe(channel);
  }

  isConnected(): boolean {
    if (typeof this.socket !== 'undefined') {
      if (this.socket.connection.state === 'connected' || this.socket.connection.state === 'connecting') {
        return true;
      }
    }
    return false;
  }

  closeConnection() {
    if (this.isConnected()) {
      this.socket.disconnect();
      this.channels = {};
    }
  }

  subscribeChannel(channelName: string) {
    if (typeof this.channels[channelName] === 'undefined') {
      let channel = this.socket.subscribe(channelName);
      this.channels[channelName] = channel;
    }
  }

  unSubscribeChannel(channelName: string) {
    if (typeof this.channels[channelName] !== 'undefined') {
      this.socket.unsubscribe(channelName);
      this.channels[channelName] = undefined;
    }
  }

  bindAllChannelEvents(channelName: string) {
    // let self = this;
    // in some cases, at refresh, the socket is not yet present
    // and leads to application to break
    try {
      this.socket.allChannels().forEach((channel: any) => {
        if (channel.subscribed === false) {
          channel.bind_global((event: any, data: any) => {
            if (channel.name === 'pools') {
              // this._notificationService.handleEvents(event, data);
              this.handlePoolEvents(event, data);
            }
          })
        }
      });
    } catch (e: any) {
      this.bindAllChannelEvents(channelName);
    }
  }

  handlePoolEvents(event, data): void {
    const basicEvents = {
      'show': 'POOL_SHOW',
      'hide': 'POOL_HIDE',
      'trading_enabled': 'TRADING_ENABLED',
      'trading_disabled': 'TRADING_DISABLED'
    };

    if (basicEvents[event]) {
      this.store.dispatch(setWebsocketEvent({
        event: basicEvents[event],
        data: data?.data
      }));
    }

    if (
      event === 'cycle_update' &&
      (data.route.indexOf('cycledata.pools') !== -1 || data.route.indexOf('tote_api.pools') !== -1)
    ) {
      // manually update pool menu & pool
      this.store.dispatch(setWebsocketEvent({
        event: 'POOL_INFO_CHANGED',
        data: data?.data
      }));
    }

    if (event === 'competitors_update') {
      // NOTE: to check again
      this.store.dispatch(setWebsocketEvent({
        event: 'SPORT_EVENT_COMPETITORS_UPDATE',
        data: data
      }));
    }

    // reload everything
    if (event === 'status_change') {
      this.store.dispatch(setWebsocketEvent({
        event: 'POOL_STATUS_CHANGED',
        data: data?.data
      }));
    }

    /* NOTE: New events */

    // reload pool - win sel changed
    if (event === 'winnings_update' ||
      event === 'remunit_update'
    ) {
      this.store.dispatch(setWebsocketEvent({
        event: 'POOL_UPDATED',
        data: data?.data
      }));
    }


    if (event === 'results_update') {
      this.store.dispatch(setWebsocketEvent({
        event: 'RESULTS_UPDATED',
        data: data
      }));
    }

    // manually update competitor - SINGLE COMPETITOR
    if (event === 'competitor_update') {
      this.store.dispatch(setWebsocketEvent({
        event: 'SPORT_EVENT_COMPETITOR_UPDATE',
        data: data
      }));
    }


    // manually update sport event
    if (event === 'sport_event_update')
      this.store.dispatch(setWebsocketEvent({
        event: 'SPORT_EVENT_UPDATE',
        data: data
      }));

    /* NOTE: Will be deprecated  */
    if (event !== 'external_db_update') return;

    // reload ticket & pool
    if (data.route.indexOf('tote_controller.pools') !== -1) {
      this.store.dispatch(setWebsocketEvent({
        event: 'POOL_UPDATED',
        data: data?.data
      }));
    }


    // reload pool & ticket & ticket menu
    if (data.route.indexOf('remunit_controller.pools') !== -1) {
      this.store.dispatch(setWebsocketEvent({
        event: 'UNIT_DISTRIBUTION_CHANGED',
        data: data?.data
      }));
    }
  }
}
