import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslationService } from '../../services/translation/translation.service';
import * as Big from 'big.js';
import * as _ from 'lodash-es';
import { AppState } from '@cbcore/NGRX/core.state';
import { selectMicrositeSessions } from '../../NGRX/coreAuth/coreAuth.selectors';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MicrositeSessionsState } from '@cbcore/NGRX/coreAuth/coreAuth.models';

//TODO: Implement and add UserService then un-comment the code.

@Pipe({
  name: 'localeNumberFormat',
  pure: false
})

@Injectable()
export class LocaleNumberFormatPipe implements PipeTransform {
  private ngDestroyed$ = new Subject();
  private micrositeSessions$: Observable<MicrositeSessionsState>;
  private currency_multiplier: number | null = null;

  constructor(
    private _translationService: TranslationService,
    private store: Store<AppState>
  ) {
    this.micrositeSessions$ = this.store.select(selectMicrositeSessions);
    this.micrositeSessions$
    .pipe(takeUntil(this.ngDestroyed$), filter(micrositeSessions => micrositeSessions.currency_multiplier !== null))
    .subscribe(micrositeSessions => {
      this.currency_multiplier = micrositeSessions.currency_multiplier;
    });
  }

  transform(amount: any = 0, precision: any = 2, disableMultiplier: boolean = false): any {
    const thousands_separator: string = this._translationService.getThousandSeparator();
    const decimal_separator: string = this._translationService.getDecimalSeparator();

    const defaultDP: string = '.';
    const defaultTP: RegExp = /,/g;

    if (disableMultiplier) {
      amount = Number(amount).toFixed(precision);
      this.ngDestroyed$.next();
    } else {
      const divisor: any = this.currency_multiplier;
      if(divisor) 
        this.ngDestroyed$.next();
      amount = new Big.Big(amount).div(divisor || 1).toFixed(precision);
    }

    const number = amount.toString().split(defaultDP);
    let thousands_amount: any = number[0];
    const decimal_amount: any = number[1];
    const negative_symbol: string = /^-/.test(thousands_amount) ? '-' : '';

    thousands_amount = thousands_amount.replace('-', '').split('').reverse();
    thousands_amount = _.map(_.chunk(thousands_amount, 3), function (item) {
      return _.join(_.reverse(item), '');
    });

    thousands_amount = _.join(_.reverse(thousands_amount), thousands_separator);
    thousands_amount = negative_symbol + thousands_amount;
    return decimal_amount === undefined ? thousands_amount : thousands_amount + decimal_separator + decimal_amount;
  }
}
