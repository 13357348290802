export class PoolSelections {
  constructor (
    public id: number | null = null,
    public type_code: string = '',
    public leg_num: number | null = null,
    public legs_with_selections: number = 0,
    public selections_string: string = '',
    public votes_string: string = '',
    public total_lines: number = 0,
    public smartpick: number = 0,
    public cross_sell: number = 0,
    public legs: object = {},
    public valid_legs: object = {},
    public sep_correlations: object = {}
  ) {}
}
