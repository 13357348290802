import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { callPoolsMenu, successPoolsMenu, failPools, setPoolsExtraDetails, setSportCode, callPool, successPool, partialUpdate, callPoolFeatured, successPoolFeatured, callPoolsHeadline, successPoolsHeadline, toggleSelection, clearAllPoolSelections, generateTicket, clearCurrentLines, removeSelectionsInLeg, removeLastSelectionInLeg } from './pool.actions';

import { EMPTY, of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {Config} from "@cbcore/services/config/config.service";
import { PoolClient } from "@cbcore/client/pool";
import { AppState, RouterStateUrl, selectRouter, successCoreSettings } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
import { selectConfiguration, selectConfigurationCore, selectConfigurationState } from '@cbcore/NGRX/configuration/configuration.selectors';
import { setPools, setPoolsExtra, setPoolsFilters } from './parsePools';
import { PoolCategories, PoolScope, PoolsMenuScope } from './pool.models';
import { routerNavigatedAction, routerRequestAction, routerNavigationAction } from "@ngrx/router-store";
import { setDisplayState } from '../configuration/configuration.actions';
import { selectPools } from './pool.selectors';
import { ws } from "./pool.reducer";
import { setWebsocketEvent } from '../coreWebsockets/coreWebsockets.actions';
import { LocalStorageService } from '../meta-reducers/local-storage.service';
import { Observable } from 'rxjs';
import { callList, loadList } from '../syndicates/syndicates.actions';

const MICROSITE_POOLS_STATE_KEY = 'micrositePools';

@Injectable()
export class MicrositePoolsEffects {

  routerNavigationPool = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter((routerProps) => !(routerProps.payload.routerState as unknown as RouterStateUrl).wallet),
      filter(propVal => propVal.payload.event.url.includes('/pools') || propVal.payload.event.url.includes('/syndicates/create')),
      map(propVal => (propVal.payload.routerState as unknown as RouterStateUrl)?.params?.pool_id),
      filter(poolId => !!poolId),
      switchMap((poolId: string) => {
        return [
          callPool({ id: poolId }),
          loadList({model: { filters: { pool_id: poolId } }})
        ];
      }))
  }, { dispatch: true });

  callPool = createEffect(() => {
    return this.actions$.pipe(
      ofType(callPool),
      exhaustMap((propVal) => {
        return this._poolClient.get(propVal.id)
          .pipe(
            map((data: PoolScope.CurrentPool) => {
              // KEPT FOR COMPATIBILITY
              return successPool({ pool: data });
            }),
            catchError(error => {
              // this._router.navigate(['/errors/poolnotfound']);
              return of(failPools({ error: error }))
            })
          )
      }))
  }, { dispatch: true });

  toggleSelection = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleSelection),
      withLatestFrom(this.store.select(selectPools)),
      tap(([propVal, poolsState]) => {
        // save to localstorage
        this.ngrxLocalStorage.setItem(MICROSITE_POOLS_STATE_KEY, { currentPoolSelections: poolsState.currentPoolSelections, currentGTSelections: poolsState.currentGTSelections });
        // KEPT FOR COMPATIBILITY
        // this._selectionService.toggleSelection(poolsState.currentPool.id, Number(propVal.legId), Number(propVal.bin));
      }))
  }, { dispatch: false });

  // KEPT FOR COMPATIBILITY
  // generateTicket = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(generateTicket),
  //     withLatestFrom(this.store.select(selectPools)),
  //     tap(([propVal, poolsState]) => {
  //       if (poolsState.currentPoolSelections[poolsState.currentPool.id].smartpick) {
  //         this._selectionService.generateSmartpick(poolsState.currentPool.id, propVal.selections);
  //       }
  //     }))
  // }, { dispatch: false });

  clearCurrentLines = createEffect(() => {
    return this.actions$.pipe(
      ofType(clearCurrentLines),
      withLatestFrom(this.store.select(selectPools)),
      tap(([propVal, poolsState]) => {
        // save to local storage
        this.ngrxLocalStorage.setItem(MICROSITE_POOLS_STATE_KEY, { currentPoolSelections: poolsState.currentPoolSelections });

        // KEPT FOR COMPATIBILITY
        // this._selectionService.clearLines(poolsState.currentPool.id);
      }))
  }, { dispatch: false });

  // KEPT FOR COMPATIBILITY
  // removeSelectionsInLeg = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(removeSelectionsInLeg),
  //     withLatestFrom(this.store.select(selectPools)),
  //     tap(([propVal, poolsState]) => {
  //       // this._selectionService.removeSelectionsInLeg(poolsState.currentPool.id, propVal.legId);
  //     }))
  // }, { dispatch: false });

  // KEPT FOR COMPATIBILITY
  // removeLastSelectionInLeg = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(removeLastSelectionInLeg),
  //     withLatestFrom(this.store.select(selectPools)),
  //     tap(([propVal, poolsState]) => {
  //       this._selectionService.removeLastSelectionInLeg(poolsState.currentPool.id, propVal.legId);
  //     }))
  // }, { dispatch: false });

  // KEPT FOR COMPATIBILITY
  // clearAllPoolSelections = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(clearAllPoolSelections),
  //     withLatestFrom(this.store.select(selectPools)),
  //     tap(([propVal, poolsState]) => {
  //       this._selectionService.clearAllSelections();
  //     }))
  // }, { dispatch: false });

  callPoolFeatured = createEffect(() => {
    return this.actions$.pipe(
      ofType(callPoolFeatured),
      exhaustMap((propVal) => {
        return this._poolClient.featured()
          .pipe(
            map((data: PoolScope.PoolFeatured) => {
              // KEPT FOR COMPATIBILITY
              // this._featuredPoolMiddleware.updateData(_.cloneDeep(data));
              return successPoolFeatured({ featuredPoolResponse: data });
            }),
            catchError(error => {
              return of(failPools({ error: error }))
            })
          )
      }))
  }, { dispatch: true });

  callPoolsMenu = createEffect(() => {
    return this.actions$.pipe(
      ofType(callPoolsMenu),
      withLatestFrom(this.store.select(selectConfiguration), this.store.select(selectPools)),
      switchMap(([propVal, configuration, pools]) => {
        return this._poolClient.getMenu()
          .pipe(
            switchMap((data: PoolsMenuScope.Response) => {
              const parsedPoolsMenu = setPools(data.pools, configuration.coreSettings, configuration.localSettings);

              // KEPT FOR COMPATIBILITY
              // this._poolListMiddelware.loadPoolList(_.cloneDeep(parsedPoolsMenu));
              // this._settingsMiddelware.setPools(_.cloneDeep(data.pools));

              return [
                successPoolsMenu({ parsedPoolsMenu: parsedPoolsMenu }),
                setPoolsExtraDetails({
                  poolsExtraModel: setPoolsExtra(data.pools, pools.poolsExtraModel, configuration.coreSettings, configuration.localSettings),
                  filters: setPoolsFilters(data.pools, configuration.coreSettings, configuration.localSettings)
                }),
                // it was called instantly before and waited for the settings promise
                callPoolFeatured()
              ];
            }),
            catchError(error => {
              return of(failPools({ error: error }))
            })
          )
      }))
  }, { dispatch: true });

  // setPoolsExtraDetails = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(setPoolsExtraDetails),
  //     withLatestFrom(this.store.select(selectRouter)),
  //     // filter(([propVal, routerState]) => routerState.state.url === '/'),
  //     tap(([propVal, routerState]) => {
  //       // if (propVal.poolsExtraModel.ALL == 0) {
  //         // this._router.navigate(['/pools']);
  //       // }
  //     }))
  // }, { dispatch: false });

  callPoolsHeadline = createEffect(() => {
    return this.actions$.pipe(
      ofType(callPoolsHeadline),
      withLatestFrom(this.store.select(selectConfigurationState)),
      // delay(10),
      exhaustMap(([propVal, configuration]) => {
        return this._poolClient.headlines()
          .pipe(
            map((data: PoolsMenuScope.PoolHeadlineItem[]) => {
              // KEPT FOR COMPATIBILITY
              // this._headlinePoolsMiddleware.updateData(_.cloneDeep(data));
              return successPoolsHeadline({ poolsHeadlineResponse: data, coreSettings: configuration.coreSettings });
            }),
            catchError(error => {
              return of(failPools({ error: error }))
            })
          )
      }))
  }, { dispatch: true });

  routerNavigatedAction = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(propVal => propVal.payload.event.url.includes('/pools')),
      map(propVal => {
        return propVal.payload.event.url.split('/')[2]?.toUpperCase()
      }),
      filter(view => (isNaN(Number(view)) || isNaN(parseFloat(view)))),
      filter(view => (view in PoolCategories)),
      delay(1000),
      exhaustMap((view) => {

        // might cause problems in other paths which contain '/pools'
        return of(setDisplayState({
          displayState: {
            view: view
          }
        }))
      }))
  }, { dispatch: true });

  successCoreSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(successCoreSettings),
      switchMap((propVal) => {
        return [
          callPoolsMenu(),
          callPoolsHeadline(), // it was called instantly before and waited for the settings promise
        ]
      }
      ))
  }, { dispatch: true });

  ///// LIVE UPDATE
  setWebsocketEvent = createEffect(() => {
    return this.actions$.pipe(
      ofType(setWebsocketEvent),
      filter(propVal => ws.RELOAD_EVENTS.concat(Object.keys(ws)).includes(propVal.event)),
      withLatestFrom(this.store.select(selectPools), this.store.select(selectConfiguration)),
      switchMap(([propVal, poolsState, configuration]) => {
        if(!configuration.coreSettings) return EMPTY;

        if (ws.RELOAD_EVENTS.includes(propVal.event)) {
          return [
            callPoolsMenu(),
            callPoolsHeadline(),
            (poolsState.currentPool?.id ? callPool({ id: poolsState.currentPool.id }) : null),
          ].filter(action => !!action);
        }

        return of(partialUpdate({
          data: propVal.data,
          event: propVal.event
        }))
      })
    )
  }, { dispatch: true })

  isMobile: boolean;
  rootUrl: string;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private _config: Config,
    private _poolClient: PoolClient,
    // private _selectionService: SelectionService,
    private ngrxLocalStorage: LocalStorageService,
    private _router: Router
  ) {
    this.isMobile = this._config.CONFIG.isMobile;
    this.rootUrl = this._config.CONFIG.rootUrl;
  }
}



