import {Injectable} from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()
export class SepService {
  private scope: any = {};

  calculateSep(selections_string: string, num_legs: number, sep_correlations: any) {
    const t0: any = new Date();
    this.scope.total_lines = 0;
    let linii = 0; // linii - lines, number of correct lines that user is having valid selections
    const new_selections_string_object = {};
    for (let idx = 0; idx < num_legs; idx++) {
      new_selections_string_object[idx] = [];
    }

    const x1 = selections_string.split('/');
    this.scope.selections = new Array(x1.length);
    for (let i = 0; i < x1.length; i++) {
      const x2 = x1[i].split(',');
      this.scope.selections[i] = x2;
      for (let j = 0; j < x2.length; j++) {
        this.scope.selections[i][j] = Number(x2[j]);
      }
    }

    const permutations = this.generateSelectionsCombos(this.scope.selections);

    // Explanation of below code
    // You pass thru correlations, and for each [key_lvl_1, key_lvl_2[, ley_lvl_3]] you check if the bin the selection string at
    // position[key_lvl_1, key_lvl_2[, ley_lvl_3]] is within the blacklisted correlations array
    for (let p = 0; p < permutations.length; p++) {
      const line: any = permutations[p];
      let valid = true;

      for (const [oj_key, oj_value] of Object.entries(sep_correlations)) {
          if (valid) {
            for (const [io_key, io_value] of Object.entries(sep_correlations[oj_key])) {
              const lvl2_value: any = io_value; // Need to force cast it as object
              const lvl2_keys = Object.keys(lvl2_value);
              lvl2_keys.forEach(key => {
                // We check if it's array or object. If it's object, we use the 3 selections blacklist
                if (typeof(lvl2_value[key] === 'object') && !(lvl2_value[key].constructor == Array)) {
                  const toCheck = JSON.stringify([permutations[p][oj_key], permutations[p][io_key], permutations[p][Number(key)]]);
                  const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key][key]);
                  const check = blacklistedSelections.indexOf(toCheck);
                  if (check != -1) {
                    valid = false;
                  }
                } else {
                  if (lvl2_value[key].length > 0) { // We check only if there are any correlations
                    const toCheck = JSON.stringify([permutations[p][oj_key], permutations[p][io_key]]);
                    const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key]);
                    // const blacklistedSelections = JSON.stringify(sep_correlations[oj_key][io_key].correlations);
                    const check = blacklistedSelections.indexOf(toCheck);
                    if (check != -1) {
                      valid = false;
                    }
                  }
                }
              });
            }
          }
      }

      if (valid) {
        line.reduce(function (result: any, item: any, index: any, array: any) {
          new_selections_string_object[index].push(item);
        }, {});
        linii++;
      }
    }

    for (let idx = 0; idx < num_legs; idx++) {
      new_selections_string_object[idx] = _.uniq(new_selections_string_object[idx]);
    }

    this.scope.total_lines = linii;
    this.scope.selection_string = this.calculateSelectionString(new_selections_string_object);
    const t1: any = new Date();
    console.log('Total time: ' + (t1 - t0) / 1000);
    return {
      selections_string: this.scope.selection_string,
      total_lines: this.scope.total_lines
    };
  }

  generateSelectionsCombos(list: any, n = 0, result: any = [], current: any = []) {
    if (n === list.length) {
      result.push(current);
    } else {
      list[n].forEach((item: any) => this.generateSelectionsCombos(list, n + 1, result, [...current, item]));
    }

    return result;
  }

  calculateSelectionString(selectionObj: any) {
    let valid_selection_string = '';
    if (this.scope.total_lines > 0) {
      let str = '';
      _.forEach(selectionObj, function (leg, key) {
        str += '/';
        _.forEach(leg, function (bin) {
          str += bin;
          if (_.last(leg) !== bin) {
            str += ',';
          }
        });
      });
      valid_selection_string = str.substr(1);
    } else {
      valid_selection_string = '';
    }
    return valid_selection_string;
  }
}
