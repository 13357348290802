import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {PostMessageService} from "@cbcore/services/utils/postmessage/postmessage.service";

const INTERVAL = 60000;

@Injectable()
export class ActivityService {
  private interval: any;

  constructor(
    private _router: Router,
    private _postMessageService: PostMessageService
  ) {}

  init() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationStart),
      distinctUntilChanged()
    ).subscribe(
      event => this.resetInterval()
    );
  }

  resetInterval() {
    this.removeInterval();
    this.activeUser();
    this.interval = setInterval(() => {
      this.inactiveUser();
    },INTERVAL);
  }

  removeInterval() {
    clearInterval(this.interval);
  }

  activeUser() {
    this._postMessageService.send({
      action: 'KEEP_ALIVE'
    });
    console.warn('User became ACTIVE');
  }

  inactiveUser() {
    this.removeInterval();
    console.warn('User became INACTIVE');
  }
}
