import { NgModule } from '@angular/core';

// import { HttpService } from './http.service';
import { SessionClient } from '../../client/session';
import { PoolClient } from '../../client/pool';
import { TicketClient } from '../../client/ticket';
import { OfferClient } from '../../client/offer';
import { SyndicateClient } from '../../client/syndicate';
import { ManagerClient } from '../../client/manager';
import { TokenClient } from '../../client/token';
import { GiveawayClient } from '../../client/giveaway';
import { ReservedTicketsClient } from '../../client/reserved_tickets';
import { ImageClient } from '../../client/image';
import { UserClient } from '../../client/user';
import { SyndicatePortionClient } from '../../client/syndicate_portions';
import { FxRatesClient } from '../../client/fx_rates';
import { SettingsClient } from '../../client/settings';
import { TrackingClient } from "../../client/tracking";
import { CampaignsClient } from "../../client/campaigns";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import {CommentaryClient} from "@cbcore/client/commentary";
import {GroupTicketClient} from "@cbcore/client/group_tickets";

export * from './http.service';
export * from './auth.interceptor';
export * from '../../client/session';
export * from '../../client/pool';
export * from '../../client/ticket';
export * from '../../client/offer';
export * from '../../client/syndicate';
export * from '../../client/manager';
export * from '../../client/token';
export * from '../../client/giveaway';
export * from '../../client/reserved_tickets';
export * from '../../client/image';
export * from '../../client/user';
export * from '../../client/fx_rates';
export * from '../../client/settings';
export * from '../../client/campaigns';
export * from '../../client/commentary'
export * from '../../client/group_tickets';

@NgModule({
  imports: [],
  providers: [
    SessionClient,
    PoolClient,
    TicketClient,
    OfferClient,
    SyndicateClient,
    ManagerClient,
    TokenClient,
    GiveawayClient,
    ReservedTicketsClient,
    ImageClient,
    UserClient,
    SyndicatePortionClient,
    FxRatesClient,
    SettingsClient,
    AuthInterceptor,
    TrackingClient,
    CampaignsClient,
    CommentaryClient,
    GroupTicketClient,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ]
})
export class ClientModule {
  constructor(_httpService: AuthInterceptor) {
    _httpService.init();
  }
}
