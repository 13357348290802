<ul class="vertical-nav">
  <li class="home focus:shadow-outline" *ngIf="haveHomeLink" [ngClass]="{'active': activated(rootUrl)}">
    <a class="drawer-navlink" routerLink="{{ rootUrl }}" [track]="{event: 'micrositeEvent', 'eventCategory': 'Homepage', 'eventAction': 'Click', 'eventLabel': 'Homepage', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fas fa-home custom-home"></i>
        <span [translate]="'menu.HOME'"></span>
        <!--          <span class="cb-bold" class="freeplay_badge" *ngIf="freeplays && isAgeVerified">{{freeplays}}</span>-->
      </div>
    </a>
  </li>
  <li class="pools focus:shadow-outline 1" *ngIf="noContentLink" [routerLinkActive]="['active']" #rla="routerLinkActive" [ngClass]="{'active': activated('/no-content')}">
    <a class="drawer-navlink" routerLink="/no-content" [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fal fa-list-ul"></i>
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </a>
  </li>
  <li class="pools focus:shadow-outline 2" (click)="setDisplayState('SPORTS')" *ngIf="displayState.microsite_type !== 'ALL' && displayState.microsite_type === 'SPORTS' && !noContentLink" [routerLinkActive]="['active']"  #rla="routerLinkActive" [ngClass]="{'active': activated('/pools/sports')}">
    <a class="drawer-navlink" routerLink="/pools/sports" [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fal fa-list-ul"></i>
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </a>
  </li>
  <li class="racing focus:shadow-outline 3" (click)="setDisplayState('RACING')" *ngIf="displayState.microsite_type !== 'ALL' && displayState.microsite_type === 'RACING' && !noContentLink" [routerLinkActive]="['active']"  #rla="routerLinkActive" [ngClass]="{'active': activated('/pools/racing')}">
    <a class="drawer-navlink" routerLink="/pools/racing" [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Racing', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fal fa-list-ul"></i>
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </a>
  </li>

  <li class="pools focus:shadow-outline 4" (click)="setDisplayState('SPORTS')" *ngIf="displayState.microsite_type === 'ALL' && !noContentLink" [ngClass]="{'active': activated('/pools/sports')}">
    <a class="drawer-navlink" routerLink="/pools/sports" [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon far fa-futbol"></i>
        <span [translate]="'contentType.SPORTS'"></span>
        <!-- {{displayState.view === 'SPORTS'}} / {{ ((displayState.view === 'SPORTS') && !isOnGTicketsPage && !isOnSyndicatesPages) }} -->
      </div>
    </a>
  </li>
  <li class="racing focus:shadow-outline 5" (click)="setDisplayState('RACING')" *ngIf="displayState.microsite_type === 'ALL' && !noContentLink" [ngClass]="{'active': activated('/pools/racing')}">
    <a class="drawer-navlink" routerLink="/pools/racing" [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Racing', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fak fa-racing"></i>
        <span [translate]="'contentType.RACING'"></span>
        <!-- {{displayState.view === 'RACING'}} / {{ (displayState.view === 'RACING' || rla.isActive) }} -->
      </div>
    </a>
  </li>

  <li class="freeplay focus:shadow-outline" *ngIf="enableFreeplay && !hideFreeplayTab && isAgeVerified" [routerLinkActive]="['active']">
    <a class="drawer-navlink" routerLink="/freeplay" [track]="{event: 'micrositeEvent', 'eventCategory': 'Freeplay', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fak fa-freeplay"></i>
        <span [translate]="'poolsMenu.FREEPLAY'"></span>
      </div>
      <span class="fp-badge" *ngIf="freeplays && freeplays > 0 && isAgeVerified">{{freeplays}}</span>
    </a>
  </li>

  <li class="syndicates focus:shadow-outline" *ngIf="syndicatesEnabled && !hideSyndicatesTab" [routerLinkActive]="['active']" #rla="routerLinkActive" [ngClass]="{'active': activated('/syndicates')}">
    <a class="drawer-navlink" (click)="resetSyndicateList()" routerLink="/syndicates" [track]="{event: 'micrositeEvent', 'eventCategory': 'Syndicates', 'eventAction': 'Click', 'eventLabel': 'All', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fas fa-user-friends"></i>
        <span [translate]="'menu.SYNDICATES'"></span>
      </div>
    </a>
  </li>

  <li class="tickets focus:shadow-outline" *ngIf="isLoggedIn && !isCashOut" [routerLinkActive]="['active']" [ngClass]="{'active': activated('/tickets')}">
    <a class="drawer-navlink" (click)="resetTicketFilter()" routerLink="/tickets" [track]="{event: 'micrositeEvent', 'eventCategory': 'Tickets', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fas fa-ticket-alt"></i>
        <span [translate]="'menu.TICKETS'"></span>
      </div>
    </a>
  </li>
  <li class="tickets focus:shadow-outline" *ngIf="isLoggedIn && isCashOut" [routerLinkActive]="['active']">
    <a class="drawer-navlink" (click)="resetTicketFilter()" routerLink="/tickets" [track]="{event: 'micrositeEvent', 'eventCategory': 'Tickets', 'eventAction': 'Click', 'eventLabel': 'Unauth', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fas fa-ticket-alt"></i>
        <span [translate]="'menu.TICKETS'"></span>
      </div>
    </a>
  </li>
  <li class="pools submenu focus:shadow-outline" *ngIf="isLoggedIn && !isCashOut && haveGroupTickets" [routerLinkActive]="['active']">
    <a class="drawer-navlink" routerLink="/tickets/list/group" [queryParams]="{show: 'invitations'}">
      <div class="sidebar-nav-link relative sub">
        <i class="icon"></i>
        <span *ngIf="groupTicketInvites > 1" [innerHTML]="'menu.GT_INVITES' | translate:{count: groupTicketInvites}"></span>
        <span *ngIf="groupTicketInvites === 1" [innerHTML]="'menu.GT_INVITE' | translate"></span>
      </div>
    </a>
  </li>
  <li class="cashout focus:shadow-outline" *ngIf="enableUnauthCashout" [routerLinkActive]="['active']">
    <a class="drawer-navlink" routerLink="/tickets/unauth" [track]="{event: 'micrositeEvent', 'eventCategory': 'CashOut', 'eventAction': 'Click', 'eventLabel': 'Unauth', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon fas fa-ticket-alt"></i>
        <span [translate]="'menu.CASHOUT'"></span>
      </div>
    </a>
  </li>
  <!-- <li class="results focus:shadow-outline">
    <a class="drawer-navlink" routerLink="/results" [track]="{event: 'micrositeEvent', 'eventCategory': 'Results', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon far fa-poll-h"></i>
        <span [translate]="'menu.RESULTS'"></span>
      </div>
    </a>
  </li> -->
  <li class="help focus:shadow-outline help-menu-item" [routerLinkActive]="['active']">
    <a class="drawer-navlink" routerLink="/help" [track]="{event: 'micrositeEvent', 'eventCategory': 'Help', 'eventAction': 'Click', 'eventLabel': '', isTracking: true}">
      <div class="sidebar-nav-link">
        <i class="icon far fa-question-circle"></i>
        <span [translate]="'menu.HELP'"></span>
      </div>
    </a>
  </li>

  <ng-container *ngIf="additionalLeftNavMenu">
    <div class="divider"></div>

    <li class="additional-link focus:shadow-outline"
        *ngIf="true && 'onlyWallet'">

      <a class="drawer-navlink" [routerLink]="['/', 'promotion']">
        <span>Promotions</span>
      </a>
    </li>
    <li class="additional-link focus:shadow-outline"
        *ngIf="isLoggedIn && 'onlyWallet'">
      <a class="drawer-navlink" [routerLink]="['/account/responsiblegambling']">
        <span>Responsible gambling</span>
      </a>
    </li>

    <li class="additional-link focus:shadow-outline"
        *ngIf="false && 'onlyWallet'">

      <a class="drawer-navlink border-t border-tertiary-color" href="https://www.colossusbets.com/blog?utm_source=Colossushome&utm_medium=header">
        <span>Blog</span>
      </a>
    </li>

    <li class="additional-link focus:shadow-outline"
        *ngIf="true && 'onlyWallet'">

      <a class="drawer-navlink" href="{{helpBaseLink + '/?utm_source=Colossus&utm_medium=Header&utm_campaign=Help'}}" target="_blank">
        <span>Help</span>
      </a>
    </li>
  </ng-container>
</ul>
