import { createAction, props } from '@ngrx/store';
import { TicketsScope } from "./tickets.model";

/**
 * The following might be obsolete and have not been moved to NGRX:
 * getFilters | getShared | getCrossSell | getSummary
 *
 * Also, the inStore ones haven't been tested.
 */

// Create ticket (betslip)

export const createTicket = createAction('[Tickets] Create ticket',
  props<{
    model?: any
  }>());

export const setUnauthSecurityCode = createAction('[Tickets] Unauth Set Security Code',
  props<{
    securityCode: string
  }>());

export const successCreateTicket = createAction('[Tickets] Success create ticket',
  props<{
    model?: any
  }>());

// Individual tickets
export const callSoloTicket = createAction('[Ticket] Call solo ticket',
props<{
  ticket_id: string
}>());

export const callSyndicateTicket = createAction('[Ticket] Call syndicate ticket',
props<{
  portion_id: number | string
}>());

export const callGTTicket = createAction('[Ticket] Call GT ticket',
props<{
  pool_id: number,
  manager_id: number | string
}>());

export const successTicket = createAction('[Ticket] Success ticket',
props<{
  response: TicketsScope.TicketItem
}>());

export const successGTTicket = createAction('[Ticket] Success GT ticket',
props<{
  response: TicketsScope.GroupTicketItem
}>());

// Tickets list

export const callTicketList = createAction('[Tickets] Call ticket list',
props<{
  model?: any
}>());

export const loadTicketList = createAction('[Tickets] Load tickets list',
  props<{
    model?: any
  }>());

export const successList = createAction('[Tickets] Success ticket list',
props<{
  response: TicketsScope.TicketList
}>());

export const listLoadMore = createAction('[Tickets] Load more tickets list');

// Syndicate list

export const callSyndicateTicketList = createAction('[Tickets] Call syndicate ticket list',
  props<{
    model?: any
  }>());

export const loadSyndicateTicketList = createAction('[Tickets] Load syndicate tickets list',
  props<{
    model?: any
  }>());

export const syndicateListLoadMore = createAction('[Tickets] Load more syndicate tickets list');

// GT list

export const callGTList = createAction('[Tickets] Call group ticket list',
  props<{
    model?: any
  }>());

export const successGTList = createAction('[Tickets] Success group ticket list',
  props<{
    response: TicketsScope.TicketList
  }>());

export const loadGTTicketList = createAction('[Tickets] Load GT tickets list',
  props<{
    model?: any
  }>());

export const gtTicketListLoadMore = createAction('[Tickets] Load more GT tickets list');
//

export const loadListDispatcher = createAction('[Tickets] Load list dispatcher',
  props<{
    extra?: any
    filters?: any
}>());

export const loadMoreDispatcher = createAction('[Tickets] Load more dispatcher');

// Share

export const shareTicket = createAction('[Ticket] Call share ticket',
props<{
  model?: any
}>());

export const successShareTicket = createAction('[Ticket] Success share ticket',
  props<{
    model?: any
  }>());

// Instore and others....

export const callUnAuthTicket = createAction('[inStore] Call unAuth ticket',
  props<{
    merchant_ref: string,
    security_code?: string
  }>());

// this works as normal ticket in middleware
// export const successUnAuthTicket = createAction('[inStore] Success unAuth ticket',
// props<{
//   model?: any
// }>());

export const callInstoreTicket = createAction('[inStore] Call inStore ticket',
props<{
  merchant_ref: string,
  security_code?: string
}>());

export const successInstoreTicket = createAction('[inStore] Success inStore ticket',
props<{
  model?: any
}>());

export const updateSecurityCode = createAction('[inStore] Update security code',
props<{
  merchant_ref: string,
  body: any
}>());

export const successSecurityCode = createAction('[inStore] Success security code',
props<{
  model?: any
}>());

export const callMarkAsPaid = createAction('[inStore] Call mark as paid',
props<{
  merchant_ref: string,
  body: any
}>());

export const successMarkAsPaid = createAction('[inStore] Success mark as paid',
props<{
  model?: any
}>());

// errors

export const failTicket = createAction('[Ticket] Fail',
  props<{
    error: any
  }>());

export const failTicketList = createAction('[Ticket] List Fail',
  props<{
    error: any
  }>());


export const failGTTicketList = createAction('[Ticket] GT List Fail',
  props<{
    error: any
  }>());
