import { Injectable } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';


// import { ShareTicketsModule } from '../../components/shared-ticket/shared-ticket.module';
import { SharedTicketViewComponent } from './shared-ticket/shared-ticket';

const appRoutes: Routes = [
  { path: 'sharedtickets/:ticket_id', component: SharedTicketViewComponent }
];

@Injectable()
@NgModule({
  imports: [
    // ShareTicketsModule,
    // RouterModule.forRoot(appRoutes),
  ],
  declarations: [
    SharedTicketViewComponent
  ],
  exports: []
})
export class SharedTicketModule { }
