import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class GiveawayClient {
  public endpoint: string;

  constructor(public http: HttpClient, public _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/giveaways';
  }

  claim(id: string): Observable<any> {
    return this.http.post(this.endpoint + '/' + id + '/claim', {})
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

}
