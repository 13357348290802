import { ConfigurationState } from './configuration.models';
import { setDisplayState, setLocalSettings, successCoreSettings, successFxRates } from './configuration.actions';
import { createReducer, on, Action } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Config } from "../../services/config/config.service";

export const initialState: ConfigurationState = {
  coreSettings            : undefined,  
  localSettings           : _.cloneDeep(new Config().CONFIG),
  fxRates                 : undefined,
  // computed
  
  // isLoaded: true,
  displayState            : {
    microsite_type: null,
    view: null,
    sport_category_id: null,
    display_sports: 'display_groups',
    display_racing: 'display_groups',
    display_fantasy: 'display_groups',
    display_results: 'running_pools'
  },

  win_commission: undefined,
  wager_tax: undefined,
  settlement_commission: undefined,
  micrositeCurrency: undefined,
  
  max_group_tickets: undefined,
  commentarySettings: undefined
};

const reducer = createReducer(
  initialState,
  on(successCoreSettings, (state, action) => {
    // add core settings
    let finalSettings = _.cloneDeep(Object.assign({}, state.coreSettings, action.response));
    // REMOVE WHEN DEPLOYED THE NEW VERSION - replaces HORSE_RACING with RACING in sport_categories
    const sport_categories = finalSettings.sport_categories;
    finalSettings.sport_categories = sport_categories.map((c: any) => {
      c.code = c.code === 'HORSE_RACING' ? 'RACING' : c.code;
      return c;
    });

    // group tickets
    const shouldInitGroupTickets = !!(state.localSettings.haveGroupedTickets && action.response.max_grouped_tickets)

    // commentary
    const isCommentaryActive = state.localSettings.isCommentaryActive ? (action.response.post_delete_window_in_miliseconds && action.response.post_edit_window_in_miliseconds) : false;
    let newLocalSettings = _.cloneDeep(Object.assign({}, state.localSettings, { isCommentaryActive: isCommentaryActive }));

    // prizes
    const hasSettlementThresholdCommision = action.response.settlement_threshold_commission && action.response.currency_threshold;
    return {
      ...state,

      // add core settings
      coreSettings: finalSettings,

      // group tickets
      max_group_tickets: shouldInitGroupTickets ? action.response.max_group_tickets : state.max_group_tickets,

      // commentary
      localSettings: !isCommentaryActive ? newLocalSettings : state.localSettings,
      commentarySettings: isCommentaryActive ? {
        delete: action.response.post_delete_window_in_miliseconds,
        edit: action.response.post_edit_window_in_miliseconds
      } : state.commentarySettings,


      // prizes
      win_commission: action.response.win_commission,
      wager_tax: action.response.reversed_wager_tax,
      micrositeCurrency: action.response.default_currency,
      settlement_comission: !hasSettlementThresholdCommision ? null : {
        settlement_threshold_commission: action.response.settlement_threshold_commission,
        currency_threshold: action.response.currency_threshold
      },

      isLoaded: true
    } 
  }),

  /* START COMPUTED */

  // group tickets related
  // on(successCoreSettings, (state, action) => {
  //   if(state.localSettings.haveGroupedTickets && action.response.max_grouped_tickets)
  //     return {
  //       ...state,
  //       max_group_tickets: action.response.max_group_tickets
  //     }

  //   return {
  //     ...state
  //   } 
  // }),

  // commentary related
  // on(successCoreSettings, (state, action) => {
  //   const isCommentaryActive = state.localSettings.isCommentaryActive ? (action.response.post_delete_window_in_miliseconds && action.response.post_edit_window_in_miliseconds) : false;
  //   let newLocalSettings = _.cloneDeep(Object.assign({}, state.localSettings, { isCommentaryActive: isCommentaryActive }));
    
  //   if(!isCommentaryActive) return { ...state, localSettings: newLocalSettings };

  //   return {
  //     ...state,
  //     commentarySettings: {
  //       delete: action.response.post_delete_window_in_miliseconds,
  //       edit: action.response.post_edit_window_in_miliseconds
  //     }
  //   } 
  // }),

  // prizes related?
  // on(successCoreSettings, (state, action) => {

  //   // prizes
  //   const hasSettlementThresholdCommision = action.response.settlement_threshold_commission && action.response.currency_threshold;
    
  //   return {
  //     ...state,
      
  //     // prizes
  //     win_commission: action.response.win_commission,
  //     wager_tax: action.response.reversed_wager_tax,
  //     micrositeCurrency: action.response.default_currency,
  //     settlement_comission: !hasSettlementThresholdCommision ? null : {
  //       settlement_threshold_commission: action.response.settlement_threshold_commission,
  //       currency_threshold: action.response.currency_threshold
  //     }
  //   } 
  // }),

  /* END COMPUTED */

  on(successFxRates, (state, action) => {
    return {
      ...state,
      fxRates: action.response
    } 
  }),

  on(setDisplayState, (state, action) => {
    let newDisplayState = _.cloneDeep(Object.assign({}, state.displayState, action.displayState));
    newDisplayState.sport_category_id = _.result(
      _.find(state.coreSettings?.sport_categories, { code: newDisplayState.view }),
      'id'
    );

    return {
      ...state,
      // computed keys
      displayState: newDisplayState
    } 
  }),

  on(setLocalSettings, (state, action) => {
    let finalSettings = _.cloneDeep(Object.assign({}, state.coreSettings, action.localSettings));
    return {
      ...state,

      localSettings: finalSettings,
    } 
  }),
);

export const ws = {
  RELOAD_EVENTS: [
    'MICROSITE_AUTHENTICATION_REFRESH', 'USER_REGISTER', 'USER_LOGIN', 'USER_LOGOUT' 
  ]
}


export function configurationReducer(
  state: ConfigurationState | undefined,
  action: Action
): ConfigurationState {
  return reducer(state, action);
}

/*

  walletSettings.dob was hardcoded on the registration component
  localSettings is filled using the actual core Config service because I found no other way for an override and the server config to reach this reducer in any other way
  
  Still localSettings are used from the service not this store because it's not convenient to get it asynchronous when you don't need to.
  The main reason for localSettings to be added here is because in the future, composed selectors can be made so components will get data proprietary to their project config without including the configuration at all in the component or effect.

*/