import { Injectable, NgModule } from '@angular/core';

import { MenuModule } from './components/menu/menu.module';
import { NotificationsModule } from './components/notifications/notification.module';
//import { ErrorModule } from './modules/errors/errors.module';
//import { PoolsModule } from './modules/pools/pools.module';
//import { TicketsModule } from './modules/tickets/tickets.module';
//import { SyndicatesModule } from './modules/syndicates/syndicates.module';
//import { FreeplayModule } from './modules/freeplay/freeplay.module';
//import { ResultsModule } from './modules/results/results.module';
import { SharedTicketModule } from './modules/shared/shared-ticket.module';
//import { HelpModule } from './modules/help/help.module';
import { UICommonModule } from './helpers/helpers.module';
import { SyndicateShareModule } from './components/syndicate-share/syndicate-share.module';
import { SyndicateProfileRebateModule } from './components/syndicate-profile-rebate/syndicate-profile-rebate.module';
//import { HomepagePageModule } from './modules/homepage/homepage.module';
import { CentralisedRoutingModule } from './modules/centralises-routing/centralises-routing.module';
import { SyndicateProfileCreateModule } from './components/syndicate-profile-create/syndicate-profile-create.module';

//export * from './modules/homepage/homepage.module';

@Injectable()
@NgModule({
  imports: [
    MenuModule,
    NotificationsModule,
    //ErrorModule,
    //PoolsModule,
    //TicketsModule,
    //SyndicatesModule,
    //FreeplayModule,
   //ResultsModule,
    SharedTicketModule,
    //HelpModule,
    //HomepagePageModule,
    SyndicateProfileRebateModule,
    CentralisedRoutingModule,
    UICommonModule,
    SyndicateProfileCreateModule
  ],
  exports: [
    MenuModule,
    UICommonModule,
    NotificationsModule,
    SyndicateShareModule,
    SyndicateProfileRebateModule,
    CentralisedRoutingModule,
   //HomepagePageModule,
    SyndicateProfileCreateModule
  ]
})
export class MicrositeModule { }
