import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { ImageClient } from './../../../client/image';
import { Config } from '../../config/config.service';

@Injectable()
export class ImageService {
  constructor(private _imagesClient: ImageClient, private _config: Config) { }

  getAvatars() {
    let avatarList: Array<string> = [];

    const obsv = this._imagesClient.getConfig().subscribe(result => {
      avatarList = result;
    }, error => {
      avatarList.push(this._config.CONFIG.imagesUrl + '/images/useravatars/default.jpg');
    }, () => {
      obsv.unsubscribe();
    });

    return avatarList;
  }

  createImageList(path: any, config: any) {
    const imageList: Array<string> = [];

    _.times(config.total_items, function (index) {
      imageList.push(path + '/images/useravatars/' + config.prefix + (index + 1) + config.suffix);
    });

    return _.shuffle(imageList);
  }

  async uploadImage(type?: any): Promise<any> {
    return null;
  }
}
