import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppState, track } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';

@Injectable()
export class ManagerClient {
  public endpoint: string;

  constructor(
    private http: HttpClient,
    private _config: Config,
    private store: Store<AppState>
  ) {
    this.endpoint = _config.CONFIG.apiUrl + '/managers';
  }

  list(model: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('page', model.page.toString())
      .set('per', model.per.toString())
      .set('filters[followed]', model.filters.followed);
    if (model.filters.search !== 'undefined') {
      params.set('filters[search]', model.filters.search);
    }

    Object.keys(model.filters).forEach((key, index) => {
      if (model.filters[key]) {
        params = params.set(`filters[${key}]`, model.filters[key]);
      }
    });

    return this.http.get(this.endpoint, { params }).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  get(id: number): Observable<any> {
    if (!id) {
      return of(EMPTY);
    }
    let params: HttpParams = new HttpParams().set(
      'includes',
      'manager_rankings,manager_counters,favourite_syndicates.pool,favourite_syndicates.manager,live_rebate_rate'
    );

    return this.http.get(this.endpoint + '/' + id, { params }).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  create(manager: any): Observable<any> {
    return this.http.post(this.endpoint, manager).pipe(
      map(data => {
        const trackingObject = {
          eventCategory: 'manager',
          eventAction: 'microsite',
          eventLabel: 'create',
          eventValue: (<any>data).id
        }

        this.store.dispatch(track({event: trackingObject}));

        return data;
      }),
      catchError(err => throwError(err))
    );
  }

  update(manager: any): Observable<any> {
    return this.http.put(this.endpoint + '/' + manager.id, manager).pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  unfollow(id: string): Observable<any> {
    return this.http.delete(this.endpoint + '/unfollow/' + id)
      .pipe(
        map(data => {
          const trackingObject = {
            'event': 'micrositeEvent',
            'eventCategory': 'Captain',
            'eventAction': 'Click',
            'eventLabel': 'Unfollow',
            'eventValue': id
          }

          this.store.dispatch(track({event: trackingObject}));

          return data
        }),
        catchError(err => throwError(err))
      );
  }

  share(body: any): Observable<any> {
    let params: HttpParams = new HttpParams().set('no_serializer', 'true');

    return this.http
      .post(this.endpoint + '/' + body.id + '/share', body, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  follow(id: string): Observable<any> {
    return this.http.get(this.endpoint + '/follow/' + id)
      .pipe(
        map(data => {
          const trackingObject = {
            'event': 'micrositeEvent',
            'eventCategory': 'Captain',
            'eventAction': 'Click',
            'eventLabel': 'Follow',
            'eventValue': id
          }

          this.store.dispatch(track({event: trackingObject}));

          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  subscribe(id: any): Observable<any> {
    return this.http.post(this.endpoint + '/subscribe/' + id, {})
      .pipe(
        map(data => {
          const trackingObject = {
            'event': 'micrositeEvent',
            'eventCategory': 'Captain',
            'eventAction': 'Click',
            'eventLabel': 'Subscribe',
            'eventValue': id
          }

          this.store.dispatch(track({event: trackingObject}));

          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  unSubscribe(id: any): Observable<any> {
    return this.http.post(this.endpoint + '/unsubscribe/' + id, {})
      .pipe(
        map(data => {
          const trackingObject = {
            'event': 'micrositeEvent',
            'eventCategory': 'Captain',
            'eventAction': 'Click',
            'eventLabel': 'Unsubscribe',
            'eventValue': id
          }

          this.store.dispatch(track({event: trackingObject}));

          return data;
        }),
        catchError(err => throwError(err))
      );
  }

  get_leaderboard(model: any): Observable<any> {
    let params: HttpParams = new HttpParams()
      .set('sort_by', model.sort_by)
      .set('period', model.period.id)
      .set('sport_category', model.sport_category.id)
      .set('per', model.per)
      .set('page', model.page)
      .set('open_syndicates', model.open_syndicates);



    return this.http.get(this.endpoint + '/leaderboard/', { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  get_leaderboard_periods(): Observable<any> {
    return this.http.get(this.endpoint + '/rank_periods/').pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }

  get_top_followed(): Observable<any> {
    // return of(
    //   { "items": [{ "username": "Shelby1X2", "picture_url": "https://colossusbets-managers.s3.amazonaws.com/production/TommyShelby/30820_b635e99a.png", "open_syndicates_count": 106, "id": 30820 }, { "username": "Yliporomies", "picture_url": "//images.colossusbets.com/images/useravatars/Avatar_213.jpg", "open_syndicates_count": 25, "id": 100008512 }, { "username": "im the bkpool tower", "picture_url": "//images.colossusbets.com/images/useravatars/Avatar_32.jpg", "open_syndicates_count": 24, "id": 5111 }, { "username": "going for gold", "picture_url": "//images.colossusbets.com/images/useravatars/captain-goingforgold.jpg", "open_syndicates_count": 11, "id": 359 }], "total_items": 4 }
    // )
    return this.http.get(this.endpoint + '/top_followed').pipe(
      map(data => data),
      catchError(err => throwError(err))
    );
  }
}
