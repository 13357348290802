import { Injectable }  from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Config }      from './../config/config.service';;

@Injectable()
export class CashoutRouteGuard implements CanActivate {
  constructor(private _config: Config, private _router: Router) {}
  canActivate() {
    return this.checkEnabled();
  }

  checkEnabled(): boolean {
    if (this._config.CONFIG.enableUnauthCashout) { return true; }

    this._router.navigate(['pools']);
    return false;
  }
}
