// tslint:disable:max-line-length
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {Config} from "@cbcore/services/config/config.service";
import {CalculationsService} from "@cbcore/services/utils/calculations/calculations.service";
import {CordovaService} from "@cbcore/services/utils/cordova/cordova.service";
import {LinkService} from "@cbcore/services/utils/links/links.service";
import {ManagerClient} from "@cbcore/client/manager";
import {RAFService} from "@cbcore/services/utils/raf/raf.service";
import {TranslationService} from "@cbcore/services/translation/translation.service";
import {WhatsappService} from "@cbcore/services/utils/social-networks/whatsapp.service";
import {WindowService} from "@cbcore/services/utils/window/window.service";

import { AppState, track } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'syndicate-share-list',
  templateUrl: './list-share.html'
})

export class ListShareComponent {
  public $subscribers: any = {};

  @Input() isSmall: boolean;
  @Input('managerId') managerId: string;
  @Input('managerName') managerName: string;
  @Input('syndicatesCounter') syndicatesCounter: any;
  @Input('linkData') linkData?: any;

  @Input('state') state?: string = 'AVAILABLE';

  public shareData: any;
  public _window: any;

  public shareSuccess: boolean = false;
  public shareError: boolean = false;
  public shareLoading: boolean = false;

  public isMobile: boolean;
  public sharingEnabled: boolean;
  public shareListEnabled: boolean;
  public socialNetworks: any;
  public currentLang: string;

  public sharingTextOptions: number;
  public linkCopied: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _managerClient: ManagerClient,
    private _whatsappService: WhatsappService,
    private _cordovaService: CordovaService,
    private _config: Config,
    private _translateService: TranslateService,
    private _windowService: WindowService,
    private _translationService: TranslationService,
    private _rafService: RAFService,
    private _linkService: LinkService,
    private _calculationsService: CalculationsService,
    private store: Store<AppState>
    // private _trackingService: TrackingService
  ) {
  }

  ngOnInit(): void {
    this.isMobile = this._config.CONFIG.isMobile;
    this.sharingEnabled = this._config.CONFIG.enableShare && !this._activatedRoute.snapshot.data.sharingdisabled;
    this.shareListEnabled = this._config.CONFIG.shareListEnabled;
    this.socialNetworks = this._config.CONFIG.socialNetworks;
    this._window = this._windowService.nativeWindow;
    this.currentLang = this._translationService.getLanguage();
    this.sharingTextOptions = this._config.CONFIG.shareListTextVersions || 1;
  }

  share(): void {
    this.shareLoading = true;

    let serviceUrl: string = this._config.CONFIG.templateServiceUrl + '&tz=' + this.getTimezoneOffset() + '&lang=' + this._translationService.getLanguage() || (this._window.location.protocol + '//' + this._window.location.host + '/sharedtickets/[UUID_CODE]');

    let body: any = {
      id: this.managerId,
      share_type: 'LIST_SYNDICATES_AVAILABLE',
      affiliate_redirect_url: this.getRedirectUrl(),
      site_name: this._config.CONFIG.twitterVia,
      shared_ticket_title: this._config.CONFIG.sharedTicketTitle,
      shared_ticket_description: this._translateService.instant('share.CARD_DESCRIPTION'),
      no_serializer: true,
      template_service_url: serviceUrl
    };

    this.$subscribers.share = this._managerClient.share(body).subscribe(
      data => {
        this.shareSuccess = true;
        this.shareLoading = false;
        this.updateData(data);
      },
      err => {
        this.shareError = true;
        this.shareLoading = false;
      }
    );
  }

  openLink(url: string): void {
    const eventValue = {
      manager_id: this.managerId,
      platform: url.includes('twitter') ? 'twitter' : url.includes('facebook') ? 'facebook' : 'whatsapp'
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'Manager',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));

    this._linkService.openLink(url);
  }

  updateData(data: any): void {
    if (data.snapshot) {
      this.shareData = data;
      // social networks messages
      this.shareData.message = this.composeText();
      this.shareData.messageWs = this.composeWhatsapp() ? this.composeWhatsapp() : this.shareData.message;

      this.shareData.twitterUrl = 'https://www.twitter.com/intent/tweet?text=' + this.shareData.message + '&url=' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.twitter);
      this.shareData.whatsappUrl = 'whatsapp://send?text=' + this.shareData.messageWs + ' ' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.whatsapp);
      this.shareData.facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.facebook);
    } else {
      this.shareError = true;
    }
  }

  composeText(): any {
    let message: any = undefined;

    let randomValue: number = Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;

    if (this._config.CONFIG.shareListTextDynamic) {
      if (this.sharingTextOptions > 1) {
        message = this._translateService.instant('socialSharing.MESSAGE_LIST_' + randomValue);
      } else {
        message = this._translateService.instant('socialSharing.MESSAGE_LIST');
      }
    } else {
      message = this._translateService.instant('socialSharing.MESSAGE_LIST');
    }


    message = this.replaceValues(message);
    return message;
  }

  replaceValues(message: string): any {
    if (!message) {
      return;
    }


    message = message.replace('[via]', this._config.CONFIG.twitterVia);
    message = message.replace('[counter]', this.syndicatesCounter);
    message = message.replace('[manager]', this.managerName);

    message = encodeURIComponent(message);
    return message;
  }

  composeWhatsapp(): any {
    let messageKey: any = undefined;
    let randomValue: number = Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;

    if (this.sharingTextOptions > 1) {
      messageKey = 'socialSharing.MESSAGE_LIST' + randomValue + '_WS';
    } else {
      messageKey = 'socialSharing.MESSAGE_LIST_' + randomValue + '_WS';
    }
    // tslint:disable-next-line:typedef
    const hasMessage = () => (this._translateService.instant(messageKey) !== messageKey) && this._config.CONFIG.shareSyndicateDynamicText;

    if (!hasMessage()) return null;

    let message: any = undefined;
    message = this._translateService.instant(messageKey);
    message = this.replaceValues(message);
    return message;
  }

  getRedirectUrl(): any {
    return this._calculationsService.uiGetListRedirectUrl(this.getUtmParams(), this.managerId, this._config.CONFIG.affiliatesUrl, this._translationService.getLanguage());
  }

  getTimezoneOffset(): string {
    return this._calculationsService.uiGetTimezoneOffset();
  }

  isMobileDevice(): boolean {
    return this._cordovaService.isAndroid() || this._cordovaService.isIOS();
  }

  whatsapp(): void {
    const eventValue = {
      manager_id: this.managerId,
      platform: 'whatsapp'
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'Manager',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));

    this._whatsappService.shareLink(this.shareData);
  }

  getUtmParams(): string {
    let cb_ref_code: string = this._rafService.getCode();
    let utm_term = 'list_syndicates_available';

    const utmParameters = {
      ...(cb_ref_code && {cb_ref_code: cb_ref_code}),
      ...(utm_term && {utm_term: utm_term}),
    }

    return '?' + Object.keys(utmParameters).map(key => {
      return key + '=' + utmParameters[key].toLowerCase()
    }).join('&');
  }

  copyLink($event: any): void {
    if (this.linkCopied)
      return;

    let redirect_url: any;
    let affiliatesUrl: string = this._config.CONFIG.affiliatesUrl;

    if (this.linkData.type === 'profile') {
      redirect_url = '/syndicates/profile/' + this.managerId;
    }

    // tslint:disable-next-line:max-line-length
    affiliatesUrl = /(redirectUrl=\[REDIRECT_URL\])/.exec(affiliatesUrl)
      ? affiliatesUrl.replace('redirectUrl=[REDIRECT_URL]', '')
      : affiliatesUrl;

    // tslint:disable-next-line:max-line-length
    let url: any = this._config.CONFIG.affiliatesUrl
      ? affiliatesUrl + '?redirectUrl=' + redirect_url
      : this._window.location.protocol +
      '//' +
      this._window.location.host +
      redirect_url;

    let result: any = this._calculationsService.uiGlobalCopyTextToClipboard(url);
    if(this.isMobile) {
      url = url.replace('?redirectUrl=', '');
    }
    if (result) {
      this.linkCopied = true;
    }
  }
}
