<div id="cropper" #cropper>
  <div class="clearfix">
    <div class="crop-area" #cropArea>
      <i class="cb-icon-image small"></i>
      <i class="cb-icon-image big"></i>
    </div>
    <div class="flex flex-row w-full justify-between items-center">
      <button (click)="createAvatar()" class="btn secondary w-button" [translate]="'buttons.APPLY'"></button>
      <button (click)="cancelCrop()" class="btn tertiary w-button" [translate]="'buttons.CANCEL'"></button>
    </div>
  </div>
</div>

