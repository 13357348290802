// tslint:disable:max-line-length
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


import {CalculationsService} from "@cbcore/services/utils/calculations/calculations.service";
import {Config} from "@cbcore/services/config/config.service";
import {CordovaService} from "@cbcore/services/utils/cordova/cordova.service";
import {LinkService} from "@cbcore/services/utils/links/links.service";
import {RAFService} from "@cbcore/services/utils/raf/raf.service";
import {TranslationService} from "@cbcore/services/translation/translation.service";
import {UserClient} from "@cbcore/client/user";
import {WhatsappService} from "@cbcore/services/utils/social-networks/whatsapp.service";
import {WindowService} from "@cbcore/services/utils/window/window.service";

import { AppState, track } from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'users-share',
  templateUrl: './users-share.html',
  styleUrls: ['../../syndicate-share.scss']
})

export class UsersShareComponent {
  public $subscribers: any = {};

  @Input('userId') userId: string;
  @Input('rafCode') rafCode: string;

  public shareData: any;
  public _window: any;

  public shareSuccess: boolean = false;
  public shareError: boolean = false;
  public shareLoading: boolean = false;

  public sharingEnabled: boolean;
  public shareRafEnabled: boolean;
  public socialNetworks: any;
  public currentLang: string;

  public sharingTextOptions: number;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _usersClient: UserClient,
    private _whatsappService: WhatsappService,
    private _cordovaService: CordovaService,
    private _config: Config,
    private _translateService: TranslateService,
    private _windowService: WindowService,
    private _translationService: TranslationService,
    private _rafService: RAFService,
    private _cdr: ChangeDetectorRef,
    private _linkService: LinkService,
    private _calculationsService: CalculationsService,
    private store: Store<AppState>
    // private _trackingService: TrackingService
  ) {
  }

  ngOnInit(): void {
    this.sharingEnabled = this._config.CONFIG.enableShare && !this._activatedRoute.snapshot.data.sharingdisabled;
    this.shareRafEnabled = this._config.CONFIG.shareRafEnabled;
    this.socialNetworks = this._config.CONFIG.socialNetworks;
    this._window = this._windowService.nativeWindow;
    this.currentLang = this._translationService.getLanguage();
    this.sharingTextOptions = this._config.CONFIG.shareRafTextVersions || 1;
  }

  share(): void {
    this.shareLoading = true;

    let serviceUrl: string = this._config.CONFIG.templateServiceUrl + '&tz=' + this.getTimezoneOffset() + '&lang=' + this._translationService.getLanguage() || (this._window.location.protocol + '//' + this._window.location.host + '/sharedtickets/[UUID_CODE]');

    let body: any = {
      id: this.userId,
      share_type: 'RAF_CODE',
      affiliate_redirect_url: this.getRedirectUrl(),
      site_name: this._config.CONFIG.twitterVia,
      shared_ticket_title: this._config.CONFIG.sharedTicketTitle,
      shared_ticket_description: this._translateService.instant('share.CARD_DESCRIPTION'),
      no_serializer: true,
      template_service_url: serviceUrl,
      shareable_data: {
        raf_code: this.rafCode
      }
    };


    this.$subscribers.share = this._usersClient.share(body).subscribe(
      data => {
        this.shareSuccess = true;
        this.shareLoading = false;
        this.updateData(data);
      },
      err => {
        this.shareError = true;
        this.shareLoading = false;
      }
    );
  }

  openLink(url: string): void {
    const eventValue = {
      platform: url.includes('twitter') ? 'twitter' : url.includes('facebook') ? 'facebook' : 'whatsapp',
      username: this.userId
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'User',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));

    this._linkService.openLink(url);
  }

  updateData(data: any): void {
    if (data.snapshot) {
      this.shareData = data;

      // social networks messages
      this.shareData.message = this.composeText();
      this.shareData.messageWs = this.composeWhatsapp() ? this.composeWhatsapp() : this.shareData.message;

      this.shareData.twitterUrl = 'https://www.twitter.com/intent/tweet?text=' + this.shareData.message + '&url=' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.twitter);
      this.shareData.whatsappUrl = 'whatsapp://send?text=' + this.shareData.messageWs + ' ' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.whatsapp);
      this.shareData.facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.shareData.short_url + '?p=' + this.shareData.platforms.facebook);
    } else {
      this.shareError = true;
    }
    this._cdr.markForCheck();
  }

  composeText(): any {
    let message: any = undefined;

    let randomValue: number = Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;

    if (this.sharingTextOptions > 1) {
      message = this._translateService.instant('socialSharing.MESSAGE_RAF_' + randomValue);
    } else {
      message = this._translateService.instant('socialSharing.MESSAGE_RAF');
    }


    message = this.replaceValues(message);
    return message;
  }

  replaceValues(message: string): any {
    if (!message)
      return;

    message = message.replace('[via]', this._config.CONFIG.twitterVia);
    message = message.replace('[RAF_CODE]', this.rafCode);

    message = encodeURIComponent(message);
    return message;
  }

  composeWhatsapp(): any {
    let messageKey: any = undefined;
    let randomValue: number = Math.floor(Math.random() * (this.sharingTextOptions - 1 + 1)) + 1;

    if (this.sharingTextOptions > 1) {
      messageKey = 'socialSharing.MESSAGE_LIST' + randomValue + '_WS';
    } else {
      messageKey = 'socialSharing.MESSAGE_LIST_' + randomValue + '_WS';
    }
    // tslint:disable-next-line:typedef
    const hasMessage = () => (this._translateService.instant(messageKey) !== messageKey) && this._config.CONFIG.shareSyndicateDynamicText;

    if (!hasMessage()) return null;

    let message: any = undefined;
    message = this._translateService.instant(messageKey);
    message = this.replaceValues(message);
    return message;
  }

  getRedirectUrl(): any {
    return this._calculationsService.uiGetUsersRedirectUrl(this.getUtmParams(), this._config.CONFIG.affiliatesUrl, this._translationService.getLanguage());
  }

  getTimezoneOffset(): string {
    return this._calculationsService.uiGetTimezoneOffset();
  }

  isMobileDevice(): boolean {
    return this._cordovaService.isAndroid() || this._cordovaService.isIOS();
  }

  whatsapp(): void {
    const eventValue = {
      platform: 'whatsapp',
      username: this.userId
    }

    const trackingObject = {
      event: 'micrositeEvent',
      eventCategory: 'User',
      eventAction: 'Click',
      eventLabel: 'Share',
      eventValue: encodeURIComponent(JSON.stringify(eventValue))
    }

    this.store.dispatch(track({event: trackingObject}));

    this._whatsappService.shareLink(this.shareData);
  }

  getUtmParams(): string {
    let cb_ref_code: string = this._rafService.getCode();
    let utm_term = 'raf_code';
    let registration = true;

    const utmParameters = {
      ...(cb_ref_code && {cb_ref_code: cb_ref_code}),
      ...(utm_term && {utm_term: utm_term}),
      ...(registration && {registration: registration})
    }

    return '?' + Object.keys(utmParameters).map(key => {

      return key + '=' + String(utmParameters[key]).toLowerCase()
    }).join('&');
  }
}
