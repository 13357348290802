import { createFeatureSelector, createSelector } from '@ngrx/store'

import { TicketsState } from "../../NGRX/tickets/tickets.model";
import { AppState } from "../../NGRX/core.state";

export const selectTicketState = createFeatureSelector<AppState, TicketsState>('tickets');

export const selectTicketsList = createSelector(selectTicketState, (state: TicketsState) => state.list);

export const selectTicket = createSelector(selectTicketState, (state: TicketsState) => state.current_ticket);

export const seletctTicketLoadingError = createSelector(selectTicketState, (state: TicketsState) => state.ticketLoadError);

export const selectGroupTicket = createSelector(selectTicketState, (state: TicketsState) => state.group_ticket);

export const selectTicketFilters = createSelector(selectTicketState, (state: TicketsState) => state.pagination);

export const selectLoadingStatus = createSelector(selectTicketState, (state: TicketsState) => state.isLoading);

export const selectIsSyndicate = createSelector(selectTicketState, (state: TicketsState) => state.isSyndicate);

export const selectUnauthSecurityCode = createSelector(selectTicketState, (state: TicketsState) => state.unauthSecurityCode);
