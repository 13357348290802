import { NgModule, Injectable, ErrorHandler } from '@angular/core';
// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/angular";
// for A15 when migration complete
// import * as Sentry from "@sentry/angular-ivy";
import { SentryService } from './sentry.service';
import { Config } from "../config/config.service";
import { Router } from '@angular/router';

// export * from './sentry.service';

// not used anymore
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() { }
//   handleError(error: any) {
//     // Sentry.
//     Sentry.captureException(error.originalError || error);
//     throw error;
//   }
// }

@Injectable()
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    SentryService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },

    // optional for tracing
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // }
  ]
})

export class SentryModule {
  constructor(
      _sentryService: SentryService,
      _config: Config,

      // optional for tracing
      trace: Sentry.TraceService
    ) {
        if(_config.CONFIG.isMobile) return;
        _sentryService.init();


  }
}
