<nav id="mobile-menu-container" [ngClass]="{'fixed': navIsFixed}">
  <a routerLink="{{ rootUrl }}"
     *ngIf="haveHomeLink" [ngClass]="{'active': activated(rootUrl)}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Homepage', 'eventAction': 'Click', 'eventLabel': 'Homepage', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fas fa-home custom-home"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.HOME'"></span>
<!--        <span class="freeplay_badge" *ngIf="freeplays && isAgeVerified">{{freeplays}}</span>-->
      </div>
    </div>
  </a>

  <a routerLink="/no-content"
     *ngIf="noContentLink" [routerLinkActive]="['active']" #rla="routerLinkActive" [ngClass]="{'active': activated('/no-content')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="fal fa-list-ul"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </div>
  </a>

  <a routerLink="/pools/sports" (click)="setDisplayState('SPORTS')"
     *ngIf="displayState.microsite_type !== 'ALL' && displayState.microsite_type === 'SPORTS' && !noContentLink" [routerLinkActive]="['active']"  #rla="routerLinkActive" [ngClass]="{'active': activated('/pools/sports')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="fal fa-list-ul"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </div>
  </a>

  <a routerLink="/pools/racing" (click)="setDisplayState('RACING')"
     *ngIf="displayState.microsite_type !== 'ALL' && displayState.microsite_type === 'RACING' && !noContentLink" [routerLinkActive]="['active']"  #rla="routerLinkActive" [ngClass]="{'active': activated('/pools/racing')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Racing', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="fal fa-list-ul"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.POOLS'"></span>
      </div>
    </div>
  </a>

  <a routerLink="/pools/sports" (click)="setDisplayState('SPORTS')"
     *ngIf="displayState.microsite_type === 'ALL' && !noContentLink" [ngClass]="{'active': activated('/pools/sports')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Sports', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon far fa-futbol"></i>
      </div>
      <div class="mobile-nav-label">
        <span  [translate]="'contentType.SPORTS'"></span>
      </div>
    </div>
  </a>

  <a routerLink="/pools/racing" (click)="setDisplayState('RACING')"
     *ngIf="displayState.microsite_type === 'ALL' && !noContentLink" [ngClass]="{'active': activated('/pools/racing')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Pools_List', 'eventAction': 'Click', 'eventLabel': 'Racing', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fak fa-racing"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'contentType.RACING'"></span>
      </div>
    </div>
  </a>

  <a class="freeplay" routerLink="/freeplay"
     *ngIf="enableFreeplay && !hideFreeplayTab && isAgeVerified" [routerLinkActive]="['active']"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Freeplay', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fak fa-freeplay"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'poolsMenu.FREEPLAY'"></span>
      </div>
      <span class="fp-badge" *ngIf="freeplays && freeplays > 0 && isAgeVerified">{{freeplays}}</span>
    </div>
  </a>

  <a (click)="resetSyndicateList()" routerLink="/syndicates"
     *ngIf="syndicatesEnabled && !hideSyndicatesTab" [routerLinkActive]="['active']" #rla="routerLinkActive" [ngClass]="{'active': activated('/syndicates')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Syndicates', 'eventAction': 'Click', 'eventLabel': 'All', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fas fa-user-friends"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.SYNDICATES'"></span>
      </div>
    </div>
  </a>

  <a class="tickets" (click)="resetTicketFilter()" routerLink="/tickets"
     *ngIf="isLoggedIn && !isCashOut" [routerLinkActive]="['active']" [ngClass]="{'active': activated('/tickets')}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Tickets', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fas fa-ticket-alt"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.TICKETS'"></span>
      </div>
      <a class="gt-badge" routerLink="/tickets/list/group" [queryParams]="{show: 'invitations'}" *ngIf="isLoggedIn && !isCashOut && haveGroupTickets">{{groupTicketInvites}}</a>
    </div>
  </a>

  <a (click)="resetTicketFilter()" routerLink="/tickets"
     *ngIf="isLoggedIn && isCashOut" [routerLinkActive]="['active']"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Tickets', 'eventAction': 'Click', 'eventLabel': 'Unauth', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fas fa-ticket-alt"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.TICKETS'"></span>
      </div>
    </div>
  </a>

  <a routerLink="/tickets/unauth"
     *ngIf="enableUnauthCashout" [routerLinkActive]="['active']"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'CashOut', 'eventAction': 'Click', 'eventLabel': 'Unauth', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon fas fa-ticket-alt"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.CASHOUT'"></span>
      </div>
    </div>
  </a>

  <!-- <a routerLink="/results"
     [routerLinkActive]="['active']"
     [routerLinkActiveOptions]="{exact:false}"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Results', 'eventAction': 'Click', 'eventLabel': 'List', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon far fa-poll-h"></i>
      </div>
      <div class="mobile-nav-label">
        <span [translate]="'menu.RESULTS'"></span>
      </div>
    </div>
  </a> -->

  <a routerLink="/help"  class="help-menu-item"
     [routerLinkActive]="['active']"
     [track]="{event: 'micrositeEvent', 'eventCategory': 'Help', 'eventAction': 'Click', 'eventLabel': '', isTracking: true}">
    <div class="mobile-nav-item">
      <div class="mobile-nav-icon">
        <i class="icon far fa-question-circle"></i>
      </div>
      <div class="mobile-nav-label">
        <strong [translate]="'menu.HELP'"></strong>
      </div>
    </div>
  </a>
</nav>
