import { createAction, props } from '@ngrx/store';
import {FreeplayScope} from "../../NGRX/freeplay/freeplay.model";

export const callFreeplayList = createAction('[Freeplay] Get giveaways');

export const successFreeplayList = createAction('[Freeplay] success freeplay list',
props<{
  list: FreeplayScope.FreeplaysList
}>());

export const failFreeplayList = createAction('[Freeplay] fail freeplay list',
  props<{
    error: any
  }>());

export const addGiveaway = createAction('[Freeplay] Add new giveaway',
props<{
  giveaway: any
}>());

export const getPoolToken = createAction('[Freeplay] Get pool token',
  props<{
    id: number
  }>());

export const loadToken = createAction('[Freeplay] Load token',
  props<{
    id: number,
    token_id: string
  }>());

export const claimToken = createAction('[Freeplay] Claim token',
  props<{
    id: number,
    giveaway_id: string
  }>());

export const clearToken = createAction('[Freeplay] Clear token');

export const successToken = createAction('[Freeplay] Success token',
  props<{
    token: FreeplayScope.FreeplayToken
  }>());

export const failClaimToken = createAction('[Freeplay] Fail token',
  props<{
    error: any
  }>());
