import { MicrositeWebsocketsState } from './coreWebsockets.models';
import { createReducer, on, Action } from '@ngrx/store';
import { connectToPrivateChannel, setWebsocketEvent } from './coreWebsockets.actions';

export const initialState: MicrositeWebsocketsState = {
  currentEvent: {},
  balance: undefined
};

const reducer = createReducer(
  initialState,

  on(setWebsocketEvent, (state, action) => {

    return {
      ...state,
      currentEvent: {
        event: action.event,
        data: action.data
      }
    }
  })
);

export function micrositeWebsocketsReducer(
  state: MicrositeWebsocketsState | undefined,
  action: Action
): MicrositeWebsocketsState {
  return reducer(state, action);
}
