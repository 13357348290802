import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import * as bigIntProxy from 'big-integer';
const bigInt: any = (<any>bigIntProxy) || bigIntProxy;

@Injectable()
export class FectaService {
  private scope: any = {};

  calculateFecta(selections_string: string, num_legs: number) {
    let t0: any = new Date();
    this.createScope(selections_string, num_legs);
    this.getTicket(this.scope);
    let valid_selections_string = this.get_final_ticket(this.scope);
    let t1: any = new Date();
    return {
      selections_string: valid_selections_string,
      total_lines: this.scope.total_selections
    };
  }

  createScope(selections_string: string, num_legs: number) {
    var x1 = selections_string.split('/');
    this.scope.selections = new Array(x1.length);
    for (var i = 0; i < x1.length; i++) {
      var x2 = x1[i].split(',');
      this.scope.selections[i] = x2;
      for (var j = 0; j < x2.length; j++) {
        this.scope.selections[i][j] = Number(x2[j]);
      }
    }
    this.scope.total_selections = 0;
    this.scope.perm_selec = new Array(this.scope.selections.length);
    this.scope.selec_per_place = new Array(this.scope.selections.length);
  }

  getTicket(scope: any, leg = 0, perm_bin = bigInt(0)) {
    if (leg == scope.selections.length) {
      ++scope.total_selections;

      for (var i = 0; i < scope.perm_selec.length; ++i) {
        scope.selec_per_place[i] = bigInt(scope.selec_per_place[i]).or(bigInt(1).shiftLeft(scope.perm_selec[i] - 1));
      }
    }
    else {
      for (var i = 0; i < scope.selections[leg].length; ++i) {
        scope.perm_selec[leg] = scope.selections[leg][i];
        // Conversion not needed here
        let y = bigInt(1).shiftLeft((scope.selections[leg][i] - 1));
        let x = bigInt(perm_bin).or(y);

        if (!x.eq(bigInt(perm_bin))) {
          this.getTicket(scope, leg + 1, x);
        }
        // if ((perm_bin | 1 << (scope.selections[leg][i] - 1)) != perm_bin)
        //   this.getTicket(scope, leg + 1, perm_bin | 1 << (scope.selections[leg][i] - 1));
      }
    }
  }

  get_final_ticket(scope: any) {
    var ss = '';
    var selec = 0;
    var first_place = true, first_selec = false;

    scope.selec_per_place.forEach(function (el: any) {
      el = bigInt(el);
      if (first_place) {
        first_place = false;
      }
      else {
        ss += '/';
      }
      selec = 0;
      first_selec = true;
      while (el.greater(bigInt.zero)) {
        var sLeft = bigInt(1).shiftLeft(selec);
        if (el.and(sLeft).notEquals(0)) {
          if (first_selec) {
            first_selec = false;
          }
          else {
            ss += ',';
          }
          ss += selec + 1;
          el = el.minus(bigInt(1).shiftLeft(selec));
        }
        ++selec;
      }
    });
    return ss;
  }

  generateValidLegs(selections_string: string) {
    let valid_legs: any = {};
    let legs: any = selections_string.split('/');
    _.each(legs, function (leg, index) {
      var bins = leg.split(',');
      valid_legs[index + 1] = [];
      _.each(bins, function (bin) {
        valid_legs[index + 1].push(Number(bin));
      });
    });
    return valid_legs;
  }
}
