import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackingMiddleware } from '../../../middlewares/tracking/tracking.middleware';

@Injectable()
export class RAFService {
  private rafcode: any = {
    CODE: null,
    REF_CODE: null
  };

  private utmParameters: any = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _trackingMiddleware: TrackingMiddleware
  ) { }

  init(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      if (params['cb_ref_code']) {
        this.rafcode.REF_CODE = params['cb_ref_code'];
      }
      // if (params['utm_source'] || params['utm_medium'] || params['utm_campaign']) {
      //   this.setUtmParameters(params['utm_source'], params['utm_medium'], params['utm_campaign'])
      // }
    });
  }

  set(code: string): void {
    this.rafcode.CODE = code;
  }

  getCode(): string {
    return this.rafcode.CODE;
  }

  getReferal(): string {
    return this.rafcode.REF_CODE;
  }

  setReferal(code: string): void {
    this.rafcode.REF_CODE = code;
  }

  getUtmParameters(): any {
    return this._trackingMiddleware.getCampaign();
  }

  setUtmParameters(utm_source: any, utm_medium: any, utm_campaign: any): void {
    this.utmParameters = {
      ...(utm_source   && { utm_source: utm_source }),
      ...(utm_medium   && { utm_medium: utm_medium }),
      ...(utm_campaign && { utm_campaign: utm_campaign }),
    }
  }

  clearUtmParameters(): void {
    this.utmParameters = null
  }
}
