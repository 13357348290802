import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Config} from "@cbcore/services/config/config.service";
import {WindowService} from "@cbcore/services/utils/window/window.service";
import {AppState, selectConfiguration, selectInfo, selectMicrositeSessions} from '@cbcore/NGRX/core.state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { MicrositeSessionsState } from '@cbcore/NGRX/coreAuth/coreAuth.models';
import { takeUntil } from 'rxjs/operators';
import { callList, loadList } from '@cbcore/NGRX/syndicates/syndicates.actions';
import {loadTicketList} from "@cbcore/NGRX/tickets/tickets.actions";
import { PoolsMenuScope } from '@cbcore/NGRX/pool/pool.models';
import { selectPoolExtraModel } from '@cbcore/NGRX/pool/pool.selectors';
import {ConfigurationState} from "@cbcore/NGRX/configuration/configuration.models";
import {GroupTicketsScope} from "@cbcore/NGRX/group-tickets/group-tickets.model";
import { MenuRouteService } from './menu.service';
import {setDisplayState} from "@cbcore/NGRX/configuration/configuration.actions";


@Component({
  selector: 'component-menu',
  templateUrl: './menu.html'
})
export class MenuComponent {
  public isLoggedIn: boolean = false;
  public freeplays!: number;
  public hideSyndicatesTab!: boolean;
  public syndicatesEnabled!: boolean;
  public hideFreeplayTab!: boolean;
  public enableFreeplay!: boolean;
  public enableUnauthCashout!: boolean;
  public freePlayStandalone!: boolean;
  public displayState: any;
  public navIsFixed: boolean = false;
  public stickyHeader: boolean = false;
  public sport_code!: string;
  public _window: Window;
  public isCashOut!: boolean;
  public isAgeVerified!: boolean;
  public haveHomeLink!: boolean;
  public noContentLink!: boolean;
  public rootUrl: string;
  public helpBaseLink: string;
  public haveGroupTickets: boolean;
  public groupTicketInvites: number;
  public isOnGTicketsPage: boolean = true; // we assume it is
  public isOnSyndicatesPages: boolean = true;
  public additionalLeftNavMenu: boolean;
  public skipVerifyAge: boolean;


  public ngDestroyed$ = new Subject();
  private poolExtraModel$: Observable<PoolsMenuScope.PoolsExtraModel>;
  private micrositeSessions$: Observable<MicrositeSessionsState>;
  private displayState$: Observable<ConfigurationState>;
  gtInfo$: Observable<GroupTicketsScope.GroupTicketsInfo>;

  constructor(
    public _activatedRoute: ActivatedRoute,
    public _router: Router,
    public _config: Config,
    public _cdr: ChangeDetectorRef,
    protected windowRef: WindowService,
    public store: Store<AppState>,
    public _menuRouteService: MenuRouteService,
    @Inject(DOCUMENT) protected _document: any
  ) {
    this._window = windowRef.nativeWindow;
  }

  ngOnInit(): void {
    this.helpBaseLink = this._config.CONFIG.helpBaseLink;
    this.rootUrl = this._config.CONFIG.rootUrl || '';
    this.stickyHeader = this._config.CONFIG.stickyHeader;
    this.syndicatesEnabled = this._config.CONFIG.enableSyndicates;
    this.hideSyndicatesTab = this._config.CONFIG.hideSyndicatesTab;
    this.hideFreeplayTab = this._config.CONFIG.hideFreeplayTab;
    this.enableFreeplay = this._config.CONFIG.enableFreeplay;
    this.enableUnauthCashout = this._config.CONFIG.enableUnauthCashout;
    this.freePlayStandalone = this._config.CONFIG.freePlayStandalone;
    this.haveHomeLink = this._config.CONFIG.haveHomepage;
    this.additionalLeftNavMenu = this._config.CONFIG.additionalLeftNavMenu;
    this.skipVerifyAge = this._config.CONFIG.skipVerifyAge;

    this.isCashOut = /unauth/.test(this._router.url);

    this.displayState$ = this.store.select(selectConfiguration);
    this.displayState$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(configuration => {
        this.displayState = configuration.displayState;
        this._cdr.detectChanges();
      });

    this.micrositeSessions$ = this.store.select(selectMicrositeSessions);
    this.micrositeSessions$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(micrositeSessions => {
        this.isLoggedIn = !!micrositeSessions.ext_id;
        this.isAgeVerified = micrositeSessions.age_verified || this.skipVerifyAge;
        this.freeplays = micrositeSessions.freeplays;
        this._cdr.detectChanges();
      });

    this._router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        this.isOnGTicketsPage = event.url.indexOf('tickets') > 0 && event.url.indexOf('group') > 0 && event.url.indexOf('pools') > 0;
        this.isOnSyndicatesPages = event.url.indexOf('syndicates/create') > 0;
        this._cdr.detectChanges();
      }
    });

    this.poolExtraModel$ = this.store.select(selectPoolExtraModel);
    this.poolExtraModel$.pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe(poolExtraModel => {
      if (poolExtraModel) {
        this.noContentLink = poolExtraModel.ALL === 0;
      }
      this._cdr.detectChanges();
    });

    this.gtInfo$ = this.store.select(selectInfo);
    this.gtInfo$.pipe(
      takeUntil(this.ngDestroyed$)
    ).subscribe(info => {
      if (info) {
        this.groupTicketInvites = info.invitation_count;
        this.haveGroupTickets = this._config.CONFIG.haveGroupedTickets && (this.groupTicketInvites > 0);
        this._cdr.detectChanges();
      }
    })
  }

  activated(route: string): boolean {
    return this._menuRouteService.activated(route);
  }

  // iaa deprecate now, do the route
  resetSyndicateList(): void {
    this.store.dispatch(loadList({}));
    // this._syndicateListMiddleware.clearFilters();
    // this._syndicateListMiddleware.clearSyndicateList();
    // this._syndicateListMiddleware.initSyndicateList();
  }

  resetTicketFilter(): void {
    this.store.dispatch(loadTicketList({}));
    // this._ticketListMiddleware.clearFilters();
    // this.groupTicketListMiddleware.clearFilters();
  }

  setDisplayState(type: string) {
    this.store.dispatch(setDisplayState({displayState: {view: type}}));
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
  }
}
