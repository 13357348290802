<div id="merchant" *ngIf="ready">
  <div class="nav-symbol">
<!--    <header-component></header-component>-->
    <component-menu class="mobile-menu-wrapper flex lg:hidden"></component-menu>
  </div>
<!--  <lib-cb-video-player></lib-cb-video-player>-->
  <component-notification></component-notification>
  <div class="page">
    <div class="page-content">
      <div class="desktop-fixed-nav">
        <desktop-fixed-menu></desktop-fixed-menu>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


<!--<div class="nav-symbol" *ngIf="ready">-->
<!--  <component-menu class="mobile-menu-wrapper flex lg:hidden"></component-menu>-->
<!--</div>-->
<!--<div class="flex flex-col" *ngIf="ready">-->
<!--  <div class="page">-->
<!--    <div class="page-content">-->
<!--      <div class="desktop-fixed-nav">-->
<!--        <desktop-fixed-menu></desktop-fixed-menu>-->
<!--      </div>-->
<!--      <router-outlet></router-outlet>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<lib-cb-video-player></lib-cb-video-player>-->

<!--<component-notification></component-notification>-->
<cb-modal #createProfile [autostart]="false" identifier="createProfile" [closable]="false" [dismissable]="false" [escapable]="false">
  <syndicate-profile-create-component></syndicate-profile-create-component>
</cb-modal>

