import { Injectable } from '@angular/core';
import { WindowService } from './../window/window.service';
import { CordovaService } from './../cordova/cordova.service';
import { LinkService } from '../links/links.service';

@Injectable()

export class WhatsappService {
  public _window: any;

  constructor(private windowRef: WindowService, private cordovaService: CordovaService, public _linkService: LinkService) {
    this._window = windowRef.nativeWindow;
  }

  shareLink(shareData: any): void {
    if (this.cordovaService.isApp()) {
      const message = {
        action: 'browse',
        url: shareData.whatsappUrl,
        target: '_system',
        options: 'locations=yes,toolbar=no,clearcache=yes'
      };
      this._window.parent.postMessage(message, '*');
    } else {
      this._linkService.openLink(shareData.whatsappUrl);
    }
  }
}
