import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../core.state';

// import { selectMicrositeTrackingState } from '../../NGRX/core.state';
export const selectMicrositeTrackingState = createFeatureSelector<
  AppState, 
  MicrositeTrackingState
>('micrositeTracking');

import { MicrositeTrackingState } from './coreTracking.models';

export const selectTracking = 
// undefined;
createSelector(
  selectMicrositeTrackingState,
  (state: MicrositeTrackingState) => state
);
