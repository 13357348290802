import {Injectable, Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()

@Pipe({name: 'reverse', pure: false})

export class Reverse implements PipeTransform {
  transform(input:any): any {
    return _.reverse(input);
  }
}
