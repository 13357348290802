import { Config } from '../services/config/config.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class CampaignsClient {
  private endpoint: string;

  constructor(private http: HttpClient, private _config: Config) {
    this.endpoint = _config.CONFIG.apiUrl + '/campaigns';
  }

  get(): Observable<any> {
    return this.http.get(this.endpoint)
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  optIn(campaign_id: any): Observable<any> {
    const params = new HttpParams()
      .set('campaign_id', campaign_id)

    return this.http.post(this.endpoint + `/${campaign_id}` + '/opt_in', {}, { params })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }

  optInBonusCode(bonus_code: any): Observable<any> {
    return this.http.post(this.endpoint + `/bonus_code`, { bonus_code: bonus_code })
      .pipe(
        map(data => data),
        catchError(err => throwError(err))
      );
  }
}
