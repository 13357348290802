import { LANGUAGE_MAPPING } from '../translation/languages.constant';
// tslint:disable: max-line-length
export var DEFAULT_CONFIG = {
  apiUrl: '/api',
  streamUrl: '/streams',
  imagesUrl: '//images.colossusbets.com',
  mvpPointsUrl: '//images.colossusbets.com/config',
  silksUrl: '//s3.eu-west-2.amazonaws.com/',
  silkBucket: 'pa-feed',
  localesUrl: '//locales.colossusbets.com/json',
  websocketsUrl: 'ws-eu.pusher.com', // format: 'ws-{CLUSTER}.pusher.com'
  websocketsKey: '73c617c8039669312058', // CB stg default key
  websocketCluster: 'eu', // default cluster - based on MS location
  ldcClientKey: null,
  unitsRoundedToInteger: false,
  abTestingActive: false,
  abTestingFeatures: [],
  walletUrl: null,
  pcaPredictUrl: null,
  pcaPredictKey: null,
  micrositeType: 'ALL',
  allowLogin: false,
  allowRegister: false,
  allowBalance: false,
  allowResizeEvents: false,
  allowRouteChangedMessage: false,
  allowCashOuts: true,
  ravenUrl: null,
  ravenRelease: null,
  ravenEnvironment: 'web',
  defaultLanguage: 'en',
  languageThroughParams: false,
  supportedLanguages: [
    'en', 'zh-CN', 'zh-TW', 'ja', 'ko-KR', 'id-ID', 'th-TH', 'vi-VN',
    'fr', 'pt', 'es', 'cs', 'ru', 'tr', 'hy', 'de', 'fi', 'hu', 'nb',
    'pl', 'sv', 'ge', 'pt-BR', 'en-NL', 'nl', 'fr-BE', 'el', 'uk-UA',
    'es-CO', 'es-PE', 'et', 'am'
  ],
  scopes: ['locales', 'competitors', 'syndicates', 'sharing'],
  languageMapping: LANGUAGE_MAPPING,
  poolTypesOrder: {
    'FOOTBALL.MATCH': 10,
    'FOOTBALL.HDA': 20,
    'FOOTBALL.CORRECT_SCORE': 30,
    'FOOTBALL.OVER_UNDER': 40,
    'FOOTBALL.BTTS': 50,
    'FOOTBALL.ASIAN_HANDICAP': 60,
    'NFL.POINT_MARGIN': 70,
    'NBA.POINT_MARGIN': 80,
    'TENNIS.CORRECT_SCORE_3': 90,
    'TENNIS.CORRECT_SCORE_5': 100,
    'TENNIS.H2H': 110,
    'WRC.RACE_ORDER': 120,
    'WRC.RACE_WIN': 130,
    'HORSE_RACING.RACE_PLACE': 140,
    'HORSE_RACING.RACE_WIN': 150,
    'HORSE_RACING.RACE_ORDER': 160,
    'GREYHOUNDS.RACE_PLACE': 170,
    'GREYHOUNDS.RACE_WIN': 180,
    'GREYHOUNDS.RACE_ORDER': 190
  },
  overridesPoolTypesOrder: {},
  rootUrl: '',
  templateServiceUrl: null,
  affiliatesUrl: null,
  twitterVia: '@ColossusBets',
  haveTwitterWidgets: false,
  sharedTicketTitle: 'Colossus Bets',
  shareSyndicateDynamicText: false,
  shareSoloText: 'I\'m shooting for [prize] in [via] [pool_type] [pool_name] pool. Click below to get your ticket now',
  shareSyndicateText: [
    'Join my [via] Syndicate to play for a part of [prize]. Click here to join me',
    'Click below to join my [via] Syndicate to play for a part of [prize]. ',
    'My [via] Syndicate looks good to hit, part of the [prize] pool, join me here',
    'Join our [via] Syndicate we are playing for [prize]! Get involved! ',
    'Do you want to win part of [prize]? Join my [via] Syndicate'
  ],
  syndicateMinCostMultiplier: 1,
  automaticFiltering: true,
  widgetImages: [
    { img_en: '/assets/images/widget/colossus/placeholder.jpg', path: null }
  ],
  widgetBanners: {
    SPORTS: [{ default: { image: '/assets/images/widget/colossus/placeholder.jpg', path: null, external: false } }],
    RACING: [{ default: { image: '/assets/images/widget/colossus/racing.jpg', path: null, external: false } }],
    FANTASY: [{ default: { image: '/assets/images/widget/colossus/placeholder.jpg', path: null, external: false } }]
  },
  widgetRegion: 'DEFAULT',
  dynamicWidgets: true,
  enableQuickTicketWidget: false,
  rulesDocs: {
    default: '2PACX-1vTzNI-4_iOjAEGKr-ruA5n9BwQxKeS7eq34Ru2iYU9vs3JYT6-2KHSD9JTokYaSwJpAa2jcDMhX3vOW',
    en: '2PACX-1vTzNI-4_iOjAEGKr-ruA5n9BwQxKeS7eq34Ru2iYU9vs3JYT6-2KHSD9JTokYaSwJpAa2jcDMhX3vOW'
  },
  faqDocs: {
    default: '2PACX-1vR_AxVHiwjliWQ2RO-501cK_RXswmQI2jurCFGmSMKYcOn_oVrCLyLHgKwTaaAF6ZkWb7_WeYilqg7V',
    cs: '2PACX-1vTgGP5r9fI9qxh5vYqijRO-DBL2vTZBDqx8A0JV3f_WuTOTFm3DTskZtc1DoLV1zYfnZojwCoVhe2jv',
    de: '2PACX-1vRqGS10KJEt_Eqn7rBVwV-fwodAWy2VI0EVISTrIEc9-3hq5RAfU9-8LKrhLmgGwUwftpMOu_c-nCm-',
    el: '2PACX-1vQfDlz3gz8-Yluy5TmWTLxK6tEJc_N1xo1xsKHu61dA3DgGvx1g4wuXu1SDAvqYe70YuQU60EVlV4XA',
    en: '2PACX-1vR5YJWLIrr0Wrk7q6zceb-5DdveuIDnmmRKAqoFX8DhPxXrVDuKGA49P0KCiUxoIV0pVaw4SLxeJg3F',
    es_CO: '2PACX-1vRHXkSsbl0aP7KHZaL3MYupez8CX0fLc199LpeuPfV1s0lWCAprm7iEwAim0rlIRdW31e5YNpbAltmD',
    es_PE: '2PACX-1vStpuzkW9_oNncNNM9I-FG2R5N-rh77H5bGsOuDgHTBsL3YKuhkf3fldVXUzP2mCbJ5pgdj2dujyw69',
    es: '2PACX-1vQtP-eFFumxaQ1T_KKiwozW0qdk6Y74zITANmSABmzGJxqj2gRrjGErFH4xoMNLDaFPz2pOOiJ7_NbM',
    fi: '2PACX-1vSkWLdVBP7c8ofBMSnNYDS8vGUsTadRAZdB_74YBT6kYAlXz33rYRwYDb5VcI_gotyjsc2Be3GPaRTd',
    fr: '2PACX-1vRmLlzGDwFaVlhp8V8TwdsE36PBCGvar2B-cTOGFKJ9sWI_JDMM2XzNUrrUkfC8Q1wBzOUnMZji0Qwi',
    ge: '2PACX-1vTpP5GTEm79RzIMbjQalqB6TXg4TQlmLCh3RbwGsJ0PMBAVFEzTbdg21vYcHnJLdr3F7JeQOQQl6Yfs',
    hu: '2PACX-1vRGYkg3LekeqpNMrAuXzcmSQE5nYL0BXynMYwTCYMZ0M3cMEXZPEjvnwmI_cod4si0xpgX7ATnIig7_',
    hy: '2PACX-1vSGfGbP4QaVlOGu1K_ERFpuMUfhjtuits3t-rXZ82e3Xd1T2kWfgw3P6bbYfrW8jurSxi3IQWfFL2Ur',
    id_ID: '2PACX-1vSyKrTS9gDxZJdGUsjJPCRRF5DvSBHo9_06gDlTNTrR15giZFn71AN3JU36dRDBOgco2X4bLWU0e0WZ',
    ja: '2PACX-1vT7eTD4XUODzm221-nDJoYcYiwQApiWql7UFc8Rdup1aGZ7kmZDpvEDNDwHmxIoMVBZmtLKk37XGtyE',
    ko_KR: '2PACX-1vTZwMGmsc-DEMyF8O0535m_29qX_nAEk4gX5LaOSqpKC7mc6ac__WXvSn8scSHCEb61Tg1kxFDNpHFI',
    nb: '2PACX-1vT8n7LIdyESLzrmeePUMvE4ZM3FbZB0z2VP4tQHl5UMhTJHXeg67lyuOadmGNIY78cUicz-Rc5LgIQm',
    nl: '2PACX-1vT4G_7Xaxr0tCCxp9w_7Cv11MOhQAIfo9b1DJxdE5ki52vS4i4cCFh4hq_GPSH5qVrrf5Dsfg_85DVS',
    pl: '2PACX-1vSR6XhbAk4iwMMlQaI5BU9bvXxytnbDgMqMXO3wwPEb8h1_zThhSlxQjqq3jZoGYs6A9W6-SAl8JakR',
    pt_BR: '2PACX-1vT8hwFpvXw-kqGdN9OZEVMS9ASjEejIDJIsCr3lHIN3VyDzfRPmohHCL7LQ-zobNwlI6L4J-jg2GKAj',
    pt: '2PACX-1vRd2ocZ2y0H6YQE77HAJa_V-oHcxDK4nXC5znqJis_yjoQFr56NaGiTKnAY_pG4DOmJwGQN9xPC5jd1',
    ru: '2PACX-1vSB_F4P7qy9trCgVEEakc5bkdM_kP2MycXFv9RzRd-DNaFhKR6P85-Texa6HR9HAfZnZ6595Wianruf',
    sv: '2PACX-1vQzTjkJNK6YKz8Gbwt4umGzI9q1e7mh1pgjw9BxjWhHLEvFyxmVgvObepAOAADFEjxOPm5TZE3IxE2B',
    th_TH: '2PACX-1vSc6eWfQgUcRlU0lt_6SqXBH3cppY8F2TotWElM7cwYMPzFLhHUJZ20AnK9F3tiF0m-vvJbypqTu8ln',
    tr: '2PACX-1vRcQLlZzhTFLCKNmSugVbqK8KarkLRROf13fe49CScmCZCkzsNNNeK6TQh1DySynCpczH2Upt7eH_wb',
    uk_UA: '2PACX-1vQAobBEhuFiDL3BMocVgCWJtEt0hp8j7areLyL3izsBGTNyUDUiUJJMliNKQcDSEaUOarNmImZs4O0_',
    vi_VN: '2PACX-1vTZC89q13zFTcjMIee9mLbxMCxHPsb37rld1j61gHS1KFNEMgZg8NVfwLoLzFekVRrdtNzuNWrOgZRi',
    zh_CN: '2PACX-1vQYDV6F65h8il2Db0HAT5SJxW5xKj3p1nDh1HjmVIJr9R5tpefVzRejS4SEy53Uzhev71UsYtQTo5pX',
    zh_TW: '2PACX-1vSlqZCfrFnkM4P2W9_P5rm70jurpRkfoQLjJgTCzDRsLGTqhDyHdD4BEvgTQI6hSgiBkHopK4ZOpXAu'
  },
  enableSyndicates: false,
  enableSyndicatePreview: true,
  hideSyndicatesTab: false,
  enableSyndicatesLeaderboard: false,
  syndicateJoinTopSpacer: 0,
  enableFreeplay: false,
  freeplayOnlyMicrosite: false,
  hideFreeplayTab: false,
  enableShare: true,
  socialNetworks: {
    twitter: true,
    facebook: true,
    whatsapp: true
  },
  crossSellStakeTier: null,
  crossSellCustomPath: null,
  enableUnauthCashout: false,
  enableSummaryCashout: false,
  enableBookaBet: false,
  freePlayStandalone: false,
  freePlayStandaloneLogo: '/assets/images/standalone/colossus/logo.png',
  freePlayStandaloneFooter: '/assets/images/standalone/colossus/racing_july',
  racingSortingOrder: 0,
  leftHandMenu: false,
  allowLinkCopy: false,
  enableMultipleStakes: false,
  allowWidgetBannerTerms: false,
  allowRightSideWidget: true,
  fallbackSyndicateProfileImage: '/images/useravatars/default.jpg',
  showPoolBestDividend: false,
  enableStats: true,
  numberOfSharingTextVersions: 1,
  haveSyndicateScroll: true,
  haveManagerTwitter: true,
  requireUnauthSecurityCode: true,
  shareListEnabled: false,
  shareListTextDynamic: false,
  shareListTextVersions: 0,
  shareRafEnabled: false,
  shareRafTextVersions: 0,
  showTicketRefSyndicates: false,
  showWidgetSyndicateList: true,
  haveSeoData: false,
  seoData: {
    title_sports: 'Sports Pool betting | Sports Betting Reinvented | Cash Out | Colossus',
    desc_sports: 'Join Colossus sports pools! Join Syndicates, create your own or play solo. Choose from a wide range of pools, including football, tennis, NFL and NBA.',
    title_racing: ' Racing Pool betting | Horse Racing Betting | Colossus Place 6 ',
    desc_racing: 'Join Colossus racing pools! Bet on your favourite horses and greyhounds online with our Win, Place and Finishing Order pools.',
    title_syndicates: 'Betting Syndicates | UK\'s Best Football and Racing Syndicates | Colossus',
    desc_syndicates: 'Bet with Colossus Syndicates! Choose a betting Syndicate into any of our sports and racing pools. Join a community where #WeWinTogether.',
    title_freeplay: 'Free-to-play pool betting coupon | Get your FreePlay tokens | Colossus',
    desc_freeplay: 'Check for available FreePlay tokens here! Not registered? Join us and take advantage of our Refer a Friend promotion to earn FreePlay.',
    title_results: 'Check pool betting results | Sports and Racing pools | Colossus',
    desc_results: 'Check out our pool results and prize breakdowns for all recent and live Colossus pools.',
    title_default: 'Colossus Bets | Pool and Syndicate betting | Cash Out | Up to £100 welcome bonus',
    desc_default: 'Welcome to Colossus - the world\'s biggest sports and racing jackpots. Join Syndicates, create your own or play solo. Register for up to £100 welcome bonus.'
  },
  enableVideoStreaming: false,
  videoStreamingSport: ['GREYHOUNDS'],
  haveManagerRebates: false,
  allowCashoutFromSpecialRoute: false,
  stickyBetslip: false,
  haveVoidToSolo: true,
  haveHomepage: false,
  helpBaseLink: 'https://www.colossusbets.com/help',
  haveMatchPoolOverlay: true,
  homepageHelpGlobal: '?utm_source=Colossus&utm_medium=homepage&utm_campaign=goto',
  homepageLoggedOutLink: '/category/promotions/new-player-bonus/?utm_source=Colossus&utm_medium=homepage&utm_campaign=npb',
  homepageLoggedInLink: '/what-is-the-freeplay-loyalty-scheme/?utm_source=Colossus&utm_medium=homepage&utm_campaign=loyalty',
  homepageBlogLink: 'https://www.colossusbets.com/blog/?utm_source=Colossushome&utm_medium=header',
  DHBDGReadMore: 'https://help.colossusbets.com/hc/en-us/articles/360010585051-What-is-Best-Dividend-Guaranteed-',
  DHNPBLearnMore: 'https://help.colossusbets.com/hc/en-us/sections/360000596011-New-player-bonus-',
  dhFeaturedDefaultImage: 'feature_pool_generic.png',
  dhFeaturedStaticImage: 'feature_pool_right_image_fixed.jpg',
  blackListedPageviewPaths: [],
  trackVirtualPageviews: false,
  trackingUrl: 'https://tracking.staging-colossusbets.com/',
  merchantTrackingId: '1',
  enableInternalTracking: false,
  enableGA: false,
  cbLicenceNumber: '000-031514-R-314905-016',
  syndicatesSingposting: false,
  isCommentaryActive: true,
  commentaryRolesAvatars: {
    admin: '/assets/images/wallet/icon.png'
  },
  modalLaunchCustomSettings: {
    cordova: true,
    iframe: false
  },
  haveGroupedTickets: true,
  additionalLeftNavMenu: true,
  fallbackSecureWS: false,
  fallbackSecureWSnonCB: false,
  skipVerifyAge: true,
  globalShowPrizes: true,
  freeplayTiebreaker: false
};
