import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable()

@Pipe({ name: 'orderByNumber', pure: false })

export class OrderByNumber implements PipeTransform {
  // array - array
  // config - parameter
  // order - asc / desc

  transform(array: any, params: any, order?: any): any {

    array = _.map(array, function(item) {
      _.each(params, function(param) {
        item[param] = Number(item[param]);
      })
      return item;
    });
    return _.orderBy(array, params, order);
  }
}
